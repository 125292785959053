import { acronymsToUpperCase, camelCaseToSpaces } from 'common/helpers/stringsConverter';
import { AuditTrailAction } from 'graphql/__generated__/types';
import { get, set } from 'lodash-es';
import moment from 'moment';
import { getReleaseIdentifierName } from 'pages/TradingPartnersPage/components/forms/GeneralSettingsTab/GeneralSettingsTab';
import {
  AuditTrailVersion,
  RetailerDeliveryDestinationForAudit,
  RetailerProductRddAssortment
} from '../ChangeHistory';
import { BusinessUnitsForAuditQuery } from '../gql/__generated__/businessUnitsForAudit.query';
import { DistributionCentersForShipToAuditQuery } from '../gql/__generated__/distributionCentersForShipToAudit.query';
import {
  AuditTrailDiffItemFragmentFragment,
  AuditTrailDiffItemFragment_AuditTrailAddition_Fragment,
  AuditTrailDiffItemFragment_AuditTrailChange_Fragment,
  AuditTrailDiffItemFragment_AuditTrailRemoval_Fragment,
  AuditTrailValueFragmentFragment,
  AuditTrailValueFragment_AuditTrailListOfPrimitives_Fragment,
  AuditTrailValueFragment_AuditTrailPrimitive_Fragment
} from '../gql/__generated__/rddAuditVersions.query';
import { RetailerChannelsForShipToAuditQuery } from '../gql/__generated__/retailerChannelsForShipToAudit.query';
import { RetailerDeliveryDestinationsForAssortmentAuditQuery } from '../gql/__generated__/retailerDeliveryDestinationsForAssortmentAudit.query';
import { RetailersForRetailerChannelAuditQuery } from '../gql/__generated__/retailersForRetailerChannelAudit.query';
import { TradingPartnersForShipToAuditQuery } from '../gql/__generated__/tradingPartnersForShipToAudit.query';
import { ChannelAdvisorProfilesQuery } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { getAssortmentEventName, prepareAssortmentAuditVersions } from './assortmentHelper';
import { getShipToEventName, prepareShipToAuditVersions } from './shipToHelper';
import { prepareUserAuditVersions } from './usersHelper';
import {
  getTradingPartnerEventName,
  tpGeneralSettingConfigs,
  tpIntegrationConfigs,
  tpIntegrationSubconfigs
} from './tradingPartnerHelper';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { FacilitiesForDistributionCenterAuditQuery } from '../gql/__generated__/facilitiesForDistributionCenterAudit.query';
import { capitalize } from 'lodash-es';
import { RepackChangeLogsQuery } from '../gql/__generated__/repackChangeLogs.query';
import { ReactNode } from 'react';
import { ChangeTooltip } from 'pages/RepackPlanning/components/ChangeTooltip/ChangeTooltip';
import React from 'react';
import chatIcon from 'assets/icons/chat.svg';
import { compareDates } from 'common/helpers/date';

export interface ChangeHistoryData {
  key: string;
  eventName: string;
  subpageName: string;
  changes: ChangeHistoryItem[];
  actor: string;
  updatedAt: string;
  detailsTooltip?: ReactNode;
  meta: {
    tradingPartnerId?: string;
    tradingPartnerExternalId?: string;
    tradingPartnerName?: string;
  };
}

export type DistributionCenterForShipToAudit = InferNodeType<
  DistributionCentersForShipToAuditQuery,
  'distributionCenters'
>;

export type FacilityForDistributionCenterAudit = InferNodeType<
  FacilitiesForDistributionCenterAuditQuery,
  'distributionCenterFacilities'
>;

export type TradingPartnerForAudit = InferNodeType<
  TradingPartnersForShipToAuditQuery,
  'tradingPartners'
>;

type RetailerForRetailerChannelAudit = InferNodeType<
  RetailersForRetailerChannelAuditQuery,
  'vendors'
>;

export type RetailerDeliveryDestinationForAssortmentAudit = InferNodeType<
  RetailerDeliveryDestinationsForAssortmentAuditQuery,
  'retailerDeliveryDestinations'
>;

type RetailerChannelForShipToAudit = InferNodeType<
  RetailerChannelsForShipToAuditQuery,
  'vendorMarkets'
>;

type BusinessUnitForAudit = InferNodeType<BusinessUnitsForAuditQuery, 'businessUnits'>;

type ProfileIdsForAudit = InferNodeType<ChannelAdvisorProfilesQuery, 'channelAdvisorProfiles'>;

interface ChangeHistoryDiffItem {
  fieldTitle: string;
  fieldName: string;
  path: string;
  previousValue: string | string[] | undefined | null;
  newValue: string | string[] | undefined | null;
}

interface ChangeHistoryValue {
  fieldTitle: string;
  fieldName: string;
  path: string;
  value: string | string[] | null | undefined;
}

export type ChangeHistoryItem = ChangeHistoryDiffItem | ChangeHistoryValue;

export function isChangeHistoryValue(item: ChangeHistoryItem): item is ChangeHistoryValue {
  return (item as ChangeHistoryValue).value !== undefined;
}

export type ChangeHistoryEntityType =
  | 'Trading Partner'
  | 'Ship-To'
  | 'Retailer'
  | 'Retailer Channel'
  | 'Distribution Center'
  | 'Assortment'
  | 'New Assortment'
  | 'User'
  | 'Repack Planning'
  | 'unknown-use-for-mocks-only'; // For mocks only

const dcIntegrationConfigs = ['sftpConfig', 's3Config'];

const getPrimitiveValue = (valueItem: AuditTrailValueFragmentFragment) => {
  const typeName = valueItem.__typename;
  switch (typeName) {
    case 'AuditTrailPrimitive':
      return (valueItem as AuditTrailValueFragment_AuditTrailPrimitive_Fragment).value;
    case 'AuditTrailListOfPrimitives':
      return (valueItem as AuditTrailValueFragment_AuditTrailListOfPrimitives_Fragment).values;
    default:
      return '';
  }
};

export const getDefaultFieldTitles = (value: string) => {
  switch (value) {
    case 'UPC':
      return 'UPC';
    case 'EXTERNAL_ID':
      return 'External ID';
    case 'SAP_MATERIAL_ID':
      return 'SAP Material ID';
    case 'ORACLE_INVEN_ID':
      return 'Oracle Inventory ID';
    case 'DISTRIBUTION_CENTER_IDS':
      return 'Distribution Centers';
    case 'MOQ_UNIT_OF_MEASURE':
      return 'MOQ Unit Of Measure';
    case 'MOQ_MINIMUM':
      return 'MOQ Minimum';
    case 'GTIN14':
      return value;
    default:
      return value
        .split('_')
        .map((v) => v.slice(0, 1).toUpperCase() + v.slice(1).toLowerCase())
        .join(' ');
  }
};

export const getValues = (
  diffItem: AuditTrailDiffItemFragmentFragment
): {
  previousValue: string | string[] | undefined | null;
  newValue: string | string[] | undefined | null;
} => {
  const typeName = diffItem.__typename;
  switch (typeName) {
    case 'AuditTrailAddition':
      return {
        previousValue: undefined,
        newValue: getPrimitiveValue(
          (diffItem as AuditTrailDiffItemFragment_AuditTrailAddition_Fragment).value
        )
      };
    case 'AuditTrailChange':
      return {
        previousValue: getPrimitiveValue(
          (diffItem as AuditTrailDiffItemFragment_AuditTrailChange_Fragment).previousValue
        ),
        newValue: getPrimitiveValue(
          (diffItem as AuditTrailDiffItemFragment_AuditTrailChange_Fragment).newValue
        )
      };
    case 'AuditTrailRemoval':
      return {
        previousValue: getPrimitiveValue(
          (diffItem as AuditTrailDiffItemFragment_AuditTrailRemoval_Fragment).value
        ),
        newValue: undefined
      };

    default:
      return {
        previousValue: undefined,
        newValue: undefined
      };
  }
};

export const fieldNameToString = (
  fieldName: string,
  type: ChangeHistoryEntityType,
  path: string
) => {
  if (fieldName === 'deletedAt') return 'Deactivated At';

  if (type === 'Assortment' || type === 'New Assortment') {
    // todo: use config names
    if (fieldName === 'active') return 'Status';
    if (fieldName === 'oracleInvenId') return 'Oracle Inventory ID';
    if (fieldName === 'layersPerPalletCount') return 'Layers Per Pallet';
    if (fieldName === 'casesPerLayerCount') return 'Cases Per Layer';
    if (fieldName === 'palletCount') return 'Cases Per Pallet';
    if (fieldName === 'substitutionUpcs') return 'UPC reordered';
    if (fieldName.startsWith('upc_')) {
      const parts = fieldName.split('_');
      return acronymsToUpperCase(camelCaseToSpaces(parts.join(' ')));
    }
  }
  if (type === 'Trading Partner') {
    if (fieldName === 'edi856Config') return 'EDI message config - ASN (856)';
    if (fieldName === 'edi810Config') return 'EDI message config - Invoice (810)';
    if (fieldName === 'edi855Config')
      return 'EDI message config - Purchase Order Acknowledgements (855)';
    if (fieldName === 'edi850Config') return 'EDI message config - Purchase Order (850)';
    if (fieldName === 'match850Sequence') {
      if (path.includes('edi855Config')) return 'Match PO101 sequence to original 850';
      if (path.includes('edi856Config')) return 'Match LIN01 Sequence to original 850';
      if (path.includes('edi810Config')) return 'Match IT101 Sequence to original 850';
    }
    if (fieldName === 'ignoreAdditionalItems') {
      if (path.includes('edi855Config')) return 'PO1 - Additional item(s) added';
      if (path.includes('edi856Config')) return 'LIN - Additional item(s) added';
      if (path.includes('edi810Config')) return 'IT1 - Additional Item(s) added';
    }
    if (fieldName === 'n1Remittance') return 'N1 Remittance: Recipient Name';
    if (fieldName === 'n3Remittance') return 'N3 Remittance: Address';
    if (fieldName === 'n4Remittance') return 'N4 Remittance: City/State/Zip/Country';
    if (fieldName === 'passThroughUpcToBu') return 'Pass UPC Through To BU';
    if (fieldName === 'shipToPath') return 'Ship-To/Supplier info segment';
    if (fieldName === 'gsLocalId') return 'GS Local Code';
    if (fieldName === 'gsRemoteId') return 'GS Remote Code';
    if (fieldName === 'isaInterchangeControlVersion')
      return 'ISA Interchange Control Version Number';
    if (fieldName === 'mtx02UnavailableItem') return 'MTX02 Item not available';
    if (fieldName === 'mtx02InvalidItem') return 'MTX02 Item invalid/rejected';
    if (fieldName === 'value') return 'Input';
  }

  if (type === 'Retailer Channel') {
    if (fieldName === 'parentId') return 'Parent Retailer Channel';
  }

  if (type === 'Distribution Center') {
    if (fieldName === 'distributionCenterFacilityId') return 'Facility';
  }

  if (fieldName === 'addressCity') return 'City';
  if (fieldName === 'addressState') return 'State';
  if (fieldName === 'addressZip') return 'Zip';
  if (fieldName === 'addressCountryCode') return 'Country Code';
  if (fieldName === 'duns') return 'DUNS';
  if (fieldName === 'sapCustomerId') return 'SAP Ship-To Number';
  if (fieldName === 'cofId') return 'Oracle COF Number';
  if (fieldName === 'soldToSapCustomerId') return 'SAP Sold To Number';
  if (fieldName === 'vendorMarketId') return 'Retailer Channel';
  if (fieldName === 'vendorId') return 'Retailer';
  if (fieldName === 'active') return 'Status';
  if (fieldName === 'entirelySet') return 'Active';
  if (fieldName === 'tradingPartnerRetailerDeliveryDestinations') return 'Ship-Tos';
  return acronymsToUpperCase(camelCaseToSpaces(fieldName));
};

const getAuditFieldName = (fieldPath: string, type: ChangeHistoryEntityType, path: string) =>
  fieldNameToString(fieldPath.replace('.', ''), type, path);

const getAuditFieldValue = (
  fieldPath: string,
  value: string | string[] | null | undefined,
  additionalData: AdditionalAuditData,
  path: string
) => {
  if (!value) return '';
  switch (fieldPath) {
    case 'profileId':
      return additionalData.profileIds
        ? additionalData.profileIds.find((profile) => profile.profileId === Number(value))
            ?.accountName
        : '';
    case 'dayOfWeek':
      return additionalData.daysOfWeek ? additionalData.daysOfWeek[Number(value) - 1] : '';
    case 'distributionCenterFacilityId':
      return (
        (additionalData.distributionCenterFacilities || []).find(
          (dcFacility) => dcFacility.id === value
        )?.name || value
      );
    case 'vendorId':
      return (additionalData.retailers || []).find((retailer) => retailer.id === value)?.name || '';
    case 'vendorMarketId':
      return (additionalData.retailerChannels || []).find((rc) => rc.id === value)?.name || '';
    case 'active':
      return value === 'true' ? 'Active' : 'Inactive';
    case 'tradingPartnerId':
      return (additionalData.tradingPartners || []).find((tp) => tp.id === value)?.name || '';
    case 'parentId':
      return (additionalData.retailerChannels || []).find((rc) => rc.id === value)?.name || '';
    case 'tradingPartnerRetailerDeliveryDestinations':
      const rddIds = typeof value === 'string' ? [value] : value;
      return rddIds.map(
        (id) =>
          (additionalData.retailerDeliveryDestinations || []).find(
            (rdd) => rdd.id === id.split('::')[1]
          )?.name || ''
      );
    case 'retailerDeliveryDestinations':
    case 'forShipTos':
    case 'forVendorFlex':
      const ids = typeof value === 'string' ? [value] : value;
      return ids.map(
        (id) =>
          (additionalData.retailerDeliveryDestinations || []).find((rdd) => rdd.id === id)?.name ||
          ''
      );
    case 'deletedAt':
      return moment(`${value}Z`).format('MMM D YYYY, h:mm a');
    case 'addTradingPartners':
    case 'addDistributionCenters':
    case 'removeTradingPartners':
    case 'removeDistributionCenters':
    case 'forDistributionCenters':
      return typeof value === 'string' ? [value] : value;
    case 'shipToReleaseIdentifier':
    case 'requiredIds':
      return typeof value === 'string'
        ? getReleaseIdentifierName(value.toUpperCase())
        : (value || [])
            .map((identifier) => getReleaseIdentifierName(identifier.toUpperCase()))
            .join(', ');
    case 'businessUnitId':
      return (additionalData.businessUnits || []).find((bu) => bu.id === value)?.name || '';
    case 'ignoreAdditionalItems':
      return value === 'true' ? 'Ignore' : 'Custom';
    case 'isaInterchangeUsageIndicator':
      return value === 'T' ? 'TESTING' : value === 'P' ? 'PRODUCTION' : value;
    case 'position':
      if (path.includes('edi855Config')) return `PO10${value}`;
      if (path.includes('edi856Config')) return `LIN0${value}`;
      if (path.includes('edi810Config')) return `IT10${value}`;
      return value;
    case 'distributionCenterId':
      const dc = (additionalData.distributionCenters || []).find((rc) => rc.id === value);
      return dc ? `${dc.name} (${dc.code})` : '';
    case 'catalogItemType':
    case 'releaseAcknowledgement':
    case 'releaseExchangeReturn':
    case 'releaseShipment':
      return typeof value === 'string' ? value.toUpperCase() : value.join(', ');
    case 'productType':
      return capitalize(typeof value === 'string' ? value : value.join(', '));
    default:
      return typeof value === 'string' ? value : value.join(', ');
  }
};

const isExcludedRecord = (fieldName: string, type: ChangeHistoryEntityType, path: string) => {
  switch (type) {
    case 'Retailer':
    case 'Retailer Channel':
    case 'Distribution Center':
      return ['entirelySet', 'distributionCenterId'].includes(fieldName);
    case 'Ship-To':
      return [
        'distributionCenterId',
        'entirelySet',
        'retailerDeliveryDestinationId',
        'tradingPartnerRetailerDeliveryDestinations',
        'vendorMarketId'
      ].includes(fieldName);
    case 'Trading Partner':
      if (fieldName === 'entirelySet') {
        const parentField = path.split('.').at(-1) || '';
        return (
          parentField === 'ediSelfServiceConfig' ||
          ![
            ...tpIntegrationConfigs,
            ...tpGeneralSettingConfigs,
            ...tpIntegrationSubconfigs,
            'assortmentConfig'
          ].includes(parentField)
        );
      }
      return ['entirelySet', 'regexp', 'slug', 'tradingPartnerId'].includes(fieldName);
    case 'Assortment':
    case 'New Assortment':
      return [
        'businessUnitId',
        'customerId',
        'daysUntilExpiration',
        'dtcInventoryReserve',
        'enableAutomation',
        'entirelySet',
        'source',
        'tradingPartnerRetailerDeliveryDestinationId',
        'vendorProductId'
      ].includes(fieldName);
    case 'User':
      return ['deletedAt'].includes(fieldName);
    default:
      return false;
  }
};

const skipExtraData = (type: ChangeHistoryEntityType, key: string, path: string) => {
  switch (type) {
    case 'Trading Partner':
      return key === 'rules' && path.includes('assortmentConfig');
    case 'Assortment':
    case 'New Assortment':
      return key === 'active' && path.startsWith('');
    default:
      return false;
  }
};

const isEventWithoutDiffs = (
  type: ChangeHistoryEntityType,
  path: string,
  changes: ChangeHistoryItem[]
) => {
  switch (type) {
    case 'User':
      return path === '' && changes.length === 1 && changes[0].fieldName === 'deletedAt';
    default:
      return false;
  }
};

interface AdditionalAuditData {
  daysOfWeek?: string[];
  distributionCenters?: DistributionCenterForShipToAudit[];
  distributionCenterFacilities?: FacilityForDistributionCenterAudit[];
  tradingPartners?: TradingPartnerForAudit[];
  retailers?: RetailerForRetailerChannelAudit[];
  retailerChannels?: RetailerChannelForShipToAudit[];
  retailerDeliveryDestinations?: RetailerDeliveryDestinationForAssortmentAudit[];
  businessUnits?: BusinessUnitForAudit[];
  profileIds?: ProfileIdsForAudit[];
}

export const mapRepackChangeLogsToChangeHistoryData = (
  changeHistoryData: NonNullable<NonNullable<RepackChangeLogsQuery['repackChangeLogs']>['logs']>
): ChangeHistoryData[] => {
  return changeHistoryData
    .map((x) => ({
      key: x.insertedAt + x.action,
      eventName: `${x.action === 'MOVED_TO_FULFILL' ? 'Moved to prod' : 'Cut'}: ${Math.abs(
        x.newQuantity - x.oldQuantity
      )}`,
      updatedAt: x.insertedAt,
      actor: x.userName,
      meta: {},
      subpageName: '',
      changes: [
        {
          fieldTitle: x.plant,
          fieldName: '',
          path: '',
          previousValue: x.oldQuantity,
          newValue: x.newQuantity
        }
      ],
      // Calling react directly (and components) isn't inherently forbidden, but alows me to NOT convert this to tsx
      detailsTooltip: ChangeTooltip({
        lastChangeInfo: {
          reason: x.reason || '',
          updatedAt: x.insertedAt,
          userName: x.userName,
          comment: x.comment
        },
        children: React.createElement('img', {
          src: chatIcon,
          alt: 'Details',
          style: { cursor: 'pointer' }
        })
      })
    }))
    .sort((a, z) => compareDates(z.updatedAt, a.updatedAt));
};

export const mapAuditTrailVersionToChangeHistoryData = <Node>(
  versions: AuditTrailVersion[],
  type: ChangeHistoryEntityType,
  entity: Node | null | undefined,
  additionalData: AdditionalAuditData,
  assortmentTradingPartnerId?: string
): ChangeHistoryData[] => {
  const allVersions = prepareAuditVersions(
    versions,
    type,
    entity,
    additionalData,
    assortmentTradingPartnerId
  );
  const resultData = allVersions.flatMap((version) => {
    if (!entity) return [];
    const updateObject: any = {};
    version?.diff.forEach((diff: any) => {
      set(updateObject, diff.fieldPath.substring(1), getValues(diff));
    });
    const result: ChangeHistoryData[] = [];

    const mapToChangeHistoryData = (
      path: string,
      updateObject: Object = {},
      result: ChangeHistoryData[]
    ) => {
      const simpleChanges: ChangeHistoryItem[] = [];
      Object.keys(updateObject).forEach((key) => {
        if (skipExtraData(type, key, path)) {
          // skip extra data
        } else {
          const currentObject = get(updateObject, key);
          const newPath = `${path}.${key}`;
          if (
            get(currentObject, 'previousValue') !== undefined ||
            get(currentObject, 'newValue') !== undefined
          ) {
            if (isShouldBeDiffItem(type, key)) {
              simpleChanges.push({
                fieldTitle: getAuditFieldName(key, type, path),
                fieldName: key,
                path,
                previousValue: getAuditFieldValue(
                  key,
                  currentObject.previousValue,
                  additionalData,
                  path
                ),
                newValue: getAuditFieldValue(key, currentObject.newValue, additionalData, path)
              });
            } else {
              simpleChanges.push({
                fieldTitle: getAuditFieldName(key, type, path),
                fieldName: key,
                path,
                value: getAuditFieldValue(
                  key,
                  currentObject.newValue || currentObject.previousValue,
                  additionalData,
                  path
                )
              });
            }
          } else {
            mapToChangeHistoryData(newPath, currentObject, result);
          }
        }
      });
      const finalSimpleChanges = simpleChanges
        .filter((change) => !isExcludedRecord(change.fieldName, type, path))
        .sort((c1, c2) => c1.fieldTitle.localeCompare(c2.fieldTitle));
      if (finalSimpleChanges.length > 0 || isEventWithoutDiffs(type, path, simpleChanges)) {
        result.push({
          key: `${version?.appliedAt}_${path}`,
          eventName: getEventName(
            type,
            version?.action || 'CHANGE',
            path,
            simpleChanges,
            additionalData.distributionCenters,
            additionalData.tradingPartners
          ),
          subpageName: getSubPageName(type, path),
          changes: finalSimpleChanges,
          actor: version?.actor === 'SYSTEM' ? 'System' : version?.user?.name || '',
          updatedAt: version?.appliedAt,
          meta: {
            tradingPartnerId: version?.meta?.tradingPartnerId || undefined,
            tradingPartnerExternalId: version?.meta?.tradingPartnerExternalId || undefined,
            tradingPartnerName: version?.meta?.tradingPartnerName || undefined
          }
        });
      }
    };

    mapToChangeHistoryData('', updateObject, result);
    return result;
  });
  return resultData;
};

const prepareAuditVersions = (
  versions: AuditTrailVersion[],
  type: ChangeHistoryEntityType,
  entity: any,
  additionalData: AdditionalAuditData,
  assortmentTradingPartnerId?: string
) => {
  switch (type) {
    case 'Ship-To':
      return prepareShipToAuditVersions(
        versions,
        entity as unknown as RetailerDeliveryDestinationForAudit
      );
    case 'Assortment':
    case 'New Assortment':
      return prepareAssortmentAuditVersions(
        versions,
        entity as unknown as RetailerProductRddAssortment,
        additionalData.retailerDeliveryDestinations || [],
        assortmentTradingPartnerId || ''
      );
    case 'User':
      return prepareUserAuditVersions(
        versions,
        additionalData.tradingPartners || [],
        additionalData.distributionCenters || []
      );
    default:
      return versions;
  }
};

const getEventName = (
  type: ChangeHistoryEntityType,
  action: AuditTrailAction,
  path: string,
  simpleChanges: ChangeHistoryItem[],
  distributionCenters?: DistributionCenterForShipToAudit[],
  tradingPartners?: TradingPartnerForAudit[]
) => {
  switch (action) {
    case 'ADDITION':
      return getAddEventName(
        type,
        path,
        simpleChanges,
        tradingPartners || [],
        distributionCenters || []
      );
    case 'CHANGE':
      return getUpdateEventName(type, path, simpleChanges, distributionCenters, tradingPartners);
    case 'REMOVAL':
      if (type === 'New Assortment') {
        return 'Delete Product';
      } else {
        return `Delete ${type}`;
      }
  }
};

const getAddEventName = (
  type: ChangeHistoryEntityType,
  path: string,
  simpleChanges: ChangeHistoryItem[],
  tradingPartners: TradingPartnerForAudit[],
  distributionCenters: DistributionCenterForShipToAudit[]
) => {
  switch (type) {
    case 'Distribution Center':
      return getDistributionCenterEventName(path, simpleChanges, 'Add');
    case 'Trading Partner':
      return getTradingPartnerEventName(path, simpleChanges, type, 'Add');
    case 'Ship-To':
      return getShipToEventName(
        path,
        simpleChanges,
        type,
        'Add',
        tradingPartners || [],
        distributionCenters || []
      );
    case 'Assortment':
    case 'New Assortment':
      return getAssortmentEventName(path, simpleChanges, 'Add', tradingPartners);
    default:
      return `Add ${type}`;
  }
};

const getUpdateEventName = (
  type: ChangeHistoryEntityType,
  path: string,
  simpleChanges: ChangeHistoryItem[],
  distributionCenters?: DistributionCenterForShipToAudit[],
  tradingPartners?: TradingPartnerForAudit[]
) => {
  switch (type) {
    case 'Retailer':
      if (path === '') {
        return `Update ${simpleChanges.length === 1 ? simpleChanges[0].fieldTitle : type}`;
      }
      return `Update ${type}`;
    case 'Ship-To':
      if (path === '') {
        return `Update ${simpleChanges.length === 1 ? simpleChanges[0].fieldTitle : type}`;
      }
      return getShipToEventName(
        path,
        simpleChanges,
        type,
        'Update',
        tradingPartners || [],
        distributionCenters || []
      );
    case 'Distribution Center':
      if (path === '') {
        return `Update ${simpleChanges.length === 1 ? simpleChanges[0].fieldTitle : type}`;
      }
      return getDistributionCenterEventName(path, simpleChanges, 'Update');
    case 'Trading Partner':
      if (path === '') {
        return `Update ${simpleChanges.length === 1 ? simpleChanges[0].fieldTitle : type}`;
      }
      return getTradingPartnerEventName(path, simpleChanges, type, 'Update');
    case 'Assortment':
    case 'New Assortment':
      if (path === '') {
        return `Update Product`;
      }
      return getAssortmentEventName(path, simpleChanges, 'Update', tradingPartners || []);
    case 'User':
      if (path === '' && simpleChanges.length === 1 && simpleChanges[0].fieldName === 'deletedAt') {
        return !!(simpleChanges[0] as ChangeHistoryDiffItem).newValue
          ? 'Deactivate user'
          : 'Activate user';
      }
      return `Update ${type}`;
    default:
      return `Update ${type}`;
  }
};

const getSubPageName = (type: ChangeHistoryEntityType, path: string) => {
  switch (type) {
    case 'Ship-To':
      if (path === '') return 'General Settings';
      if (path.startsWith('.tradingPartnerRetailerDeliveryDestinations'))
        return 'TP-Specific Settings';
      if (path.startsWith('.allowedDistributionCenters')) return 'DC';
      return '-';
    case 'Trading Partner':
      if (path === '') return 'General Settings';
      const pathFirstPart = path.split('.')[1];
      if (pathFirstPart === 'assortmentConfig') return 'Active Assortment';
      if ([...tpIntegrationConfigs, 'ediSelfServiceConfig'].includes(pathFirstPart))
        return 'Integrations';
      if (tpGeneralSettingConfigs.includes(pathFirstPart)) return 'General Settings';
      return '-';
    case 'Distribution Center':
      const dcPathFirstPart = path.split('.')[1];
      if (
        path === '' ||
        dcPathFirstPart === 'truckCapacity' ||
        dcPathFirstPart === 'costAndCapacity' ||
        dcPathFirstPart === 'blackouts' ||
        dcPathFirstPart === 'codes'
      )
        return 'General Settings';
      if (dcPathFirstPart === 'customizationHeaders') return 'Customization';
      if (dcIntegrationConfigs.includes(dcPathFirstPart)) return 'Integrations';
      return '-';
    default:
      return '-';
  }
};

export const getEventNameByAction = (
  simpleChanges: ChangeHistoryItem[],
  name: string,
  addDescription?: { before: boolean; value: string },
  removeDescription?: { before: boolean; value: string },
  parentName?: string
) => {
  const entirelySetChange = simpleChanges.find(
    (simpleChange) => simpleChange.fieldName === 'entirelySet'
  );
  if (!entirelySetChange) {
    return `Update ${name}${parentName ? ` ${parentName}` : ''}`;
  } else if (
    (isChangeHistoryValue(entirelySetChange) && entirelySetChange.value === 'true') ||
    (!isChangeHistoryValue(entirelySetChange) && entirelySetChange.newValue === 'true')
  ) {
    return `${
      addDescription ? (addDescription.before ? addDescription.value : '') : 'Add '
    }${name}${parentName ? ` to ${parentName}` : ''} ${
      addDescription && !addDescription.before ? addDescription.value : ''
    }`;
  } else {
    return `${
      removeDescription ? (removeDescription.before ? removeDescription.value : '') : 'Delete'
    } ${name}${parentName ? ` to ${parentName}` : ''} ${
      removeDescription && !removeDescription.before ? removeDescription.value : ''
    }`;
  }
};

function isShouldBeDiffItem(type: ChangeHistoryEntityType, key: string) {
  switch (type) {
    case 'Assortment':
    case 'New Assortment':
      return key !== 'forDistributionCenters' && key !== 'forShipTos' && key !== 'forVendorFlex';
    case 'User':
      return ![
        'addTradingPartners',
        'removeTradingPartners',
        'addDistributionCenters',
        'removeDistributionCenters'
      ].includes(key);
    default:
      return true;
  }
}

const getDistributionCenterEventName = (
  path: string,
  simpleChanges: ChangeHistoryItem[],
  action: string
) => {
  const type: ChangeHistoryEntityType = 'Distribution Center';
  const dcPathFirstPart = path.split('.')[1];
  if (dcPathFirstPart === 'truckCapacity') {
    return `${action} Truck Capacity`;
  } else if (path.includes('blackouts')) {
    return getEventNameByAction(simpleChanges, 'blackout date');
  } else if (path.includes('codes')) {
    return getEventNameByAction(simpleChanges, 'Identifier And Source');
  } else if (dcPathFirstPart === 'customizationHeaders') {
    return getEventNameByAction(simpleChanges, 'customization field');
  } else if (dcIntegrationConfigs.includes(dcPathFirstPart)) {
    const configName = fieldNameToString(dcPathFirstPart, type, path);
    return getEventNameByAction(
      simpleChanges,
      configName,
      { before: false, value: 'turned on' },
      { before: false, value: 'turned off' }
    );
  } else if (path.includes('costAndCapacity')) {
    return `${action} Cost & Capacity`;
  }

  return `${action} ${type}`;
};
