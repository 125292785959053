import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Chip, ChipProps } from '../Chip/Chip';
import { useElementSize } from '@reactuses/core';
import clsx from 'clsx';
import s from './ChipsList.module.scss';
import { BaseProperties } from '../Utils';
import { v4 as uuidv4 } from 'uuid';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface ChipsListProps extends BaseProperties {
  chips: ChipProps[];
  moreChipProps?: Omit<Omit<ChipProps, 'label'>, 'showSelectedIcon'>;
  maxWidth?: number;
}

export const ChipsList = ({
  chips,
  moreChipProps,
  style,
  className,
  size,
  maxWidth
}: ChipsListProps) => {
  const [uuid] = useState(uuidv4());

  const containerRef = useRef<HTMLDivElement>(null);
  const moreChipContainerRef = useRef<HTMLDivElement>(null);

  const [containerWidth] = useElementSize(containerRef);
  const [moreChipContainerWidth] = useElementSize(moreChipContainerRef);

  const [maxTagCount, setMaxTagCount] = useState(0);
  const [showMoreTag, setShowMoreTag] = useState(true);

  const chipsValues = useMemo(() => {
    return chips.map((chip) => chip.id).join(', ');
  }, [chips]);

  const updateTagsCount = useCallback(() => {
    const spaceForChips = maxWidth !== undefined ? maxWidth : containerWidth;
    let totalWidth = 0;
    let calculatedMaxTagCount = 0;

    chips.forEach((chip, index) => {
      const chipId = `chip-${chip.id ?? chip.label}-${uuid}`;
      const tagElement = document.getElementById(chipId);
      if (tagElement) {
        totalWidth += tagElement.offsetWidth + 8;
        if (
          totalWidth <
          spaceForChips - (index !== chips.length - 1 ? moreChipContainerWidth : 0)
        ) {
          calculatedMaxTagCount++;
        }
      }
    });
    setMaxTagCount(calculatedMaxTagCount);
  }, [containerWidth, moreChipContainerWidth, maxWidth, chipsValues]);

  useEffect(() => {
    setMaxTagCount(0);
    setShowMoreTag(false);
  }, [chipsValues]);

  useEffect(() => {
    updateTagsCount();
    setShowMoreTag(true);
  }, [maxWidth, moreChipContainerWidth]);

  useEffect(() => {
    if (maxWidth !== undefined) return;
    updateTagsCount();
    setShowMoreTag(true);
  }, [containerWidth]);

  useEffect(() => {
    if (showMoreTag === true) return;
    updateTagsCount();
    setShowMoreTag(true);
  }, [showMoreTag]);

  return (
    <div
      ref={containerRef}
      style={
        maxWidth !== undefined
          ? { ...style, width: 'fit-content', maxWidth: `${maxWidth}px` }
          : { ...style, width: style?.width ?? '100%' }
      }
      className={className}
    >
      <div className={s.chips_list}>
        {chips.map((chip, index) => (
          <Chip
            {...chip}
            id={`chip-${chip.id ?? chip.label}-${uuid}`}
            key={`chip-${index}-${uuid}`}
            className={clsx({ [s.hidden_chip]: index >= maxTagCount || !showMoreTag })}
            size={size}
            style={{ ...chip.style, maxWidth: 'none' }}
          />
        ))}
        <AlloyTooltip
          title={chips
            .slice(maxTagCount)
            .map((tag) => tag.label)
            .join(', ')}
        >
          <div ref={moreChipContainerRef} className={s.more_chip_container}>
            <Chip
              {...moreChipProps}
              id={`chip-more-${uuid}`}
              key={`chip-more-${uuid}`}
              label={`+${chips.length - maxTagCount}`}
              showSelectedIcon={false}
              size={size}
              style={{ ...moreChipProps?.style, marginRight: '8px' }}
              className={clsx(moreChipProps?.className, {
                [s.hidden_chip]: chips.length <= maxTagCount || !showMoreTag
              })}
            />
          </div>
        </AlloyTooltip>
      </div>
    </div>
  );
};
