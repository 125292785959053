import React, { useMemo } from 'react';
import s from './ForecastTable.module.scss';
import { EMPTY_WIDE } from 'common/constants';
import clsx from 'clsx';
import { StarFilled } from '@ant-design/icons';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { getCurrentPeriod } from 'components/PeriodCalendar/helpers';
import {
  convertWeekToPeriodWeekString,
  getWeeksInFinancialYear
} from 'common/helpers/fiscalCalendar';
import { range, sumBy, groupBy } from 'lodash-es';
import { getNextWeekAndYear } from 'common/helpers/periodHelper';
import { AlloyTable, ColumnsType, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import lockedLock from 'assets/icons/lock_locked.svg';

const formatNumber = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY_WIDE;
  return parsedValue.toLocaleString();
};

type TimeframeOptionValue = 'balance-of-year' | '1-year-view' | 'next-3-periods' | 'next-1-period';

// Most probably will change anyway
type TableData = {
  name: string;
} & {
  [K in `year_${number}_week_${number}`]: number;
};

function generateMockTableData(names: string[]): TableData[] {
  const { year } = getCurrentPeriod();

  function getRandomValue(min: number = 500, max: number = 2000): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const weekNumbers = range(1, 54);

  const weekYearPairs = [
    ...weekNumbers.map((week) => ({ year, week })),
    ...weekNumbers.map((week) => ({ year: year + 1, week }))
  ];

  const tableData: TableData[] = names.map((name) => {
    const weeks = weekYearPairs.reduce(
      (acc, cur) => {
        acc[`year_${cur.year}_week_${cur.week}` as const] = getRandomValue();
        return acc;
      },
      {} as { [key in `year_${number}_week_${number}`]: number }
    );

    return {
      name,
      ...weeks
    };
  });

  return tableData;
}

const TIMEFRAME_OPTIONS: { label: string; value: TimeframeOptionValue }[] = [
  {
    label: 'Balance of the year',
    value: 'balance-of-year'
  },
  {
    label: '1 year view',
    value: '1-year-view'
  },
  {
    label: 'Next 3 periods',
    value: 'next-3-periods'
  },
  {
    label: 'Next period',
    value: 'next-1-period'
  }
];

const getColumns = (type: TimeframeOptionValue) => {
  const { week: currentWeek, year: currentYear } = getCurrentPeriod();

  const maxWeeks = getWeeksInFinancialYear(currentYear);

  let weekYearPairs: { week: number; year: number }[] = [{ week: currentWeek, year: currentYear }];

  if (type === 'balance-of-year') {
    const weeks = range(currentWeek + 1, maxWeeks + 1);
    weekYearPairs = [...weekYearPairs, ...weeks.map((week) => ({ week, year: currentYear }))];
  } else {
    const iterations = type === 'next-3-periods' ? 12 : type === 'next-1-period' ? 4 : 52;
    let currentWeekYear = weekYearPairs[0];
    for (let i = 0; i < iterations; i++) {
      currentWeekYear = getNextWeekAndYear(currentWeekYear.week, currentWeekYear.year);
      weekYearPairs.push(currentWeekYear);
    }
  }

  const weekYearPairsByYear = groupBy(weekYearPairs, 'year');

  const columns: ColumnsType<TableData> = [
    {
      title: '',
      render: (_, record) => (
        <div className={clsx({ [s.bold]: record.name === 'Recommended Forecast' })}>
          {record.name}
        </div>
      ),
      width: 160,
      fixed: 'left',
      onCell: (record) => ({
        style: {
          background: record.name === 'Recommended Forecast' ? '#F8F8F8' : ''
        }
      })
    },
    ...Object.entries(weekYearPairsByYear).map(([key, weekYearPairsForYear]) => ({
      title: (
        <div className={s.year_wrapper}>
          <div className={s.year_title}>{key}</div>
        </div>
      ),
      children: weekYearPairsForYear.map(({ week, year }, index): ColumnType<TableData> => {
        const isColumnLocked = index < 2;
        const isColumnHighlighted = index >= 2 && index < 6;

        return {
          title: (
            <div className={s.column_title}>
              {convertWeekToPeriodWeekString(week)}
              {isColumnLocked && <img src={lockedLock} alt="locked" />}
            </div>
          ),
          render: (_, record) => (
            <div className={clsx(s.value)}>{formatNumber(record[`year_${year}_week_${week}`])}</div>
          ),
          onCell: (record) => ({
            style: {
              background:
                isColumnLocked || record.name === 'Recommended Forecast'
                  ? '#F8F8F8'
                  : isColumnHighlighted
                    ? '#E5F7FF'
                    : ''
            }
          }),
          width: isColumnLocked ? 82 : 70,
          align: 'center'
        };
      })
    }))
  ];

  return {
    columns,
    width: sumBy(columns, 'width')
  };
};

const MOCK_DATA = generateMockTableData([
  'Current Forecast',
  'Trend Recommendation',
  'Promotion',
  'Stat Forecast',
  'Amazon PO Fcst',
  'Recommended Forecast'
]);

export const ForecastTable = () => {
  const [timeframe, setTimeframe] = useQueryParam(
    'timeframe',
    withDefault(StringParam, TIMEFRAME_OPTIONS[0].value)
  );
  const typedTimeframe = timeframe as TimeframeOptionValue;
  const { columns, width } = useMemo(() => getColumns(typedTimeframe), [typedTimeframe]);

  return (
    <section className={s.wrapper}>
      <div className={s.top}>
        <h2 className={s.title}>Forecast</h2>
        <div className={s.legend_wrapper}>
          <div className={s.legend}>
            Promotional
            <StarFilled width={16} height={16} />
          </div>
          <div className={s.legend}>
            Time Fence
            <div className={clsx(s.square, s.time_fence)} />
          </div>
          <div className={s.legend}>
            Recommendation
            <div className={clsx(s.square, s.recommendation)} />
          </div>
        </div>
        <AlloySelect
          options={TIMEFRAME_OPTIONS}
          onChange={(value) => setTimeframe(value)}
          value={timeframe}
          className={s.timeframe}
        />
      </div>
      <div className={s.table_wrapper}>
        <AlloyTable
          columns={columns}
          dataSource={MOCK_DATA}
          key="name"
          pagination={false}
          size="small"
          scroll={{ x: width }}
        />
      </div>
    </section>
  );
};
