import React, { useMemo } from 'react';
import { ReconcileBatchDetailsMetric, makeReasonsReadable } from '../types';
import { Form } from 'react-final-form';
import { App } from 'ant5';
import s from './EditReasonsForm.module.scss';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import clsx from 'clsx';
import { sumBy } from 'lodash-es';
import { OnChange } from 'react-final-form-listeners';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { InventoryDiscrepancyReason } from 'graphql/__generated__/types';
import { useEnumValue } from 'common/useEnumValue';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

type Reason = {
  reason: InventoryDiscrepancyReason;
  quantity: string;
};
interface EditReasons {
  reasons: Reason[];
  openVariance: number;
  totalDiscrepancy: number;
}
export const EditReasonsForm = ({
  editBatch,
  onCancel
}: {
  editBatch: ReconcileBatchDetailsMetric | undefined;
  onCancel: () => void;
}) => {
  const reasons = useEnumValue('InventoryDiscrepancyReason');
  const { message } = App.useApp();

  const initialTotal = useMemo(
    () => sumBy(editBatch?.reasons, 'quantity') + editBatch?.openVariance,
    [editBatch]
  );
  return (
    <div className={s.form}>
      <Form<EditReasons>
        initialValues={{
          reasons: [
            ...(editBatch?.reasons || []).map((x) => ({
              reason: x.reason as InventoryDiscrepancyReason,
              quantity: x.quantity
            }))
          ],
          openVariance: editBatch?.openVariance
        }}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={(_values) => message.success('Reasons updated')}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="reasons">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <div key={name} className={s.formRow}>
                      <div className={s.amount}>
                        <AlloyFormField
                          component="input"
                          name={`${name}.quantity`}
                          fieldProps={{ title: '' }}
                        />
                      </div>
                      <div className={s.reason}>
                        <AlloyFormField
                          component="select"
                          name={`${name}.reason`}
                          fieldProps={{
                            title: '',
                            loading: reasons.loading,
                            allowClear: false,
                            showSearch: false,
                            options: [...reasons.enumValues]
                              .sort(safeLocaleCompare)
                              .filter((x) => x !== 'SWAP') // We don't need SWAP here, we have a separate modal for that
                              .map((x) => ({
                                label: makeReasonsReadable(x),
                                value: x,
                                disabled: values.reasons.some((item) => item.reason === x)
                              }))
                          }}
                        />
                      </div>
                      <div>
                        <AlloyButton
                          onClick={() => fields.remove(index)}
                          icon={<MinusOutlined />}
                        />
                      </div>
                    </div>
                  ))}
                  <div className={clsx(s.formRow, s.amountAndReason)}>
                    <div className={s.amount}>{values.openVariance}</div>
                    <div className={s.reason}>Open Variance</div>
                    <AlloyButton
                      onClick={() =>
                        fields.push({ quantity: values.openVariance, reason: undefined })
                      }
                      disabled={(values.reasons || []).some((x) => !x.reason || !x.quantity)}
                      icon={<PlusOutlined />}
                    />
                  </div>
                </>
              )}
            </FieldArray>

            <div className={s.divider} />
            <div className={clsx(s.formRow, s.amountAndReason)}>
              <div className={s.amount}>{editBatch?.discrepancy}</div>
              <div className={s.reason}>Total Discrepancy</div>
            </div>
            <div className={s.buttons}>
              <AlloyButton onClick={onCancel}>Cancel</AlloyButton>
              <AlloyButton type="primary" htmlType="submit">
                Confirm
              </AlloyButton>

              <OnChange name="reasons">
                {(value) => {
                  const total = sumBy(value, (item: { quantity: string }) =>
                    parseFloat(item.quantity)
                  );
                  form.change('openVariance', initialTotal - (total || 0));
                }}
              </OnChange>
            </div>
          </form>
        )}
      />
    </div>
  );
};
