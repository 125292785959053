import React from 'react';
import { Upload as AntUpload, UploadProps } from 'ant5';
import { UploadChangeParam, UploadFile } from 'ant5/es/upload';

export function AlloyUpload<T>({ ...otherProps }: UploadProps<T>) {
  return <AntUpload<T> {...otherProps} />;
}

AlloyUpload.Dragger = AntUpload.Dragger;

// Re-exporting types
export type { UploadProps, UploadChangeParam, UploadFile };
