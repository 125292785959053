import { useLazyQuery } from '@apollo/client';
import {
  LoadTradingPartnerAssortmentsDocument,
  LoadTradingPartnerAssortmentsQuery
} from 'pages/ShipToPage/gql/__generated__/loadTradingPartnerAssortments.query';
import React, { useEffect, useState } from 'react';
import { TradingPartner } from '../../AddShipToModal';
import { BulkAddAssortmentTable } from '../BulkAddProductsTable';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import s from './BulkAddAssortmentModal.module.scss';

export type Product = InferNodeType<
  LoadTradingPartnerAssortmentsQuery,
  'tradingPartnerAssortments'
>;

interface BulkAddAssortmentModalProps {
  visible: boolean;
  onAdd: (assortment: Product[]) => void;
  onClose: () => void;
  tradingPartners: TradingPartner[];
}

export const BulkAddAssortmentModal = ({
  visible,
  onAdd,
  onClose,
  tradingPartners
}: BulkAddAssortmentModalProps) => {
  const [tradingPartner, setTradingPartner] = useState<TradingPartner>();
  const [tradingPartnerAssortment, setTradingPartnerAssortment] = useState<Product[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const [loadTradingPartnerAssortment, { loading }] = useLazyQuery(
    LoadTradingPartnerAssortmentsDocument,
    {
      onCompleted: (data) => {
        const products = getNodesFromEdges(data?.tradingPartnerAssortments);
        setTradingPartnerAssortment(products);
        setSelected(products.map((vp) => vp.retailerProductId));
      }
    }
  );

  useEffect(() => {
    if (tradingPartner) {
      loadTradingPartnerAssortment({
        variables: {
          vendorMarketId: tradingPartner?.vendorMarket.id || '',
          tradingPartnerId: tradingPartner?.id || ''
        }
      });
    }
  }, [tradingPartner, loadTradingPartnerAssortment]);

  useEffect(() => {
    if (!visible) {
      setTradingPartner(undefined);
      setTradingPartnerAssortment([]);
      setSelected([]);
    }
  }, [visible]);

  return (
    <AlloyModal
      open={visible}
      title="Add Products"
      closable
      onCancel={onClose}
      classNames={{
        body: s.add_products_body,
        footer: s.add_products_footer,
        header: s.add_products_header,
        content: s.add_products_content
      }}
      onOk={() => {
        onAdd(
          tradingPartnerAssortment.filter((assortment) =>
            selected.includes(assortment.retailerProductId)
          )
        );
        onClose();
      }}
    >
      <AlloySelect
        onChange={(value) => {
          setTradingPartner(tradingPartners.find((tp) => tp.id === value));
        }}
        style={{ width: '360px', marginBottom: '16px' }}
        placeholder="Select Trading Partner"
        value={tradingPartner?.id}
        size="large"
        options={(tradingPartners || [])
          .sort((tp1, tp2) => (tp1.name ?? '').localeCompare(tp2.name ?? ''))
          .map((tp) => ({
            label: tp.name,
            value: tp.id
          }))}
      />
      <BulkAddAssortmentTable
        loading={loading}
        tradingPartnerAssortment={tradingPartnerAssortment || []}
        selected={selected}
        setSelected={setSelected}
      />
    </AlloyModal>
  );
};
