import { useMutation } from '@apollo/client';
import { App, Spin } from 'ant5';
import React from 'react';
import { Form } from 'react-final-form';
import s from './AddFacilityForm.module.scss';
import { EditDistributionCenterFacilitiesDocument } from 'pages/DistributionCentersPage/gql/__generated__/editDistributionCenterFacilitiesData.query';
import {
  DistributionCenterFacility,
  UpsertDistributionCenterFacilityInput
} from 'graphql/__generated__/types';
import { UpsertDistributionCenterFacilityDocument } from '../../../gql/__generated__/upsertDistributionCenterFacility.mutation';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface AddFacilityFormProps {
  onFacilityAdded: (id: string) => void;
}

export const AddFacilityForm = ({ onFacilityAdded }: AddFacilityFormProps) => {
  const { notification } = App.useApp();
  const facility = undefined as DistributionCenterFacility | undefined;

  const [upsertFacility, { loading: upsertingFacility }] = useMutation(
    UpsertDistributionCenterFacilityDocument,
    {
      refetchQueries: [
        'distributionCenterFacilities',
        'distributionCenters',
        EditDistributionCenterFacilitiesDocument
      ],
      onCompleted: (data) => {
        onFacilityAdded(
          data?.upsertDistributionCenterFacility?.distributionCenterFacility?.id || ''
        );
        notification.success({
          message: 'New facility successfully added'
        });
      },
      onError: (error) => {
        console.error(error.message);
        notification.error({
          message: 'Unable to add this facility',
          description: error.message
        });
      }
    }
  );

  const submitForm = async (values: UpsertDistributionCenterFacilityInput) => {
    await upsertFacility({
      variables: {
        input: {
          id: values.id,
          name: values.name ?? ''
        }
      }
    });
  };

  return (
    <Spin spinning={upsertingFacility}>
      <Form<UpsertDistributionCenterFacilityInput>
        initialValues={{
          name: facility?.name || ''
        }}
        onSubmit={submitForm}
        render={({ handleSubmit, form }) => (
          <form
            style={{ display: 'flex', alignItems: 'flex-end' }}
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  form.reset();
                  form.resetFieldState('name');
                });
              return promise;
            }}
          >
            <div className={s.form_container}>
              <div className={s.row}>
                <AlloyFormField
                  component="input"
                  name="name"
                  fieldProps={{ title: 'Name' }}
                  required
                />
              </div>
            </div>
            <button
              className="ant-btn ant-btn-primary"
              type="submit"
              style={{ marginBottom: '28px', marginRight: '16px' }}
            >
              + Add
            </button>
          </form>
        )}
      ></Form>
    </Spin>
  );
};
