import { LegendItem, TooltipItem } from 'chart.js';
import { BusinessUnit } from 'pages/FillRateReport/types';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import s from './ColumnsPlotDataView.module.scss';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';

interface ColumnsPlotDataViewProps {
  subBusinessUnits?: BusinessUnit[];
  metricsByEntity: {
    subBusinessUnitId?: string;
    title?: string;
    metrics: FillRateReportMetricFragment;
  }[];
}

export const ColumnsPlotDataView = ({
  metricsByEntity,
  subBusinessUnits
}: ColumnsPlotDataViewProps) => {
  const [data, setData] = useState<
    { bu?: BusinessUnit; title?: string; metrics: FillRateReportMetricFragment }[]
  >([]);

  useEffect(() => {
    if (subBusinessUnits) {
      const result = [] as { bu: BusinessUnit; metrics: FillRateReportMetricFragment }[];
      metricsByEntity.forEach((v) => {
        const bu = subBusinessUnits.find((businessUnit) => businessUnit.id === v.subBusinessUnitId);
        result.push({
          bu: bu || ({ name: 'Unknown' } as BusinessUnit),
          metrics: v.metrics
        });
      });
      setData(result);
    } else {
      setData(metricsByEntity);
    }
  }, [metricsByEntity, subBusinessUnits]);

  const datasets = [
    {
      type: 'line' as const,
      label: 'Fill Rate',
      borderColor: '#88B441',
      backgroundColor: '#88B441',
      borderWidth: 2,
      data: data.map((d) => d.metrics.submittedFillRate),
      yAxisID: 'y2',
      xAxisID: 'x'
    },
    {
      type: 'bar' as const,
      label: 'Accepted Qty',
      data: data.map((d) => d.metrics.acceptedQuantity),
      yAxisID: 'y',
      xAxisID: 'x3',
      barThickness: 118,
      backgroundColor: 'rgb(0,0,0,0)',
      borderColor: 'rgb(0,0,0,1)',
      borderWidth: { top: 1, right: 0, bottom: 0, left: 0 }
    },
    {
      type: 'bar' as const,
      label: 'Delivered Qty',
      data: data.map((d) => d.metrics.deliveredQuantity),
      backgroundColor: '#88B441',
      stack: 'Stack 0',
      yAxisID: 'y',
      xAxisID: 'x',
      barThickness: 72
    },
    {
      type: 'bar' as const,
      label: 'Cuts',
      data: data.map((d) => d.metrics.cutQuantity),
      backgroundColor: '#FFA59C',
      stack: 'Stack 0',
      yAxisID: 'y',
      xAxisID: 'x',
      barThickness: 72
    },
    {
      type: 'bar' as const,
      label: 'Orig. Order Cases',
      data: data.map((d) => d.metrics.submittedQuantity),
      backgroundColor: '#E8E8E8',
      stack: 'Stack 1',
      yAxisID: 'y',
      xAxisID: 'x2',
      barThickness: 95
    }
  ];

  const getScales = () => {
    const scales = {
      x: {
        stacked: true,
        grid: {
          drawOnChartArea: false
        }
      },
      x2: {
        display: false
      },
      x3: {
        display: false
      },
      y: {
        title: {
          text: 'QTY',
          align: 'start' as const
        },
        stacked: true,
        grid: {
          drawOnChartArea: false
        }
      },
      y2: {
        min: 0,
        max: 100,
        position: 'right' as const,
        ticks: {
          callback: function (value: string | number) {
            return value + '%';
          }
        },
        grid: {
          drawOnChartArea: false
        }
      }
    };
    if (subBusinessUnits) {
      return {
        ...scales,
        x4: {
          display: true,
          labels: data.map((d) =>
            d.bu ? (d.bu.parentBusinessUnit ? d.bu.parentBusinessUnit.name : d.bu.name) : d.title
          ),
          ticks: {
            autoSkip: true,
            maxTicksLimit: [
              ...new Set(
                data.map((d) =>
                  d.bu
                    ? d.bu.parentBusinessUnit
                      ? d.bu.parentBusinessUnit.name
                      : d.bu.name
                    : d.title
                )
              )
            ].length
          }
        }
      };
    }
    return scales;
  };

  return (
    <div className={s.chart_container}>
      <Chart
        type="bar"
        data={{
          labels: data.map((d) => (d.bu ? d.bu.name : d.title)),
          datasets
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: getScales(),
          interaction: {
            intersect: false,
            mode: 'index'
          },
          plugins: {
            legend: {
              align: 'end',
              labels: {
                usePointStyle: true,
                generateLabels: (chart) =>
                  datasets.map(
                    (dataset, index) =>
                      ({
                        text: dataset.label,
                        pointStyle:
                          dataset.type === 'line' || dataset.label === 'Accepted Qty'
                            ? 'dash'
                            : 'rect',
                        datasetIndex: index,
                        textAlign: 'left',
                        fillStyle:
                          dataset.type === 'line' || dataset.label === 'Accepted Qty'
                            ? dataset.borderColor
                            : dataset.backgroundColor,
                        strokeStyle:
                          dataset.type === 'line' || dataset.label === 'Accepted Qty'
                            ? dataset.borderColor
                            : dataset.backgroundColor,
                        lineWidth: 2
                      }) as LegendItem
                  )
              }
            },
            tooltip: {
              callbacks: {
                label: (item) => '',
                footer: (tooltipItems: TooltipItem<'bar' | 'line'>[]) => {
                  const res = [] as string[];
                  tooltipItems.forEach((item) => {
                    res.push(
                      `${item.dataset.label}: ${item.formattedValue}${
                        item.datasetIndex === 0 ? '%' : ''
                      }`
                    );
                  });
                  return res;
                }
              }
            }
          }
        }}
      />
    </div>
  );
};
