import React from 'react';
import s from './Warning.module.scss';
import warningIcon from 'assets/icons/inventory/warning.svg';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

export const Warning = ({ text }: { text: string }) => {
  return (
    <AlloyTooltip placement="bottomLeft" overlayClassName={s.overlay} overlay={<>{text}</>}>
      <img src={warningIcon} alt={text} width={16} height={16} />
    </AlloyTooltip>
  );
};
