import React from 'react';
import { useQuery } from '@apollo/client';
import s from './ProductsStats.module.scss';
import { StatsTile } from 'components/StatsTile/StatsTile';
import { ProductsFillRateFilters } from 'graphql/__generated__/types';
import currency from 'currency.js';
import { ProductsFillRateTotalsDocument } from 'pages/Visibility/gql/__generated__/productsFillRateTotals.query';

export const ProductsStats = ({ filters }: { filters: ProductsFillRateFilters }) => {
  const productFillRateTotalsData = useQuery(ProductsFillRateTotalsDocument, {
    variables: {
      filters
    }
  });

  const formattedTotalGrossValueNew = currency(
    productFillRateTotalsData.data?.productsFillRateTotals?.grossValue,
    { precision: 0 }
  ).format();

  return (
    <div className={s.stats}>
      <StatsTile
        title="Total Customer Original QTY"
        count={
          productFillRateTotalsData.data?.productsFillRateTotals?.customerOriginalQuantity || 0
        }
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
      />
      <StatsTile
        title="Total Customer Accepted QTY"
        count={
          productFillRateTotalsData.data?.productsFillRateTotals?.customerAcceptedQuantity || 0
        }
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
      />
      <StatsTile
        title="Total SAP Accepted QTY"
        count={productFillRateTotalsData.data?.productsFillRateTotals?.acceptedQuantitySap || 0}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
      />
      <StatsTile
        title="Total Shipped QTY"
        count={productFillRateTotalsData.data?.productsFillRateTotals?.deliveredQuantity || 0}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
      />
      <StatsTile
        title="Total Remaining To Ship QTY"
        count={productFillRateTotalsData.data?.productsFillRateTotals?.remainingToShipQuantity || 0}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
        tooltip="Zero if Ship Qty exists otherwise SAP Accepted Qty"
      />
      <StatsTile
        title="Total Gross Value on Orders"
        count={formattedTotalGrossValueNew}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
      />
      <StatsTile
        title="Submitted Fill Rate"
        count={parseFloat(
          productFillRateTotalsData.data?.productsFillRateTotals?.submittedFillRate
        )}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
        tooltip="Ship Qty / Customer Original Qty"
        percentage
      />
      <StatsTile
        title="Accepted Fill Rate"
        count={parseFloat(productFillRateTotalsData.data?.productsFillRateTotals?.acceptedFillRate)}
        loading={productFillRateTotalsData.loading}
        error={!!productFillRateTotalsData.error}
        tooltip="Ship Qty / Customer Accepted Qty"
        percentage
        maximumFractionDigits={2}
      />
    </div>
  );
};
