import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { ActiveAssortmentTab } from 'pages/TradingPartnersPage/components/forms/ActiveAssortmentTab/ActiveAssortmentTab';
import { GeneralSettingsTab } from 'pages/TradingPartnersPage/components/forms/GeneralSettingsTab/GeneralSettingsTab';
import { IntegrationsTab } from 'pages/TradingPartnersPage/components/forms/IntegrationsTab/IntegrationsTab';
import { ShipToTab } from 'pages/TradingPartnersPage/components/forms/ShipToTab/ShipToTab';
import { useForm } from 'react-final-form';
import {
  SourcingRule,
  TradingPartner
} from 'pages/TradingPartnersPage/components/TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { BusinessUnitsAndVendorMarketsDocument } from 'pages/TradingPartnersPage/gql/__generated__/buAndVendorMarketLists.query';

interface EditTradingPartnerTabsProps {
  onClose: () => void;
  open: boolean;
  firstOpenTab?: string;
  upserting: boolean;
  tradingPartner?: TradingPartner;
  defaultSourcingRules: SourcingRule[];
}

export const EditTradingPartnerTabs = ({
  onClose,
  open,
  firstOpenTab,
  upserting,
  defaultSourcingRules,
  tradingPartner
}: EditTradingPartnerTabsProps) => {
  const form = useForm();

  const { data: initData, loading: initLoading } = useQuery(
    BusinessUnitsAndVendorMarketsDocument,
    {}
  );

  const businessUnits = useMemo(() => getNodesFromEdges(initData?.businessUnits), [initData]);
  const vendorMarkets = useMemo(() => getNodesFromEdges(initData?.vendorMarkets), [initData]);

  const tabs = [
    {
      key: 'general',
      label: <span data-testid="edit-trading-partner-general-tab">General Settings</span>,
      children: (
        <GeneralSettingsTab
          setVendorMarketId={(id: string) => {
            form.mutators.setVendorMarketId(id);
          }}
          upserting={upserting}
          defaultSourcingRules={defaultSourcingRules}
          allowedDistributionCenters={tradingPartner?.distributionCenters || []}
          vendorMarkets={vendorMarkets}
          businessUnits={businessUnits}
          initLoading={initLoading}
        />
      )
    },
    {
      key: 'ship_to',
      label: <span data-testid="edit-trading-partner-ship-to-settings-tab">Ship-Tos</span>,
      children: <ShipToTab tradingPartner={tradingPartner} />
    },
    {
      key: 'integrations',
      label: <span data-testid="edit-trading-partner-integrations-tab">Integrations</span>,
      children: (
        <IntegrationsTab
          tradingPartner={tradingPartner}
          upserting={upserting}
          setUseConfig={(integrationName: string | undefined, value: boolean) => {
            switch (integrationName) {
              case 'channelAdvisorConfig':
                form.mutators.useChannelAdvisorConfig(value);
                break;
              case 'ochConfig':
                form.mutators.useOchConfig(value);
                break;
              case 'pepdirectConfig':
                form.mutators.usePepdirectConfig(value);
                break;
              case 'shippingConfig':
                form.mutators.useShippingConfig(value);
                break;
              case 'ediConfigInternal':
                form.mutators.useEdiConfigInternal(value);
                break;
              case 'ediConfigExternal':
                form.mutators.useEdiConfigExternal(value);
                break;
              case 'emailConfigSalesOrderRelease':
                form.mutators.useEmailConfigSalesOrderRelease(value);
                break;
              case 'emailConfigSalesOrderExport':
                form.mutators.useEmailConfigSalesOrderExport(value);
                break;
              case 'internalAlertEmailConfig':
                form.mutators.useInternalAlertEmailConfig(value);
                break;
              case 'internalAlertConfig':
                form.mutators.useInternalAlertConfig(value);
                break;
              case 'ediMessageConfiguration':
                form.mutators.useEdiMessageConfiguration(value);
                break;
              case 'purchaseOrderCustomization850':
                form.mutators.usePurchaseOrderCustomization850(value);
                break;
              case 'purchaseOrderAckCustomization855':
                form.mutators.usePurchaseOrderAckCustomization855(value);
                break;
              case 'purchaseOrderCustomization856':
                form.mutators.usePurchaseOrderCustomization856(value);
                break;
              case 'purchaseOrderCustomization810':
                form.mutators.usePurchaseOrderCustomization810(value);
                break;
              case 'storeConfig':
                form.mutators.useStoreConfig(value);
                break;
              case 'amazonSellingPartnerConfig':
                form.mutators.useAmazonSellingPartnerConfig(value);
                break;
              case 'samsClubConfig':
                form.mutators.useSamsClubConfig(value);
                break;
              case 'invoiceConfig':
                form.mutators.useInvoiceConfig(value);
            }
          }}
        />
      )
    },
    {
      key: 'activeAssortment',
      label: (
        <span data-testid="edit-trading-partner-active-assortment-tab">Active Assortment</span>
      ),
      children: <ActiveAssortmentTab />
    }
  ];

  return (
    <FullScreenEditingModal
      title="Edit Trading Partner"
      onClose={onClose}
      open={open}
      buttons={
        <>
          <AlloyButton onClick={onClose} data-testid="edit-trading-partner-cancel-button">
            Cancel
          </AlloyButton>
          <AlloyButton
            type="primary"
            data-testid="edit-trading-partner-save-button"
            onClick={() => {
              document
                ?.getElementById('edit-trading-partner-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            Save
          </AlloyButton>
        </>
      }
    >
      <AlloyTabs style={{ flex: 1 }} defaultActiveKey={firstOpenTab} items={tabs} />
    </FullScreenEditingModal>
  );
};
