import React, { useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { unionBy } from 'lodash-es';
import { FilterOption } from '../types';
import VirtualList from 'rc-virtual-list';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import s from './MultipleSelectWithSearch.module.scss';
import clsx from 'clsx';

export const MultipleSelectWithSearch = ({
  values,
  setValues,
  options,
  displaySelected = true,
  allValuesOption
}: {
  values: string[];
  setValues: (value: string[]) => void;
  options: FilterOption[];
  allValuesOption?: FilterOption;
  displaySelected?: boolean;
}) => {
  const [search, setSearch] = useState('');

  const valuesAsOptions = useMemo(
    () => values.map((value) => ({ name: value, value: value })),
    [values]
  );
  const optionsNoDuplicates = useMemo(
    () =>
      unionBy(options, valuesAsOptions, 'value')
        .sort((a, b) => safeLocaleCompare(a.name, b.name))
        .filter((x) => x.value !== allValuesOption?.value),
    [allValuesOption?.value, options, valuesAsOptions]
  );

  const filteredOptions = useMemo(() => {
    const filtered = (
      allValuesOption ? [allValuesOption, ...optionsNoDuplicates] : optionsNoDuplicates
    ).filter((x) => (x?.name || '').toString().toLowerCase().includes(search.toLowerCase()));
    return filtered;
  }, [allValuesOption, optionsNoDuplicates, search]);

  const removeSelected = (x: string) => {
    const filteredValues = values.filter((v) => v.toLowerCase() !== x.toLowerCase());
    setValues(filteredValues);
  };

  const ITEM_HEIGHT = 26;
  const MAX_HEIGHT = 140;

  const listHeight = Math.min(filteredOptions.length * ITEM_HEIGHT, MAX_HEIGHT);

  const isOverflowing = listHeight === MAX_HEIGHT;

  return (
    <div
      style={{
        paddingBottom: isOverflowing ? 0 : '4px'
      }}
    >
      <div className={s.search_input}>
        <AlloyInput
          size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          variant="borderless"
          className={s.input}
        />
      </div>
      {displaySelected && (
        <div className={s.multiple_selected_values}>
          {values.map((x) => (
            <AlloyButton
              data-testid={`remove-option-${x || 'n-a'}`}
              key={x}
              size="small"
              onClick={() => removeSelected(x)}
              className={s.remove_button}
            >
              {options.find((option) => option.value === x)?.name || x || '[N/A]'}
            </AlloyButton>
          ))}
        </div>
      )}
      {filteredOptions.length === 0 && !!search ? (
        <div className={s.empty}>No options found</div>
      ) : (
        <></>
      )}
      <VirtualList
        data={filteredOptions}
        height={listHeight}
        itemHeight={ITEM_HEIGHT}
        itemKey="value"
      >
        {(x) => (
          <div>
            <AlloyCheckbox
              checked={values.includes(x.value)}
              onChange={(e) =>
                e.target.checked
                  ? setValues([...new Set([...values, x.value])])
                  : removeSelected(x.value)
              }
              value={x.value}
              data-testid={`option-${x.name || 'n-a'}`}
              className={clsx(s.checkbox)}
            >
              {x.name || '[N/A]'}
            </AlloyCheckbox>
          </div>
        )}
      </VirtualList>
    </div>
  );
};
