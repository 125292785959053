import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import s from './ErrorVisibility.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySwitch } from 'components/ui/AlloySwitch/AlloySwitch';
import { UserContext } from 'context/userContext';
import { AppHistoryContext } from 'context/AppHistoryContext';
import { BooleanParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { SearchOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { ErrorsToolbar } from './components/ErrorsToolbar/ErrorsToolbar';
import { ErrorsTable } from './components/ErrorsTable/ErrorsTable';
import { ExportErrorsModal } from './components/ExportErrorsModal/ExportErrorsModal';
import { getPageSize, Paginator } from 'components/Paginator/Paginator';
import { ReportableErrorsDocument } from './gql/__generated__/reportableErrors.query';
import {
  ReportableError,
  ReportableErrorSort,
  SortOrderDirection
} from 'graphql/__generated__/types';
import { ReportableErrorFromQuery } from './types';
import { parse, ParsedQuery } from 'query-string';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { ErrorToolbarState } from './components/ErrorsToolbar/ErrorsToolbar';
import { useUrlParams } from 'common/helpers/urlParams';
import { DisplayMode } from './types';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';

interface SortConfig {
  columnKey: string;
  order: string;
  limit: string | null;
}

const getSortConfig = (location: ParsedQuery): SortConfig => {
  return Object.assign(
    {
      columnKey: 'INSERTED_AT',
      order: 'DESC',
      limit: null
    },
    location
  );
};

export const ErrorVisibilityPage = () => {
  const history = useHistory();
  const { isAdmin, isOperations } = useContext(UserContext);
  const { getLink } = useContext(AppHistoryContext);

  const [urlParams, setUrlParams] = useUrlParams();

  const toolbarState = useRef<ErrorToolbarState>();
  const [searchPoIds, setSearchPoIds] = useState<string[]>(
    Array.isArray(urlParams.purchaseOrders)
      ? urlParams.purchaseOrders
      : urlParams.purchaseOrders
        ? [urlParams.purchaseOrders]
        : []
  );
  const location = parse(history.location.search);
  const sortConfig = getSortConfig(location);
  const [selectedRows, setSelectedRows] = useState<ReportableErrorFromQuery[]>([]);
  const [showExportErrorsModal, setShowExportErrorsModal] = useState(false);

  // for page toggle display state
  const DEFAULT_TAB: DisplayMode = 'errorVisibility';
  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));

  const [isOnlyLive, setIsOnlyLive] = useQueryParam('live', withDefault(BooleanParam, false));

  useEffect(() => {
    setUrlParams({
      ...toolbarState.current?.filters,
      purchaseOrders: searchPoIds,
      after: undefined,
      before: undefined
    });
  }, [searchPoIds, setUrlParams]);

  const { data, loading, called } = useQuery(ReportableErrorsDocument, {
    variables: {
      first: location.after || !location.before ? getPageSize(location) : null,
      last: !location.after && location.before ? getPageSize(location) : null,
      after: location?.after ? (location?.after as string) : undefined,
      before: location.before && !location.after ? (location.before as string) : undefined,
      filter: {
        tpExternalIds: toolbarState.current?.filters?.tradingPartners || null,
        type: toolbarState.current?.filters?.type || null,
        insertedAt:
          toolbarState.current?.filters?.startDate && toolbarState.current?.filters?.startDate
            ? {
                start: toolbarState.current?.filters?.startDate || null,
                end: toolbarState.current?.filters?.endDate || null
              }
            : undefined,
        customerPos: searchPoIds
      },
      ignoreAllowedTradingPartners: false,
      sort: {
        column: sortConfig.columnKey as ReportableErrorSort,
        direction: sortConfig.order as SortOrderDirection
      }
    }
  });

  const errorsList = getNodesFromEdges(data?.reportableErrors);

  const onSelectionAction = (selectedRows: ReportableError[]) => {
    setSelectedRows(selectedRows);
  };

  /***************  Pagination Functions ******************************/

  const handlePageSizeChange = async (value: number) => {
    setUrlParams({ limit: value, after: null, before: null });
    window.scrollTo(0, 0);
  };

  const nextPage = async () => {
    setUrlParams({ after: data?.reportableErrors?.pageInfo?.endCursor, before: null });
    window.scrollTo(0, 0);
  };

  const prevPage = async () => {
    setUrlParams({ before: data?.reportableErrors?.pageInfo?.startCursor, after: null });
    window.scrollTo(0, 0);
  };

  /******************  Table Sorting  ***************/

  const handleTableSorting = (columnKey: string, order: string) => {
    setUrlParams({
      after: null,
      before: null,
      columnKey,
      order
    });
  };

  const getSortOrder = (columnKey: string) => {
    if (columnKey !== location.columnKey) return undefined;
    return location.order === 'ASC' ? 'ascend' : 'descend';
  };

  const toggleExportErrorsModal = () => {
    setShowExportErrorsModal(!showExportErrorsModal);
  };

  /***********   For page toggle   ************/
  const allModes: { value: DisplayMode; label: string }[] = [
    { value: 'purchaseOrders', label: 'Purchase Orders' },
    { value: 'errorVisibility', label: 'Error Visibility' }
  ];

  const availableModes =
    isAdmin() || isOperations()
      ? allModes
      : allModes.filter((x) => !['errorVisibility'].includes(x.value));
  /************************************************************** */

  /*********************   For live orders toggle   *********************/
  const getErrorsList = () => {
    if (isOnlyLive) {
      const filteredErrors = [...errorsList].filter((error) => error.purchaseOrder?.live);
      return filteredErrors;
    } else return errorsList;
  };
  /************************************************************* */

  return (
    <div data-testid="error-page-container">
      <div data-testiid="error-page-header" className={s.header}>
        <div data-testid="error-page-title" className={s.page_title}>
          <PageHeader>Error Visibility</PageHeader>
        </div>
        <div data-testid="error-page-toolbar">
          <ErrorsToolbar
            ref={toolbarState}
            selectedRows={selectedRows}
            toggleExportErrorsModal={toggleExportErrorsModal}
          />
        </div>
      </div>
      <div className={s.page_toggle}>
        <Link to={getLink('/')}>
          <AlloySegmented
            options={availableModes}
            value={mode}
            onChange={(value) => setMode(value)}
          />
        </Link>
      </div>
      <div className={s.search}>
        <MultipleValuesInput
          data-testid="error-page-search-bar"
          placeholder="Search by PO Number"
          value={searchPoIds}
          onChange={(value) => setSearchPoIds(value)}
          allowClear={true}
          prefix={<SearchOutlined />}
          splitInputValue={/[^0-9a-zA-Z-]+/g}
        />
      </div>
      <div className={s.error_table_toolbar}>
        <div className={s.errors_count_container}>
          {selectedRows.length === 0 && (
            <div className={s.total_count}>{data?.reportableErrors?.totalCount} errors</div>
          )}
          {selectedRows.length > 0 && (
            <div className={s.selected_items_info}>
              {selectedRows.length} error{selectedRows.length > 1 && 's'} selected
              {selectedRows.length > 0 ? (
                <AlloyButton className="filled_grey_btn" onClick={() => onSelectionAction([])}>
                  Clear selection
                </AlloyButton>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        <div className={s.toggle_and_paginator_container}>
          <div className={s.orders_toggle}>
            <AlloySwitch
              checked={isOnlyLive}
              onChange={(value) => {
                setIsOnlyLive(value);
              }}
              size="small"
              loading={loading}
            />
            <span>Show Live PO only</span>
          </div>
          <div data-testid="error-page-paginator" className={s.paginator_start}>
            <Paginator
              nextPage={nextPage}
              prevPage={prevPage}
              handlePageSizeChange={handlePageSizeChange}
              hasNextPage={!!data?.reportableErrors?.pageInfo?.hasNextPage}
              hasPreviousPage={!!data?.reportableErrors?.pageInfo?.hasPreviousPage}
              pageSize={getPageSize(location, DEFAULT_PAGE_SIZE)}
              onlyButtons={false}
            />
          </div>
        </div>
      </div>
      <div data-testid="error-page-table" className={s.table}>
        <ErrorsTable
          loading={loading || !called}
          errorsList={getErrorsList()}
          getSortOrder={getSortOrder}
          handleTableSorting={handleTableSorting}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
      <div data-testid="error-page-paginator" className={s.paginator_end}>
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          handlePageSizeChange={handlePageSizeChange}
          hasNextPage={!!data?.reportableErrors?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.reportableErrors?.pageInfo?.hasPreviousPage}
          pageSize={getPageSize(location, DEFAULT_PAGE_SIZE)}
          onlyButtons={false}
        />
      </div>
      <ExportErrorsModal
        visibility={showExportErrorsModal}
        toggleExportErrorsModal={() => setShowExportErrorsModal(false)}
        selectedRows={selectedRows}
      />
    </div>
  );
};
