import React from 'react';
import { ReportableErrorFromQuery } from '../../types';
import moment from 'moment';
import { NOT_AVAILABLE } from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';

interface ErrorTableProps {
  selectedRows: ReportableErrorFromQuery[];
}

export const ExportErrorsTable = ({ selectedRows }: ErrorTableProps) => {
  const columns: ColumnsType<ReportableErrorFromQuery> = [
    {
      title: 'Date & Time',
      key: 'INSERTED_AT',
      width: '200px',
      render: (_, { insertedAt }) => (
        <div data-testid="reportable-error-timeline">
          {moment(insertedAt).format('MMM D YYYY, hh:mm A') || NOT_AVAILABLE}
        </div>
      )
    },
    {
      title: 'Event',
      key: 'MESSAGE',
      width: '350px',
      render: (_, { message }) => (
        <div data-testid="reportable-error-event">
          {message?.replace(/^./, message[0].toUpperCase()) || NOT_AVAILABLE}
        </div>
      )
    },
    {
      title: 'Error count',
      key: 'COUNT',
      width: '50px',
      render: ({ count }) => (count > 1 ? count : null)
    },
    {
      title: 'Last attempt',
      key: 'UPDATED_AT',
      width: '50px',
      render: ({ updatedAt, count }) =>
        count > 1 ? (
          <div data-testid="reportable-error-updatedAt">
            {moment(updatedAt).format('MMM D YYYY, hh:mm A') || NOT_AVAILABLE}
          </div>
        ) : null
    },
    {
      title: 'Error status',
      key: 'STATUS',
      width: '50px',
      render: ({ status }) => status
    },
    {
      title: 'PO',
      key: 'CUSTOMER_PO',
      width: '200px',
      render: (_, { purchaseOrder }) => (
        <div data-testid="reportable-error-po">{purchaseOrder?.customerPo || NOT_AVAILABLE}</div>
      )
    },
    {
      title: 'Trading Partner',
      key: 'TRADING_PARTNER_EXTERNAL_ID',
      width: '250px',
      render: (_, { purchaseOrder }) => (
        <div data-testid="reportable-error-tp">
          {purchaseOrder?.tradingPartner?.externalId || NOT_AVAILABLE}
        </div>
      )
    },
    {
      title: 'Current PO status',
      key: 'PO_STATUS',
      width: '150px',
      render: (_, { purchaseOrder }) => (
        <div data-testid="reportable-errors-po-status">
          {purchaseOrder?.status || NOT_AVAILABLE}
        </div>
      )
    }
  ];

  return (
    <div data-testid="export-errors-table" className="export-errors-table">
      <AlloyTable
        rowKey="id"
        className="legacy_borderless_bordered"
        pagination={false}
        dataSource={selectedRows}
        columns={columns}
        bordered
      />
    </div>
  );
};
