import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SaveOrdersSpreadsheetMutationVariables = Types.Exact<{
  input: Types.SaveOrdersSpreadsheetInput;
}>;


export type SaveOrdersSpreadsheetMutation = { __typename?: 'RootMutationType', saveOrdersSpreadsheet?: { __typename?: 'SaveOrdersSpreadsheetPayload', message: string, uploaded: number } | null };


export const SaveOrdersSpreadsheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"saveOrdersSpreadsheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveOrdersSpreadsheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveOrdersSpreadsheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"uploaded"}}]}}]}}]} as unknown as DocumentNode<SaveOrdersSpreadsheetMutation, SaveOrdersSpreadsheetMutationVariables>;