import React from 'react';
import { Steps as AntSteps, Steps, StepsProps } from 'ant5';

export function AlloySteps({ ...otherProps }: StepsProps) {
  return <AntSteps {...otherProps} />;
}

AlloySteps.Step = Steps.Step;

// Re-exporting types
export type { StepsProps };
