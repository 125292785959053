import { BlockOutlined, CloseOutlined } from '@ant-design/icons';
import { TpAssortment } from 'common/interfaces';
import { useEnumValue } from 'common/useEnumValue';
import { composeValidators, validateRequired } from 'components/Common/fields/Utils';
import { ValidationMessage } from 'components/Common/fields/ValidationMessage/ValidationMessage';
import { FieldValidator, FormApi } from 'final-form';
import { cloneDeep, groupBy } from 'lodash-es';
import {
  AddStoreInput,
  EditStoreInput,
  getDistributionCenterTitle,
  SpecialForTradingPartnersFields
} from 'pages/ShipToPage/helper';
import { RetailerChannelsForShipTo } from 'pages/ShipToPage/ShipToPage';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { DistributionCenter, TradingPartner } from '../../AddShipToModal';
import { BulkAddAssortmentModal, Product } from '../BulkAddAssortmentModal/BulkAddAssortmentModal';
import { BulkAddAssortmentTable } from '../BulkAddProductsTable';
import {
  DistributionCenterItem,
  AllowedDistributionCentersList
} from '../../../AllowedDistributionCentersList/AllowedDistributionCentersList';
import {
  RetailerDeliveryDestinationToCopy,
  SelectShipToModal,
  TP_RDD_ERROR
} from '../SelectShipToModal/SelectShipToModal';
import s from './AddShipToStepper.module.scss';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import clsx from 'clsx';

interface AddTradingPartnerStepperProps {
  form: FormApi<AddStoreInput, Partial<AddStoreInput>>;
  deliveryDestination: RetailerDeliveryDestinationToCopy | undefined;
  upserting: boolean;
  onCancel: () => void;
  distributionCenters: DistributionCenter[];
  tradingPartners: TradingPartner[];
  vendorMarkets: RetailerChannelsForShipTo[];
  onSelectShipTo: (shipTo?: RetailerDeliveryDestinationToCopy, tpId?: string) => void;
}

const getRequiredFieldName = (name: string) => {
  switch (name) {
    case 'cisId':
      return 'CIS_ID';
    case 'cofId':
      return 'COF_ID';
    case 'externalId':
      return 'EXTERNAL_ID';
    case 'externalStoreNumber':
      return 'EXTERNAL_STORE_NUMBER';
    case 'sanCode':
      return 'SAN_CODE';
    case 'sapCustomerId':
      return 'SAP_CUSTOMER_ID';
    case 'soldToSapCustomerId':
      return 'SOLD_TO_SAP_CUSTOMER_ID';
    default:
      return name.toUpperCase();
  }
};

export const getTradingPartnerOptions = (
  tradingPartners: TradingPartner[],
  vendorMarkets: RetailerChannelsForShipTo[]
) => {
  const options = [];
  const rcDict = groupBy(tradingPartners, 'vendorMarket.id');
  for (const retailerChannelId in rcDict) {
    const name = vendorMarkets.find((vm) => vm.id === retailerChannelId)?.name || '';
    options.push({
      label: name,
      options: rcDict[retailerChannelId]
        .sort((tp1, tp2) => (tp1.name || '').localeCompare(tp2.name || ''))
        .map((tp) => ({
          label: tp.name || '',
          value: tp.id
        }))
    });
  }
  return options;
};

export const getShipToGeneralFields = (
  storeTypesLoading: boolean,
  storeTypes: string[] | undefined,
  tradingPartners: TradingPartner[],
  vendorMarkets: RetailerChannelsForShipTo[],
  withTradingPartner: boolean,
  requiredFields: string[],
  form:
    | FormApi<AddStoreInput, Partial<AddStoreInput>>
    | FormApi<EditStoreInput, Partial<EditStoreInput>>,
  deliveryDestination?: RetailerDeliveryDestinationToCopy,
  focusible?: boolean,
  showTitle = true
) => {
  const validateRequiredForTpField = (value: string, all: AddStoreInput, meta: any) => {
    const tpIds: (string | null | undefined)[] = [];

    if (all?.tradingPartnerId) {
      tpIds.push(all?.tradingPartnerId);
    }
    if (all?.tradingPartnerConfiguration) {
      all?.tradingPartnerConfiguration.forEach((tpc) => {
        tpIds.push(tpc.tradingPartnerId);
      });
    }
    if (tpIds.length > 0) {
      const rfs = tradingPartners
        .filter((tp) => tpIds.includes(tp.id))
        .reduce((result, current) => {
          if (current.storeConfig?.requiredIds) {
            current.storeConfig?.requiredIds.forEach((field) => {
              if (field && !result.includes(field)) {
                result.push(field);
              }
            });
          }
          return result;
        }, [] as string[]);
      if (rfs.includes(getRequiredFieldName(meta.name))) return validateRequired(value);
    }
    return null;
  };

  return (
    <>
      {showTitle && <AlloyRow className={s.title_row}>General Settings</AlloyRow>}
      <AlloyRow gutter={16}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            name="externalId"
            required
            fieldProps={{
              title: 'External ID'
            }}
          />
          <AlloyFormField
            component="input"
            name="name"
            required
            fieldProps={{
              title: 'Name'
            }}
          />
          <AlloyFormField
            component="input"
            name="externalStoreNumber"
            required={requiredFields.includes('EXTERNAL_STORE_NUMBER')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'Store Number'
            }}
          />
          <AlloyFormField
            component="input"
            name="cisId"
            required={requiredFields.includes('CIS_ID')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'CIS Number'
            }}
          />
          <AlloyFormField
            component="input"
            name="sanCode"
            required={requiredFields.includes('SAN_CODE')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'SAN Number'
            }}
          />
          <AlloyFormField
            component="select"
            name="storeType"
            fieldProps={{
              loading: storeTypesLoading,
              title: 'Store Type',
              options: (storeTypes || []).map((type) => ({
                value: type,
                label: type
              }))
            }}
          />
          <AlloyFormField
            component="input"
            name="timezone"
            fieldProps={{
              title: 'Timezone'
            }}
          />
          <AlloyFormField
            component="checkbox"
            name="specialAssortment"
            inline="after"
            fieldProps={{
              title: 'Special Assortment (Vendor Flex)'
            }}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          {withTradingPartner && (
            <>
              <AlloyFormField
                component="select"
                name="tradingPartnerId"
                required
                validate={
                  !deliveryDestination?.id
                    ? validateRequired
                    : composeValidators([
                        validateRequired,
                        (value: string) =>
                          deliveryDestination.tradingPartners?.find((tp) => tp?.id === value)
                            ? TP_RDD_ERROR
                            : null
                      ])
                }
                fieldProps={{
                  title: 'Trading Partner',
                  options: getTradingPartnerOptions(tradingPartners, vendorMarkets)
                }}
              />
              <OnChange name="tradingPartnerId">
                {(value: string) => {
                  const currentTp = tradingPartners.find((tp) => tp.id === value);
                  if (currentTp) {
                    form.mutators.setRetailerChannel(currentTp?.vendorMarket?.id);
                  }
                }}
              </OnChange>
            </>
          )}
          {!withTradingPartner && (
            <AlloyFormField
              component="select"
              name="vendorMarketId"
              required
              fieldProps={{
                title: 'Retailer Channel',
                options: (vendorMarkets || [])
                  .sort((vm1, vm2) => (vm1.name || '').localeCompare(vm2.name || ''))
                  .map((vm) => ({
                    value: vm.id || '',
                    label: vm.name || ''
                  })),
                disabled: true
              }}
            />
          )}
          <AlloyFormField
            component="input"
            name="cofId"
            required={requiredFields.includes('COF_ID')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'Oracle COF Number'
            }}
          />
          <AlloyFormField
            component="input"
            name="sapCustomerId"
            required={requiredFields.includes('SAP_CUSTOMER_ID')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'SAP Ship-To Number'
            }}
          />
          <AlloyFormField
            component="input"
            name="soldToSapCustomerId"
            required={requiredFields.includes('SOLD_TO_SAP_CUSTOMER_ID')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'SAP Sold To Number'
            }}
          />
          <AlloyFormField
            component="input"
            name="duns"
            required={requiredFields.includes('DUNS')}
            validate={validateRequiredForTpField as FieldValidator<string>}
            fieldProps={{
              title: 'DUNS'
            }}
          />
          <AlloyFormField
            component="datepicker"
            name="openingDate"
            fieldProps={{
              title: 'Opening Date'
            }}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow className={s.title_row}>Address</AlloyRow>
      <AlloyRow gutter={16}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            name="address"
            fieldProps={{
              title: 'Address'
            }}
          />
          <AlloyFormField
            component="input"
            name="addressState"
            fieldProps={{
              title: 'State'
            }}
          />
          <AlloyFormField
            component="input"
            name="metroArea"
            fieldProps={{
              title: 'Metro Area'
            }}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            name="addressCity"
            fieldProps={{
              title: 'City'
            }}
          />
          <AlloyFormField
            component="input"
            name="addressZip"
            fieldProps={{
              title: 'Zip'
            }}
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );
};

export const getDeliveryScheduleArrayEditing = (
  daysOfWeek: string[],
  daysOfWeekLoading: boolean,
  name?: string
) => (
  <FieldArray name={name ? `${name}.deliverySchedule` : 'deliverySchedule'}>
    {({ fields }) => (
      <div>
        <AlloyRow className={s.array_title}>
          Delivery Schedule
          <AlloyButton
            className={s.bold_button}
            onClick={() => {
              fields.push({ dayOfWeek: undefined, cutoffTime: undefined });
            }}
            type="secondary"
          >
            Add a day
          </AlloyButton>
        </AlloyRow>
        <AlloyRow>
          <AlloyTable
            size="small"
            className={s.delivery_schedule_table}
            rowKey={({ index }) => index}
            columns={[
              {
                title: 'Day of the week',
                key: 'dayOfWeek',
                width: 265,
                render: ({ name }) => (
                  <div>
                    <AlloyFormField
                      component="select"
                      name={`${name}.dayOfWeek`}
                      required
                      fieldProps={{
                        loading: daysOfWeekLoading,
                        title: 'Day Of Week',
                        filterSort: undefined,
                        options: (daysOfWeek || []).map((day) => ({
                          value: day,
                          label: day
                        }))
                      }}
                    />
                    <AlloyFormField
                      component="input"
                      name={`${name}.expectedAsnHoursBeforeDelivery`}
                      fieldProps={{
                        title: 'Expected Asn Hours Before Delivery'
                      }}
                    />
                    <AlloyFormField
                      component="input"
                      name={`${name}.expectedInvoiceHoursAfterDelivery`}
                      fieldProps={{
                        title: 'Expected Invoice Hours After Delivery'
                      }}
                    />
                    <AlloyFormField
                      component="input"
                      name={`${name}.expectedPurchaseOrderHoursBeforeDelivery`}
                      fieldProps={{
                        title: 'Expected Purchase Order Hours Before Delivery'
                      }}
                    />
                  </div>
                )
              },
              {
                title: 'Cutoff time',
                key: 'cutoffTime',
                render: ({ name }) => (
                  <div>
                    <AlloyFormField
                      component="timepicker"
                      name={`${name}.cutoffTime`}
                      required
                      fieldProps={{
                        title: 'Cutoff Time'
                      }}
                    />
                    <AlloyFormField
                      component="timepicker"
                      name={`${name}.expectedDeliveryTime`}
                      fieldProps={{
                        title: 'Expected Delivery Time'
                      }}
                    />
                    <AlloyFormField
                      component="input"
                      name={`${name}.expectedPoAcknowledgementHoursBeforeDelivery`}
                      fieldProps={{
                        title: 'Expected PO Acknowledgement Hours Before Delivery'
                      }}
                    />
                    <AlloyFormField
                      component="input"
                      name={`${name}.expectedSalesOrderHoursBeforeDelivery`}
                      fieldProps={{
                        title: 'Expected Sales Order Hours Before Delivery'
                      }}
                    />
                  </div>
                )
              },
              {
                title: '',
                key: 'delete',
                width: 50,
                render: ({ index }) => (
                  <AlloyButton
                    shape="circle"
                    type="text"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <CloseOutlined />
                  </AlloyButton>
                )
              }
            ]}
            dataSource={fields.map((name, index) => ({
              name,
              index
            }))}
            pagination={false}
          />
        </AlloyRow>
      </div>
    )}
  </FieldArray>
);

export const AddShipToStepper = ({
  form,
  onCancel,
  distributionCenters,
  tradingPartners,
  vendorMarkets,
  onSelectShipTo,
  deliveryDestination
}: AddTradingPartnerStepperProps) => {
  const [showSelectShipToModal, setShowSelectShipToModal] = useState<boolean>(false);
  const [current, setCurrent] = useState(0);
  const [showBulkAddAssortment, setShowBulkAddAssortment] = useState<boolean>(false);

  const { enumValues: storeTypes, loading: storeTypesLoading } = useEnumValue('StoreType');
  const { enumValues: daysOfWeek, loading: daysOfWeekLoading } = useEnumValue('DayOfWeek');

  const [requiredFields, setRequiredFields] = useState<string[]>([]);

  const [tradingPartnerAssortment, setTradingPartnerAssortment] = useState<Product[]>([]);
  const tpId = form.getFieldState('tradingPartnerId')?.value;
  useEffect(() => {
    const newRequiredFields = form.getFieldState('tradingPartnerId')?.value
      ? tradingPartners
          .filter((tp) => [form.getFieldState('tradingPartnerId')?.value].includes(tp.id))
          .reduce((result, current) => {
            if (current.storeConfig?.requiredIds) {
              current.storeConfig?.requiredIds.forEach((field) => {
                if (field && !result.includes(field)) {
                  result.push(field);
                }
              });
            }
            return result;
          }, [] as string[])
      : [];
    setRequiredFields(newRequiredFields);
  }, [tpId, form, tradingPartners]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleClose = () => {
    setTradingPartnerAssortment([]);
    form.mutators.setTpAssortments([]);
    setCurrent(0);
    onCancel();
  };

  const getAddShipToForm = () => {
    return (
      <div className={s.form_content}>
        <AlloyRow>
          Add Ship-To information below
          <span style={{ fontWeight: '600', margin: '0 4px' }}>or</span>prefill the page by
          duplicating an existing Ship-To.
        </AlloyRow>
        <AlloyRow className={s.buttons_container}>
          <AlloyButton
            icon={<BlockOutlined />}
            className={s.bold_button}
            onClick={() => {
              setShowSelectShipToModal(true);
            }}
            type="secondary"
          >
            Fill out with existing Ship-To
          </AlloyButton>
          <AlloyButton
            onClick={() => {
              onSelectShipTo();
            }}
            className={s.bold_button}
            disabled={!deliveryDestination?.id}
            type="secondary"
          >
            Clear form
          </AlloyButton>
        </AlloyRow>
        {getShipToGeneralFields(
          storeTypesLoading,
          storeTypes,
          tradingPartners,
          vendorMarkets,
          true,
          requiredFields,
          form,
          deliveryDestination,
          current === 0
        )}
      </div>
    );
  };

  const steps = [
    {
      title: 'Ship-Tos',
      content: getAddShipToForm()
    },
    {
      title: 'TP settings',
      content: (
        <div className={s.form_content}>
          <AlloyRow>
            Add Trading Partner settings information below. The <span className={s.error}>*</span>{' '}
            indicates the field is required.
          </AlloyRow>
          <AlloyRow className={s.title_row}>Trading Partner specific settings</AlloyRow>
          <SpecialForTradingPartnersFields distributionCenters={distributionCenters} />
          {getDeliveryScheduleArrayEditing(daysOfWeek || [], daysOfWeekLoading)}
        </div>
      )
    },
    {
      title: 'DC settings',
      content: (
        <FieldArray name="allowedDcs" validate={validateRequired} key="allowedDcs">
          {({ fields, meta }) => {
            return (
              <div
                style={{
                  width: '678px',
                  maxWidth: '678px',
                  marginRight: '8px',
                  marginLeft: '8px'
                }}
              >
                <AllowedDistributionCentersList
                  data-testid="add-ship-to-dc-tab-ordering-dc-list"
                  allDistributionCenters={distributionCenters.map((dc) => ({
                    id: dc.id,
                    text: `${dc.name} (${dc.code})`
                  }))}
                  selected={(fields.value || [])
                    .sort((dc1, dc2) => dc1.priority - dc2.priority)
                    .map((dc) => ({
                      id: dc.distributionCenterId,
                      text: getDistributionCenterTitle(dc, distributionCenters),
                      externalId: dc.externalId
                    }))}
                  onChange={(newAllowedDcs: DistributionCenterItem[]) => {
                    form.mutators.setAllowedDcs(
                      newAllowedDcs.map((dc, index) => ({
                        distributionCenterId: dc.id,
                        externalId: dc.externalId,
                        priority: index
                      }))
                    );
                  }}
                />
                <ValidationMessage
                  meta={meta}
                  overrideValidationMessage="Please select at least one DC to continue"
                />
              </div>
            );
          }}
        </FieldArray>
      )
    },
    {
      title: 'Assortment upload',
      content: (
        <FieldArray name="tpAssortments" key="tpAssortments">
          {({ fields }) => (
            <div
              style={{ width: '678px', maxWidth: '678px', marginRight: '8px', marginLeft: '8px' }}
            >
              <span className={s.copy_description}>
                <span>Optional</span>: Select a Trading Partner's assortment list you'd like to
                duplicate.
              </span>
              <div className={s.add_product_buttons}>
                <AlloyButton
                  className={clsx(s.add_button, s.bold_button)}
                  onClick={() => {
                    setTradingPartnerAssortment([]);
                    form.mutators.setTpAssortments([]);
                  }}
                  disabled={tradingPartnerAssortment.length === 0}
                  type="secondary"
                >
                  Clear selection
                </AlloyButton>
                <AlloyButton
                  onClick={() => setShowBulkAddAssortment(true)}
                  type="primary"
                  className={s.bold_button}
                >
                  Add products
                </AlloyButton>
              </div>
              <div style={{ marginBottom: '12px' }}>
                {(fields.value || []).length} Products selected
              </div>
              <BulkAddAssortmentTable
                loading={false}
                tradingPartnerAssortment={tradingPartnerAssortment}
                selected={(fields.value || []).map(
                  (assortmentInput) => assortmentInput.vendorProductId
                )}
                setSelected={(selected: string[]) => {
                  form.mutators.setTpAssortments(
                    tradingPartnerAssortment.reduce((result, current) => {
                      if (selected.includes(current.retailerProductId)) {
                        result.push({
                          active: true,
                          vendorProductId: current.retailerProductId,
                          tradingPartnerId: form.getFieldState('tradingPartnerId')?.value || ''
                        });
                      }
                      return result;
                    }, [] as TpAssortment[])
                  );
                }}
              />
              <BulkAddAssortmentModal
                visible={showBulkAddAssortment}
                onAdd={(newAssortment: Product[]) => {
                  const newTradingPartnerAssortment = cloneDeep(tradingPartnerAssortment);
                  const existingIds = tradingPartnerAssortment.map(
                    (assortment) => assortment.retailerProductId
                  );
                  newAssortment.forEach((assortment) => {
                    if (!existingIds.includes(assortment.retailerProductId)) {
                      newTradingPartnerAssortment.push(assortment);
                    }
                  });
                  setTradingPartnerAssortment(newTradingPartnerAssortment);
                  const selectedIds = fields.value.map((selected) => selected.vendorProductId);
                  newAssortment.forEach((assortment) => {
                    if (!selectedIds.includes(assortment.retailerProductId)) {
                      selectedIds.push(assortment.retailerProductId);
                    }
                  });
                  form.mutators.setTpAssortments(
                    selectedIds.map((vpId) => ({
                      active: true,
                      vendorProductId: vpId,
                      tradingPartnerId: form.getFieldState('tradingPartnerId')?.value
                    }))
                  );
                }}
                onClose={() => setShowBulkAddAssortment(false)}
                tradingPartners={tradingPartners}
              />
            </div>
          )}
        </FieldArray>
      )
    }
  ];

  return (
    <>
      <FullScreenEditingModal
        title={`${!!deliveryDestination?.id ? 'Edit' : 'Add'} Ship-To`}
        open={!!deliveryDestination}
        onClose={handleClose}
        buttons={
          <>
            <AlloyButton className={s.steps_button} onClick={handleClose}>
              Cancel
            </AlloyButton>
            {current > 0 && (
              <AlloyButton className={s.steps_button} onClick={() => prev()}>
                Back
              </AlloyButton>
            )}
            {current < steps.length - 1 && (
              <AlloyButton
                className={s.steps_button}
                type="primary"
                onClick={() => {
                  const { errors } = form.getState();
                  if (
                    errors &&
                    ((current === 0 &&
                      (errors['externalId'] ||
                        errors['name'] ||
                        errors['vendorMarketId'] ||
                        errors['tradingPartnerId'] ||
                        errors['cisId'] ||
                        errors['duns'] ||
                        errors['sapCustomerId'] ||
                        errors['soldToSapCustomerId'] ||
                        errors['externalStoreNumber'] ||
                        errors['cofIf'] ||
                        errors['sanCode'])) ||
                      (current === 1 && (errors['deliverySchedule'] || errors['dcSoldTos'])) ||
                      (current === 2 && errors['allowedDcs']))
                  ) {
                    form.submit();
                  } else {
                    next();
                  }
                }}
              >
                Next
              </AlloyButton>
            )}
            {current === steps.length - 1 && (
              <AlloyButton
                className={s.steps_button}
                type="primary"
                onClick={() => {
                  form.submit()?.finally(() => {
                    setTradingPartnerAssortment([]);
                    form.mutators.setTpAssortments([]);
                    setCurrent(0);
                  });
                }}
              >
                Add
              </AlloyButton>
            )}
          </>
        }
        steps={
          <AlloySteps current={current} size="small" style={{ width: '718px' }} items={steps} />
        }
      >
        {steps[current].content}
      </FullScreenEditingModal>
      {showSelectShipToModal && (
        <SelectShipToModal
          tradingPartners={tradingPartners}
          onSelect={(tpTo: string, shipTo: RetailerDeliveryDestinationToCopy) => {
            onSelectShipTo(shipTo, tpTo);
            setShowSelectShipToModal(false);
          }}
          onCancel={() => setShowSelectShipToModal(false)}
          vendorMarkets={vendorMarkets}
        />
      )}
    </>
  );
};
