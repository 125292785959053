import React from 'react';
import { Select as AntSelect, SelectProps } from 'ant5';
import { BaseOptionType, DefaultOptionType } from 'ant5/lib/select';
import { OptGroup, Option } from 'rc-select';
import s from './AlloySelect.module.scss';
import clsx from 'clsx';

export function AlloySelect<
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType
>({ className, ...otherProps }: SelectProps<ValueType, OptionType>) {
  return (
    <AntSelect<ValueType, OptionType>
      maxTagPlaceholder={(omittedValues) => (
        <div title={omittedValues.map((value) => value.label).join(', ')}>
          +{omittedValues.length}
        </div>
      )} //NOTE: this is to show a tooltip of the array of selected values when number exceeds maxTagCount
      {...otherProps}
      className={clsx(s.alloy_select, className)}
    />
  );
}

AlloySelect.Option = Option;
AlloySelect.OptGroup = OptGroup;

// Re-exporting types
export type { SelectProps, DefaultOptionType };
