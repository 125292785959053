import { difference } from 'lodash-es';

/**
 * Manages an array state with the ability to toggle between a specific "all" value and a distinct set of values.
 * This function modifies the array state to either include or exclude all possible values based on user selection.
 *
 * @example
 * // Sets the state to ["all"] if the new value is an empty array.
 * setArrayWithAll([], ["all"], setState); // setState will be called with ["all"]
 *
 * @example
 * // Toggles off the "all" value if it is already included in the previous value.
 * setArrayWithAll(["all", "a"], ["all"], setState); // setState will be called with ["a"]
 *
 * @example
 * // Sets the state to the newValue when "all" is not included.
 * setArrayWithAll(["a"], ["b"], setState); // setState will be called with ["a"]
 *
 * @param newValue - The new array values proposed, which may include the "all" indicator.
 * @param prevValue - The previous array values for comparison.
 * @param setState - A function to update the state with selected values.
 * @param allValue - The string that represents the "all" option.
 */
export function setArrayWithAll(
  newValue: string[],
  prevValue: string[],
  setState: (value: string[]) => void,
  allValue = 'all'
) {
  const allLowercase = allValue.toLowerCase();

  if (newValue.length === 0) {
    setState([allValue]);
  } else if (newValue.map((x) => x.toLowerCase()).includes(allLowercase)) {
    if ((prevValue || []).map((x) => x.toLowerCase()).includes(allLowercase)) {
      setState(newValue.filter((v) => v.toLowerCase() !== allLowercase));
    } else {
      setState([allValue]);
    }
  } else {
    setState(newValue);
  }
}

/**
 * Manages an array state, allowing for toggling the inclusion of an "all" indicator, with the option to permit empty state.
 * This function updates the state based on user input, supporting both full selection and individual items, including empty selections.
 *
 * @example
 * // Sets state to an empty array if the new value is empty.
 * setArrayWithAllAndAllowEmptyState([], ["all"], setState, ["a", "b", "c"]); // setState will be called with []
 *
 * @example
 * // Toggles off the "all" value if it is already present in the previous value.
 * setArrayWithAllAndAllowEmptyState(["all", "a"], ["all"], setState, ["a", "b", "c"]); // setState will be called with ["a"]
 *
 * @example
 * // Sets state to ["all"] when "all" is indicated.
 * setArrayWithAllAndAllowEmptyState(["all"], [], setState, ["a", "b"]); // setState will be called with ["all"]
 *
 * @example
 * // Sets state to the full list plus "all" when all values are selected.
 * setArrayWithAllAndAllowEmptyState(["a", "b", "c"], [], setState, ["a", "b", "c"]); // setState will be called with ["all", "a", "b", "c"]
 *
 * @param newValue - The new set of values that might include the "all" indicator.
 * @param prevValue - The previous set of values to compare against.
 * @param setState - A function to update the current state with the desired values.
 * @param allValues - An array of all possible values.
 * @param allValue - The string used to denote the "all" option.
 */
export function setArrayWithAllAndAllowEmptyState(
  newValue: string[],
  prevValue: string[],
  setState: (value: string[]) => void,
  allValues: string[],
  allValue = 'all'
) {
  const allLowercase = allValue.toLowerCase();

  if (newValue.length === 0) {
    setState(newValue);
  } else if (newValue.map((x) => x.toLowerCase()).includes(allLowercase)) {
    if ((prevValue || []).map((x) => x.toLowerCase()).includes(allLowercase)) {
      setState(newValue.filter((v) => v.toLowerCase() !== allLowercase));
    } else {
      setState([allValue]);
    }
  } else {
    if ((prevValue || []).map((x) => x.toLowerCase()).includes(allLowercase)) {
      setState([]);
    } else if (difference(allValues, newValue).length === 0) {
      setState([allValue, ...newValue]);
    } else {
      setState(newValue);
    }
  }
}

/**
 * Resolves a list that may include the specified "all" indicator to represent all possible values.
 * This function is typically used in filter operations where the presence of the "all" string in the
 * input array signifies that all available options should be selected.
 *
 * @example
 * // Returns ["a", "b", "c"] because "all" indicates all possible values should be used.
 * getValueWithAll(["all"], ["a", "b", "c"]);
 *
 * @example
 * // Returns ["a"] because "all" is not present in the value array.
 * getValueWithAll(["a"], ["a", "b", "c"]);
 *
 * @param value – actual value
 * @param allValues – ALL possible values, which will be used in place of allValue
 * @param includeAll – if allValue should be included or not in the resulting array
 * @param allValue – string which represents "all" value
 */
export function getValueWithAll(
  value: string[],
  allValues: string[],
  includeAll = false,
  allValue = 'all'
): string[] {
  const allLowercase = allValue.toLowerCase();

  if (value.map((x) => x.toLowerCase()).includes(allLowercase)) {
    if (includeAll) {
      return [allValue, ...allValues];
    } else {
      return allValues;
    }
  } else {
    if (includeAll && difference(allValues, value).length === 0) {
      return [allValue, ...allValues];
    } else {
      return value;
    }
  }
}
