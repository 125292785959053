import React, { useEffect, useMemo, useState } from 'react';
import s from './ShipToTab.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { App } from 'ant5';
import { TradingPartner } from '../../TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import removeIcon from 'assets/icons/editing/cross_filled.svg';
import { useMutation } from '@apollo/client';
import { DeleteTradingPartnerRetailerDeliveryDestinationsDocument } from 'pages/TradingPartnersPage/gql/__generated__/deleteTradingPartnerRdd.mutation';
import ConfirmationModal from 'components/Modals/ConfirmationModal/ConfirmationModal';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { notEmpty } from 'common/helpers/notEmpty';

interface ShipToTabProps {
  tradingPartner?: TradingPartner;
}

type DeliveryDestination = NonNullable<
  NonNullable<NonNullable<TradingPartner>['retailerDeliveryDestinations']>[number]
>;

export const ShipToTab = ({ tradingPartner }: ShipToTabProps) => {
  const { notification } = App.useApp();
  const [shipTos, setShipTos] = useState<DeliveryDestination[]>(
    tradingPartner?.retailerDeliveryDestinations.filter(notEmpty) || []
  );

  const activeCount = useMemo(() => shipTos?.filter((rdd) => rdd?.active).length ?? 0, [shipTos]);
  const totalCount = useMemo(() => shipTos?.length ?? 0, [shipTos]);

  const [shipToToDelete, setShipToToDelete] = useState<DeliveryDestination>();

  useEffect(() => {
    setShipTos(tradingPartner?.retailerDeliveryDestinations.filter(notEmpty) || []);
  }, [tradingPartner]);

  const [removeShipTo, { loading }] = useMutation(
    DeleteTradingPartnerRetailerDeliveryDestinationsDocument
  );

  const columns: ColumnsType<DeliveryDestination> = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'External ID',
      render: (record) => {
        return record.externalId;
      },
      sorter: (a, b) => safeLocaleCompare(a?.externalId, b?.externalId),
      defaultSortOrder: 'ascend'
    },
    {
      title: '',
      width: 48,
      render: (record) => {
        return (
          <AlloyButton
            data-testid={`ship-to-remove-button-${record.id}`}
            icon={<img alt="" src={removeIcon} />}
            shape="circle"
            style={{ padding: 0 }}
            type="text"
            onClick={() => setShipToToDelete(record)}
          />
        );
      }
    }
  ];

  return (
    <>
      <AlloySpin spinning={loading}>
        {tradingPartner && tradingPartner.id && (
          <p className={s.total}>
            {totalCount} Ship-To{totalCount !== 1 ? 's' : ''}{' '}
            {totalCount > 0 && (
              <span>
                {' '}
                ({activeCount} Active, {totalCount - activeCount} Inactive)
              </span>
            )}
          </p>
        )}
        <AlloyTable
          columns={columns}
          dataSource={shipTos}
          rowKey={(record) => record.id}
          pagination={false}
          rowClassName={(record) => (!record.active ? s.inactive : '')}
          size="small"
        />
      </AlloySpin>
      <ConfirmationModal
        visible={!!shipToToDelete}
        type="deleting"
        message={`Are you sure you want to delete the Ship To ${shipToToDelete?.name} from the Trading Partner ${tradingPartner?.name}?`}
        confirmed={(confirmed: boolean) => {
          if (confirmed && shipToToDelete && tradingPartner) {
            removeShipTo({
              variables: {
                input: {
                  id: tradingPartner?.id,
                  retailerDeliveryDestinationIds: [shipToToDelete.id]
                }
              },
              onCompleted: (data) => {
                const newShipTos =
                  data.deleteTradingPartnerRetailerDeliveryDestinations?.tradingPartner?.retailerDeliveryDestinations.filter(
                    notEmpty
                  ) || [];
                setShipTos(newShipTos);
                notification.success({
                  message: `Ship To ${shipToToDelete.name} was successfully deleted`
                });
                setShipToToDelete(undefined);
              },
              onError: () => {
                notification.error({
                  message: `An error occurred while deleting Ship To ${shipToToDelete.name}`
                });
              }
            });
          } else {
            setShipToToDelete(undefined);
          }
        }}
      />
    </>
  );
};
