import React, { Dispatch, SetStateAction, useState, useCallback } from 'react';
import './styles.scss';
import { ClockCircleOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { PurchaseOrderHistoryDrawerState } from 'pages/OrdersPage/components/OrdersTable/OrdersTable';
import { useLazyQuery } from '@apollo/client';
import {
  PurchaseOrderDocument,
  PurchaseOrderQuery
} from './gql/__generated__/PurchaseOrderStatus.query';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

type PurchaseOrderEvent = NonNullable<PurchaseOrderQuery['purchaseOrder']>['history'][number];

interface PurchaseOrderStatusProps {
  status: string;
  secondaryStatuses?: string[];
  overdue?: boolean;
  poId?: string;
  poNumber?: string | null;
  setPoHistoryDrawerState?: Dispatch<SetStateAction<PurchaseOrderHistoryDrawerState>>;
  poHistoryDrawerState?: PurchaseOrderHistoryDrawerState;
}

const PurchaseOrderStatus = ({
  status,
  secondaryStatuses,
  overdue,
  poId,
  poNumber,
  setPoHistoryDrawerState
}: PurchaseOrderStatusProps) => {
  const [history, setHistory] = useState<PurchaseOrderEvent[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<PurchaseOrderEvent[]>([]);
  const [loaded, setloaded] = useState(false);
  const [slowLoading, setSlowLoading] = useState(false);
  const [loadPo, { loading, data }] = useLazyQuery(PurchaseOrderDocument, {
    onCompleted: (data) => {
      setHistory(data.purchaseOrder?.history || []);
      setFilteredHistory(
        (data.purchaseOrder?.history || [])
          .filter((history) => {
            if (
              history?.user &&
              history.source !== 'USER_SIDE_EFFECT' &&
              history.user.name !== null
            ) {
              return history;
            } else if (
              history?.action === 'CREATE' ||
              history?.action === 'ERROR_ADDED' ||
              history?.action === 'ERROR_CLEARED'
            ) {
              return history;
            } else {
              return null;
            }
          })
          .filter(notEmpty)
          .sort((a, b) => moment(b.actionTime).diff(moment(a.actionTime)))
      );
      setloaded(true);
    }
  });

  const getData = useCallback(() => {
    if (loaded) return;

    const spinnerIfSlow = setTimeout(() => {
      setSlowLoading(true);
    }, 600);
    loadPo({ variables: { id: poId as string } }).then(() => {
      clearTimeout(spinnerIfSlow);
      if (slowLoading) setSlowLoading(false);
    });
  }, [loadPo, setSlowLoading, loaded, slowLoading, poId]);

  const displayStatus = (
    <span
      data-testid="po-status"
      onMouseEnter={() => {
        if (poId && poNumber) getData();
      }}
      className={`status status_${status?.toLowerCase().replace(/ /g, '_')} ${
        slowLoading ? 'loading' : ''
      }`}
    >
      {status?.replace(/_/g, ' ').toUpperCase()}
    </span>
  );

  //this component is used as a simple display in the po details screen
  if (!poId || !poNumber)
    return (
      <div
        data-testid="order-status-container"
        className={`order_status ${overdue ? 'overdue' : ''}`}
      >
        {displayStatus}
      </div>
    );

  return (
    <div
      data-testid={`order-status-container-${poNumber}`}
      className={`order_status tooltip ${overdue ? 'overdue' : ''}`}
    >
      <AlloyTooltip
        overlayClassName="po_history_tooltip"
        mouseEnterDelay={0.1}
        placement="top"
        title={
          <div data-testid="po-history-tooltip" onClick={(e) => e.stopPropagation()}>
            {filteredHistory
              .slice(-5)
              .map(({ action, actionTime, context, details, user, error }) => (
                <div
                  data-testid="po-history-item"
                  className={`po_history_item ${!!error ? 'error' : ''}`}
                  key={actionTime}
                >
                  <div data-testid="po-history-action" className="action">
                    <div>
                      {action
                        ?.toLowerCase()
                        .split('_')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                    </div>

                    {action === 'UPDATE_DESTINATION' && context && (
                      <div className="desc">
                        {context.updateDestination?.previousValue} to{' '}
                        {context.updateDestination?.currentValue}
                      </div>
                    )}
                    {details?.map((detail) => <div className="desc">{detail}</div>)}
                  </div>

                  <div className="right">
                    <div data-testid={`po-history-action-time${!!error ? '-with-error' : ''}`}>
                      {moment(actionTime).format('MMM D, YYYY h:mm A')}
                    </div>

                    <div data-testid="po-history-user" className="user">
                      {user ? user.name : '-'}
                    </div>
                  </div>
                </div>
              ))}
            {setPoHistoryDrawerState && (
              <div data-testid="view-log-button-container" className="view_log_button_container">
                <AlloyButton
                  data-testid="view-log-button"
                  className="view_log_button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setPoHistoryDrawerState &&
                      setPoHistoryDrawerState({
                        visible: true,
                        poHistory: history || [],
                        poNumber: poNumber || ''
                      });
                  }}
                  ghost={true}
                >
                  VIEW LOG
                  <RightOutlined className="arrow" data-testid="view-log-icon" />
                </AlloyButton>
              </div>
            )}
          </div>
        }
      >
        {displayStatus}
      </AlloyTooltip>

      {secondaryStatuses && secondaryStatuses.length > 0 && (
        <AlloyTooltip
          data-testid="secondary-status-tooltip"
          title={
            <div data-testid="secondary-status-tooltip-title">
              {[...secondaryStatuses]
                .sort((s1, s2) => s1.localeCompare(s2))
                .map((status) => (
                  <p data-testid="secondary-status" key={status} className="secondary_status">
                    {status?.replace(/_/g, ' ').toUpperCase()}
                  </p>
                ))}
            </div>
          }
        >
          <span
            data-testid="secondary-status-count"
            className={`status status_${status?.toLowerCase().replace(/ /g, '_')}`}
          >
            +{secondaryStatuses.length}
          </span>
        </AlloyTooltip>
      )}
      {overdue && (
        <AlloyTooltip data-testid="po-overdue-tooltip" title="Overdue">
          <span data-testid="po-overdue-flag" className="flag">
            <ClockCircleOutlined className="po_overdue" />
          </span>
        </AlloyTooltip>
      )}
    </div>
  );
};

export default PurchaseOrderStatus;
