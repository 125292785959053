import React, { useContext, useMemo, useState } from 'react';
import { LastUpdated } from './components/LastUpdated/LastUpdated';
import s from './Inventory.module.scss';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { useQuery } from '@apollo/client';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { isEqual, range, upperFirst } from 'lodash-es';
import { useQueryParam, withDefault, ArrayParam, StringParam, JsonParam } from 'use-query-params';
import moment from 'moment';
import { PERFECT_SWAP, Stats } from './components/Stats/Stats';
import { AccuracyGraph } from './components/AccuracyGraph/AccuracyGraph';
import { InventoryTable } from './components/InventoryTable/InventoryTable';
import { notEmpty } from 'common/helpers/notEmpty';
import { RoundedRadioButton } from 'components/RoundedRadioButton';
import { getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { AvailableInventoryReconciliationFiltersDocument } from './gql/__generated__/availableInventoryReconciliationFilters.query';
import {
  DistributionCenter,
  FILTERS_QUERY_PARAM_STRING,
  FilterKeys,
  InventoryMetric
} from './types';
import { InventoryReconciliationFilters, UnitOfMeasureType } from 'graphql/__generated__/types';
import { UserContext } from 'context/userContext';
import clsx from 'clsx';
import { useDeepCompareMemo } from 'use-deep-compare';
// use PickerProps after migration to v5
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDatePicker, PickerProps } from 'components/ui/AlloyDatePicker/AlloyDatePicker';

const CURRENT_YEAR = new Date().getFullYear();
const { week: CURRENT_PERIOD_WEEK } = getFinancialInfo(new Date());

export type DisplayMode = 'visibility' | 'summary';
const DEFAULT_TAB: DisplayMode = 'visibility';

type InventoryTab = { key: DisplayMode; title: string };
const SUMMARY_TAB: InventoryTab = { key: 'summary', title: 'Executive Summary' };
const RECONCILIATION_TAB: InventoryTab = { key: 'visibility', title: 'Visibility' };

const dcToLabel = ({ name, sapPlantNumber }: DistributionCenter) =>
  `(${sapPlantNumber}) ${upperFirst(name || '')}`;
const dcToValue = ({ id, sapPlantNumber }: DistributionCenter) => `${id}:${sapPlantNumber}`;
const valueToDcPair = (value: string) => {
  const [id, sapPlantNumber] = value.split(':');
  return { id, sapPlantNumber };
};

// TODO: use ZOD?
const parseStatus = (status: string | string[]) => {
  if (status === 'true') return true;
  if (status === 'false') return false;
  return undefined;
};

const DATE_FORMAT = 'M/D/YYYY';

const YESTERDAY = moment().subtract(1, 'days').format(DATE_FORMAT);

const disabledDates: PickerProps<moment.Moment>['disabledDate'] = (current) => {
  // Dates should not include today
  return current && current > moment().subtract(1, 'days').endOf('day');
};

export const Inventory = () => {
  const { isAdmin } = useContext(UserContext);

  const [dcs, setDcs] = useQueryParam('dcs', withDefault(ArrayParam, ['all']));
  // TODO: do we want to make it multiple?
  const [date, setDate] = useQueryParam('date', withDefault(StringParam, YESTERDAY));
  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  const [displayFilter] = useQueryParam('display-filter', withDefault(StringParam, undefined));
  const [searchTerm] = useQueryParam('search', withDefault(ArrayParam, []));
  const [tableFiltersFromQuery] = useQueryParam<Record<FilterKeys, string[]>>(
    FILTERS_QUERY_PARAM_STRING,
    withDefault(JsonParam, {})
  );
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  // Well, it's not an actual type transformation, it's for convinience only.
  const modeWithType = mode as DisplayMode;

  const [graphPeriod, setGraphPeriod] = useQueryParam<CalendarValue>(
    'graph_period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [2023],
      start: 1,
      end: CURRENT_PERIOD_WEEK
    })
  );

  const [exportVisible, setExportVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState<InventoryMetric[]>([]);
  const resetSelectedRows = () => setSelectedRows([]);

  const availableFiltersData = useQuery(AvailableInventoryReconciliationFiltersDocument);
  const dcOptions = useMemo(
    () =>
      [
        ...(availableFiltersData.data?.availableInventoryReconciliationFilters
          ?.distributionCenters || [])
      ].sort((a, b) => {
        if (a.sapPlantNumber === b.sapPlantNumber) {
          return safeLocaleCompare(a.name, b.name);
        }
        return safeNumberComparator(
          parseInt(a.sapPlantNumber || ''),
          parseInt(b.sapPlantNumber || '')
        );
      }),
    [availableFiltersData.data]
  );

  const dcKeys = getValueWithAll(
    dcs as string[],
    dcOptions.map((x) => dcToValue(x))
  ).map(valueToDcPair);

  // Usual useMemo is not enough because values returned by useQueryParam are not referentially stable.
  const filters: InventoryReconciliationFilters = useDeepCompareMemo(
    () => ({
      distributionCenterKeys: dcKeys,
      processDate: moment(date, DATE_FORMAT).format('YYYY-MM-DD'),
      smartSearchTerms: nonEmptySearch,
      unitsOfMeasure: (tableFiltersFromQuery?.uom as UnitOfMeasureType[]) || undefined,
      variance: parseStatus(tableFiltersFromQuery?.status),
      perfectSwapBatchesOnly: displayFilter === PERFECT_SWAP || undefined
    }),
    [
      date,
      dcKeys,
      displayFilter,
      nonEmptySearch,
      tableFiltersFromQuery?.status,
      tableFiltersFromQuery?.uom
    ]
  );

  const dcsFilename =
    dcs?.[0]?.toLowerCase() === 'all'
      ? ''
      : (
          dcs
            .map((x) => dcOptions.find((dc) => dc.id === x)?.sapPlantNumber)
            .filter(notEmpty)
            .join('_') + '_'
        ).replaceAll(' ', '-');

  const modes: InventoryTab[] = isAdmin()
    ? [SUMMARY_TAB, RECONCILIATION_TAB]
    : [RECONCILIATION_TAB];

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <span className={s.title}>Inventory Visibility</span>
          <LastUpdated />
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            listItemHeight={32}
            loading={availableFiltersData.loading}
            className={clsx(s.select, dcs.length > 1 && s.moreThanOne)}
            value={dcs}
            mode="multiple"
            showSearch={false}
            onChange={(value) => {
              if (!isEqual(value, dcs) && value?.length > 0) {
                setSelectedRows([]);
              }
              setArrayWithAll(value as string[], dcs as string[], setDcs);
            }}
            popupMatchSelectWidth={300}
            maxTagCount={1}
            options={[
              { label: 'ALL DCs', value: 'all' },
              ...dcOptions.map((dc) => ({
                label: dcToLabel(dc),
                value: dcToValue(dc)
              }))
            ]}
          />
          {modeWithType === 'summary' && (
            <PeriodCalendar
              years={range(2016, CURRENT_YEAR + 1)}
              value={graphPeriod}
              onChange={setGraphPeriod}
            />
          )}
          {/* Backup type */}
          {(modeWithType === 'visibility' ||
            modeWithType === ('reconciliation' as unknown as DisplayMode)) && (
            <>
              <AlloyDatePicker
                style={{ width: '140px' }}
                placeholder=""
                value={moment(date, DATE_FORMAT)}
                format={DATE_FORMAT}
                allowClear={false}
                onClick={(e) => {
                  e.preventDefault();
                }}
                disabledDate={disabledDates}
                onChange={(date) => {
                  setDate((date as moment.Moment)?.format(DATE_FORMAT));
                }}
                data-testid="inventory-date-picker"
              />
              <AlloyButton
                data-testid="export-inventory-button"
                onClick={() => setExportVisible(true)}
                size="large"
                style={{ minWidth: '180px' }}
                type="secondary"
              >
                Export{' '}
                {selectedRows?.length > 0
                  ? `${selectedRows.length} item${selectedRows.length === 1 ? '' : 's'}`
                  : 'view'}
              </AlloyButton>
            </>
          )}
        </div>
      </div>
      <div className={s.modeSelector}>
        <RoundedRadioButton
          buttons={modes}
          value={mode}
          onChange={(key) => {
            resetSelectedRows();
            setMode(key);
          }}
        />
      </div>
      {modeWithType === 'summary' && <AccuracyGraph graphPeriod={graphPeriod} />}
      {(modeWithType === 'visibility' ||
        modeWithType === ('reconciliation' as unknown as DisplayMode)) && (
        <>
          <Stats filters={filters} />
          <InventoryTable
            filters={filters}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            exportModalVisible={exportVisible}
            setExportModalVisible={setExportVisible}
            resetSelectedRows={resetSelectedRows}
            dcsFilename={dcsFilename}
            mode={mode as DisplayMode}
          />
        </>
      )}
    </>
  );
};
