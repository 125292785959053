//This context is used onScroll animations of the product view table and child tables in the expandable rows

import React, { ReactNode, useRef, useMemo, MutableRefObject, useEffect } from 'react';

type ProviderProps = {
  children: ReactNode;
};

export const ProductViewContext = React.createContext<{
  subscribers: MutableRefObject<HTMLElement[]>;
  primaryTable: MutableRefObject<HTMLElement | null | undefined>;
  evaluateSubscriberPosition: () => void;
}>({
  subscribers: { current: [] },
  primaryTable: { current: null },
  evaluateSubscriberPosition: () => {}
});

const ProductViewContextProvider = (props: ProviderProps) => {
  const primaryTable = useRef<HTMLElement>();
  const subscribers = useRef<HTMLElement[]>([]);
  const recheck = useRef<undefined | ReturnType<typeof setTimeout>>();

  const evaluateSubscriberPosition = () => {
    if (!primaryTable.current) return;

    //multiple expanded rows may be open at any time, so all of these tables need to slide with the left scroll
    for (let i = 0; i < subscribers.current.length; i++) {
      const element = subscribers.current[i];
      const header = element.querySelector('.ant5-table-header') as HTMLElement;

      /*This part is a bit annoying. To slide the table position:fixed is used. The actual animation is done with 
      translate3d to get hardware accelation and smoother animation. However, translate3d will then prevent the table header from
      using position fixed. So here we use translate3d if the header is not currently in position:fixed, and we use the 'left'
      property if it is in fixed postion. This still means the table itself will have a slight stutter when the left position is rapidly
      modified. This is the best compromise I could find at the moment. The only way to truely get a smooth scroll animation in this scenario,
      would be to make the header a sibling of the table and manage their animations with translate3d. */
      //@ts-ignore
      if (header?.computedStyleMap().get('position')?.value === 'fixed') {
        subscribers.current[i].style.left = `${primaryTable.current.scrollLeft}px`;
        subscribers.current[i].style.transform = '';
      } else {
        subscribers.current[i].style.left = `0px`;
        subscribers.current[i].style.transform =
          `translate3d(${primaryTable.current.scrollLeft}px, 0px, 0px)`;
      }
    }
  };

  useEffect(() => {
    //this ensures the child tables match the parent's width after the browser has been zoomed.
    //The width is important to prevent white space

    const resize = () => {
      if (primaryTable.current) {
        for (let i = 0; i < subscribers.current.length; i++) {
          subscribers.current[i].style.width = `${
            primaryTable.current.getBoundingClientRect().width
          }px`;
        }
      }

      //if the resize handler is being called rapidly, a finally recheck may be required
      if (recheck.current) clearTimeout(recheck.current);

      recheck.current = setTimeout(() => {
        if (primaryTable.current) {
          for (let i = 0; i < subscribers.current.length; i++) {
            subscribers.current[i].style.width = `${
              primaryTable.current.getBoundingClientRect().width
            }px`;
          }
        }

        recheck.current = undefined;
      }, 200);
    };

    window.addEventListener('resize', resize);

    return () => {
      if (recheck.current) clearTimeout(recheck.current);
      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <ProductViewContext.Provider
      value={{
        subscribers,
        primaryTable,
        evaluateSubscriberPosition
      }}
    >
      {props.children}
    </ProductViewContext.Provider>
  );
};

export default ProductViewContextProvider;
