import React from 'react';
import s from './CollapsibleSalesOrderTable.module.scss';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { SelectedShipmentGroup } from 'common/helpers/purchaseOrderDataHelper';
import { PurchaseOrderWithOverdue, SalesOrderItem } from 'pages/OrderDetailsPage/types';
import SalesOrderShipment from '../SalesOrderShipment/SalesOrderShipment';

const { Panel } = AlloyCollapse;
interface SalesOrderTableProps {
  salesOrderShipmentGroupList: SelectedShipmentGroup[];
  purchaseOrder: PurchaseOrderWithOverdue;
  onSelectAction: (selectedRows: SalesOrderItem[]) => void;
  selectedKeys: string[];
  toolbarHeight: number;
}

export const CollapsibleSalesOrderTable = ({
  salesOrderShipmentGroupList,
  purchaseOrder,
  onSelectAction,
  selectedKeys,
  toolbarHeight
}: SalesOrderTableProps) => {
  const masterHeaderHeightAdjust = 55;
  const orderDetailsHeaderHeightAdjust = 250;

  return (
    <div
      style={{
        height: `calc(100vh - ${toolbarHeight}px - ${masterHeaderHeightAdjust}px - ${orderDetailsHeaderHeightAdjust}px)`,
        overflowY: 'scroll'
      }}
    >
      <AlloyCollapse
        bordered={false}
        className={s.collapsible_table}
        defaultActiveKey={salesOrderShipmentGroupList.map((_, i) => i)}
      >
        {salesOrderShipmentGroupList.length > 0 &&
          salesOrderShipmentGroupList.map(({ shipmentGroup, shipments }, i) => {
            const productCount = shipments.reduce((total, shipment) => {
              return (total += shipment.items.length);
            }, 0);

            return (
              <Panel
                key={i}
                className={s.collapsible_so_panel}
                showArrow={true}
                header={
                  <div
                    key={shipmentGroup}
                    data-testid="shipment-group-header"
                    className={s.panel_header}
                  >
                    <span>{shipmentGroup ? shipmentGroup : null} </span>
                    <span>
                      ({shipments.length} Shipment
                      {`${shipments.length === 1 ? '' : 's'}`} | {productCount} Product
                      {`${productCount === 1 ? '' : 's'}`})
                    </span>
                  </div>
                }
              >
                <SalesOrderShipment
                  key={i}
                  shipments={shipments}
                  shipmentGroup={shipmentGroup}
                  purchaseOrder={purchaseOrder}
                  onSelectAction={onSelectAction}
                  selectedKeys={selectedKeys}
                />
              </Panel>
            );
          })}
      </AlloyCollapse>
    </div>
  );
};
