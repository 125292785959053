import React, { useMemo, ReactNode, useState } from 'react';
import s from './ReconciliationModal.module.scss';
import { InventoryMetric } from 'pages/Inventory/types';
import { StatsTile } from 'components/StatsTile/StatsTile';
import { notEmpty } from 'common/helpers/notEmpty';
import { SearchOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import clsx from 'clsx';
import {
  InventoryDiscrepancyReason,
  InventoryReconciliationFilters
} from 'graphql/__generated__/types';
import { ReconciliationTable } from './ReconciliationTable/ReconciliationTable';
import { useQuery } from '@apollo/client';
import { InventoryReconciliationDiscrepanciesTotalsDocument } from './gql/__generated__/inventoryReconciliationDiscrepanciesTotals.query';
import { NOT_AVAILABLE } from 'common/constants';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { useEnumValue } from 'common/useEnumValue';
import { makeReasonsReadable } from './types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

const toNumber = (number: any) => {
  return isNaN(parseFloat(number))
    ? NOT_AVAILABLE
    : parseFloat(number).toLocaleString('en-US', {
        maximumFractionDigits: 3
      });
};

export const ReconciliationModal = ({
  rowsToReconcile,
  parentFilters
}: {
  rowsToReconcile: InventoryMetric[] | undefined;
  parentFilters: InventoryReconciliationFilters;
}) => {
  const reasons = useEnumValue('InventoryDiscrepancyReason');

  const [searchTerm, setSearchTerm] = useState<Array<string>>(
    () => parentFilters.smartSearchTerms || []
  );
  const nonEmptySearch = useMemo(() => searchTerm.filter(notEmpty) as string[], [searchTerm]);
  const [reasonsFilter, setReasonsFilter] = useState<Array<InventoryDiscrepancyReason>>([]);

  const filters = useMemo(
    () => ({
      ...parentFilters,
      reasons: reasonsFilter?.length > 0 ? reasonsFilter : undefined,
      smartSearchTerms: searchTerm
    }),
    [parentFilters, reasonsFilter, searchTerm]
  );

  return (
    <div>
      <Stats rowsToReconcile={rowsToReconcile} parentFilters={filters} />
      <div className={s.topBar}>
        <div className={s.left}>
          <div className={s.search}>
            <MultipleValuesInput
              placeholder="Search by GTIN, SAP Material ID or description"
              value={
                nonEmptySearch
                  ? typeof nonEmptySearch === 'string'
                    ? [nonEmptySearch]
                    : nonEmptySearch
                  : []
              }
              onChange={setSearchTerm}
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
              splitInputValue={/[^0-9a-zA-Z-]+/g}
            />
          </div>
          <AlloySelect
            className={clsx(s.select, reasonsFilter.length > 1 && s.moreThanOne)}
            value={reasonsFilter}
            mode="multiple"
            showSearch={false}
            placeholder="Filters"
            onChange={(value) => {
              setReasonsFilter(value);
            }}
            popupMatchSelectWidth={250}
            maxTagCount={1}
            loading={reasons.loading}
            options={reasons.enumValues
              .map((x) => ({ label: makeReasonsReadable(x), value: x }))
              .sort((a, z) => safeLocaleCompare(a.label, z.label))}
          />
        </div>
        <div className={s.right}>
          <AlloyButton size="large">Export</AlloyButton>
          <AlloyButton size="large" type="primary">
            Finish
          </AlloyButton>
        </div>
      </div>
      <ReconciliationTable
        rowsToReconcile={rowsToReconcile}
        parentFilters={filters}
        filters={reasonsFilter}
      />
    </div>
  );
};

const LongStatsTile = ({
  title,
  stats
}: {
  title: ReactNode;
  stats: { key: string; value: string; loading?: boolean }[];
}) => {
  return (
    <div className={s.long_stats_tile}>
      <div className={s.title}>{title}</div>
      <div className={s.body}>
        {stats.map((x) => (
          <div className={s.value} key={x.key + x.value}>
            <span>{x.key}</span>
            {x.loading ? <span className={s.loading} /> : <span>{x.value}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

const Stats = ({
  rowsToReconcile,
  parentFilters
}: {
  rowsToReconcile: InventoryMetric[] | undefined;
  parentFilters: InventoryReconciliationFilters;
}) => {
  const totals = useQuery(InventoryReconciliationDiscrepanciesTotalsDocument, {
    variables: {
      filters: parentFilters,
      discrepancyMainKeys: rowsToReconcile
        ? rowsToReconcile.map((x) => ({ plantNumber: x.plant, sapMaterialId: x.sapMaterialId }))
        : []
    }
  });
  const totalSapOnHand = toNumber(
    totals.data?.inventoryReconciliationDiscrepanciesTotals?.sapOnHand
  );
  const totalWMSOnHand = toNumber(
    totals.data?.inventoryReconciliationDiscrepanciesTotals?.wmsOnHand
  );
  const totalDiscrepancy = toNumber(
    totals.data?.inventoryReconciliationDiscrepanciesTotals?.discrepancy
  );
  const totalOpenVariance = toNumber(
    totals.data?.inventoryReconciliationDiscrepanciesTotals?.openVariance
  );

  const totalsByReason = (totals.data?.inventoryReconciliationDiscrepanciesTotals?.byReason || [])
    .filter(notEmpty)
    .reduce(
      (acc, { reason, value }) => {
        acc[reason || 'UNKNOWN_REASON_BE_SENT_NULL'] = toNumber(value);
        return acc;
      },
      {} as Record<InventoryDiscrepancyReason | 'UNKNOWN_REASON_BE_SENT_NULL', string>
    );

  return (
    <div>
      <div className={s.stats}>
        <StatsTile
          title="Total SAP On Hand"
          count={<NumberAndText number={totalSapOnHand} text="CS" />}
          loading={totals.loading}
        />
        <StatsTile
          title="Total WMS On Hand"
          count={<NumberAndText number={totalWMSOnHand} text="CS" />}
          loading={totals.loading}
        />
        <StatsTile
          title="Total Discrepancy"
          count={<NumberAndText number={totalDiscrepancy} text="CS" />}
          loading={totals.loading}
        />
        <StatsTile
          title="Total Open Variance"
          count={<NumberAndText number={totalOpenVariance} text="CS" />}
          loading={totals.loading}
        />
        <div className={s.long_stats_wrapper}>
          <LongStatsTile
            title="ORDERS (CS)"
            stats={[
              {
                key: makeReasonsReadable('DELAYED_ORDER_SHIPMENT_UPDATE'),
                value: totalsByReason['DELAYED_ORDER_SHIPMENT_UPDATE'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('AWAITING_ORDER_SHIPMENT_CONFIRMATION'),
                value: totalsByReason['AWAITING_ORDER_SHIPMENT_CONFIRMATION'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('SHIPMENT_QUANTITY'),
                value: totalsByReason['SHIPMENT_QUANTITY'] ?? NOT_AVAILABLE,
                loading: totals.loading
              }
            ]}
          />
          <LongStatsTile
            title="STO (CS)"
            stats={[
              {
                key: makeReasonsReadable('DELAYED_STO_RECEIPT_UPDATE'),
                value: totalsByReason['DELAYED_STO_RECEIPT_UPDATE'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('AWAITING_STO_RECEIPT_CONFIRMATION'),
                value: totalsByReason['AWAITING_STO_RECEIPT_CONFIRMATION'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('STO_QUANTITY'),
                value: totalsByReason['STO_QUANTITY'] ?? NOT_AVAILABLE,
                loading: totals.loading
              }
            ]}
          />
          <LongStatsTile
            title="Adjustments (CS)"
            stats={[
              {
                key: makeReasonsReadable('STALE'),
                value: totalsByReason['STALE'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('DAMAGE'),
                value: totalsByReason['DAMAGE'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('SAMPLE'),
                value: totalsByReason['SAMPLE'] ?? NOT_AVAILABLE,
                loading: totals.loading
              },
              {
                key: makeReasonsReadable('OTHER_ADJUSTMENTS'),
                value:
                  totalsByReason['OTHER_ADJUSTMENTS'] ??
                  totalsByReason['UNKNOWN_REASON_BE_SENT_NULL'] ??
                  NOT_AVAILABLE,
                loading: totals.loading
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const NumberAndText = ({ text, number }: { text: string; number: string | number }) => {
  return (
    <div className={s.number_and_text}>
      <span className={s.number}>{number}</span> <span className={s.text}>{text}</span>
    </div>
  );
};
