import { useLazyQuery } from '@apollo/client';
import { fieldNameToCapitalize } from 'common/helpers/stringsConverter';
import { UserContext } from 'context/userContext';
import moment from 'moment';
import { parse } from 'query-string';
import React, { useContext, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import {
  AssortmentConfig,
  DistributionCenterForAA,
  getVendorMarketExternalId,
  RetailerChannel,
  RetailerDeliveryDestination,
  TradingPartner,
  TradingPartnerAssortment
} from 'pages/AssortmentPage/AssortmentPage';
import {
  AllTpAssortmentsForExportDocument,
  AllTpAssortmentsForExportQuery
} from 'pages/AssortmentPage/gql/__generated__/allTpAssortmentsForExportDocument.query';
import {
  getAssortmentItemFieldValueBySlug,
  getItemHeaders,
  SHIP_TO_FORM_FIELD,
  SPECIAL_ASSORTMENT_FORM_FIELD,
  TRADING_PARTNER_FORM_FIELD,
  UPDATED_AT_FORM_FIELD
} from 'pages/AssortmentPage/fieldsHelper';
import {
  locationPropertyToStringArrayOrNull,
  locationPropertyToStringOrNull,
  getNodesFromEdges,
  InferNodeType
} from 'common/helpers/mappingHelper';
import { TradingPartnerAssortmentDocument } from '../gql/__generated__/tradingPartnerAssortment.query';
import { ConfigNotfication } from './ConfigNotfication/ConfigNotfication';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { TradingPartnerAssortmentFullData } from './ProductDetailsModal/ProductDetailsDrawer';
import { capitalize } from 'lodash-es';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface ExportActiveAssortmentByConfigProps {
  selectedRows: TradingPartnerAssortment[];
  retailersList: RetailerChannel[];
  assortmentConfig?: AssortmentConfig | null;
  tradingPartner: TradingPartner;
  disabled: boolean;
  retailerDeliveryDestinations: RetailerDeliveryDestination[];
  distributionCenters: DistributionCenterForAA[] | undefined;
}

type FullTradingPartnerAssortment = InferNodeType<
  AllTpAssortmentsForExportQuery,
  'tradingPartnerAssortments'
>;

export type AllTpAssortmentsForExportSubstitution =
  FullTradingPartnerAssortment['substitutions'][number];

export const ExportActiveAssortmentByConfig = ({
  selectedRows,
  retailersList,
  assortmentConfig,
  tradingPartner,
  disabled,
  retailerDeliveryDestinations,
  distributionCenters
}: ExportActiveAssortmentByConfigProps) => {
  const [allItemsList, setAllItemsList] = useState<FullTradingPartnerAssortment[]>([]);
  const [fullSelectedItemsList, setFullSelectedItemsList] = useState<
    TradingPartnerAssortmentFullData[]
  >([]);
  const [csvFileName, setCsvFileName] = useState<string>();
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const selectedCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(
    null
  );

  const { message } = App.useApp();

  const history = useHistory();
  const location = parse(history.location.search);
  const [active] = useQueryParam('active', BooleanParam);
  const [vendorMarketId] = useQueryParam('vendorMarketId', StringParam);

  const { isAdmin } = useContext(UserContext);

  const [getAllProducts] = useLazyQuery(AllTpAssortmentsForExportDocument, {});

  const [getFullSelectedProducts] = useLazyQuery(TradingPartnerAssortmentDocument, {});

  if (!assortmentConfig) {
    return (
      <AlloyModal title="Active Assortment configuration is not set">
        <ConfigNotfication forAdmin={isAdmin()} tradingPartnerId={tradingPartner?.id || ''} />
      </AlloyModal>
    );
  }

  const headers = getItemHeaders(assortmentConfig, true);

  const getRetailerDeliveryDestinationsString = (
    retailerDeliveryDestinations: RetailerDeliveryDestination[]
  ) => {
    const rdds = location.retailerDeliveryDestinationIds;
    if (rdds?.includes('all')) {
      return retailerDeliveryDestinations.map((rdd) => rdd.externalId).join(', ');
    } else {
      return retailerDeliveryDestinations
        .reduce((result, current) => {
          if (rdds?.includes(current.id)) {
            result.push(current.externalId || '');
          }
          return result;
        }, [] as string[])
        .join(', ');
    }
  };

  const getDistributionCentersString = (distributionCenters: DistributionCenterForAA[]) =>
    distributionCenters.map((dc) => `${dc.name} ${dc.code}`).join(', ');

  const parseSelectedProducts = (
    exportRows: TradingPartnerAssortmentFullData[] | FullTradingPartnerAssortment[]
  ) =>
    exportRows
      .map((row) =>
        row.substitutions.map((substitutionProduct, index) => {
          const item = {} as any;
          assortmentConfig.fields.forEach((field) => {
            item[fieldNameToCapitalize(field.slug || '')] = getAssortmentItemFieldValueBySlug(
              field.slug || '',
              substitutionProduct
            );
          });
          item.externalId = row.retailerProductExternalId;
          item[fieldNameToCapitalize(SHIP_TO_FORM_FIELD.slug || '')] =
            getRetailerDeliveryDestinationsString(
              retailerDeliveryDestinations.filter(
                (rdd) => row.rddIds?.includes(rdd.id) && !rdd.specialAssortment
              )
            );
          item[fieldNameToCapitalize(TRADING_PARTNER_FORM_FIELD.slug || '')] = tradingPartner?.name;
          item[fieldNameToCapitalize(SPECIAL_ASSORTMENT_FORM_FIELD.slug || '')] =
            getRetailerDeliveryDestinationsString(
              retailerDeliveryDestinations.filter(
                (rdd) => row.rddIds?.includes(rdd.id) && rdd.specialAssortment
              )
            );
          item[fieldNameToCapitalize(UPDATED_AT_FORM_FIELD.slug || '')] = moment(row.updatedAt)
            .startOf('minute')
            .fromNow();
          item[fieldNameToCapitalize('DISTRIBUTION_CENTER_IDS')] = getDistributionCentersString(
            distributionCenters?.filter((dc) =>
              (row.dcIds ?? substitutionProduct?.activeDcIds).includes(dc.id)
            ) || []
          );
          item.substitutionOrder = index + 1;
          item.grossWeight = substitutionProduct.grossWeight;
          item.casesPerPallet = substitutionProduct.casesPerPallet;
          item.productType = capitalize(substitutionProduct.productType ?? '');

          return item;
        })
      )
      .flat();

  return (
    <>
      {selectedRows.length < 1 ? (
        <>
          <AlloyButton
            data-testid="export-aa-all-item-btn"
            onClick={async () => {
              const data = await getAllProducts({
                variables: {
                  first: 10000000,
                  tradingPartnerId:
                    locationPropertyToStringOrNull(location.tradingPartnerIds) || '',
                  filters: {
                    active: active === null || active === undefined ? true : active,
                    productSapMaterialIdLike: locationPropertyToStringOrNull(
                      location.productSapMaterialIdLike
                    ),
                    productUpcLike: locationPropertyToStringOrNull(location.productUpcLike),
                    vendorProductExternalIdLike: locationPropertyToStringOrNull(
                      location.vendorProductExternalIdLike
                    ),
                    productNameLike: locationPropertyToStringOrNull(location.productNameLike),
                    retailerDeliveryDestinationIds:
                      !location.retailerDeliveryDestinationIds?.includes('all')
                        ? locationPropertyToStringArrayOrNull(
                            location.retailerDeliveryDestinationIds
                          )
                        : null,
                    vendorMarketId:
                      retailersList.find(
                        (item) => item.externalId === getVendorMarketExternalId(vendorMarketId)
                      )?.id || 'VmVuZG9yTWFya2V0OjI='
                  }
                }
              });
              if (data.data) {
                const fileName = `${tradingPartner?.name}-all-active-assortment-${moment().format(
                  'MM-DD-YYYY_hh:mm:ssA'
                )}.csv`;
                setCsvFileName(fileName);
                setAllItemsList(getNodesFromEdges(data.data?.tradingPartnerAssortments));
                setTimeout(() => csvLinkRef?.current?.link.click(), 1500);
              } else {
                message.error('Failed to export items');
              }
            }}
            disabled={disabled}
          >
            Export All
          </AlloyButton>
          <CSVLink
            filename={csvFileName}
            headers={headers}
            ref={csvLinkRef}
            data={parseSelectedProducts(allItemsList)}
          />
        </>
      ) : (
        <>
          <AlloyButton
            data-testid="export-aa-selected-item-btn"
            disabled={disabled}
            onClick={async () => {
              const data = await getFullSelectedProducts({
                variables: {
                  tradingPartnerId:
                    locationPropertyToStringOrNull(location.tradingPartnerIds) || '',
                  active: active === null || active === undefined ? true : active,
                  retailerProductIds: selectedRows.map((row) => row.retailerProductId)
                }
              });
              if (data.data) {
                setFullSelectedItemsList(
                  data.data?.tradingPartnerAssortmentsByRpIds?.filter(
                    (tpa): tpa is TradingPartnerAssortmentFullData => tpa !== null
                  ) || []
                );
                setTimeout(() => selectedCsvLinkRef?.current?.link.click(), 1500);
              } else {
                message.error('Failed to export items');
              }
            }}
          >
            Export Selected
          </AlloyButton>
          <CSVLink
            filename={`${tradingPartner?.name}-selected-active-assortment_${moment().format(
              'MM-DD-YYYY_hh:mm:ssA'
            )}.csv`}
            ref={selectedCsvLinkRef}
            headers={headers}
            data={parseSelectedProducts(fullSelectedItemsList)}
          />
        </>
      )}
    </>
  );
};
