import React from 'react';
import { logout } from 'common/helpers/logout';
import logoName from 'assets/icons/logo_name.svg';
import logoImage from 'assets/icons/logo_image.svg';
import clsx from 'clsx';
import s from './MasterHeader.module.scss';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

const menuIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
    <rect x="0.5" y="0.5" width="23" height="1" fill="white" stroke="white" />
    <rect x="0.5" y="7.5" width="23" height="1" fill="white" stroke="white" />
    <rect x="0.5" y="14.5" width="23" height="1" fill="white" stroke="white" />
  </svg>
);

const chevronIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
    <path
      d="M5 9L1 5L5 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface MasterHeaderProps {
  userName: string;
  toggleCollapsedSidebar: () => void;
  collapsedSidebar: boolean;
}

const MasterHeader = ({
  userName,
  toggleCollapsedSidebar,
  collapsedSidebar
}: MasterHeaderProps) => {
  return (
    <header className={s.master_header}>
      <div className={s.title}>
        <button
          data-testid="left-sidebar-button"
          aria-label="Menu"
          onClick={toggleCollapsedSidebar}
          className={clsx(s.master_header_toggle_menu_button, {
            [s.collapsed]: collapsedSidebar
          })}
        >
          {menuIcon}
          {chevronIcon}
        </button>
        <div style={{ display: 'inline-block' }}>
          <img
            data-testid="master-header-logo"
            src={logoImage}
            alt=""
            style={{ marginRight: '8px' }}
          />
          <img data-testid="master-header-logo" src={logoName} alt="" />
        </div>
      </div>
      <div className={s.extra}>
        <AlloyDropdown
          key="drop"
          menu={{
            items: [
              {
                key: 'logout',
                onClick: logout,
                label: 'Logout'
              }
            ]
          }}
        >
          <AlloyButton data-testid="header-user-menu" className={s.button}>
            <span className={s.user_name} data-testid="header-user-name">
              {userName}
            </span>
          </AlloyButton>
        </AlloyDropdown>
      </div>
    </header>
  );
};

export default MasterHeader;
