import { useMutation } from '@apollo/client';
import { PurchaseOrderConfirmation } from 'graphql/__generated__/types';
import moment from 'moment';
import { UpdatePurchaseOrderConfirmationExpectedDeliveryDateDocument } from './gql/__generated__/updatePurchaseOrderConfirmationExpectedDeliveryDate.mutation';
import React from 'react';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyDatePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';

type PartialPurchaseOrderConfirmation = Pick<
  PurchaseOrderConfirmation,
  'expectedDeliveryDate' | 'id'
>;

export const ExpectedDeliveryDateField = ({
  confirmation
}: {
  confirmation: PartialPurchaseOrderConfirmation;
}) => {
  const { message } = App.useApp();
  const [updateExpectedDeliveryDate, { loading }] = useMutation(
    UpdatePurchaseOrderConfirmationExpectedDeliveryDateDocument,
    {
      onCompleted: () => {
        message.success('Expected Date was successfully changed');
      },
      onError: (error) => {
        console.error(error.message);
        message.error(`Error happened during Expected Date changing: ${error.message}`);
      }
    }
  );

  return (
    <AlloySpin spinning={loading}>
      <AlloyDatePicker
        data-testid="expected-delivery-date-picker"
        placeholder=""
        allowClear={false}
        value={!loading ? moment(confirmation?.expectedDeliveryDate) : undefined}
        format="M/D/YY"
        onClick={(e) => {
          e.preventDefault();
        }}
        onChange={(date) => {
          updateExpectedDeliveryDate({
            variables: {
              input: {
                id: confirmation.id,
                expectedDeliveryDate: date ? (date as moment.Moment).format('YYYY-MM-DD') : ''
              }
            }
          });
        }}
      />
    </AlloySpin>
  );
};
