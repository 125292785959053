import React from 'react';
import s from './CollapsibleCutSummariesTable.module.scss';
import clsx from 'clsx';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { PoDetailsCutSummaryOrderDetailsPage } from 'pages/OrderDetailsPage/types';
import { EMPTY } from 'common/constants';
import moment from 'moment';
import { CutSource } from 'graphql/__generated__/types';

interface CollapsibleCutSummariesTableProps {
  cutSummariesList: PoDetailsCutSummaryOrderDetailsPage[];
  loading: boolean;
  height: number;
}

type ProductItem = PoDetailsCutSummaryOrderDetailsPage['items'][number];

const { Summary } = AlloyTable;
const { Panel } = AlloyCollapse;

export const getOrderSource = (orderSource?: CutSource) =>
  orderSource === 'SAP' ? 'Manual' : orderSource || EMPTY;

export const CollapsibleCutSummariesTable = ({
  cutSummariesList,
  loading,
  height
}: CollapsibleCutSummariesTableProps) => {
  if (!cutSummariesList.length) return null;

  const columns: ColumnsType<ProductItem> = [
    {
      title: (
        <>
          <div>BALANCE QTY / TOTAL QTY</div>
        </>
      ),
      key: 'orderQuantity',
      width: '7%',
      align: 'right' as const,
      render: (_, record) => {
        return `${
          record.orderQuantity ? `${record.orderQuantity} / ${record.totalOrderQuantity}` : '0'
        } ${record?.unitOfMeasure}`;
      }
    },
    {
      title: 'ALLOCATED QTY',
      key: 'allocatedQuantity',
      width: '7%',
      align: 'right' as const,
      render: (_, record) => {
        return `${record.allocatedQuantity ? record.allocatedQuantity : 0} ${
          record?.unitOfMeasure
        }`;
      }
    },
    {
      title: 'CUT QTY',
      key: 'cutQuantity',
      width: '7%',
      align: 'right' as const,
      render: (_, record) =>
        record.cutQuantity !== null || 0 ? (
          <>
            <span className={clsx({ [s.cut_qty_red_text]: record.cutQuantity })}>
              {record.cutQuantity}{' '}
            </span>
            <span>{record.unitOfMeasure}</span>
          </>
        ) : (
          EMPTY
        )
    },
    {
      title: 'CUT REASON',
      key: 'cutReason',
      width: '7%',
      align: 'right' as const,
      render: (_, record) =>
        record.reason === '' || record.reason === null ? EMPTY : record.reason
    },
    {
      title: 'ORDER NUMBER',
      key: 'soAlternateId',
      width: '7%',
      align: 'right' as const,
      render: (_, record) =>
        record.soAlternateId === '' || record.soAlternateId === null ? EMPTY : record.soAlternateId
    },
    {
      title: 'ORDER SOURCE',
      key: 'salesOrderSource',
      width: '7%',
      align: 'left' as const,
      render: (_, record) => getOrderSource(record.orderSource)
    },
    {
      title: 'CUT SOURCE',
      key: 'cutSource',
      width: '7%',
      align: 'left' as const,
      render: (_, record) => (record.cutSource ? record.cutSource : EMPTY)
    },
    {
      title: 'DATE',
      key: 'cutDate',
      width: '10%',
      align: 'left' as const,
      render: (_, record) =>
        record.datetime ? moment(record.datetime).format('MMM DD, YYYY, hh:mm a') : EMPTY
    }
  ];

  return (
    <AlloyCollapse
      className={s.collapsible_table}
      bordered={false}
      defaultActiveKey={cutSummariesList.map(({ id }) => id)}
    >
      {cutSummariesList.length > 0 &&
        cutSummariesList.map(({ product, summary, items, id }) => {
          return (
            <Panel
              key={id}
              style={{
                position: 'sticky',
                top: 246 + height,
                zIndex: 9
              }}
              className={s.cut_product_panel}
              header={
                <div className={s.wrapper}>
                  <div className={s.cut_product_info}>
                    <AlloyCol>
                      <AlloyRow className={s.col_title}>UPC</AlloyRow>
                      <AlloyRow className={s.item_info}>{product.upc}</AlloyRow>
                    </AlloyCol>
                    <AlloyCol>
                      <AlloyRow className={s.col_title_right}>External ID</AlloyRow>
                      <AlloyRow className={s.item_info_right}>{product.externalId}</AlloyRow>
                    </AlloyCol>
                    <AlloyCol>
                      <AlloyRow className={s.col_title_right}>{''}</AlloyRow>
                      <AlloyRow className={s.item_info_right}>{product.name}</AlloyRow>
                    </AlloyCol>
                  </div>
                  <div className={s.outstanding_cuts}>
                    Outstanding Cuts{' '}
                    <span
                      className={
                        product.outstandingCuts !== 0
                          ? s.badge
                          : [s.badge, s.badge_value_zero].join(' ')
                      }
                    >
                      {product.outstandingCuts}
                    </span>
                  </div>
                </div>
              }
            >
              {loading ? (
                <AlloySpin />
              ) : (
                <AlloyTable
                  className={s.cut_product_table}
                  pagination={false}
                  columns={columns}
                  dataSource={items}
                  rowKey={(record) => record.soAlternateId + '_' + record.reason}
                  scroll={{ y: 500 }}
                  summary={() => {
                    return (
                      <Summary fixed="top">
                        <Summary.Row>
                          {/* Order Qty */}
                          <Summary.Cell index={0} colSpan={1} className={s.summary_row}>
                            <div className={s.right_align_data}>
                              {`${summary.orderQuantity} ${summary.unitOfMeasure}`}
                            </div>
                          </Summary.Cell>

                          {/* Allocated Qty */}
                          <Summary.Cell index={1} colSpan={1} className={s.summary_row}>
                            <div className={s.right_align_data}>
                              {`${summary.allocatedQuantity} ${summary.unitOfMeasure}`}
                            </div>
                          </Summary.Cell>

                          {/* Cut Qty */}
                          <Summary.Cell index={2} colSpan={1} className={s.summary_row}>
                            <div className={s.right_align_data}>
                              {summary.cutQuantity !== 0 || null ? (
                                <>
                                  <span
                                    className={clsx({
                                      [s.cut_qty_red_text]:
                                        summary.cutQuantity && summary.cutQuantity > 0
                                    })}
                                  >
                                    {summary.cutQuantity}{' '}
                                  </span>
                                  <span>{summary.unitOfMeasure}</span>
                                </>
                              ) : (
                                <div>{EMPTY}</div>
                              )}
                            </div>
                          </Summary.Cell>

                          {/* Cut Reasons (total number of cut reasons)*/}
                          <Summary.Cell index={3} colSpan={1} className={s.summary_row}>
                            <div className={s.right_align_data}>
                              {summary.cutReasons.length === 0 || summary.cutReasons === null
                                ? EMPTY
                                : summary.cutReasons.length}
                            </div>
                          </Summary.Cell>

                          {/* Order Number (total number of SOs affected by the cuts) */}
                          <Summary.Cell index={4} colSpan={1} className={s.summary_row}>
                            <div className={s.right_align_data}>
                              {summary.soAlternateIds.length === 0 ||
                              summary.soAlternateIds === null
                                ? EMPTY
                                : summary.soAlternateIds.length === 1
                                  ? `${summary.soAlternateIds.length} order`
                                  : `${summary.soAlternateIds.length} orders`}
                            </div>
                          </Summary.Cell>

                          {/* Order Source */}
                          <Summary.Cell index={5} colSpan={1} className={s.summary_row}>
                            {summary.orderSource}
                          </Summary.Cell>

                          {/* Cut Source */}
                          <Summary.Cell index={6} colSpan={1} className={s.summary_row}>
                            {summary.cutSource}
                          </Summary.Cell>

                          {/* Date */}
                          <Summary.Cell index={7} colSpan={1} className={s.summary_row}>
                            {summary.datetime
                              ? moment(summary.datetime).format('MMM DD, YYYY, hh:mm a')
                              : EMPTY}
                          </Summary.Cell>
                        </Summary.Row>
                      </Summary>
                    );
                  }}
                />
              )}
            </Panel>
          );
        })}
    </AlloyCollapse>
  );
};
