import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  PurchaseOrderDetailsDocument,
  PurchaseOrderDetailsQuery
} from '../../gql/__generated__/purchaseOrderDetailsReport.query';
import {
  RateCellWithTargetCompact,
  onCellRateWithTargetCompact
} from 'components/RateCellWithTargetCompact/RateCellWithTarget';
import s from './PurchaseOrderDetailsTable.module.scss';
import { sumBy } from 'lodash-es';
import { EMPTY } from 'common/constants';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';

type DetailItem = NonNullable<
  PurchaseOrderDetailsQuery['purchaseOrderDetailsReport']
>['metrics'][number];

export const PurchaseOrderDetailsTable = ({
  uniqueOrderId,
  customerPo
}: {
  uniqueOrderId: string;
  customerPo: string;
}) => {
  const { data, loading, error } = useQuery(PurchaseOrderDetailsDocument, {
    variables: {
      uniqueOrderId
    }
  });

  // We can do it in one pass, but it's more cumbersome
  const submittedQuantityTotal = useMemo(
    () => sumBy(data?.purchaseOrderDetailsReport?.metrics || [], 'submittedQuantity'),
    [data]
  );
  const acceptedQuantityTotal = useMemo(
    () => sumBy(data?.purchaseOrderDetailsReport?.metrics || [], 'acceptedQuantity'),
    [data]
  );
  const deliveredQuantityTotal = useMemo(
    () => sumBy(data?.purchaseOrderDetailsReport?.metrics || [], 'deliveredQuantity'),
    [data]
  );

  if (error || !uniqueOrderId) {
    return <div>Can not load PO details for {customerPo}</div>;
  }

  const columns: ColumnsType<DetailItem> = [
    {
      title: 'DC',
      key: 'dc',
      render: (_, { dc }) => dc || EMPTY,
      sorter: (a, b) => (a?.dc || '').localeCompare(b?.dc || '')
    },
    {
      title: 'UPC',
      key: 'upc',
      // TODO: what shall we do with it?
      render: (_, { upc }) => upc || EMPTY,
      sorter: (a, b) => (a?.upc || '').localeCompare(b?.upc || '')
    },
    {
      title: 'External ID',
      key: 'externalId',
      render: (_, { externalId }) => externalId || EMPTY,
      sorter: (a, b) => (a?.externalId || '').localeCompare(b?.externalId || '')
    },
    {
      title: 'Description',
      key: 'description',
      render: (_, { description }) => description || EMPTY,
      sorter: (a, b) => (a?.description || '').localeCompare(b?.description || '')
    },
    {
      title: 'Orig. QTY',
      key: 'submittedQuantity',
      render: (_, { submittedQuantity }) => (
        <div className={s.number_cell}>{submittedQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.submittedQuantity - b.submittedQuantity
    },
    {
      title: 'Accepted QTY',
      key: 'acceptedQuantity',
      render: (_, { acceptedQuantity }) => (
        <div className={s.number_cell}>{acceptedQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.acceptedQuantity - b.acceptedQuantity
    },
    {
      title: 'Delivered QTY',
      key: 'deliveredQuantity',
      render: (_, { deliveredQuantity }) => (
        <div className={s.number_cell}>{deliveredQuantity.toLocaleString()}</div>
      ),
      sorter: (a, b) => a.deliveredQuantity - b.deliveredQuantity
    },
    {
      title: 'AFR',
      width: 90,
      key: 'acceptedFillRate',
      render: (_, { acceptedFillRate }) => <RateCellWithTargetCompact value={acceptedFillRate} />,
      onCell: ({ acceptedFillRate }) => onCellRateWithTargetCompact(acceptedFillRate),
      sorter: (a, b) => a.acceptedFillRate - b.acceptedFillRate
    },
    {
      title: 'SFR',
      width: 90,
      key: 'submittedFillRate',
      render: (_, { submittedFillRate }) => <RateCellWithTargetCompact value={submittedFillRate} />,
      onCell: ({ submittedFillRate }) => onCellRateWithTargetCompact(submittedFillRate),
      sorter: (a, b) => a.submittedFillRate - b.submittedFillRate
    },
    {
      title: 'Cut Reason',
      key: 'reason',
      render: (_, { reason }) => reason || EMPTY,
      sorter: (a, b) => (a?.reason || '').localeCompare(b?.reason || '')
    }
  ];

  return (
    <div className={s.container}>
      <AlloyTable
        rowKey={(row) => `${row.id || ''}-${row.externalId || ''}`}
        className="styled_report_table inner_table_top_pagination_margin legacy_borderless_bordered"
        size="small"
        dataSource={data?.purchaseOrderDetailsReport?.metrics || []}
        columns={columns}
        pagination={{ position: ['topRight'], showSizeChanger: true }}
        bordered
        loading={loading}
        summary={(data) => {
          if (!data || data?.length === 0) return <></>;
          // We can do it in one pass, but it's more cumbersome
          const submittedQuantity = sumBy(data, 'submittedQuantity');
          const acceptedQuantity = sumBy(data, 'acceptedQuantity');
          const deliveredQuantity = sumBy(data, 'deliveredQuantity');

          return (
            <AlloyTable.Summary>
              <AlloyTable.Summary.Row>
                <AlloyTable.Summary.Cell index={1} className={s.total} colSpan={6}>
                  This page
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={2} className={s.total}>
                  {submittedQuantity.toLocaleString()}
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={3} className={s.total}>
                  {acceptedQuantity.toLocaleString()}
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={4} className={s.total}>
                  {deliveredQuantity.toLocaleString()}
                </AlloyTable.Summary.Cell>
              </AlloyTable.Summary.Row>
              <AlloyTable.Summary.Row>
                <AlloyTable.Summary.Cell index={1} className={s.total} colSpan={6}>
                  Total
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={2} className={s.total}>
                  {submittedQuantityTotal.toLocaleString()}
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={3} className={s.total}>
                  {acceptedQuantityTotal.toLocaleString()}
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={4} className={s.total}>
                  {deliveredQuantityTotal.toLocaleString()}
                </AlloyTable.Summary.Cell>
              </AlloyTable.Summary.Row>
            </AlloyTable.Summary>
          );
        }}
      />
    </div>
  );
};
