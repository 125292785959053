import { EMPTY } from 'common/constants';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import currency from 'currency.js';

// TODO: remove this. Currently backend can return empty "sub issue type".
export const __EMPTY__DIMENION__ = '__EMPTY__DIMENSION__';

export const stringifyYearWeek = (
  { week, year }: { week: number; year: number },
  withYear: boolean
) => {
  return `${withYear ? year : ''}${convertWeekToPeriodWeekString(week)}`.trim();
};

export const stringifyBackendValue = (text: string | undefined) => {
  if (text === __EMPTY__DIMENION__) return '[N/A]';

  const abbreviations = {
    'Prep ': 'Prep - ',
    NotFilled: 'Not filled',
    NotOnTime: 'Not on time',
    DecertificationGrace: 'Decertification Grace',
    asn: 'ASN',
    imia: 'IMIA',
    otnc: 'OTNC'
  };

  let transformed = text || '';
  transformed = transformed.replaceAll('_', ' ');

  for (const [key, value] of Object.entries(abbreviations)) {
    const regex = new RegExp(`\\b${key}\\b`, 'gi');
    transformed = transformed.replace(regex, value);
  }

  return transformed;
};

export const formatCurrency = (value: string | number | undefined | currency, precision = 2) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  const money = currency(value, { symbol: '$', precision, separator: ',', decimal: '.' });
  return money.format();
};
