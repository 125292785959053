import { useLazyQuery } from '@apollo/client';
import { locationPropertyToStringArrayOrNull } from 'common/helpers/mappingHelper';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { RetailerDeliveryDestination } from 'pages/AssortmentPage/AssortmentPage';
import { LazyLoadingRddsDocument } from 'pages/AssortmentPage/gql/__generated__/lazyLoadingRdds.query';
import { parse } from 'query-string';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface LazyLoadingRddsListProps {
  retailerProductId: string;
  count: number;
  firstRddId: string;
  tpRdds: RetailerDeliveryDestination[];
  tradingPartnerId: string;
}

const getListBeforeLoading = (
  tpRdds: RetailerDeliveryDestination[],
  count: number,
  firstRddId: string
): string[] => {
  const first = tpRdds.find((rdd) => rdd.id === firstRddId)?.externalId || '';
  const result = [first];
  while (result.length < count) {
    result.push(`${result.length}`);
  }
  return result;
};

const getListAfterLoading = (
  tpRdds: RetailerDeliveryDestination[],
  rddIds: string[],
  firstRddId: string
) => {
  return tpRdds
    .filter((tpRdd) => rddIds.includes(tpRdd.id) && !tpRdd.specialAssortment && tpRdd.active)
    .sort((tpRdd1, tpRdd2) =>
      tpRdd1.id === firstRddId
        ? -1
        : tpRdd2.id === firstRddId
          ? 1
          : (tpRdd1.externalId || '').localeCompare(tpRdd2.externalId || '')
    )
    .map((tpRdd) => tpRdd.externalId || '');
};

export const LazyLoadingRddsList = ({
  count,
  firstRddId,
  tpRdds,
  retailerProductId,
  tradingPartnerId
}: LazyLoadingRddsListProps) => {
  const history = useHistory();
  const location = parse(history.location.search);
  const [active] = useQueryParam('active', BooleanParam);
  const filterRetailerDeliveryDestinationIds = !location.retailerDeliveryDestinationIds?.includes(
    'all'
  )
    ? locationPropertyToStringArrayOrNull(location.retailerDeliveryDestinationIds)
    : null;

  const [loadAllRdds, { data, loading }] = useLazyQuery(LazyLoadingRddsDocument, {});

  const loadedData = !!data && !loading && !!data.tradingPartnerAssortmentsByRpIds;

  return (
    <div
      onMouseEnter={() => {
        if (!loadedData && count > 1) {
          loadAllRdds({
            variables: {
              retailerProductIds: [retailerProductId],
              active: active === undefined || active === null ? true : active,
              tradingPartnerId,
              retailerDeliveryDestinationIds: filterRetailerDeliveryDestinationIds
            }
          });
        }
      }}
    >
      <ListOfItemsColumn
        loading={loading}
        items={
          loadedData
            ? getListAfterLoading(
                tpRdds,
                data.tradingPartnerAssortmentsByRpIds &&
                  data.tradingPartnerAssortmentsByRpIds.length > 0
                  ? data.tradingPartnerAssortmentsByRpIds[0]?.rddIds || []
                  : [],
                firstRddId
              )
            : getListBeforeLoading(tpRdds, count, firstRddId)
        }
      />
    </div>
  );
};
