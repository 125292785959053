import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { uniq } from 'lodash-es';
import { DistributionCenter } from 'pages/AssortmentPage/AssortmentPage';
import React from 'react';

interface RddsListProps {
  activeDcIds: string[];
  tpDcs: DistributionCenter[];
}

const getListByIds = (tpDcs: DistributionCenter[], activeDcIds: string[]) => {
  return uniq(
    activeDcIds.map((dcId) => {
      const found = tpDcs.find((dc) => dc.id === dcId);
      return `${found?.name || ''} ${found?.code || ''}`;
    })
  );
};

export const DcsList = ({ tpDcs, activeDcIds }: RddsListProps) => (
  <div>
    <ListOfItemsColumn items={getListByIds(tpDcs, activeDcIds)} />
  </div>
);
