import React from 'react';
import s from './PurchaseOrderDetailsInformation.module.scss';
import { App } from 'ant5';
import { useMutation } from '@apollo/client';
import PurchaseOrderDetailsInformationItem from 'pages/OrderDetailsPage/components/PurchaseOrderDetailsInformationItem/PurchaseOrderDetailsInformationItem';
import questionIcon from 'assets/icons/question_icon.svg';
import { PO_STATUS_NEW, DeliveryType } from 'common/constants';
import { dateFormat } from 'common/helpers/date';
import moment, { Moment } from 'moment';
import { useRef } from 'react';
import { UpdatePurchaseOrderDetailsMabdDocument } from './gql/__generated__/updatePurchaseOrderDetailsMABD.mutation';
import { PurchaseOrderWithOverdue } from 'pages/OrderDetailsPage/types';
import EditIcon from 'assets/icons/common/edit.svg';
import { PurchaseOrderRefetchQueries } from 'common/constants';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDatePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { ChipsList } from 'components/alloy/ChipsList/ChipsList';

interface PurchaseOrderDetailsInformationProps {
  purchaseOrder: PurchaseOrderWithOverdue;
  address: string;
  addressZip: string;
  addressCity: string;
  addressState: string;
  businessUnit: string;
  deliveryType: string;
  deliveryWindowEnd: string;
  deliveryWindowStart: string;
  orderType: string;
  poDate: string;
  poTradingPartnerCode: string;
  shipToNumber: string;
  toggleEditPoTagsModal: () => void;
}

const PurchaseOrderDetailsInformation = ({
  purchaseOrder,
  address,
  addressZip,
  addressCity,
  addressState,
  businessUnit,
  deliveryType,
  deliveryWindowStart,
  deliveryWindowEnd,
  orderType,
  poDate,
  poTradingPartnerCode,
  shipToNumber,
  toggleEditPoTagsModal
}: PurchaseOrderDetailsInformationProps) => {
  const { message } = App.useApp();

  let fullAddress = '';
  if (purchaseOrder.retailerDeliveryDestination) {
    fullAddress = `${address || ''} ${addressCity || ''}, ${addressState || ''} ${
      addressZip || ''
    }`;
  } else if (purchaseOrder.tradingPartner) {
    const rdd = purchaseOrder.tradingPartner.retailerDeliveryDestinations?.find(
      (rdd) => rdd.externalId === purchaseOrder.retailerDeliveryDestinationExternalId
    );
    if (rdd) {
      fullAddress = `${rdd.address || ''} ${rdd.addressCity || ''}, ${rdd.addressState || ''} ${
        rdd.addressZip || ''
      }`;
    }
  }

  let fullCustomer = 'Not Found';
  if (purchaseOrder.retailerDeliveryDestination) {
    fullCustomer =
      deliveryType !== DeliveryType.ECOMM
        ? `${purchaseOrder.retailerDeliveryDestination?.name} - ${purchaseOrder.retailerDeliveryDestination?.externalId}`
        : purchaseOrder.retailerDeliveryDestination?.name || '';
  } else if (purchaseOrder.retailerDeliveryDestinationExternalId) {
    fullCustomer = purchaseOrder.retailerDeliveryDestinationExternalId;
  }

  const MustArriveByDateField = ({
    purchaseOrderId,
    deliveryWindowEnd
  }: {
    purchaseOrderId: string;
    deliveryWindowEnd: Moment | undefined;
  }) => {
    const ref = useRef<any>(null);
    const [updateSalesOrderDate, { loading }] = useMutation(
      UpdatePurchaseOrderDetailsMabdDocument,
      {
        refetchQueries: PurchaseOrderRefetchQueries,
        onCompleted: () => {
          ref?.current?.blur();
          message.success('Must Arrive By Date was successfully changed');
        },
        onError: (error) => {
          console.error(error.message);
          message.error(`Error happened during Must Arrive By Date changing: ${error.message}`);
        }
      }
    );

    return (
      <AlloySpin spinning={loading}>
        <AlloyDatePicker
          data-testid="mabd-delivery-date-picker"
          ref={ref}
          placeholder=""
          value={!loading ? deliveryWindowEnd : undefined}
          format="M/D/YY"
          allowClear={false}
          onClick={(e) => {
            e.preventDefault();
          }}
          onChange={(date) => {
            updateSalesOrderDate({
              variables: {
                input: {
                  id: purchaseOrderId,
                  mustArriveByDate: date ? (date as moment.Moment).format('YYYY-MM-DD') : ''
                }
              }
            });
          }}
        />
      </AlloySpin>
    );
  };

  return (
    <>
      <AlloyRow data-testid="purchase-order-details-info">
        {/* TODO: this below is a placeholder div for testing styles and how they are affected by the removal of bulkUpdateMessage */}
        <div></div>
        {/* <div>{bulkUpdateMessage}</div> */}
        <div className={s.po_details_info_container}>
          <PurchaseOrderDetailsInformationItem
            data-testid="trading-partner-code"
            itemTitle="Trading Partner"
            itemData={poTradingPartnerCode}
          />
          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="customer"
            itemTitle="Customer"
            itemData={fullCustomer}
            error={!purchaseOrder.retailerDeliveryDestination}
          />

          {deliveryType === DeliveryType.WHD || DeliveryType.DSD ? (
            <>
              <div className={s.divider} />
              <PurchaseOrderDetailsInformationItem
                data-testid="ship-to-number"
                itemTitle="Ship To #"
                itemData={shipToNumber}
                error={!purchaseOrder.retailerDeliveryDestination}
              />
            </>
          ) : null}

          {deliveryType !== DeliveryType.ECOMM && (
            <>
              <div className={s.divider} />
              <PurchaseOrderDetailsInformationItem
                data-testid="address"
                itemTitle="Address"
                itemData={fullAddress}
              />
            </>
          )}

          {deliveryType === DeliveryType.DSD && (
            <>
              <div className={s.divider} />
              <PurchaseOrderDetailsInformationItem
                data-testid="business-unit"
                itemTitle="BU"
                itemData={businessUnit}
              />
            </>
          )}

          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="po-date"
            itemTitle="PO Date"
            itemData={dateFormat(poDate)}
          />

          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="mabd"
            itemTitle={
              <>
                RDD{' '}
                <AlloyTooltip title="Requested Delivery Date" placement="top">
                  <img alt="subtract icon" className="tooltip_question_icon" src={questionIcon} />
                </AlloyTooltip>
              </>
            }
            itemData={
              orderType === DeliveryType.WHD &&
              (purchaseOrder?.statuses?.primary || '').toUpperCase() === PO_STATUS_NEW ? (
                <MustArriveByDateField
                  purchaseOrderId={purchaseOrder.id}
                  deliveryWindowEnd={moment(deliveryWindowEnd)}
                />
              ) : (
                dateFormat(deliveryWindowEnd) || 'TBD'
              )
            }
          />

          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="delivery-window"
            itemTitle="Delivery Window"
            itemData={`${dateFormat(deliveryWindowStart)} - ${dateFormat(deliveryWindowEnd)}`}
          />

          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="delivery-type"
            itemTitle="Type"
            itemData={deliveryType || '-'}
          />
          <div className={s.divider} />
          <PurchaseOrderDetailsInformationItem
            data-testid="po-details-tags"
            itemTitle={
              <div className={s.tags_title}>
                Tags
                {purchaseOrder?.labels.length > 0 && (
                  <AlloyButton
                    icon={<img src={EditIcon} alt="" />}
                    onClick={toggleEditPoTagsModal}
                    type="link"
                    className={s.edit_tags_button}
                    data-testid="edit-tags"
                  />
                )}
              </div>
            }
            itemData={
              purchaseOrder?.labels.length > 0 ? (
                <ChipsList
                  style={{ width: purchaseOrder?.labels.length ? '210px' : '100px' }}
                  chips={purchaseOrder?.labels?.map(({ text }) => ({
                    label: text,
                    selected: true
                  }))}
                  moreChipProps={{ selected: true }}
                  size="small"
                />
              ) : (
                <AlloyButton onClick={toggleEditPoTagsModal} type="primary" size="small">
                  Add tag
                </AlloyButton>
              )
            }
          />
        </div>
      </AlloyRow>
    </>
  );
};

export default PurchaseOrderDetailsInformation;
