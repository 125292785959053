import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateOrdersSpreadsheetMutationVariables = Types.Exact<{
  input: Types.ValidateOrdersSpreadsheetInput;
}>;


export type ValidateOrdersSpreadsheetMutation = { __typename?: 'RootMutationType', validateOrdersSpreadsheet?: { __typename?: 'ValidateOrdersSpreadsheetPayload', duplicates: Array<string | null>, message: string, validOrders: number } | null };


export const ValidateOrdersSpreadsheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"validateOrdersSpreadsheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ValidateOrdersSpreadsheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateOrdersSpreadsheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"duplicates"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"validOrders"}}]}}]}}]} as unknown as DocumentNode<ValidateOrdersSpreadsheetMutation, ValidateOrdersSpreadsheetMutationVariables>;