import React, { useEffect, useState } from 'react';
import s from './BusinessUnitsFrt.module.scss';
import clsx from 'clsx';
import { BusinessUnit, FrtType } from 'pages/FillRateReport/types';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';

interface BusinessUnitsFrtProps {
  subBusinessUnits: BusinessUnit[];
  metricsBySubBus: {
    subBusinessUnitId: string;
    metrics: FillRateReportMetricFragment;
  }[];
}

const aggregateBuDataByParents = (
  metricsBySubBus: {
    subBusinessUnitId: string;
    metrics: FillRateReportMetricFragment;
  }[],
  businessUnits: BusinessUnit[]
) =>
  metricsBySubBus.reduce(
    (result, current) => {
      const bu = businessUnits.find(
        (businessUnit) => businessUnit.id === current.subBusinessUnitId
      );
      if (bu?.parentBusinessUnit) {
        const existingParentBu = result.find(
          (buData) => buData.subBusinessUnitId === bu.parentBusinessUnit?.id
        );
        if (existingParentBu) {
          const newDeliveredQuantity =
            existingParentBu.metrics.deliveredQuantity + current.metrics.deliveredQuantity;
          const newSubmittedQuantity =
            existingParentBu.metrics.submittedQuantity + current.metrics.submittedQuantity;
          const newAcceptedQuantity =
            existingParentBu.metrics.acceptedQuantity + current.metrics.acceptedQuantity;
          existingParentBu.metrics = {
            acceptedQuantity: newAcceptedQuantity,
            submittedQuantity: newSubmittedQuantity,
            deliveredQuantity: newDeliveredQuantity,
            cutQuantity: existingParentBu.metrics.cutQuantity + current.metrics.cutQuantity,
            acceptedFillRate:
              Math.round((newDeliveredQuantity / newAcceptedQuantity) * 10000) / 100,
            submittedFillRate:
              Math.round((newDeliveredQuantity / newSubmittedQuantity) * 10000) / 100
          };
        } else {
          result.push({
            subBusinessUnitId: bu.parentBusinessUnit.id,
            metrics: current.metrics
          });
        }
      } else {
        result.push(current);
      }
      return result;
    },
    [] as {
      subBusinessUnitId: string;
      metrics: FillRateReportMetricFragment;
    }[]
  );

export const BusinessUnitsFrt = ({ subBusinessUnits, metricsBySubBus }: BusinessUnitsFrtProps) => {
  const [frtType, setFrtType] = useState<FrtType>('acceptedFillRate');
  const [metricsData, setMetricsData] = useState(
    aggregateBuDataByParents(metricsBySubBus, subBusinessUnits)
  );

  useEffect(() => {
    setMetricsData(aggregateBuDataByParents(metricsBySubBus, subBusinessUnits));
  }, [metricsBySubBus, subBusinessUnits]);

  const BuItem = ({
    data
  }: {
    data: { subBusinessUnitId: string; metrics: FillRateReportMetricFragment };
  }) => {
    const bu = subBusinessUnits.find((businessUnit) => businessUnit.id === data.subBusinessUnitId);
    const rate = data.metrics[frtType];
    return (
      <div
        className={clsx(s.data_item, (rate > 100 || rate < 0) && s.error, rate > 75 && s.positive)}
      >
        {bu?.name}
        <div className={s.data_container}>
          <div>
            {Math.round(rate)}
            <span className={s.percentage}>%</span>
          </div>
          {frtType === 'acceptedFillRate' && (
            <div>
              <p>PO Original Qty: {data.metrics.acceptedQuantity.toLocaleString()}</p>
              <p>Delivered Qty: {data.metrics.deliveredQuantity.toLocaleString()}</p>
            </div>
          )}
          {frtType === 'submittedFillRate' && (
            <div>
              <p>Submitted Qty: {data.metrics.submittedQuantity.toLocaleString()}</p>
              <p>Delivered Qty: {data.metrics.deliveredQuantity.toLocaleString()}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <AlloySegmented
        options={[
          {
            value: 'acceptedFillRate',
            title: 'Accepted FRT',
            label: 'Accepted FRT'
          },
          {
            value: 'submittedFillRate',
            title: 'Submitted FRT',
            label: 'Submitted FRT'
          }
        ]}
        onChange={(key: FrtType) => setFrtType(key)}
        value={frtType}
      />
      <div className={s.items_container}>
        {metricsData.map((data, idx) => (
          <BuItem key={`${data.subBusinessUnitId}-${idx}`} data={data} />
        ))}
      </div>
    </>
  );
};
