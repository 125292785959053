import { Input, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { composeValidators, validateRequired } from '../Utils';
import { ValidationMessage } from '../ValidationMessage/ValidationMessage';
import s from './FinalFormInput.module.scss';

interface FinalFormInputProps {
  name: string;
  title?: string;
  disabled?: boolean;
  required?: boolean;
  validate?: FieldValidator<string>;
  parse?: (value: string) => string;
  type?: 'number';
  textarea?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  showCount?: string;
  focusible?: boolean;
  'data-testid'?: string;
  placeholder?: string;
  min?: string | number;
  max?: string | number;
  hideValidation?: boolean;
}

export const FinalFormInput = ({
  name,
  title,
  disabled,
  validate,
  required,
  parse,
  type,
  onBlur,
  textarea = false,
  showCount,
  focusible,
  'data-testid': dataTestid = '',
  placeholder,
  min,
  max,
  hideValidation
}: FinalFormInputProps) => {
  return (
    <div className={s.field} data-testid={dataTestid}>
      {title && (
        <>
          {required && (
            <Tooltip overlay="Required" className={s.required}>
              <span data-testid="final-form-input-required">* </span>
            </Tooltip>
          )}
          <span data-testid={'final-form-input-title'}>{title}</span>
        </>
      )}
      <Field
        name={name}
        required={required}
        validate={
          required
            ? validate
              ? composeValidators([validateRequired, validate])
              : validateRequired
            : validate
        }
        parse={parse}
      >
        {({ input, meta }) => {
          return (
            <div>
              {textarea ? (
                <TextArea
                  {...input}
                  data-testid={`final-form-text-area-field`}
                  autoComplete="off"
                  disabled={disabled}
                  onBlur={(event) => {
                    onBlur && onBlur(event);
                    input.onBlur(event);
                  }}
                  placeholder={placeholder}
                />
              ) : (
                <Input
                  {...input}
                  data-testid={`final-form-input-field`}
                  autoComplete="off"
                  disabled={disabled}
                  type={type}
                  min={min}
                  max={max}
                  onBlur={(event) => {
                    onBlur && onBlur(event);
                    input.onBlur(event);
                  }}
                  placeholder={placeholder}
                />
              )}
              {showCount && !disabled ? (
                <div
                  data-testid="final-form-input-validation"
                  className={`validation-counter ${
                    meta.error && (meta.modified || meta.touched)
                      ? 'invalid'
                      : meta.valid && (meta.modified || meta.touched)
                        ? 'valid'
                        : ''
                  }`}
                >
                  {`${`${input.value}`.length}/${showCount}`}
                </div>
              ) : (
                !(hideValidation && !(meta.error && meta.touched)) && (
                  <ValidationMessage meta={meta} />
                )
              )}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
