import React from 'react';
import { Product } from './BulkAddAssortmentModal/BulkAddAssortmentModal';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';

interface BulkAddAssortmentTableProps {
  loading: boolean;
  tradingPartnerAssortment: Product[];
  selected: string[];
  setSelected: (selected: string[]) => void;
}

export const BulkAddAssortmentTable = ({
  loading,
  tradingPartnerAssortment,
  selected,
  setSelected
}: BulkAddAssortmentTableProps) => (
  <AlloyTable
    loading={loading}
    size="small"
    dataSource={tradingPartnerAssortment.sort((vp1, vp2) =>
      vp1.retailerProductExternalId.localeCompare(vp2.retailerProductExternalId)
    )}
    rowKey={(row) => row.retailerProductId}
    columns={[
      {
        title: 'External ID',
        render: (item: Product) => item.retailerProductExternalId
      },
      {
        title: 'Name',
        render: (item: Product) => (item.substitutions ? item.substitutions[0]?.name : '')
      }
    ]}
    rowSelection={{
      onChange: (selectedRowKeys) => {
        if (selectedRowKeys.length - selected.length > 1) {
          // selected all
          setSelected(tradingPartnerAssortment.map((tpa) => tpa.retailerProductId));
        } else if (selectedRowKeys.length - selected.length < -1) {
          // deselect all
          setSelected([]);
        } else {
          setSelected(selectedRowKeys.map((key) => key as string));
        }
      },
      selectedRowKeys: selected,
      type: 'checkbox'
    }}
  />
);
