import React, { useEffect, useMemo, useState } from 'react';
import s from './CutsTable.module.scss';
import { InventoryRepackFilters, RepackOptimizerStage } from 'graphql/__generated__/types';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import {
  periodWeekComparator,
  safeLocaleCompare,
  safeNumberComparator
} from 'common/helpers/comparators';
import clsx from 'clsx';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { ArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { notEmpty } from 'common/helpers/notEmpty';
import { SearchOutlined, SwapOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import {
  InventoryRepackCutsReportDocument,
  InventoryRepackCutsReportQuery
} from './gql/__generated__/inventoryRepackCutsReport.query';
import { useQuery } from '@apollo/client';
import {
  InventoryRepackCutsComponentsReportDocument,
  InventoryRepackCutsComponentsReportQuery
} from './gql/__generated__/inventoryRepackCutsComponentsReport.query';
import { convertWeekToPeriodWeekString, getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { getNextWeekAndYear } from 'common/helpers/periodHelper';
import { DEFAULT_PAGE_SIZE_OPTIONS, NOT_AVAILABLE } from 'common/constants';
import {
  CUTS_FILTERS_QUERY_PARAM,
  CUTS_SEARCH_QUERY_PARAM,
  repackPlanningTableFilters,
  TYPE_CONST
} from 'pages/RepackPlanning/constants';
import clipboardIcon from 'assets/icons/clipboard_icon.svg';
import scissorsIcon from 'assets/icons/scissors_icon.svg';
import { NotCuttingModal } from '../NotCuttingModal/NotCuttingModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { ColumnsWithExportRender } from 'common/helpers/tableExportAlloy';
import { RepackOptimizerStatus } from '../RepackOptimizerStatus/RepackOptimizerStatus';
import { ChangeTooltip } from '../ChangeTooltip/ChangeTooltip';
import { capitalize } from 'lodash-es';
import { TableFilters } from 'components/TableFilters';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { useRepackOptimizerStatus } from '../RepackOptimizerStatus/useRepackOptimiserStatus';
import { paginate, sortTableDataWithTableSorter } from 'common/helpers/table';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';
import moment from 'moment';

function displayNumber(number: number | null | undefined) {
  return number ? number.toLocaleString() : 0;
}

const formatNumber = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return '';
  return parsedValue.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export type CutsItem = NonNullable<
  NonNullable<InventoryRepackCutsReportQuery['inventoryRepackCutsReport']>['metrics']
>[number];

type CutsDetailsType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        InventoryRepackCutsComponentsReportQuery['inventoryRepackCutsComponentsReport']
      >['metrics']
    >[number]['components']
  >[number]
>;

export const getCutsColumns = (
  setOpenItemId: (value: string | null | undefined) => void,
  isExportMode = false
) => {
  const columns: ColumnsWithExportRender<CutsItem> = [
    AlloyTable.SELECTION_COLUMN,
    AlloyTable.EXPAND_COLUMN,
    {
      title: 'Item ID',
      render: (_, { itemId }) => <span className={s.number}>{itemId}</span>,
      sorter: (a, b) => safeLocaleCompare(a.itemId, b.itemId),
      width: 125,
      exportConfig: {
        render: ({ itemId }) => itemId
      }
    },
    {
      title: '',
      render: (_, { lastChangeInfo, isMoved }) => (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {isMoved && (
            <AlloyTooltip title="Was moved">
              <div className={s.was_moved}>
                <SwapOutlined />
              </div>
            </AlloyTooltip>
          )}
          {lastChangeInfo && (
            <ChangeTooltip lastChangeInfo={lastChangeInfo}>
              <div>
                <object
                  type="image/svg+xml"
                  data={scissorsIcon}
                  aria-label="Cut"
                  className={s.cut_icon}
                />
              </div>
            </ChangeTooltip>
          )}
        </div>
      ),
      width: 52,
      onCell: () => ({ style: { padding: '4px' } })
    },
    {
      title: 'Product Title',
      dataIndex: 'productTitle',
      sorter: (a, b) => safeLocaleCompare(a.productTitle, b.productTitle),
      exportConfig: {
        render: ({ productTitle }) => productTitle || ''
      }
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      render: (_, { type }) => TYPE_CONST[type || NOT_AVAILABLE],
      sorter: (a, b) => safeLocaleCompare(a.type, b.type),
      width: 125,
      className: s.capitalize,
      exportConfig: {
        title: 'Type',
        render: ({ type }) => TYPE_CONST[type || NOT_AVAILABLE]
      }
    },
    {
      title: 'ASIN',
      dataIndex: 'asin',
      sorter: (a, b) => safeLocaleCompare(a.asin, b.asin),
      width: 125,
      exportConfig: {
        render: ({ asin }) => asin || ''
      }
    },
    {
      title: 'System Cut Reason',
      dataIndex: 'cutReason',
      sorter: (a, b) => safeLocaleCompare(a.cutReason, b.cutReason),
      width: 160,
      exportConfig: {
        render: ({ cutReason }) => cutReason || ''
      }
    },
    {
      title: 'Item Cut Qty',
      dataIndex: 'cutQty',
      render: (_, { cutQty }) => (
        // TODO: add boldness if isCut ?
        <div className={clsx(s.number, s.right, { [s.bold]: false })}>{displayNumber(cutQty)}</div>
      ),
      sorter: (a, b) => safeNumberComparator(a.cutQty, b.cutQty),
      width: 125,
      defaultSortOrder: 'descend',
      exportConfig: {
        render: ({ cutQty }) => cutQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      title: 'Recovery Period',
      dataIndex: 'recoveryPeriod',
      sorter: (a, b) => periodWeekComparator(a.recoveryPeriod, b.recoveryPeriod),
      width: 90,
      exportConfig: {
        render: ({ recoveryPeriod }) => recoveryPeriod || ''
      }
    },
    {
      exportConfig: {
        title: 'Was moved',
        render: ({ isMoved }) => (isMoved ? 'Yes' : '')
      }
    },
    {
      exportConfig: {
        title: 'Last moved reason',
        render: ({ lastChangeInfo }) => lastChangeInfo?.reason || ''
      }
    },
    {
      exportConfig: {
        title: 'Last moved comment',
        render: ({ lastChangeInfo }) => lastChangeInfo?.comment || ''
      }
    },
    {
      title: 'Last Updated',
      key: 'updatedAt',
      width: 160,
      render: (_, record) => {
        return (
          <UpdatedAt
            allowForNonAdminUsers
            onClick={(e) => {
              setOpenItemId(record.itemId);
              e.stopPropagation();
            }}
            updatedAt={record.updatedAt}
          />
        );
      },
      sorter: (a, b) => moment(a.updatedAt).diff(b.updatedAt)
    }
  ];
  if (isExportMode) return columns;
  return columns.filter((x) => x.render || x.dataIndex);
};

const getKey = (item: CutsItem) => item.id;

export const CutsTable = ({
  filters,
  referenceDate,
  stage
}: {
  filters: InventoryRepackFilters;
  referenceDate: Date;
  stage: RepackOptimizerStage;
}) => {
  const [searchTerm, setSearchTerm] = useQueryParam(
    CUTS_SEARCH_QUERY_PARAM,
    withDefault(ArrayParam, [])
  );

  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const [isUncuttingVisible, setIsUncuttingVisible] = useState(false);
  const cuts = useQuery(InventoryRepackCutsReportDocument, {
    variables: { filters }
  });

  const { status, shouldShowTable } = useRepackOptimizerStatus({ stage, refetch: cuts.refetch });

  const metrics = useMemo(
    () => cuts.data?.inventoryRepackCutsReport?.metrics || [],
    [cuts.data?.inventoryRepackCutsReport?.metrics]
  );

  const [openItemId, setOpenItemId] = useQueryParam('cutsAudit', withDefault(StringParam, ''));
  const openItem = useMemo(
    () => metrics.find((x) => x.itemId === openItemId),
    [metrics, openItemId]
  );

  const [selectedRows, setSelectedRows] = useState<CutsItem[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  // If stage changes, reset select/expand.
  useEffect(() => {
    setSelectedRows([]);
    setExpandedRowKeys([]);
  }, [stage]);

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE_OPTIONS[0]);
  const [page, setPage] = useState(1);

  const [sorter, setSorter] = useState<SorterResult<CutsItem>>();

  const currentPageItemsExpandableKeys = useMemo(() => {
    return paginate(sortTableDataWithTableSorter(metrics, sorter), page, pageSize)
      .filter((x) => !!x.itemId)
      .map(getKey);
  }, [metrics, page, pageSize, sorter]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageItemsExpandableKeys.every((item) => expandedRowKeys.includes(item)),
    [expandedRowKeys, currentPageItemsExpandableKeys]
  );

  const columns = getCutsColumns(setOpenItemId);

  return (
    <div className={s.wrapper}>
      <div className={s.quantity}>
        Cuts ({cuts.loading || !shouldShowTable ? '...' : metrics.length})
        {selectedRows.length > 0 && (
          <span>
            , {selectedRows.length} selected
            <AlloyButton style={{ marginLeft: '12px' }} onClick={() => setSelectedRows([])}>
              Clear selection
            </AlloyButton>
          </span>
        )}
      </div>
      <div className={s.topRow}>
        <div className={s.actions}>
          <MultipleValuesInput
            placeholder="Search by Item ID or ASIN"
            value={
              nonEmptySearch
                ? typeof nonEmptySearch === 'string'
                  ? [nonEmptySearch]
                  : nonEmptySearch
                : []
            }
            onChange={setSearchTerm}
            allowClear={true}
            prefix={<SearchOutlined width="14px" height="14px" />}
            splitInputValue={/[^0-9a-zA-Z-]+/g}
          />
          <TableFilters
            filters={repackPlanningTableFilters}
            queryParamName={CUTS_FILTERS_QUERY_PARAM}
            loading={false}
          />
        </div>
        {selectedRows.length > 0 ? (
          <AlloyButton
            type="secondary"
            onClick={() => setIsUncuttingVisible(true)}
            icon={<img src={clipboardIcon} alt="" />}
          >
            Not Cutting
          </AlloyButton>
        ) : (
          <></>
        )}
      </div>
      {shouldShowTable ? (
        <AlloyTable
          onChange={(pagination, _filter, sorting) => {
            if (pagination.current) {
              setPage(pagination.current);
            }
            if (pagination.pageSize) {
              setPageSize(pagination.pageSize);
            }
            // For now handling only 1-way-sorting
            if (sorting) {
              if (Array.isArray(sorting)) {
                setSorter(sorting[0]);
              } else {
                setSorter(sorting);
              }
            }
          }}
          className={s.table}
          columns={columns}
          dataSource={metrics}
          loading={cuts.loading}
          rowKey={getKey}
          pagination={{
            hideOnSinglePage: true,
            size: 'small',
            pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            defaultPageSize: DEFAULT_PAGE_SIZE_OPTIONS[0]
          }}
          rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (_keys, rows) => {
              setSelectedRows(rows);
            },
            type: 'checkbox',
            selectedRowKeys: selectedRows.map(getKey),
            //@ts-ignore
            getCheckboxProps(record) {
              return { 'data-testid': getKey(record) };
            }
          }}
          expandable={{
            expandedRowRender: (row) => (
              <CutsDetailsTable
                itemId={row.itemId}
                filters={filters}
                referenceDate={referenceDate}
              />
            ),
            rowExpandable: (row) => !!row.itemId,
            expandedRowKeys: expandedRowKeys,
            onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
            columnTitle: metrics.length > 0 && (
              <ExpandButton
                expanded={areAllKeysExpandedOnThePage}
                onClick={(expanded) => {
                  expanded
                    ? setExpandedRowKeys(
                        expandedRowKeys.filter((x) => !currentPageItemsExpandableKeys.includes(x))
                      )
                    : setExpandedRowKeys([
                        ...new Set([...currentPageItemsExpandableKeys, ...expandedRowKeys])
                      ]);
                }}
                collapseMessage="Collapse all"
                expandMessage="Expand all"
              />
            )
          }}
        />
      ) : (
        <RepackOptimizerStatus
          status={status.data?.repackOptimizerStatus?.status}
          loading={status.loading}
        />
      )}
      <NotCuttingModal
        items={selectedRows}
        setIsModalVisible={setIsUncuttingVisible}
        isModalVisible={isUncuttingVisible}
        resetSelection={() => setSelectedRows([])}
        stage={stage}
      />
      <ChangeHistory
        entity={
          openItemId
            ? {
                id: openItemId,
                name: 'cutsItem',
                type: 'Repack Planning',
                inventoryRepackFilters: {
                  ...filters,
                  itemsIds: [openItemId]
                }
              }
            : undefined
        }
        onClose={() => setOpenItemId('')}
        title={
          <span>
            <b>ASIN: </b>
            {openItem?.asin}
          </span>
        }
      />
    </div>
  );
};

const highlightedCellStyle = {
  style: {
    background: '#DEF3BC',
    color: '#006E05'
  }
};

const CutsDetailsTable = ({
  itemId,
  filters,
  referenceDate
}: {
  itemId: string;
  filters: InventoryRepackFilters;
  referenceDate: Date;
}) => {
  const [currentWeek, week1, week2] = useMemo(() => {
    const current = getFinancialInfo(referenceDate);
    const week1 = getNextWeekAndYear(current.week, current.year);
    const week2 = getNextWeekAndYear(week1.week, week1.year);
    return [
      convertWeekToPeriodWeekString(current.week),
      convertWeekToPeriodWeekString(week1.week),
      convertWeekToPeriodWeekString(week2.week)
    ];
  }, [referenceDate]);

  const { data, loading } = useQuery(InventoryRepackCutsComponentsReportDocument, {
    // TODO: pass current page IDs, load multiple here, so it's only 1 call?
    variables: { filters: { ...filters, itemsIds: [itemId] } }
  });

  const metrics = useMemo(
    () =>
      (
        (data?.inventoryRepackCutsComponentsReport?.metrics || []).find((x) => x.itemId === itemId)
          ?.components || []
      ).filter(notEmpty),
    [data?.inventoryRepackCutsComponentsReport?.metrics, itemId]
  );

  const columns: ColumnsType<CutsDetailsType> = [
    {
      title: 'Cut Component Information',
      children: [
        {
          title: 'Cut component ID',
          render: (_, { componentId }) => <span className={s.number}>{componentId}</span>,
          align: 'left' as const,
          sorter: (a, b) => safeLocaleCompare(a.componentId, b.componentId),
          width: 165
        },
        {
          title: 'Cut components',
          dataIndex: 'componentName',
          align: 'left' as const,
          sorter: (a, b) => safeLocaleCompare(a.componentName, b.componentName)
        },
        {
          title: 'Plant',
          render: (_, { plant }) => capitalize(plant),
          align: 'left' as const,
          sorter: (a, b) => safeLocaleCompare(a.plant, b.plant),
          width: 115
        },
        {
          title: () => <div className={s.left}>Shortfall</div>,
          render: (_, { shortfallQty }) => (
            <span className={s.number}>{formatNumber(shortfallQty)}</span>
          ),
          align: 'right' as const,
          sorter: (a, b) => safeNumberComparator(a.shortfallQty, b.shortfallQty),
          width: 150
        }
      ]
    },
    {
      title: currentWeek,
      align: 'center' as const,
      children: [
        {
          title: () => <div className={s.center}>Day 1</div>,
          render: (_, { day1 }) => <span className={s.number}>{formatNumber(day1)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'day1' ? highlightedCellStyle : {}),
          width: 85
        },
        {
          title: () => <div className={s.center}>Day 2</div>,
          render: (_, { day2 }) => <span className={s.number}>{formatNumber(day2)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'day2' ? highlightedCellStyle : {}),
          width: 85
        },
        {
          title: () => <div className={s.center}>Day 3</div>,
          render: (_, { day3 }) => <span className={s.number}>{formatNumber(day3)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'day3' ? highlightedCellStyle : {}),
          width: 85
        },
        {
          title: () => <div className={s.center}>Day 4</div>,
          render: (_, { day4 }) => <span className={s.number}>{formatNumber(day4)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'day4' ? highlightedCellStyle : {}),
          width: 85
        },
        {
          title: () => <div className={s.center}>Day 5</div>,
          render: (_, { day5 }) => <span className={s.number}>{formatNumber(day5)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'day5' ? highlightedCellStyle : {}),
          width: 85
        }
      ]
    },
    {
      title: week1,
      align: 'center' as const,
      children: [
        {
          title: () => <div className={s.center}>All Week</div>,
          render: (_, { week1 }) => <span className={s.number}>{formatNumber(week1)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'week1' ? highlightedCellStyle : {}),
          width: 100
        }
      ]
    },
    {
      title: week2,
      align: 'center' as const,
      children: [
        {
          title: () => <div className={s.center}>All Week</div>,
          render: (_, { week2 }) => <span className={s.number}>{formatNumber(week2)}</span>,
          align: 'right',
          onCell: ({ recoveryPeriod }) => (recoveryPeriod === 'week2' ? highlightedCellStyle : {}),
          width: 100
        }
      ]
    }
  ];

  return (
    <div>
      <AlloyTable
        dataSource={metrics}
        loading={loading}
        columns={columns}
        pagination={false}
        bordered
        rowKey={(row) => `${row.componentId}-${row.componentName}-${row.plant}`}
      />
    </div>
  );
};
