import React from 'react';
import { Input, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Field, Form } from 'react-final-form';
import s from './AdvancedFilterComponent.module.scss';

interface SaveFilterModalProps {
  onSave: (name: string, description: string) => void;
  onCancel: () => void;
  existingNames: string[];
}

export const SaveFilterModal = ({ onSave, onCancel, existingNames }: SaveFilterModalProps) => {
  const { TextArea } = Input;

  const onSubmit = (values: any) => {
    const { name, description } = values;
    onSave(name, description);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <Modal
          className={s.delete_filter_modal}
          data-testid="save-filter-modal"
          title={<span data-testid="save-filter-modal-title">Save Filter</span>}
          open
          maskClosable={false}
          closeIcon={<CloseOutlined />}
          closable={true}
          onCancel={onCancel}
          onOk={() => form.submit()}
          okType="primary"
          okText="Save"
          okButtonProps={{
            // @ts-ignore
            'data-testid': 'save-filter-popup-confirm'
          }}
          cancelButtonProps={{
            // @ts-ignore
            'data-testid': 'save-filter-popup-cancel'
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className={s.field}>
              Filter Title
              <Field
                validate={(value) =>
                  value && value.length > 0
                    ? existingNames.includes(value)
                      ? 'A filter with the same name already exists'
                      : undefined
                    : 'Required'
                }
                name="name"
              >
                {({ input, meta }) => (
                  <>
                    <Input {...input} type="text" required autoComplete="off" />
                    {meta.error && meta.touched && (
                      <span
                        className={`validation-counter ${
                          meta.error && meta.touched
                            ? 'invalid'
                            : meta.valid && meta.touched
                              ? 'valid'
                              : ''
                        }`}
                      >
                        {meta.error}
                      </span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div className={s.field}>
              Description
              <Field name="description">
                {({ input, meta }) => (
                  <>
                    <TextArea
                      {...input}
                      autoSize={{ minRows: 3, maxRows: 3 }}
                      required
                      autoComplete="off"
                    />
                    {meta.error && meta.touched && (
                      <span
                        className={`validation-counter ${
                          meta.error && meta.touched
                            ? 'invalid'
                            : meta.valid && meta.touched
                              ? 'valid'
                              : ''
                        }`}
                      >
                        {meta.error}
                      </span>
                    )}
                  </>
                )}
              </Field>
            </div>
          </form>
        </Modal>
      )}
    />
  );
};
