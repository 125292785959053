import React, { useState, useMemo, useEffect } from 'react';
import s from './BulkUpsertDistributionCentersModal.module.scss';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import { useMutation, useQuery } from '@apollo/client';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  AllDcsFullDataDocument,
  AllDcsFullDataQuery
} from 'pages/DistributionCentersPage/gql/__generated__/allDcsFullData.query';
import { cloneDeep, isEqual } from 'lodash-es';
import {
  CustomizationHeader,
  DistributionCenterBlackout,
  DistributionCenterSource,
  InventorySource
} from 'graphql/__generated__/types';
import { UpsertDistributionCentersDocument } from 'pages/DistributionCentersPage/gql/__generated__/upsertDistributionCenters';
import { DistributionCentersDocument } from 'pages/DistributionCentersPage/gql/__generated__/distributionCenters.query';
import clsx from 'clsx';
import { useEnumValue } from 'common/useEnumValue';
import { AlloyUpload, UploadChangeParam, UploadProps } from 'components/ui/AlloyUpload/AlloyUpload';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { App } from 'ant5';

enum Titles {
  CODE = 'Code',
  NAME = 'Name',
  SOURCE = 'Source',
  INVENTORY_SOURCE = 'Inventory Source',
  PALLETS = 'Pallets',
  WEIGHT = 'Weight',
  ADDRESS = 'Address',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zip'
}

const itemHeaders = [
  { key: 'code', label: Titles.CODE },
  { key: 'name', label: Titles.NAME },
  { key: 'source', label: Titles.SOURCE },
  { key: 'inventorySource', label: Titles.INVENTORY_SOURCE },
  { key: 'pallets', label: Titles.PALLETS },
  { key: 'weight', label: Titles.WEIGHT },
  { key: 'address', label: Titles.ADDRESS },
  { key: 'city', label: Titles.CITY },
  { key: 'state', label: Titles.STATE },
  { key: 'zip', label: Titles.ZIP }
];

type DcFromFile = {
  [key in Titles]: string;
};

const VALIDATION_RULES_ROW = {
  code: 'Required',
  name: 'Required',
  source: 'Required'
};

type DcFullData = InferNodeType<AllDcsFullDataQuery, 'distributionCenters'>;

interface FileTarget {
  uid: string;
  name: string;
  url: string;
  rows: DcFromFile[];
  error?: string;
}

interface ParsedDc extends Omit<DcFullData, 'id'> {
  errors?: string[];
  id?: string;
}

interface DcUpdates {
  new: ParsedDc[];
  updated: ParsedDc[];
  unchanged: ParsedDc[];
}

interface BulkAddStoreModalProps {
  visible: boolean;
  onClose?: (cancel?: true) => void;
}

export const BulkUpsertDistributionCentersModal = ({
  visible,
  onClose
}: BulkAddStoreModalProps) => {
  const { message, notification } = App.useApp();
  const { enumValues: inventorySources } = useEnumValue('InventorySource');
  const { enumValues: sources } = useEnumValue('DistributionCenterSource');

  const [upsertDistributionCenters] = useMutation(UpsertDistributionCentersDocument, {
    refetchQueries: [DistributionCentersDocument]
  });

  //these are the files selected by the user. setup to allow only 1
  const [file, setFile] = useState<FileTarget>();

  useEffect(() => {
    if (!file) setStep(1);
  }, [file]);

  const { data: allDcsData, loading: allDcsDataLoading } = useQuery(AllDcsFullDataDocument, {
    skip: !visible
  });

  //csv data extracted from the file
  const fileData = useMemo<
    | {
        changes: DcUpdates;
        count: number;
      }
    | undefined
  >(() => {
    if (!file || !allDcsData) return undefined;

    const newDcs: ParsedDc[] = [];
    const updated: ParsedDc[] = [];
    const unchanged: ParsedDc[] = [];

    //TODO: add new costAndCapacity code here?
    const allDcs = getNodesFromEdges(allDcsData.distributionCenters);
    file.rows.forEach((row) => {
      const errors: string[] = [];
      if (!row.Code) errors.push('Code is required');
      if (!row.Name) errors.push('Name is required');
      if (!row.Source) errors.push('Source is required');

      if (row.Pallets && !Number.isInteger(Number(row.Pallets))) {
        errors.push(`Pallets should be an integer. Found: ${row.Pallets}`);
      }
      if (row.Weight && !Number.isInteger(Number(row.Weight))) {
        errors.push(`Weight should be an integer. Found: ${row.Weight}`);
      }

      if (!(sources || []).includes(row.Source as DistributionCenterSource)) {
        errors.push('Unknown Source');
      }
      if (
        row['Inventory Source'] &&
        !(inventorySources || []).includes(row['Inventory Source'] as InventorySource)
      ) {
        errors.push('Unknown Inventory Source');
      }

      const found = allDcs.find((dc) => dc.code === row.Code);
      if (found) {
        const updatedDc = {
          ...cloneDeep(found),
          code: row.Code,
          name: row.Name,
          source: row.Source as DistributionCenterSource,
          inventorySource: row['Inventory Source']
            ? (row['Inventory Source'] as InventorySource)
            : null,
          address: row.Address || null,
          city: row.City || null,
          state: row.State || null,
          zip: row.Zip || null,
          truckCapacity: {
            ...found.truckCapacity,
            pallets: row.Pallets ? Number(row.Pallets) : null,
            weight: row.Weight ? Number(row.Weight) : null
          }
        };
        const isUpdated = !isEqual(updatedDc, found);
        if (isUpdated) {
          updated.push({ ...updatedDc, errors });
        } else {
          unchanged.push({ ...updatedDc, errors });
        }
      } else {
        newDcs.push({
          code: row.Code,
          name: row.Name,
          source: row.Source as DistributionCenterSource,
          inventorySource: row['Inventory Source']
            ? (row['Inventory Source'] as InventorySource)
            : null,
          address: row.Address,
          city: row.City,
          state: row.State,
          zip: row.Zip,
          truckCapacity: {
            pallets: row.Pallets ? Number(row.Pallets) : null,
            weight: row.Weight ? Number(row.Weight) : null
          },
          errors,
          emails: [],
          blackouts: [],
          customizationHeaders: []
        });
      }
    });

    return {
      changes: {
        new: newDcs,
        updated,
        unchanged
      },
      count: file.rows.length
    };
  }, [file, allDcsData, sources, inventorySources]);

  //step 1: select a file, step 2: view and approve the updates / inserts
  const [step, setStep] = useState(1);

  const close = () => {
    if (onClose) onClose();
    setFile(undefined);
  };

  const uploadProps: UploadProps = {
    accept: '.csv',
    name: 'file',
    maxCount: 1,
    multiple: false,
    onRemove: () => setFile(undefined),
    customRequest: ({ onSuccess }) => {
      if (onSuccess) {
        onSuccess('ok');
      }
    },
    onChange: ({ file }: UploadChangeParam) => {
      Papa.parse<DcFromFile>(file.originFileObj as File, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const { data: rows, errors } = results;
          if (file.status !== 'removed') {
            setFile({
              uid: file.uid,
              name: file.name,
              url: file.url ?? '',
              // skip validation info
              rows: rows.filter((row) => row.Code !== 'Required'),
              error: errors.length ? errors[0].message : undefined
            });
          }
        }
      });
    }
  };

  //TODO: add new DC fields costAndCapacity here?
  const submit = async () => {
    try {
      const distributionCenters = [
        ...(fileData?.changes.new || []),
        ...(fileData?.changes.updated || [])
      ].map((item) => ({
        address: item.address,
        blackouts:
          item.blackouts
            ?.filter((blackout): blackout is DistributionCenterBlackout => !!blackout)
            .map((blackout) => ({ date: blackout.date, dayOfWeek: blackout.dayOfWeek })) || [],
        city: item.city,
        code: item.code,
        customizationHeaders:
          item.customizationHeaders
            ?.filter((header): header is CustomizationHeader => !!header)
            .map((header) => ({
              field: header?.field ?? '',
              title: header?.title ?? '',
              fieldType: header?.fieldType ?? 'TEXT'
            })) || [],
        emails: item.emails?.filter((email): email is string => !!email) || [],
        id: item.id,
        inventorySource: item.inventorySource,
        name: item.name,
        source: item.source || 'MOCK',
        state: item.state,
        truckCapacity: {
          weight: item.truckCapacity?.weight || 44500,
          pallets: item.truckCapacity?.pallets || 30
        },
        zip: item.zip
      }));
      const result = await upsertDistributionCenters({
        variables: {
          input: { distributionCenters }
        }
      });

      message.success(
        `${result.data?.upsertDistributionCenters?.distributionCenters.length} Distribution Center${
          result.data?.upsertDistributionCenters?.distributionCenters.length !== 1 ? 's' : ''
        } successfully added/updated`
      );
      close();
    } catch (error) {
      notification.error({
        message: 'An error occurred',
        description: error.message
      });
    }
  };

  const stepOne = () => {
    return (
      <>
        <div>
          <span>
            Update Distribution Centers information in bulk via a CSV upload. Table data needs to
            follow{' '}
          </span>
          <CSVLink
            className={s.download_csv}
            filename={'bulk_upsert_dc_template.csv'}
            headers={itemHeaders}
            data={[VALIDATION_RULES_ROW]}
          >
            this CSV format
          </CSVLink>
          <span>.</span>
        </div>
        <div className={s.upload_container}>
          <AlloyUpload {...uploadProps} fileList={file ? [file] : []}>
            <AlloyButton>Choose a file</AlloyButton>
          </AlloyUpload>
        </div>
        {!file?.error && (
          <div className={clsx(s.row_count, { [s.show]: fileData })}>
            The selected file contains data for <span className={s.blue}>{fileData?.count}</span>{' '}
            stores.
          </div>
        )}

        {file?.error && (
          <div className={s.fileError}>
            <div className={s.title}>There was an error reading the selected file</div>
            <div>{file?.error}</div>
          </div>
        )}
      </>
    );
  };

  const stepOneFooter = () => (
    <div className={s.footer}>
      <div></div>
      <div data-testid="button-action-group" className={s.action_group}>
        <AlloyButton data-testid="bulk-add-dcs-cancel-button" onClick={() => close()}>
          Cancel
        </AlloyButton>
        <AlloyButton
          loading={allDcsDataLoading}
          type="primary"
          disabled={allDcsDataLoading || !file}
          onClick={() => setStep(2)}
        >
          Update
        </AlloyButton>
      </div>
    </div>
  );

  const stepTwo = () => {
    const columns: ColumnsType<ParsedDc> = [
      {
        title: 'Code',
        key: 'code',
        dataIndex: 'code',
        fixed: true
      },
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Source',
        dataIndex: 'source'
      },
      {
        title: 'InventorySource',
        dataIndex: 'inventorySource'
      },
      {
        title: 'Pallets',
        render: (dc: ParsedDc) => dc.truckCapacity?.pallets || ''
      },
      {
        title: 'Weight',
        render: (dc: ParsedDc) => dc.truckCapacity?.weight || ''
      },
      {
        title: 'Address',
        dataIndex: 'address'
      },
      {
        title: 'City',
        dataIndex: 'city'
      },
      {
        title: 'State',
        dataIndex: 'state'
      },
      {
        title: 'Zip',
        dataIndex: 'zip'
      }
    ];

    return (
      <>
        {!!fileData?.changes.updated.length && (
          <div className={s.update_section}>
            <div className={s.title}>Updated DCs ({fileData?.changes.updated.length})</div>
            <AlloyTable
              dataSource={fileData?.changes.updated}
              rowClassName={(row) => clsx({ [s.row_error]: row.errors?.length })}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (row) =>
                  row.errors?.map((error: string) => <p key={error}>{error}</p>),
                rowExpandable: (row) => !!row.errors?.length,
                expandedRowClassName: () => s.expanded_row,
                expandIcon: () => null
              }}
              columns={columns}
              pagination={false}
              rowKey={(dc) => dc.code}
              scroll={{
                x: true
              }}
            />
          </div>
        )}

        {!!fileData?.changes.new.length && (
          <section className={s.update_section}>
            <div className={s.title}>New DCs ({fileData?.changes.new.length})</div>
            <AlloyTable
              dataSource={fileData?.changes.new}
              rowClassName={(row) => clsx({ [s.row_error]: row.errors?.length })}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (row) =>
                  row.errors?.map((error: string) => <p key={error}>{error}</p>),
                rowExpandable: (row) => !!row.errors?.length,
                expandedRowClassName: () => s.expanded_row,
                expandIcon: () => null
              }}
              columns={columns}
              pagination={false}
              rowKey={(dc) => dc.code}
              scroll={{
                x: true
              }}
            />
          </section>
        )}

        {!fileData?.changes.new.length && !fileData?.changes.updated.length && (
          <section className={s.update_section}>
            <div className={s.title}>Unchanged DCs ({fileData?.changes.unchanged.length})</div>
          </section>
        )}
      </>
    );
  };

  const stepTwoFooter = () => {
    const errors =
      fileData &&
      [...fileData.changes.updated, ...fileData.changes.new].filter((dc) => dc.errors?.length)
        .length > 0;

    return (
      <div className={s.footer}>
        <div>
          {errors && <span className={s.errors}>Please correct errors before continuing</span>}
        </div>
        <div className={s.action_group}>
          <AlloyButton onClick={() => close()}>Cancel</AlloyButton>
          <AlloyTooltip
            title={
              !errors &&
              fileData &&
              [...fileData.changes.updated, ...fileData.changes.new].length === 0
                ? `No changes found`
                : undefined
            }
          >
            <AlloyButton
              type="primary"
              onClick={() => submit()}
              disabled={
                errors ||
                !fileData ||
                [...fileData.changes.updated, ...fileData.changes.new].length === 0
              }
            >
              Update
            </AlloyButton>
          </AlloyTooltip>
        </div>
      </div>
    );
  };

  return (
    <AlloyModal
      width="600px"
      title="Bulk add Distribution Centers"
      open={visible}
      footer={
        <>
          {step === 1 && stepOneFooter()}
          {step === 2 && stepTwoFooter()}
        </>
      }
      onCancel={() => close()}
      classNames={{
        body: s.bulk_action_modal_body,
        header: s.bulk_action_modal_header,
        footer: s.bulk_action_modal_footer,
        content: s.bulk_action_modal_content
      }}
    >
      {step === 1 && stepOne()}
      {step === 2 && stepTwo()}
    </AlloyModal>
  );
};
