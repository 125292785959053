import React, { useMemo } from 'react';
import s from './FoundItemLink.module.scss';
import { GmProduct, VendorProduct } from '../ExternalIdField';
import { Product } from '../UpcField';
import { CatalogProduct } from '../CatalogProductUpcField';
import { AlloyTooltip, TooltipPlacement } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { capitalize, get, groupBy } from 'lodash-es';
import { AssortmentConfig } from 'pages/AssortmentPage/AssortmentPage';
import { AssortmentConfigFieldSlug, CatalogSourceType } from 'graphql/__generated__/types';
import { fieldNameToCapitalize } from 'common/helpers/stringsConverter';

const slugToErpField = new Map<AssortmentConfigFieldSlug, string>([
  ['WIDTH', 'measurements.width'],
  ['HEIGHT', 'measurements.height'],
  ['DEPTH', 'measurements.depth'],
  ['GROSS_WEIGHT', 'measurements.grossWeight'],
  ['EXTERNAL_ID', 'externalId']
]);

const slugToVendorProductField = (index: number) =>
  new Map<AssortmentConfigFieldSlug, string>([
    ['WIDTH', `substitutionProducts[${index}].product.width`],
    ['HEIGHT', `substitutionProducts[${index}].product.height`],
    ['DEPTH', `substitutionProducts[${index}].product.depth`],
    ['GROSS_WEIGHT', `substitutionProducts[${index}].product.weightInPounds`],
    ['TO_CASE_QUANTITY', 'toCaseQuantity'],
    ['LAYERS_PER_PALLET', `substitutionProducts[${index}].product.layersPerPallet`],
    ['CASES_PER_LAYER', `substitutionProducts[${index}].product.casesPerLayer`],
    ['CASES_PER_PALLET', `substitutionProducts[${index}].product.quantityPerPallet`],
    ['SAP_MATERIAL_ID', `substitutionProducts[${index}].product.sapMaterialId`],
    ['ORACLE_INVEN_ID', `substitutionProducts[${index}].product.oracleInvenId`],
    ['UPC', `substitutionProducts[${index}].product.upc`],
    ['PRODUCT_TYPE', `substitutionProducts[${index}].product.productType`],
    ['EXTERNAL_ID', 'externalId']
  ]);

const slugToProductField = new Map<AssortmentConfigFieldSlug, string>([
  ['WIDTH', 'width'],
  ['HEIGHT', 'height'],
  ['DEPTH', 'depth'],
  ['GROSS_WEIGHT', 'weightInPounds'],
  ['SAP_MATERIAL_ID', 'sapMaterialId'],
  ['ORACLE_INVEN_ID', 'oracleInvenId'],
  ['UPC', 'upc']
]);

const slugToCatalogProductField = new Map<AssortmentConfigFieldSlug, string>([
  ['WIDTH', 'width'],
  ['HEIGHT', 'height'],
  ['DEPTH', 'depth'],
  ['GROSS_WEIGHT', 'grossWeight'],
  ['SAP_MATERIAL_ID', 'sapMaterialId'],
  ['ORACLE_INVEN_ID', 'oracleInvenId'],
  ['UPC', 'gtin12']
]);

const idSlugs = ['EXTERNAL_ID', 'SAP_MATERIAL_ID', 'ORACLE_INVEN_ID', 'UPC'];
const baseMeasurementSlugs = ['DEPTH', 'GROSS_WEIGHT', 'WIDTH', 'HEIGHT'];
const otherMeasurementSlugs = [
  'CASES_PER_LAYER',
  'CASES_PER_PALLET',
  'LAYERS_PER_PALLET',
  'TO_CASE_QUANTITY',
  'PRODUCT_TYPE'
];

type AllowedProductTypes = VendorProduct | Product | GmProduct | CatalogProduct;

type ViewProduct = {
  originProduct: AllowedProductTypes;
  source: 'OrEx' | 'Catalog' | CatalogSourceType | null | undefined;
  name: string;
  externalId: string;
  ids: {
    name: string;
    value: string;
  }[];
  baseMeasurements: {
    name: string;
    value: string;
  }[];
  otherMeasurements: {
    name: string;
    value: string;
  }[];
};

const isVendorProduct = (product: AllowedProductTypes): product is VendorProduct => {
  return product.__typename === 'VendorProduct';
};

const isProduct = (product: AllowedProductTypes): product is Product => {
  return product.__typename === 'Product';
};

const isGmProduct = (product: AllowedProductTypes): product is GmProduct => {
  return product.__typename === 'GmProduct';
};

const isCatalogProduct = (product: AllowedProductTypes): product is CatalogProduct => {
  return product.__typename === 'CatalogProduct';
};

const getFieldsBlockView = (
  fields: {
    name: string;
    value: string;
  }[]
) =>
  fields.length > 0 ? (
    <p>
      {fields.map((m) => (
        <span key={`${m.name}_${m.value}`}>
          <span>{m.name}:</span> {m.value || '-'}
          <br />
        </span>
      ))}
    </p>
  ) : null;

const getTooltipText = (product: ViewProduct) => {
  return (
    <div className={s.tooltip_data}>
      <p>Found in {product.source}</p>

      <p>Name: {product.name}</p>

      {getFieldsBlockView(product.ids)}
      {getFieldsBlockView(product.baseMeasurements)}
      {getFieldsBlockView(product.otherMeasurements)}
    </div>
  );
};

const getByPriority = (viewProducts: ViewProduct[]) => {
  const vendorProduct = viewProducts.find((viewProduct) =>
    isVendorProduct(viewProduct.originProduct)
  );
  if (vendorProduct) return vendorProduct;

  const product = viewProducts.find(
    (viewProduct) =>
      !isGmProduct(viewProduct.originProduct) && !isVendorProduct(viewProduct.originProduct)
  );
  if (product) return product;

  return viewProducts[0];
};

const getFieldValue = (
  fieldSlug: AssortmentConfigFieldSlug,
  product: VendorProduct | GmProduct | Product | CatalogProduct
) => {
  const value = get(
    product,
    isGmProduct(product)
      ? slugToErpField.get(fieldSlug) || ''
      : isVendorProduct(product)
        ? slugToVendorProductField(0).get(fieldSlug) || ''
        : slugToProductField.get(fieldSlug) || fieldNameToCapitalize(fieldSlug)
  );
  if (fieldSlug === 'PRODUCT_TYPE') return capitalize(value);
  return value;
};

export const FoundItemLink = ({
  changeToUpdate,
  setShowFoundItems,
  foundItems,
  fillFormWithProduct,
  fillFormWithGmProduct,
  fillFormWithCatalogProduct,
  tooltip = undefined,
  assortmentConfig
}: {
  foundItems: (VendorProduct | GmProduct)[] | Product[] | CatalogProduct[];
  setShowFoundItems: (show: boolean) => void;
  changeToUpdate?: (vendorProduct: VendorProduct) => void;
  fillFormWithProduct?: (product: Product) => void;
  fillFormWithGmProduct?: (gmProduct: GmProduct) => void;
  fillFormWithCatalogProduct?: (product: CatalogProduct) => void;
  tooltip?: TooltipPlacement | 'any';
  assortmentConfig: AssortmentConfig;
}) => {
  const groupedFoundItems = useMemo(() => {
    const viewProducts = foundItems.map((product) => {
      const productSource = () => {
        if (isVendorProduct(product) || isProduct(product)) {
          return 'OrEx';
        } else if (isGmProduct(product)) {
          return 'Catalog';
        } else if (isCatalogProduct(product)) {
          return product.source;
        }
      };
      const viewProduct: ViewProduct = {
        originProduct: product,
        source: productSource(),
        externalId: isGmProduct(product)
          ? product.externalId || ''
          : isVendorProduct(product)
            ? product.externalId
            : '',
        name: isGmProduct(product)
          ? product.name
          : isVendorProduct(product)
            ? product.substitutionProducts.length > 0
              ? product.substitutionProducts[0].product.name
              : '-'
            : isCatalogProduct(product)
              ? product.name
              : product.name,
        ids: assortmentConfig.fields
          .filter((field) => field.slug && idSlugs.includes(field.slug))
          .map((field) => ({
            name: field.name,
            value: field.slug
              ? get(
                  product,
                  isVendorProduct(product)
                    ? slugToVendorProductField(0).get(field.slug) || ''
                    : isGmProduct(product)
                      ? slugToErpField.get(field.slug) || ''
                      : isCatalogProduct(product) || ''
                        ? slugToCatalogProductField.get(field.slug) || ''
                        : slugToProductField.get(field.slug) || ''
                )
              : undefined
          })),
        baseMeasurements: assortmentConfig.fields
          .filter((field) => field.slug && baseMeasurementSlugs.includes(field.slug))
          .map((field) => ({
            name: field.name,
            value: field.slug
              ? get(
                  product,
                  isGmProduct(product)
                    ? slugToErpField.get(field.slug) || ''
                    : isVendorProduct(product)
                      ? slugToVendorProductField(0).get(field.slug) || ''
                      : slugToProductField.get(field.slug) || ''
                )
              : undefined
          })),
        otherMeasurements: assortmentConfig.fields
          .filter((field) => field.slug && otherMeasurementSlugs.includes(field.slug))
          .map((field) => ({
            name: field.name,
            value: field.slug ? getFieldValue(field.slug, product) : undefined
          }))
      };
      return viewProduct;
    });
    const groupedMap = groupBy(viewProducts, (viewProduct) =>
      JSON.stringify({
        ...viewProduct.baseMeasurements,
        ...viewProduct.ids,
        name: viewProduct.name
      })
    );

    const result: ViewProduct[] = [];
    for (const group in groupedMap) {
      const productsWithSameFields = groupedMap[group];
      if (productsWithSameFields.length === 1) {
        result.push(productsWithSameFields[0]);
      } else {
        result.push(getByPriority(productsWithSameFields));
      }
    }
    return result;
  }, [assortmentConfig.fields, foundItems]);

  const foundItemButton = (foundItem: ViewProduct) => (
    <span className={s.link_button_container}>
      <AlloyButton
        key={
          isGmProduct(foundItem.originProduct)
            ? `${foundItem.originProduct.externalId}_${foundItem.originProduct.gtin14}`
            : isCatalogProduct(foundItem.originProduct)
              ? `${foundItem.originProduct.id}_${foundItem.originProduct.gtin12}`
              : foundItem.originProduct.id
        }
        className={s.button_to_edit}
        onClick={() => {
          if (isVendorProduct(foundItem.originProduct)) {
            changeToUpdate && changeToUpdate(foundItem.originProduct);
          } else if (isGmProduct(foundItem.originProduct)) {
            fillFormWithGmProduct && fillFormWithGmProduct(foundItem.originProduct);
          } else if (isCatalogProduct(foundItem.originProduct)) {
            fillFormWithCatalogProduct && fillFormWithCatalogProduct(foundItem.originProduct);
          } else {
            fillFormWithProduct && fillFormWithProduct(foundItem.originProduct);
          }
          setShowFoundItems(false);
        }}
      >
        {isVendorProduct(foundItem.originProduct) || isGmProduct(foundItem.originProduct)
          ? (foundItem.originProduct.externalId ?? '')
          : isCatalogProduct(foundItem.originProduct)
            ? (foundItem.originProduct.gtin12 ?? '')
            : (foundItem.originProduct.upc ?? '')}
      </AlloyButton>
    </span>
  );

  return (
    <div className={s.to_edit_container}>
      <span>Item{groupedFoundItems.length > 1 ? 's were' : ' was'} found. Click to edit:</span>
      {groupedFoundItems.slice(0, 6).map((foundItem) =>
        tooltip ? (
          <AlloyTooltip
            key={
              isGmProduct(foundItem.originProduct)
                ? `${foundItem.originProduct.externalId}_${foundItem.originProduct.gtin14}`
                : isCatalogProduct(foundItem.originProduct)
                  ? `${foundItem.originProduct.id}_${foundItem.originProduct.gtin12}`
                  : foundItem.originProduct.id
            }
            title={getTooltipText(foundItem)}
            placement={tooltip === 'any' ? undefined : tooltip}
          >
            {foundItemButton(foundItem)}
          </AlloyTooltip>
        ) : (
          foundItemButton(foundItem)
        )
      )}
      {groupedFoundItems.length > 6 && '...'}
    </div>
  );
};
