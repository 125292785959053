import React, { useState } from 'react';
import s from './BusinesUnitsLineChart.module.scss';
import { FiscalCalendarWeek } from 'common/interfaces';
import { BusinessUnit } from 'pages/FillRateReport/types';
import { Line } from 'react-chartjs-2';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { getPeriodName } from '../helpers';
import { FillRateReportMetricFragment } from 'pages/FillRateReport/gql/__generated__/fillRateReportMetric.fragment';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';

interface BusinesUnitsLineGraphProps {
  subBusinessUnits: BusinessUnit[];
  metricsByFiscalCalendarWeeks: {
    fiscalCalendarWeek: FiscalCalendarWeek;
    metrics: FillRateReportMetricFragment;
    metricsBySubBus: {
      subBusinessUnitId: string;
      metrics: FillRateReportMetricFragment;
    }[];
  }[];
}

const getColorByBu = (name: string) => {
  switch (name) {
    case 'Beverages':
      return '#0597F2';
    case 'FLNA':
      return '#FDB515';
    case 'Quaker':
      return '#CF1322';
    case 'HIVE':
      return '#88B441';
    default:
      return '#FFA59C';
  }
};

type FRTType = 'acceptedFrt' | 'submittedFrt';

export const BusinesUnitsLineChart = ({
  metricsByFiscalCalendarWeeks,
  subBusinessUnits
}: BusinesUnitsLineGraphProps) => {
  const [frt, setFrt] = useState<FRTType>('acceptedFrt');

  const prepareData = () => {
    const periods = [] as string[];
    const all = {
      acceptedFrtData: [],
      submittedFrtData: []
    } as {
      acceptedFrtData: number[];
      submittedFrtData: number[];
    };
    const dataByBu = [] as {
      bu: BusinessUnit;
      acceptedFrtData: number[];
      submittedFrtData: number[];
    }[];

    const calculateDataForParentBu = (
      parentBuId: string,
      metricsBySubBus: {
        subBusinessUnitId: string;
        metrics: FillRateReportMetricFragment;
      }[],
      businessUnits: BusinessUnit[]
    ) => {
      const buToCalculate = metricsBySubBus.filter((metricsByBu) => {
        const bu = businessUnits.find((bu) => bu.id === metricsByBu.subBusinessUnitId);
        return bu && bu.parentBusinessUnit && bu.parentBusinessUnit.id === parentBuId;
      });
      let newDeliveredQuantity = 0;
      let newSubmittedQuantity = 0;
      let newAcceptedQuantity = 0;
      buToCalculate.forEach((bu) => {
        newDeliveredQuantity += bu.metrics.deliveredQuantity;
        newSubmittedQuantity += bu.metrics.submittedQuantity;
        newAcceptedQuantity += bu.metrics.acceptedQuantity;
      });
      const acceptedFillRate =
        newAcceptedQuantity === 0
          ? 0
          : Math.round((newDeliveredQuantity / newAcceptedQuantity) * 10000) / 100;
      const submittedFillRate =
        newSubmittedQuantity === 0
          ? 0
          : Math.round((newDeliveredQuantity / newSubmittedQuantity) * 10000) / 100;
      return { acceptedFillRate, submittedFillRate };
    };

    metricsByFiscalCalendarWeeks.forEach((metricsByFCW, index) => {
      periods.push(getPeriodName(metricsByFCW.fiscalCalendarWeek));

      all.acceptedFrtData.push(metricsByFCW.metrics.acceptedFillRate);
      all.submittedFrtData.push(metricsByFCW.metrics.submittedFillRate);

      metricsByFCW.metricsBySubBus.forEach((metricsByBU) => {
        const buInMetric = subBusinessUnits.find((bu) => bu.id === metricsByBU.subBusinessUnitId);
        const foundBuData = dataByBu.find(
          (data) =>
            data.bu.id ===
            (buInMetric?.parentBusinessUnit
              ? buInMetric.parentBusinessUnit.id
              : metricsByBU.subBusinessUnitId)
        );
        if (foundBuData) {
          if (foundBuData.acceptedFrtData.length === index) {
            if (buInMetric?.parentBusinessUnit) {
              const { acceptedFillRate, submittedFillRate } = calculateDataForParentBu(
                buInMetric.parentBusinessUnit.id,
                metricsByFCW.metricsBySubBus,
                subBusinessUnits
              );
              foundBuData.acceptedFrtData.push(acceptedFillRate);
              foundBuData.submittedFrtData.push(submittedFillRate);
            } else {
              foundBuData.acceptedFrtData.push(metricsByBU.metrics.acceptedFillRate);
              foundBuData.submittedFrtData.push(metricsByBU.metrics.submittedFillRate);
            }
          }
        } else {
          if (buInMetric?.parentBusinessUnit) {
            const { acceptedFillRate, submittedFillRate } = calculateDataForParentBu(
              buInMetric.parentBusinessUnit.id,
              metricsByFCW.metricsBySubBus,
              subBusinessUnits
            );
            dataByBu.push({
              bu: buInMetric.parentBusinessUnit || ({ name: 'Unknown' } as BusinessUnit),
              acceptedFrtData: [acceptedFillRate],
              submittedFrtData: [submittedFillRate]
            });
          } else {
            dataByBu.push({
              bu: buInMetric || ({ name: 'Unknown' } as BusinessUnit),
              acceptedFrtData: [metricsByBU.metrics.acceptedFillRate],
              submittedFrtData: [metricsByBU.metrics.submittedFillRate]
            });
          }
        }
      });
    });
    return {
      periods,
      dataByBu,
      all
    } as {
      periods: string[];
      dataByBu: {
        bu: BusinessUnit;
        acceptedFrtData: number[];
        submittedFrtData: number[];
      }[];
      all: {
        acceptedFrtData: number[];
        submittedFrtData: number[];
      };
    };
  };

  const getChartData = () => {
    const data = prepareData();
    return {
      labels: data.periods,
      datasets: [
        {
          label: 'All',
          data: frt === 'acceptedFrt' ? data.all.acceptedFrtData : data.all.submittedFrtData,
          borderColor: '#E8E8E8',
          backgroundColor: '#E8E8E8',
          pointStyle: 'line'
        },
        ...data.dataByBu.map((d) => ({
          label: d.bu.name,
          data: frt === 'acceptedFrt' ? d.acceptedFrtData : d.submittedFrtData,
          borderColor: getColorByBu(d.bu.name || ''),
          backgroundColor: getColorByBu(d.bu.name || ''),
          pointStyle: 'line'
        }))
      ]
    } as ChartData<'line', (number | ScatterDataPoint | null)[], unknown>;
  };

  return (
    <div className={s.bu_line_chart}>
      <div className={s.bu_data_type_container}>
        <AlloySegmented
          options={[
            {
              value: 'acceptedFrt',
              title: 'Accepted FRT',
              label: 'Accepted FRT'
            },
            {
              value: 'submittedFrt',
              title: 'Submitted FRT',
              label: 'Submitted FRT'
            }
          ]}
          value={frt}
          onChange={(key: FRTType) => setFrt(key)}
        />
      </div>
      <div style={{ height: '300px' }}>
        <Line
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top' as const
              }
            },
            scales: {
              x: {
                offset: true,
                grid: {
                  drawOnChartArea: false
                },
                ticks: {
                  maxRotation: 90,
                  minRotation: 90,
                  labelOffset: 0,
                  autoSkip: false
                }
              },
              y: {
                min: 0,
                suggestedMax: 100,
                ticks: {
                  callback: function (value, index, ticks) {
                    return value + '%';
                  }
                },
                grid: {
                  drawOnChartArea: false
                }
              }
            }
          }}
          data={getChartData()}
        />
      </div>
    </div>
  );
};
