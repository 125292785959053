import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PurchaseOrderSearchCountsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PurchaseOrderSearchFilters>;
  advancedFilter?: Types.InputMaybe<Types.PurchaseOrderSearchAdvancedFilters>;
}>;


export type PurchaseOrderSearchCountsQuery = { __typename?: 'RootQueryType', purchaseOrderSearchCounts?: { __typename?: 'PurchaseOrderSearchCounts', new: number, acknowledged: number, delivered: number, exported: number, invoiced: number, readyForDelivery: number, scrubbed: number, submitted: number, cancelled: number } | null };


export const PurchaseOrderSearchCountsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"purchaseOrderSearchCounts"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PurchaseOrderSearchFilters"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"PurchaseOrderSearchAdvancedFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrderSearchCounts"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"advancedFilter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"advancedFilter"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"new"}},{"kind":"Field","name":{"kind":"Name","value":"acknowledged"}},{"kind":"Field","name":{"kind":"Name","value":"delivered"}},{"kind":"Field","name":{"kind":"Name","value":"exported"}},{"kind":"Field","name":{"kind":"Name","value":"invoiced"}},{"kind":"Field","name":{"kind":"Name","value":"readyForDelivery"}},{"kind":"Field","name":{"kind":"Name","value":"scrubbed"}},{"kind":"Field","name":{"kind":"Name","value":"submitted"}},{"kind":"Field","name":{"kind":"Name","value":"cancelled"}}]}}]}}]} as unknown as DocumentNode<PurchaseOrderSearchCountsQuery, PurchaseOrderSearchCountsQueryVariables>;