import React, { useState } from 'react';
import s from './BulkActivateStoreModal.module.scss';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { RetailerDeliveryDestinationFromList } from 'pages/ShipToPage/ShipToPage';
import { UpdateStatusOfRddsDocument } from 'pages/ShipToPage/gql/__generated__/updateStatusOfRdds.mutation';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { App } from 'ant5';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface BulkActivateStoreModalProps {
  storesToChangeActivating: RetailerDeliveryDestinationFromList[];
  show: boolean;
  onClose: (completed: boolean) => void;
  active: boolean;
}

export const BulkActivateStoreModal = ({
  storesToChangeActivating,
  show,
  onClose,
  active
}: BulkActivateStoreModalProps) => {
  const { message } = App.useApp();
  const [updateStatusOfRdds] = useMutation(UpdateStatusOfRddsDocument, {
    refetchQueries: ['retailerDeliveryDestinations']
  });
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const updatedStores = storesToChangeActivating.map((rdd) => {
      return {
        ...rdd,
        active: false
      };
    });

    try {
      await updateStatusOfRdds({
        variables: {
          input: {
            ids: updatedStores.map((store) => store.id).filter((id): id is string => !!id),
            active: active
          }
        }
      });

      message.success(
        `${storesToChangeActivating.length} Ship-Tos ${active ? 'activated' : 'deactivated'}`
      );
      onClose(true);
    } catch (error) {
      Sentry.addBreadcrumb({
        message: `Deactivate rdds: ${JSON.stringify(storesToChangeActivating)}`
      });
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<RetailerDeliveryDestinationFromList> = [
    {
      title: 'STORE',
      key: 'externalId',
      render: (_, rdd) => rdd.externalId
    },
    {
      title: 'REGION',
      render: (_, rdd) => {
        let address = `${rdd.addressCity ?? ''}`;
        if (address.length && rdd.addressState) address += `, ${rdd.addressState}`;
        else address += `${rdd.addressState ?? ''}`;
        return address;
      }
    },
    {
      title: 'TRADING PARTNERS',
      render: (_, rdd) =>
        rdd.tradingPartners
          .map((tradingPartner) => tradingPartner.name)
          .sort((a, b) => safeLocaleCompare(a, b))
          .join(', ')
    }
  ];

  return (
    <AlloyModal
      open={show}
      className={s.bulk_remove_store_modal}
      okText={active ? 'Activate' : 'Deactivate'}
      okButtonProps={{
        danger: !active,
        loading
      }}
      onOk={submit}
      onCancel={() => onClose(false)}
      title="Confirm updates"
      classNames={{
        footer: s.bulk_change_status_footer,
        header: s.bulk_change_status_header,
        body: s.bulk_change_status_body,
        content: s.bulk_change_status_content
      }}
    >
      <section>
        <div className={s.title}>
          {active ? 'Activate' : 'Deactivate'} Ship-Tos ({storesToChangeActivating.length})
        </div>
        <AlloyTable
          dataSource={storesToChangeActivating}
          columns={columns}
          pagination={false}
          rowKey={(rdd) => rdd.externalId || ''}
          scroll={{
            x: 'auto'
          }}
        />
      </section>
    </AlloyModal>
  );
};
