import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';
import React from 'react';
import s from './PrioritizedList.module.scss';
import removeIcon from 'assets/icons/editing/cross_filled.svg';
import holderIcon from 'assets/icons/editing/holder.svg';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

const ITEM_TYPE = 'card';

type CardProps<T> = {
  item: T;
  itemIndex: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDeleteItem: () => void;
  bodyRender: (item: T, index: number, disabled: boolean) => void;
  disabled: boolean;
  preventRemoval: boolean;
  'data-testid': string;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ListCard = <T extends object = {}>(props: CardProps<T>) => {
  const {
    item,
    itemIndex,
    moveCard,
    onDeleteItem,
    bodyRender,
    disabled,
    preventRemoval,
    'data-testid': dataTestId
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ITEM_TYPE,
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover: (item: DragItem, monitor) => {
      if (!ref.current || disabled) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = itemIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: () => ({ ...item, index: itemIndex }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  if (!disabled) drag(drop(ref));
  return (
    <div
      ref={ref}
      className={s.card}
      style={{
        opacity
      }}
      data-handler-id={handlerId}
      data-testid={dataTestId}
    >
      <span className={s.priority}>{isDragging ? ' ' : itemIndex + 1}</span>
      <img className={s.holder} alt="" src={holderIcon} />
      <div className={s.container}>{bodyRender(item, itemIndex, disabled)}</div>
      <AlloyButton
        onClick={onDeleteItem}
        shape="circle"
        type="text"
        style={{ width: '24px', height: '24px', padding: 0, minWidth: '24px' }}
        data-testid="ordering-dc-list-remove-button"
        disabled={disabled || preventRemoval}
        icon={<img alt="" src={removeIcon} />}
      />
    </div>
  );
};
