import React from 'react';
import s from './FieldInfo.module.scss';
import { camelCaseToSpaces } from 'common/helpers/stringsConverter';
import { get } from 'lodash-es';

interface FieldInfoProps {
  error?: string | any[];
  info?: string;
  disabled?: boolean;
}

export const FieldInfo = ({ error, info, disabled, ...rest }: FieldInfoProps) => {
  const errorString = typeof error === 'object' ? getArrayOfErrorsToString(error) : error;
  return (
    <p className={s.info} aria-disabled={disabled} data-haserror={!!error} {...rest}>
      {errorString || info || ''}
    </p>
  );
};

const getArrayOfErrorsToString = (errorArray: any[]): string => {
  const uniqueError: string[] = [];
  errorArray.forEach((errorItem) => {
    if (!errorItem) return;
    Object.getOwnPropertyNames(errorItem).forEach((fieldError) => {
      const error = `${camelCaseToSpaces(fieldError)}: ${get(errorItem, fieldError)}`;
      if (!uniqueError.includes(error)) {
        uniqueError.push(error);
      }
    });
  });

  return uniqueError.join('. ');
};
