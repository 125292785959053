import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OtifPurchaseOrdersReportsByPeriodQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type OtifPurchaseOrdersReportsByPeriodQuery = { __typename?: 'RootQueryType', otifPurchaseOrdersReportsByPeriod: Array<{ __typename?: 'OtifReportByPeriod', acceptedFillRate?: any | null, submittedFillRate?: any | null, inFull?: any | null, onTime?: any | null, orderCount?: number | null, otif?: any | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', period: number, week: number, year: number } }> };


export const OtifPurchaseOrdersReportsByPeriodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"otifPurchaseOrdersReportsByPeriod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"otifPurchaseOrdersReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inFull"}},{"kind":"Field","name":{"kind":"Name","value":"onTime"}},{"kind":"Field","name":{"kind":"Name","value":"orderCount"}},{"kind":"Field","name":{"kind":"Name","value":"otif"}}]}}]}}]} as unknown as DocumentNode<OtifPurchaseOrdersReportsByPeriodQuery, OtifPurchaseOrdersReportsByPeriodQueryVariables>;