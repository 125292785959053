import React from 'react';
import { Dropdown as AntDropdown, DropDownProps } from 'ant5';

export function AlloyDropdown({ ...otherProps }: DropDownProps) {
  return <AntDropdown {...otherProps} />;
}

AlloyDropdown.Button = AntDropdown.Button;

// Re-exporting types
export type { DropDownProps };
