import React, { useEffect, useState } from 'react';
import { ExportErrorsTable } from '../ExportErrorsTable/ExportErrorsTable';
import { CSVLink } from 'react-csv';
import { ReportableErrorFromQuery } from 'pages/ErrorVisibility/types';
import moment from 'moment';
import { NOT_AVAILABLE } from 'common/constants';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface ExportItemsModalProps {
  visibility: boolean;
  toggleExportErrorsModal: () => void;
  selectedRows: ReportableErrorFromQuery[];
}

export const ExportErrorsModal = ({
  visibility,
  toggleExportErrorsModal,
  selectedRows
}: ExportItemsModalProps) => {
  const [csvData, setCsvData] = useState<ReportableErrorFromQuery[]>([]);

  useEffect(() => {
    if (selectedRows.length === 0) return;
    const newRows = [...selectedRows].map((row) => {
      const newRow: { [index: string]: any } = { ...row };
      if (row.insertedAt !== null) {
        newRow.insertedAt = moment(row.insertedAt).format('MMM D YYYY, hh:mm A');
        newRow.updatedAt = moment(row.updatedAt).format('MMM D YYYY, hh:mm A');
      }

      Object.keys(row).forEach((key) => {
        if (newRow[key] === null || undefined) {
          if (key === 'purchaseOrder') {
            newRow.purchaseOrder = {
              customerPo: NOT_AVAILABLE,
              status: NOT_AVAILABLE,
              tradingPartner: { externalId: NOT_AVAILABLE }
            };
          }
        }
      });

      //Business wants these columns to be empty if the count is just 1
      if (newRow.count <= 1) {
        newRow.count = null;
        newRow.updatedAt = null;
      }

      return newRow;
    });
    setCsvData(newRows as ReportableErrorFromQuery[]);
  }, [selectedRows]);

  const errorHeaders = [
    { label: 'Date & Time', key: 'insertedAt' },
    { label: 'Event', key: 'message' },
    { label: 'Error count', key: 'count' },
    { label: 'Last attempt', key: 'updatedAt' },
    { label: 'Error status', key: 'status' },
    { label: 'Purchase Order', key: 'purchaseOrder.customerPo' },
    { label: 'Trading Partner', key: 'purchaseOrder.tradingPartner.externalId' },
    { label: 'Current PO Status', key: 'purchaseOrder.status' }
  ];

  const controls = [
    <AlloyButton
      key="cancel"
      type="secondary"
      data-testid="cancel-button"
      onClick={toggleExportErrorsModal}
      style={{ marginRight: 8 }}
    >
      Cancel
    </AlloyButton>,
    <AlloyButton
      key="export"
      data-testid="download-csv-button"
      onClick={toggleExportErrorsModal}
      type="primary"
      disabled={!(csvData.length > 0)}
    >
      <CSVLink
        onClick={toggleExportErrorsModal}
        filename={'errors-list.csv'}
        headers={errorHeaders}
        data={csvData}
      >
        Download csv
      </CSVLink>
    </AlloyButton>
  ];

  return (
    <AlloyModal
      className="export_items_modal"
      data-testid="export-errors-modal"
      title={
        <>
          <div data-testid="modal-title">Export Errors</div>
          <div data-testid="export-error-controls" className="controls">
            {controls}
          </div>
        </>
      }
      open={visibility}
      onCancel={toggleExportErrorsModal}
      width={1300}
      footer={controls}
    >
      <ExportErrorsTable selectedRows={selectedRows} />
    </AlloyModal>
  );
};
