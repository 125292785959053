import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChargebacksReportDetailsByPeriodForGraphQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
  dimensions: Array<Types.ChargebackDimension> | Types.ChargebackDimension;
}>;


export type ChargebacksReportDetailsByPeriodForGraphQuery = { __typename?: 'RootQueryType', chargebackDetailsReportsByPeriod: Array<{ __typename?: 'ChargebackDetailsReportByPeriod', financialCharge: any, businessUnit?: string | null, issueType?: string | null, totalValue?: boolean | null, status?: string | null, distributionCenter?: string | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', year: number, week: number } }> };


export const ChargebacksReportDetailsByPeriodForGraphDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"chargebacksReportDetailsByPeriodForGraph"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dimensions"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChargebackDimension"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargebackDetailsReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"dimensions"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dimensions"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"financialCharge"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"year"}},{"kind":"Field","name":{"kind":"Name","value":"week"}}]}},{"kind":"Field","name":{"kind":"Name","value":"businessUnit"}},{"kind":"Field","name":{"kind":"Name","value":"issueType"}},{"kind":"Field","name":{"kind":"Name","value":"totalValue"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenter"}}]}}]}}]} as unknown as DocumentNode<ChargebacksReportDetailsByPeriodForGraphQuery, ChargebacksReportDetailsByPeriodForGraphQueryVariables>;