import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import s from './TradingPartnersPage.module.scss';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Paginator } from 'components/Paginator/Paginator';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { SearchField } from 'components/Common/SearchField/SearchField';
import {
  TradingPartnersDocument,
  TradingPartnersQuery
} from './gql/__generated__/tradingPartners.query';
import { SortOrderDirection, TradingPartnerSortColumn } from 'graphql/__generated__/types';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import {
  TradingPartner,
  TradingPartnerViewDrawer
} from './components/TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { EditTradingPartnerModal } from './components/EditTradingPartnerModal/EditTradingPartnerModal';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';

export type TradingPartnerForList = InferNodeType<TradingPartnersQuery, 'tradingPartners'>;

export const TradingPartnersPage = () => {
  const [tradingPartnerId, setTradingPartnerId] = useQueryParam('tp', StringParam);
  const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);
  const [, setFirstOpenTab] = useQueryParam('editTab', StringParam);

  const [editingTradingPartner, setEditingTradingPartner] = useState<TradingPartner>();
  const [viewHistoryTradingPartner, setViewHistoryTradingPartner] = useState<
    TradingPartnerForList | undefined
  >();

  const { data, loading, error } = useQuery(TradingPartnersDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as TradingPartnerSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchLike: search
    },

    skip: !pageSize || !sortColumn || !sortOrder
  });

  const tradingPartnersList = useMemo(() => getNodesFromEdges(data?.tradingPartners), [data]);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(DEFAULT_PAGE_SIZE);
      setSortColumn('UPDATED_AT');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  useEffect(() => {
    if (tradingPartnerId) {
      setViewHistoryTradingPartner(undefined);
      if (editingTradingPartner && !editingTradingPartner.id) {
        setEditingTradingPartner(undefined);
      }
    }
  }, [editingTradingPartner, tradingPartnerId]);

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
        setAfter(undefined);
        setBefore(undefined);
      }
    },
    [search, setAfter, setBefore, setSearch]
  );

  if (error) return <p>Error</p>;

  const columns: ColumnsType<TradingPartnerForList> = [
    {
      title: 'ID',
      render: (_, record) => (
        <HighlightedText text={record.externalId || ''} highlight={search ?? ''} />
      )
    },
    {
      title: 'Name',
      render: (_, record) => <HighlightedText text={record.name || ''} highlight={search ?? ''} />,
      key: 'NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'NAME'),
      sorter: true
    },
    {
      title: 'Delivery Type',
      render: (_, record) => (
        <HighlightedText text={record.deliveryType || ''} highlight={search ?? ''} />
      ),
      width: '135px'
    },
    {
      title: 'Retailer Channel',
      key: 'VENDOR_MARKET_NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'VENDOR_MARKET_NAME'),
      sorter: true,
      render: (_, record) => (
        <HighlightedText text={record.vendorMarket?.name || ''} highlight={search ?? ''} />
      )
    },
    {
      title: 'Business Unit',
      width: '140px',
      render: (_, record) => (
        <HighlightedText text={record.businessUnit?.code || ''} highlight={search ?? ''} />
      )
    },
    {
      title: 'Last updated',
      width: 160,
      render: (record) => (
        <UpdatedAt
          onClick={(e) => {
            setViewHistoryTradingPartner(record);
            e.stopPropagation();
          }}
          updatedAt={record.updatedAt}
        />
      ),
      key: 'UPDATED_AT',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'UPDATED_AT'),
      sorter: true
    }
  ];

  const nextPage = async () => {
    setAfter(data?.tradingPartners?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.tradingPartners?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  return (
    <div className={s.page}>
      <div className={s.header}>
        <PageHeader className={s.trading_partners_title} data-testid="trading_partners_page_title">
          Trading Partners
        </PageHeader>
        <div>
          <AlloyButton
            onClick={() => {
              setEditingTradingPartner({} as TradingPartner);
            }}
            data-testid="add-new-trading-partner"
          >
            Add new Trading Partner
          </AlloyButton>
        </div>
      </div>
      <SearchField handleSearch={handleSearch} loading={loading} value={search ?? ''} />
      <div className={s.pagination_container}>
        <QuantityDisplay
          data-testid="tp-quantity-display"
          count={data?.tradingPartners?.totalCount}
          titleSingular="Trading Partner"
          titleMultiple="Trading Partners"
        />
        <Paginator
          hasNextPage={!!data?.tradingPartners?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.tradingPartners?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={false}
        />
      </div>
      <AlloyTable
        data-testid="tp-page-table"
        loading={loading}
        sticky
        tableLayout="auto"
        columns={columns}
        dataSource={tradingPartnersList}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => {
          return {
            'data-testid': `row-${record.id}`,
            onClick: () => {
              setTradingPartnerId(record.id);
            }
          };
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column =
            String((sorter as SorterResult<TradingPartnerForList>).columnKey) ||
            'VENDOR_MARKET_NAME';
          const order =
            (sorter as SorterResult<TradingPartnerForList>).order === 'ascend' ||
            !(sorter as SorterResult<TradingPartnerForList>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
      />
      <div className={s.pagination_container_end}>
        <Paginator
          hasNextPage={!!data?.tradingPartners?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.tradingPartners?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={true}
        />
      </div>
      <TradingPartnerViewDrawer
        tradingPartnerId={editingTradingPartner || !tradingPartnerId ? undefined : tradingPartnerId}
        onClose={() => {
          setTradingPartnerId(undefined);
          setFirstOpenTab(undefined);
        }}
        startEditing={(tradingPartner) => {
          setEditingTradingPartner({ ...tradingPartner });
        }}
      />
      <EditTradingPartnerModal
        tradingPartner={editingTradingPartner}
        onClose={() => {
          setEditingTradingPartner(undefined);
          setTradingPartnerId(undefined);
          setFirstOpenTab(undefined);
        }}
      />
      <ChangeHistory
        entity={
          viewHistoryTradingPartner
            ? {
                id: viewHistoryTradingPartner.id || '',
                name: viewHistoryTradingPartner.name || '',
                type: 'Trading Partner'
              }
            : undefined
        }
        onClose={() => setViewHistoryTradingPartner(undefined)}
      />
    </div>
  );
};
