type FeatureFlagOverrides = { [key: string]: boolean };
const STORAGE_KEY = 'feature-flag-overrides';

/**
 * Sets a specific feature flag override.
 *
 * @param key - The name of the feature flag.
 * @param enabled - The boolean value to enable or disable the feature flag override.
 * @param providedLocalStorage - Optionally provide a custom storage (e.g., for testing purposes). Defaults to the global localStorage.
 */
export function setFeatureFlagOverride(
  key: string,
  enabled: boolean,
  providedLocalStorage = localStorage
): void {
  const currentOverrides = getFeatureFlagOverrides(providedLocalStorage);
  const updatedOverrides = { ...currentOverrides, [key]: enabled };
  providedLocalStorage.setItem(STORAGE_KEY, JSON.stringify(updatedOverrides));
}

/**
 * Retrieves all feature flag overrides from storage.
 *
 * @param providedLocalStorage - Optionally provide a custom storage (e.g., for testing purposes). Defaults to the global localStorage.
 * @returns An object containing all feature flag overrides.
 */
export function getFeatureFlagOverrides(providedLocalStorage = localStorage): FeatureFlagOverrides {
  try {
    const overrides = providedLocalStorage.getItem(STORAGE_KEY);
    return overrides ? JSON.parse(overrides) : {};
  } catch (error) {
    // Maybe clear them here?
    console.error('Failed to parse feature flag overrides from localStorage:', error);
    return {};
  }
}

/**
 * Checks if a specific feature flag override is set and returns its value.
 * If the flag is absent, undefined is returned, indicating no override is present.
 *
 * @param flag - The name of the feature flag.
 * @param providedLocalStorage - Optionally provide a custom storage (e.g., for testing purposes). Defaults to the global localStorage.
 * @returns true/false if override is present, undefined if the flag is not present.
 */
export function getFeatureFlagOverridedInLocalStorageValue(
  flag: string,
  providedLocalStorage = localStorage
): boolean | undefined {
  const overrides = getFeatureFlagOverrides(providedLocalStorage);
  return overrides.hasOwnProperty(flag) ? overrides[flag] : undefined;
}

/**
 * Retrieves the override value for a specific feature flag from the provided query parameters.
 * If the flag is not present in the overrides, `undefined` is returned, indicating no override is set.
 *
 * @param flag - The name of the feature flag to check.
 * @param queryParamsOverrides - An object containing potential overrides for feature flags,
 * where the key is the flag name and the value is either `true`, `false`, `null`, or `undefined`.
 * @returns `true` or `false` if an override is present, or `undefined` if the flag is not present in the overrides.
 */
export function getFeatureFlagOverridedInQueryParamsValue(
  flag: string,
  queryParamsOverrides: { [a: string]: boolean | null | undefined }
): boolean | undefined {
  return queryParamsOverrides.hasOwnProperty(flag)
    ? (queryParamsOverrides[flag] ?? undefined)
    : undefined;
}

/**
 * Clears all feature flag overrides from storage.
 *
 * @param providedLocalStorage - Optionally provide a custom storage (e.g., for testing purposes). Defaults to the global localStorage.
 */
export function clearFeatureFlagOverrides(providedLocalStorage = localStorage) {
  providedLocalStorage.removeItem(STORAGE_KEY);
}
