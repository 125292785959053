import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChargebacksReportDetailsByPeriodForFiltersQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type ChargebacksReportDetailsByPeriodForFiltersQuery = { __typename?: 'RootQueryType', chargebackDetailsReportsByPeriod: Array<{ __typename?: 'ChargebackDetailsReportByPeriod', businessUnit?: string | null, tradingPartner?: string | null, issueType?: string | null, subTypeOfTheNonCompliance?: string | null, status?: string | null, totalValue?: boolean | null }> };


export const ChargebacksReportDetailsByPeriodForFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"chargebacksReportDetailsByPeriodForFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"chargebackDetailsReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}},{"kind":"Argument","name":{"kind":"Name","value":"dimensions"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"BUSINESS_UNIT"},{"kind":"EnumValue","value":"ISSUE_TYPE"},{"kind":"EnumValue","value":"STATUS"},{"kind":"EnumValue","value":"SUB_TYPE_OF_THE_NON_COMPLIANCE"},{"kind":"EnumValue","value":"TRADING_PARTNER"}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUnit"}},{"kind":"Field","name":{"kind":"Name","value":"tradingPartner"}},{"kind":"Field","name":{"kind":"Name","value":"issueType"}},{"kind":"Field","name":{"kind":"Name","value":"subTypeOfTheNonCompliance"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"totalValue"}}]}}]}}]} as unknown as DocumentNode<ChargebacksReportDetailsByPeriodForFiltersQuery, ChargebacksReportDetailsByPeriodForFiltersQueryVariables>;