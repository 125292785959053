import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

// This import barely increases our css bundle, but it allows us not to use vite-plugin-imp which breaks sourcemaps
// Also, we will remove it when we switch to antd v5
import 'antd/dist/antd.css';

import './App.scss';
import 'common/css/colors.css';
import 'common/css/fonts.css';
import 'common/css/grid.css';

import '@fontsource/roboto';
import '@fontsource/roboto-condensed/';
import '@fontsource/roboto-mono';

import { Layout } from 'antd';
import OrderDetailsPage from 'pages/OrderDetailsPage/OrderDetailsPage';
import PrivateRouteWithHeader from './PrivateRouteWithHeader/PrivateRouteWithHeader';
import LoginPage from 'pages/LoginPage/LoginPage';
import { PermissionsPage } from 'pages/PermissionsPage/PermissionsPage';
import ModalContextProvider from 'context/ModalContext';
import { TradingPartnersPage } from 'pages/TradingPartnersPage/TradingPartnersPage';
import { ShipToPage } from 'pages/ShipToPage/ShipToPage';
import { RetailerChannelsPage } from 'pages/RetailerChannelsPage/RetailerChannelsPage';
import { RetailersPage } from 'pages/RetailersPage/RetailersPage';
import { DistributionCentersPage } from 'pages/DistributionCentersPage/DistributionCentersPage';
import { UserContextProvider } from 'context/userContext';
import BuildDetails from 'components/Common/BuildDetails/BuildDetails';
import { DemandVisibility } from 'pages/DemandVisibility/DemandVisibility';
import { FillRateReport } from 'pages/FillRateReport/FillRateReport';
import { Visibility as OrderVisibility } from 'pages/Visibility/Visibility';
import { Inventory as InventoryVisibility } from 'pages/Inventory/Inventory';
import { ErrorVisibilityPage } from 'pages/ErrorVisibility';
import { ActiveAssortmentPage } from 'pages/AssortmentPage/AssortmentPage';
import { AppHistoryContextProvider } from 'context/AppHistoryContext';
import { InventoryOptimizationPage } from 'pages/InventoryOptimizationPage/InventoryOptimizationPage';
import ScenarioDetailsContainer from 'pages/InventoryOptimizationPage/Components/ScenarioDetailsContainer/ScenarioDetailsContainer';
import ScenarioCreatePage from 'pages/InventoryOptimizationPage/Components/ScenarioCreate/ScenarioCreatePage';
import StoDeliveryPage from 'pages/StoDeliveryPage/StoDeliveryPage';
import { RepackPlanningPage } from 'pages/RepackPlanning/RepackPlanning';
import { OnTimeInFull } from 'pages/OnTimeInFull/OnTimeInFull';
import { EventTrackerPage } from 'pages/EventTracker/EventTracker';
import { Event } from 'pages/EventTracker/components/Event/Event';
import { OrdersPageWrapper } from 'pages/OrdersPage/OrdersPageWrapper';
import { WarehouseOrderProcessingPage } from 'pages/WarehouseOrderProcessingPage/WarehouseOrderProcessingPage';
import { ExecutiveIntelligence } from 'pages/ExecutiveIntelligence/ExecutiveIntelligence';
import { ForecastPlanning } from 'pages/ForecastPlanning/ForecastPlanning';
import { Chargebacks } from 'pages/Chargebacks/Chargebacks';

const App = () => {
  return (
    <ModalContextProvider>
      <UserContextProvider>
        <div className={`App`} data-test-id="app">
          <Layout>
            <Router basename={'/'}>
              <AppHistoryContextProvider>
                <QueryParamProvider
                  adapter={ReactRouter5Adapter}
                  options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify
                  }}
                >
                  <Switch>
                    <Route exact path={'/login'} component={LoginPage} />
                    <PrivateRouteWithHeader
                      exact
                      path={'/errors'}
                      component={ErrorVisibilityPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/orders/:id'}
                      component={OrderDetailsPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/permissions'}
                      component={PermissionsPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/assortment'}
                      component={ActiveAssortmentPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/sto-delivery'}
                      component={StoDeliveryPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/warehouse-processing'}
                      component={WarehouseOrderProcessingPage}
                    />
                    <PrivateRouteWithHeader exact path={'/retailers'} component={RetailersPage} />
                    <PrivateRouteWithHeader
                      exact
                      path={'/retailer-channels'}
                      component={RetailerChannelsPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/trading-partners'}
                      component={TradingPartnersPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/distribution-centers'}
                      component={DistributionCentersPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/inventory-optimization'}
                      component={InventoryOptimizationPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/inventory-optimization/create-scenario'}
                      component={ScenarioCreatePage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/inventory-optimization/scenario/:key'}
                      component={ScenarioDetailsContainer}
                    />
                    <PrivateRouteWithHeader exact path={'/ship-to'} component={ShipToPage} />
                    <PrivateRouteWithHeader exact path={'/fill-rate'} component={FillRateReport} />
                    {/* TODO: Remove later, added for backward compatibility */}
                    <Route exact path={'/visibility'}>
                      <Redirect to={{ pathname: '/visibility/order' }} />
                    </Route>
                    <PrivateRouteWithHeader
                      exact
                      path={'/visibility/order'}
                      component={OrderVisibility}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/visibility/inventory'}
                      component={InventoryVisibility}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/repack-planning'}
                      component={RepackPlanningPage}
                    />
                    <PrivateRouteWithHeader path={'/event/:id'} component={Event} />
                    <PrivateRouteWithHeader
                      exact
                      path={'/event-tracker'}
                      component={EventTrackerPage}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/Demand-Visibility'}
                      component={DemandVisibility}
                    />
                    <PrivateRouteWithHeader exact path={'/otif'} component={OnTimeInFull} />
                    <PrivateRouteWithHeader
                      exact
                      path={'/executive-intelligence'}
                      component={ExecutiveIntelligence}
                    />
                    <PrivateRouteWithHeader
                      exact
                      path={'/forecast-planning'}
                      component={ForecastPlanning}
                    />
                    <PrivateRouteWithHeader exact path={'/chargebacks'} component={Chargebacks} />
                    <PrivateRouteWithHeader path={'/'} component={OrdersPageWrapper} />
                  </Switch>
                </QueryParamProvider>
              </AppHistoryContextProvider>
            </Router>
          </Layout>
          <BuildDetails />
        </div>
      </UserContextProvider>
    </ModalContextProvider>
  );
};

export default App;
