import { CalendarOutlined, DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import s from './PeriodCalendar.module.scss';
import clsx from 'clsx';
import {
  convertWeekToPeriodWeekString,
  getWeeksInFinancialYear
} from 'common/helpers/fiscalCalendar';
import { range } from 'lodash-es';
import { CalendarValue, CalendarValueTemporary, Mode } from './types';
import { getCurrentPeriod, stringifyCalendarValue } from './helpers';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';

interface PeriodCalendarProps {
  value: CalendarValue;
  onChange: (value: CalendarValue) => void;
  loading?: boolean;
  pastOnly?: boolean;
  years?: number[];
  periodOnly?: boolean;
}

const isWeekBetween = (week: number, start: number, end: number, strict: boolean) => {
  return strict ? start < week && week < end : start <= week && week <= end;
};

const isEndWeekAfterStartWeek = (start: number | undefined, end: number | undefined) =>
  start && end && start <= end;

export const PeriodCalendar = ({
  value,
  onChange,
  loading = false,
  pastOnly = false,
  years,
  periodOnly = false
}: PeriodCalendarProps) => {
  const [visible, setVisible] = useState(false);

  const [currentValue, setCurrentValue] = useState<CalendarValueTemporary>(value);
  const isRangeSelector = currentValue.mode === Mode.PERIOD_WEEK && currentValue.isRange;
  const isSpecificSelector = currentValue.mode === Mode.PERIOD_WEEK && !currentValue.isRange;
  const isPeriodSelector = currentValue.mode === Mode.PERIOD;
  const isYearsSelectorDisplayed = years && years?.length > 0;

  // TODO: rewrite as reducer
  const enableIsSpecific = () => {
    const { week } = getCurrentPeriod(pastOnly);
    setCurrentValue({
      years: currentValue.years,
      weeks: week ? [week] : [],
      mode: Mode.PERIOD_WEEK,
      isRange: false
    });
  };

  const toggleSpecificValue = (week: number) => {
    if (isSpecificSelector) {
      if (currentValue.weeks.includes(week)) {
        setCurrentValue({
          ...currentValue,
          weeks: currentValue.weeks.filter((x) => x !== week)
        });
      } else {
        setCurrentValue({
          ...currentValue,
          weeks: [...currentValue.weeks, week].sort((a, b) => a - b)
        });
      }
    }
  };

  const enableIsRange = () => {
    const { week } = getCurrentPeriod(pastOnly);
    setCurrentValue({
      years: currentValue.years,
      start: week,
      end: week,
      mode: Mode.PERIOD_WEEK,
      isRange: true
    });
  };

  const enablePeriodSelector = () => {
    const { period } = getCurrentPeriod(pastOnly);
    setCurrentValue({
      years: currentValue.years,
      periods: period ? [period] : [],
      mode: Mode.PERIOD
    });
  };

  const [hovered, setHovered] = useState<number | undefined>();

  /* TODO: remove this, make a proper uncontrolled prop */
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const onWeekItemClick = (week: number) => {
    if (isSpecificSelector) {
      toggleSpecificValue(week);
    } else if (isRangeSelector) {
      if (currentValue.end) {
        setCurrentValue({ ...currentValue, start: week, end: undefined });
        setHovered(undefined);
      } else {
        if (isEndWeekAfterStartWeek(currentValue.start, week)) {
          setCurrentValue({
            ...currentValue,
            end: week
          });
        } else {
          // Not sure if we want this clause
          setCurrentValue({ ...currentValue, start: week, end: undefined });
        }
      }
    }
  };

  const { period: currentPeriod, year: currentYear } = getCurrentPeriod(pastOnly);

  const lastAllowedPeriod = pastOnly ? currentPeriod : 13;

  const weeksInCurrentYear = getWeeksInFinancialYear(currentYear);
  const is53Weeks = years
    ? Math.max(...years.map((x) => getWeeksInFinancialYear(x))) === 53
    : weeksInCurrentYear === 53;

  const allPeriodsSelected = isPeriodSelector && currentValue.periods.length === lastAllowedPeriod;

  const onPeriodItemClick = (period: number) => {
    if (isPeriodSelector) {
      if (currentValue.periods.includes(period)) {
        setCurrentValue({
          ...currentValue,
          periods: currentValue.periods.filter((x) => x !== period)
        });
      } else {
        setCurrentValue({
          ...currentValue,
          periods: [...currentValue.periods, period].sort((a, b) => a - b)
        });
      }
    }
  };

  const onAllPeriodsItemClick = () => {
    if (isPeriodSelector) {
      setCurrentValue({
        ...currentValue,
        periods: range(1, lastAllowedPeriod + 1)
      });
    }
  };

  const isSelected = (week: number) => {
    if (isSpecificSelector) {
      return currentValue.weeks.includes(week);
    }
    if (isRangeSelector) {
      return currentValue.start === week || currentValue.end === week;
    }
    return false;
  };

  const isIntermediate = (week: number) =>
    isRangeSelector &&
    ((!currentValue.end &&
      currentValue.start &&
      hovered &&
      isWeekBetween(week, currentValue.start, hovered, true)) ||
      (currentValue.end &&
        currentValue.start &&
        isWeekBetween(week, currentValue.start!, currentValue.end!, true)));

  const isEnd = (week: number) =>
    isRangeSelector &&
    currentValue.start !== week &&
    (currentValue.end === week ||
      (!currentValue.end &&
        hovered &&
        hovered === week &&
        isEndWeekAfterStartWeek(currentValue.start, week)));

  const isStart = (week: number) =>
    isRangeSelector &&
    currentValue.start === week &&
    currentValue.end !== week &&
    !(
      !currentValue.end &&
      (!hovered || week === hovered || !isEndWeekAfterStartWeek(currentValue.start, hovered))
    );

  const WeekItem = ({ week, isPastOnly = false }: { week: number; isPastOnly: boolean }) => {
    const { week: currentWeek } = getCurrentPeriod(isPastOnly);

    const disabled = isPastOnly && week > currentWeek;

    const hasEnd = isRangeSelector && currentValue.end;

    return (
      <button
        className={clsx(s.reset_button, s.week_container)}
        onClick={() => onWeekItemClick(week)}
        onMouseEnter={() => {
          if (isRangeSelector && !hasEnd) {
            setHovered(week);
          }
        }}
        onMouseLeave={() => {
          if (isRangeSelector && !hasEnd) {
            setHovered(undefined);
          }
        }}
        disabled={disabled}
      >
        <span className={clsx(s.week_tile, isSelected(week) && s.selected)}>
          {convertWeekToPeriodWeekString(week)}
        </span>
        <span
          className={clsx(
            s.week_selection,
            isIntermediate(week) && s.intermediate,
            isEnd(week) && s.end,
            isStart(week) && s.start
          )}
        />
      </button>
    );
  };

  const isPeriodSelected = (period: number) => {
    if (isPeriodSelector) {
      return currentValue.periods.includes(period);
    }
    return false;
  };

  const onYearClick = (year: number) => {
    if (currentValue.years.includes(year)) {
      setCurrentValue({
        ...currentValue,
        years: currentValue.years.filter((x) => x !== year)
      });
    } else {
      setCurrentValue({
        ...currentValue,
        years: [...currentValue.years, year].sort((a, b) => a - b)
      });
    }
  };

  const isYearSelected = (year: number) => {
    return (currentValue?.years || []).includes(year);
  };

  const PeriodItem = ({ period, isPastOnly = false }: { period: number; isPastOnly: boolean }) => {
    const { period: currentPeriod } = getCurrentPeriod(isPastOnly);

    const disabled = isPastOnly && period > currentPeriod;

    return (
      <button
        className={clsx(s.reset_button, s.period_button, isPeriodSelected(period) && s.selected)}
        onClick={() => onPeriodItemClick(period)}
        disabled={disabled}
      >
        Period {period}
      </button>
    );
  };

  const onVisibleChange = (isVisible: boolean) => {
    if (!isVisible) {
      setCurrentValue(value);
    }
    setVisible(isVisible);
  };

  return (
    <AlloySpin spinning={loading}>
      <AlloyDropdown
        open={visible}
        onOpenChange={onVisibleChange}
        trigger={['click']}
        destroyPopupOnHide
        dropdownRender={() => (
          <div className={clsx(s.dropdown_container, isYearsSelectorDisplayed && s.years)}>
            {!periodOnly && (
              <div className={s.select_mode_container}>
                <AlloySegmented
                  value={currentValue.mode}
                  options={[
                    {
                      value: Mode.PERIOD,
                      title: 'Period',
                      label: 'Period'
                    },
                    {
                      value: Mode.PERIOD_WEEK,
                      title: 'Period Week',
                      label: 'Period Week'
                    }
                  ]}
                  onChange={(key) => {
                    if (key === Mode.PERIOD_WEEK) {
                      enableIsRange();
                    } else if (key === Mode.PERIOD) {
                      enablePeriodSelector();
                    }
                  }}
                />
                <div>
                  {currentValue.mode === Mode.PERIOD_WEEK && (
                    <AlloyCheckbox
                      data-testid="is-range-selector"
                      checked={currentValue.isRange}
                      onChange={() => {
                        if (currentValue.isRange) {
                          enableIsSpecific();
                        } else {
                          enableIsRange();
                        }
                      }}
                    >
                      Select by week range
                    </AlloyCheckbox>
                  )}
                </div>
              </div>
            )}
            <div className={s.calendar_body_container}>
              <div className={s.calendar_body}>
                {currentValue.mode === Mode.PERIOD ? (
                  <div className={s.period_buttons_container}>
                    <div className={s.period_part}>
                      <button
                        className={clsx(
                          s.reset_button,
                          s.period_button,
                          allPeriodsSelected && s.selected
                        )}
                        onClick={() => onAllPeriodsItemClick()}
                      >
                        {allPeriodsSelected
                          ? `All periods selected (${lastAllowedPeriod})`
                          : `Select all periods (${lastAllowedPeriod})`}
                      </button>
                      {range(1, 7).map((period) => (
                        <PeriodItem key={period} period={period} isPastOnly={pastOnly} />
                      ))}
                    </div>
                    <div className={s.period_part}>
                      {range(7, 14).map((period) => (
                        <PeriodItem key={period} period={period} isPastOnly={pastOnly} />
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={s.calendar_body_part}>
                      {range(1, 29).map((week) => (
                        <WeekItem key={week} week={week} isPastOnly={pastOnly} />
                      ))}
                    </div>
                    <div className={s.calendar_body_part}>
                      {range(29, is53Weeks ? 54 : 53).map((week) => (
                        <WeekItem key={week} week={week} isPastOnly={pastOnly} />
                      ))}
                    </div>
                  </>
                )}
              </div>
              {isYearsSelectorDisplayed && (
                <div className={clsx(s.years_container)}>
                  <div className={s.title}>Year</div>
                  {years.map((year) => (
                    <button
                      key={year}
                      className={clsx(s.reset_button, s.button, isYearSelected(year) && s.selected)}
                      onClick={() => onYearClick(year)}
                    >
                      {year}
                    </button>
                  ))}
                </div>
              )}
            </div>

            <div className={s.buttons_container}>
              <AlloyButton
                onClick={() => {
                  onVisibleChange(false);
                }}
              >
                Cancel
              </AlloyButton>
              <AlloyButton
                type="primary"
                onClick={() => {
                  const years =
                    currentValue.years && currentValue.years.length > 0
                      ? [...currentValue.years]
                      : [currentYear];

                  // TODO: rewrite cleaner somehow?
                  if (isRangeSelector && (!currentValue.start || !currentValue.end)) {
                    const start = currentValue.start || getCurrentPeriod(pastOnly).week;
                    const newValue = {
                      ...currentValue,
                      start: start,
                      end: start,
                      years
                    };
                    setCurrentValue(newValue);
                    onChange(newValue);
                  } else if (
                    isSpecificSelector &&
                    (!currentValue.weeks || currentValue.weeks.length === 0)
                  ) {
                    const { week } = getCurrentPeriod(pastOnly);
                    const newValue = {
                      ...currentValue,
                      weeks: week ? [week] : [],
                      years
                    };
                    setCurrentValue(newValue);
                    onChange(newValue);
                  } else if (
                    isPeriodSelector &&
                    (!currentValue.periods || currentValue.periods.length === 0)
                  ) {
                    const { period } = getCurrentPeriod(pastOnly);
                    const newValue = {
                      ...currentValue,
                      periods: period ? [period] : [],
                      years
                    };
                    setCurrentValue(newValue);
                    onChange(newValue);
                  } else {
                    // TS yells at this line however start = undefined/end = undefined, but it should not be here.
                    const newWalue = {
                      ...currentValue,
                      years
                    } as CalendarValue;
                    setCurrentValue(newWalue);
                    onChange(newWalue);
                  }
                  setVisible(false);
                }}
              >
                Apply
              </AlloyButton>
            </div>
          </div>
        )}
      >
        <div className={s.input_container}>
          <AlloyInput
            className={s.period_input}
            prefix={<CalendarOutlined />}
            suffix={<DownOutlined />}
            value={stringifyCalendarValue(currentValue)}
            contentEditable={false}
          />
        </div>
      </AlloyDropdown>
    </AlloySpin>
  );
};
