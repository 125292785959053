import { Badge, Drawer, message, Radio, Tooltip } from 'antd';
import { filtersToLocalUpdate } from 'common/helpers/advancedFiltersHelper';
import {
  AdvancedFilter,
  Filter,
  FilterData,
  FilterName,
  LocationUpdate,
  NewFilterName,
  ShipToFilterName
} from 'common/interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterBuilder } from './FilterBuilder';
import s from './AdvancedFilterComponent.module.scss';
import { FiltersTable } from './FiltersTable';
import { SaveFilterModal } from './SaveFilterModal';
import { ConfirmDeleteFilterModal } from './ConfirmDeleteFilterModal';
import { cloneDeep, isEqual } from 'lodash-es';
import { getItem, setItem } from 'common/services/persistentStorageServices';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { SizeType } from 'ant5/es/config-provider/SizeContext';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { LOCAL_STORAGE_FILTERS_NAME } from 'pages/OrdersPage/components/AdvancedFilters/AdvancedFilters';

interface AdvancedFilterProps<T extends FilterName | NewFilterName | ShipToFilterName> {
  searchFieldName?: T;
  onApplyAdvancedFilter: (update: LocationUpdate, name?: string) => void;
  searchStringToQueryFilter: (search: string) => any;
  filters: FilterData<T>[];
  saveToLocalStorageFieldName?: string;
  onFilterValuesFocus: (fieldName: T, currentFilters: Filter<T>[]) => void;
  queryFilterToFilters: (queryFilter: any) => Filter<T>[];
  loading: string[];
  handleSearch?: (
    filterName: string,
    filterValue: string | { start: string; end: string } | string[]
  ) => void;
  loadLazyLoadingData: (
    previousFilters: Filter<T>[],
    newCurrentFilters: Filter<T>[],
    reloadAll?: boolean,
    manualReload?: Filter<T>[]
  ) => void;
  currentFilters: Filter<T>[];
  setCurrentFilters: React.Dispatch<React.SetStateAction<Filter<T>[]>>;
  showAdvancedFilters: boolean;
  setShowAdvancedFilters: (v: boolean) => void;
  size?: SizeType;
}

export const getInitialFilters = <T extends FilterName | NewFilterName | ShipToFilterName>(
  queryFilter: any,
  queryFilterToFilters: (queryFilter: any) => Filter<T>[],
  searchFieldName?: string
) => {
  //check for presence of new filter here by console logging
  const filters = queryFilterToFilters(queryFilter);
  return filters.filter((filter) => filter.name !== searchFieldName).length > 0
    ? filters
    : [...filters, {}];
};

const TABS = [
  {
    label: <span data-testid="advanced-filters-current-filter">Current Filter</span>,
    value: 'Current Filter'
  },
  {
    label: <span data-testid="advanced-filters-saved-filters">Saved Filters</span>,
    value: 'Saved Filters'
  }
];

export const AdvancedFilterComponent = <T extends NewFilterName | FilterName | ShipToFilterName>({
  onApplyAdvancedFilter,
  searchStringToQueryFilter,
  saveToLocalStorageFieldName,
  filters,
  queryFilterToFilters,
  onFilterValuesFocus,
  loading,
  loadLazyLoadingData,
  handleSearch,
  currentFilters,
  setCurrentFilters,
  showAdvancedFilters,
  setShowAdvancedFilters,
  size,
  searchFieldName
}: AdvancedFilterProps<T>) => {
  const history = useHistory();

  const [, setSelectedFilters] = useState<Filter<T>[]>(
    getInitialFilters(
      searchStringToQueryFilter(history.location.search),
      queryFilterToFilters,
      searchFieldName
    )
  );

  const [selectedTab, setSelectedTab] = useState(TABS[0].value);
  const [savingAdvancedFilter, setSavingAdvancedFilter] = useState<AdvancedFilter<T> | undefined>();
  const [deletingAdvancedFilter, setDeletingAdvancedFilter] = useState<
    AdvancedFilter<T> | undefined
  >();

  useEffect(() => {
    if (showAdvancedFilters) {
      const newCurrentFilters = getInitialFilters(
        searchStringToQueryFilter(history.location.search),
        queryFilterToFilters,
        searchFieldName
      );
      setCurrentFilters(newCurrentFilters);
      if (saveToLocalStorageFieldName) {
        setSelectedTab(TABS[0].value);
      }
    }
  }, [
    showAdvancedFilters,
    queryFilterToFilters,
    searchStringToQueryFilter,
    history.location.search,
    saveToLocalStorageFieldName,
    setCurrentFilters,
    searchFieldName
  ]);

  useEffect(() => {
    setSelectedFilters((prev: Filter<T>[]) => {
      const newSelectedFilters = currentFilters.filter(
        (filter) => filter.value && (filter.value as string[]).length > 0
      );
      if (isEqual(newSelectedFilters.sort(), prev.sort())) return prev;
      return cloneDeep(newSelectedFilters);
    });
  }, [currentFilters]);

  const saveFilter = (advancedFilter: AdvancedFilter<T>) => {
    const newFilters = getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME) || [];
    newFilters.push(advancedFilter);
    setItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME, newFilters);
    setSavingAdvancedFilter(undefined);
    setSelectedTab(TABS[1].value);
    message.success(
      <span data-testid="advanced-filter-saved-message">Your filter has been saved</span>
    );
  };

  const loadFiltersData = useCallback(
    (filtersToView: Filter<T>[]) => {
      const lazyLoadingFilterNames = filters
        .filter((filter) => filter.lazyLoading)
        .map((filter) => filter.name);
      const filtersToReloadData = filtersToView.filter(
        (filter) => filter.name && lazyLoadingFilterNames.includes(filter.name)
      );
      filtersToReloadData.forEach((filter) => {
        filter.name && onFilterValuesFocus(filter.name, filtersToView);
      });
    },
    [filters, onFilterValuesFocus]
  );

  const deleteFilter = () => {
    if (deletingAdvancedFilter) {
      const deleteFilterName = deletingAdvancedFilter.name;
      const newFilters = getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME)?.filter(
        (filter: AdvancedFilter<T>) => filter.name !== deletingAdvancedFilter.name
      );
      setItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME, newFilters);
      setDeletingAdvancedFilter(undefined);
      message.info(
        <span data-testid="advanced-filter-deleted-message">
          Filter {deleteFilterName} has been deleted
        </span>
      );
    }
  };

  const getSearchValue = () => {
    const filter = currentFilters.find((filter) => filter.name === searchFieldName);
    return typeof filter?.value === 'string'
      ? [filter.value]
      : Array.isArray(filter?.value)
        ? filter?.value || []
        : [];
  };

  return (
    <>
      <AlloyButton
        data-testid="advanced-filters-button"
        onClick={() => {
          loadLazyLoadingData([], currentFilters);
          setShowAdvancedFilters(true);
        }}
        size={size}
      >
        Advanced Filters
        {queryFilterToFilters(searchStringToQueryFilter(history.location.search)).length > 0 ? (
          <>
            :{' '}
            <Badge
              className={s.filters_badge}
              count={
                queryFilterToFilters(searchStringToQueryFilter(history.location.search)).length
              }
            />{' '}
          </>
        ) : null}
      </AlloyButton>
      {history.location.search &&
        (saveToLocalStorageFieldName ? (
          <Drawer
            title={<span data-testid="advanced-filters-title">Advanced Filters</span>}
            placement="right"
            onClose={() => setShowAdvancedFilters(false)}
            closeIcon={<CloseOutlined data-testid="advanced-filters-close-button" />}
            open={showAdvancedFilters}
            footer={
              selectedTab === TABS[0].value ? (
                <div className={s.filter_actions}>
                  <AlloyButton
                    data-testid="advanced-filters-clear-button"
                    type="text"
                    disabled={
                      queryFilterToFilters(searchStringToQueryFilter(history.location.search))
                        .length === 0
                    }
                    onClick={() => {
                      onApplyAdvancedFilter({});
                      setShowAdvancedFilters(false);
                    }}
                  >
                    Clear Filter
                  </AlloyButton>
                  <Tooltip
                    placement="topRight"
                    title={
                      getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME)?.length >=
                      15
                        ? 'You cannot save more than 15 advanced filters'
                        : null
                    }
                  >
                    <AlloyButton
                      data-testid="advanced-filters-save-button"
                      disabled={
                        getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME)
                          ?.length >= 15 ||
                        currentFilters.filter((filter) => filter.value).length === 0
                      }
                      onClick={() =>
                        setSavingAdvancedFilter({
                          filters: cloneDeep(currentFilters),
                          name: '',
                          description: ''
                        })
                      }
                    >
                      Save Filter
                    </AlloyButton>
                  </Tooltip>
                  <AlloyButton
                    data-testid="advanced-filters-apply-button"
                    disabled={currentFilters.filter((filter) => filter.value).length === 0}
                    type="primary"
                    onClick={() => {
                      onApplyAdvancedFilter(filtersToLocalUpdate(currentFilters));
                      setShowAdvancedFilters(false);
                    }}
                  >
                    Apply Filter
                  </AlloyButton>
                </div>
              ) : null
            }
            width="50%"
          >
            <div className={s.filters_tabs}>
              <Radio.Group
                options={TABS}
                onChange={(e) => setSelectedTab(e.target.value)}
                value={selectedTab}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            {selectedTab === TABS[0].value && (
              <>
                <span data-testid="advanced-filters-match-all-title">
                  Match all of the following rules:
                </span>
                {handleSearch && (
                  <MultipleValuesInput
                    dataTestId="advanced-filters-search-box"
                    value={getSearchValue()}
                    onChange={(value) => {
                      setCurrentFilters((prev) => {
                        const newFilters = cloneDeep(prev);
                        const existing = newFilters.find(
                          (filter) => filter.name === searchFieldName
                        );
                        if (existing) {
                          existing.value = value;
                        } else {
                          newFilters.push({ name: searchFieldName, value });
                        }
                        return newFilters;
                      });
                    }}
                    allowClear={true}
                    prefix={<SearchOutlined />}
                    splitInputValue={/[^0-9a-zA-Z-]+/g}
                  />
                )}
                <FilterBuilder<T>
                  searchFieldName={searchFieldName}
                  filters={currentFilters}
                  setFilters={(filters, manualReload) => {
                    setCurrentFilters((prev: Filter<T>[]) => {
                      const newFilters = filters.length > 0 ? filters : [{}];
                      loadLazyLoadingData(prev, newFilters, false, manualReload);
                      return newFilters;
                    });
                  }}
                  supportingFilters={filters}
                  onFocus={onFilterValuesFocus}
                  loading={loading}
                />
              </>
            )}
            {selectedTab === TABS[1].value && (
              <FiltersTable
                data-testid="advanced-filters-saved-table"
                filters={getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME) || []}
                onApplyFilter={(filter: AdvancedFilter<T>) => {
                  onApplyAdvancedFilter(filtersToLocalUpdate(filter.filters), filter.name);
                  setShowAdvancedFilters(false);

                  loadFiltersData(filter.filters);
                }}
                onViewFilterClick={(filter: AdvancedFilter<T>) => {
                  const filtersToView = filter.filters.length > 0 ? filter.filters : [{}];
                  setCurrentFilters(filtersToView);
                  setSelectedTab(TABS[0].value);

                  loadFiltersData(filtersToView);
                }}
                onDeleteFilterClick={(filter: AdvancedFilter<T>) =>
                  setDeletingAdvancedFilter(filter)
                }
              />
            )}
            {savingAdvancedFilter && (
              <SaveFilterModal
                onSave={(name: string, description: string) =>
                  savingAdvancedFilter && saveFilter({ ...savingAdvancedFilter, name, description })
                }
                onCancel={() => setSavingAdvancedFilter(undefined)}
                existingNames={(
                  getItem(saveToLocalStorageFieldName ?? LOCAL_STORAGE_FILTERS_NAME) || []
                ).map((filter: AdvancedFilter<T>) => filter.name)}
              />
            )}
            {deletingAdvancedFilter && (
              <ConfirmDeleteFilterModal
                onConfirm={(confirmed: boolean) =>
                  confirmed ? deleteFilter() : setDeletingAdvancedFilter(undefined)
                }
                filter={deletingAdvancedFilter}
              />
            )}
          </Drawer>
        ) : (
          <Drawer
            title="Advanced Filters"
            placement="right"
            onClose={() => setShowAdvancedFilters(false)}
            open={showAdvancedFilters}
            footer={
              <div className={s.filter_actions}>
                <AlloyButton
                  type="text"
                  disabled={
                    queryFilterToFilters(searchStringToQueryFilter(history.location.search))
                      .length === 0
                  }
                  onClick={() => {
                    onApplyAdvancedFilter({});
                    setShowAdvancedFilters(false);
                  }}
                >
                  Clear Filter
                </AlloyButton>
                <AlloyButton
                  disabled={currentFilters.filter((filter) => filter.value).length === 0}
                  type="primary"
                  onClick={() => {
                    onApplyAdvancedFilter(filtersToLocalUpdate(currentFilters));
                    setShowAdvancedFilters(false);
                  }}
                >
                  Apply Filter
                </AlloyButton>
              </div>
            }
            width="50%"
          >
            <>
              Match all of the following rules:
              <FilterBuilder<T>
                filters={currentFilters}
                setFilters={(filters) => {
                  setCurrentFilters(filters.length > 0 ? filters : [{}]);
                }}
                supportingFilters={filters}
                onFocus={onFilterValuesFocus}
                loading={loading}
              />
            </>
          </Drawer>
        ))}
    </>
  );
};
