type RelayStyleEdges<Node> = {
  edges?: Array<{
    node?: Node | null;
  } | null> | null;
};

type RelayStyleGraphqlResponse<Node, Key extends string | number | symbol> = {
  [P in Key]?: RelayStyleEdges<Node> | null;
};

type GraphqlResponseNoEdges<Node, Key extends string | number | symbol> = {
  [P in Key]?: Node[];
};

/**
 * This type helps get NodeType from relay-style responses. Usage:
 *
 * `type PurchaseOrder = InferNodeType<GetPurchaseOrdersDocument, 'purchaseOrders'>`
 */
export type InferNodeType<QueryResponse, Key extends keyof QueryResponse> =
  QueryResponse extends RelayStyleGraphqlResponse<infer Node, Key>
    ? Node
    : QueryResponse extends GraphqlResponseNoEdges<infer Node, Key>
      ? Node
      : never;

/**
 * This function helps to get nodes from edges. Usage:
 *
 * `const purchaseOrders = getNodesFromEdges(queryResult.data?.purchaseOrders)`
 *
 * Notice that you don't need to manually pass edges to the function.
 */
export const getNodesFromEdges = <Node>(
  response: RelayStyleEdges<Node> | null | undefined
): Node[] => {
  const edges = response?.edges || [];
  const result: Node[] = [];

  for (const edge of edges) {
    if (edge?.node) {
      result.push(edge.node);
    }
  }

  return result;
};

export const locationPropertyToStringOrNull = (property: string | string[] | null) =>
  property ? (typeof property === 'string' ? property : property[0]) : null;

export const locationPropertyToStringArrayOrNull = (property: string | string[] | null) =>
  property ? (typeof property === 'string' ? [property] : property) : null;

export const queryStringToArrayOrUndefined = <T>(
  property: T | T[] | null | undefined
): T[] | undefined => (property ? (Array.isArray(property) ? property : [property]) : undefined);
