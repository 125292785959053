import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ValidateInventorySpreadsheetMutationVariables = Types.Exact<{
  input: Types.ValidateInventorySpreadsheetInput;
}>;


export type ValidateInventorySpreadsheetMutation = { __typename?: 'RootMutationType', validateInventorySpreadsheet?: { __typename?: 'ValidateInventorySpreadsheetPayload', inventoryValidated: number, message: string } | null };


export const ValidateInventorySpreadsheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"validateInventorySpreadsheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ValidateInventorySpreadsheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"validateInventorySpreadsheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inventoryValidated"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<ValidateInventorySpreadsheetMutation, ValidateInventorySpreadsheetMutationVariables>;