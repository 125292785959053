import React, { useEffect, useState } from 'react';
import s from './NewEventModal.module.scss';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyInputForEventTracker } from 'components/ui/AlloyInputForEventTracker/AlloyInputForEventTracker';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyDatePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { AlloyRangePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { InfoCircleFilled, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { EventTrackerProvider } from 'pages/EventTracker/EventTracker.context';
import { Form } from 'react-final-form';
import { EditEventDetails } from '../EditEventDetails/EditEventDetails';
import { EditEventWatchlist } from '../EditEventWatchlist/EditEventWatchlist';

interface FormErrors {
  [index: string]: string;
}

interface NewEventModal {
  open: boolean;
  close: () => void;
}

export const NewEventModal = ({ open = false, close }: NewEventModal) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const onClose = () => {
    setCurrentStep(0);
    close();
  };

  const validateEventDetails = (errors: FormErrors) => {};

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const addWatchlist = () => {};

  const EventDetails = <EditEventDetails event={{}} />;

  const EventWatchlist = <EditEventWatchlist event={{}} />;

  const ConfirmDetails = (
    <div className={s.confirm_details}>
      <div>Found orders and external IDs to be added:</div>
      <ul>
        <li>10 purchase orders</li>
        <li>25 external IDs</li>
      </ul>
    </div>
  );

  if (!open) return null;

  return (
    <EventTrackerProvider>
      <Form
        onSubmit={(values, form) => {
          console.log('values', values);
          console.log('form', form);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AlloyModal
              className={s.new_event_modal}
              open={true}
              title="Create new event"
              width={600}
              onCancel={onClose}
              footer={false}
            >
              <div className={s.header_border}></div>
              <AlloySteps
                className={s.steps}
                size="small"
                current={currentStep}
                items={[
                  {
                    title: 'Add Event details'
                  },
                  {
                    title: 'Add Event watchlist'
                  },
                  {
                    title: 'Confirm Products'
                  }
                ]}
              />

              {currentStep === 0
                ? EventDetails
                : currentStep === 1
                  ? EventWatchlist
                  : ConfirmDetails}

              <div className={s.footer_border}></div>
              <div className={s.footer}>
                <AlloyButton type="link" onClick={onClose}>
                  Cancel
                </AlloyButton>
                <div className={s.right}>
                  <AlloyButton onClick={previousStep}>Back</AlloyButton>
                  {currentStep < 2 && (
                    <AlloyButton type="primary" onClick={nextStep}>
                      Next
                    </AlloyButton>
                  )}
                  {currentStep == 2 && (
                    <AlloyButton
                      type="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add
                    </AlloyButton>
                  )}
                </div>
              </div>
            </AlloyModal>
          </form>
        )}
      ></Form>
    </EventTrackerProvider>
  );
};
