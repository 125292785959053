import React from 'react';
import { Timeline as AntTimeline, TimelineProps } from 'ant5';

const { Item } = AntTimeline;

export function AlloyTimeline({ ...otherProps }: TimelineProps) {
  return <AntTimeline {...otherProps} />;
}

AlloyTimeline.Item = Item;

// Re-exporting types
export type { TimelineProps };
