import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import s from './BatchSwapForm.module.scss';
import clsx from 'clsx';
import { Action, findMaxAmountWithAction } from './helpers';
import { App } from 'ant5';
import { ReconcileBatchDetailsMetric } from '../types';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface BatchSwap {
  firstAction: Action;
  secondAction: Action;
  targetBatchId: string;
  amount: number;
}

const SWAP_OPTIONS: { key: string; label: string; value: Action }[] = [
  {
    key: 'Add',
    value: 'Add',
    label: 'Add'
  },
  {
    key: 'Remove',
    value: 'Remove',
    label: 'Remove'
  }
];

export const BatchSwapForm = ({
  initialBatch,
  batches,
  onCancel
}: {
  initialBatch: ReconcileBatchDetailsMetric | undefined;
  batches: ReconcileBatchDetailsMetric[];
  onCancel: () => void;
}) => {
  const { message } = App.useApp();

  if (!initialBatch) return <div>No initial batch. Try again.</div>;
  return (
    <div className={s.form}>
      <Form<BatchSwap>
        initialValues={{
          firstAction: initialBatch.openVariance > 0 ? 'Remove' : 'Add',
          secondAction: initialBatch.openVariance > 0 ? 'Add' : 'Remove',
          amount: 0
        }}
        onSubmit={(_values) => message.success('Swapped batches')}
        render={({ handleSubmit, form, values }) => (
          <form onSubmit={handleSubmit}>
            <div className={s.formRow}>
              <div className={clsx(s.select, s.initialBatch)}>
                <div className={s.tooltip}>Initial Batch</div>
                <div
                  className={s.name}
                >{`${initialBatch.batchNumber} (${initialBatch.openVariance})`}</div>
              </div>
              <div className={s.action}>
                <AlloyFormField
                  component="select"
                  name="firstAction"
                  fieldProps={{
                    options: SWAP_OPTIONS,
                    title: '',
                    allowClear: false,
                    showSearch: false
                  }}
                />
              </div>
              <div className={s.amountInput}>
                <AlloyFormField
                  component="input"
                  name="amount"
                  fieldProps={{
                    title: 'Amount',
                    type: 'number',
                    min: 0
                  }}
                />
              </div>
            </div>
            <div className={s.formRow}>
              <div className={s.select}>
                <AlloyFormField
                  component="select"
                  name="targetBatchId"
                  fieldProps={{
                    title: 'Target Batch',
                    allowClear: false,
                    showSearch: false,
                    options: batches
                      .filter((x) => x.batchNumber !== initialBatch.batchNumber)
                      .map(({ batchNumber, openVariance }) => ({
                        key: batchNumber,
                        value: batchNumber,
                        label: `${batchNumber} (${openVariance})`
                      }))
                  }}
                />
              </div>
              <div className={s.action}>
                <AlloyFormField
                  component="select"
                  name="secondAction"
                  fieldProps={{
                    title: '',
                    allowClear: false,
                    showSearch: false,
                    options: SWAP_OPTIONS
                  }}
                />
              </div>
              <div className={s.amountInput}>
                <AlloyFormField
                  component="input"
                  name="amount"
                  fieldProps={{
                    title: 'Amount',
                    type: 'number',
                    min: 0
                  }}
                />
              </div>
            </div>
            <div className={s.buttons}>
              <AlloyButton onClick={onCancel}>Cancel</AlloyButton>
              <AlloyButton type="primary" htmlType="submit" disabled={!values.targetBatchId}>
                Confirm
              </AlloyButton>
            </div>
            <OnChange name="targetBatchId">
              {(value) => {
                form.change(
                  'amount',
                  findMaxAmountWithAction(
                    initialBatch.batchNumber,
                    value,
                    batches,
                    form.getFieldState('firstAction')?.value as Action
                  )
                );
              }}
            </OnChange>
            <OnChange name="firstAction">
              {(value, prevValue) => {
                form.change('secondAction', prevValue);
                form.change(
                  'amount',
                  findMaxAmountWithAction(
                    initialBatch.batchNumber,
                    form.getFieldState('targetBatchId')?.value,
                    batches,
                    value
                  )
                );
              }}
            </OnChange>
            <OnChange name="secondAction">
              {(_value, prevValue) => {
                form.change('firstAction', prevValue);
              }}
            </OnChange>
          </form>
        )}
      />
    </div>
  );
};
