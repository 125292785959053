import { ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { CellObject } from 'non_npm_dependencies/xlsx/types';

type ExportRender<T> = (item: T) => string | number;
// This is a column type with a special csvRender field. We use it for mapping + it's collocated in columns, so everything is mapped
type ColumnWithExportRenderOnly<T> = ColumnType<T> & {
  exportConfig: {
    render: ExportRender<T>;
    modes?: string[];
    title?: string;
    xlsxConfig?: CellObject;
  };
};

function isColumnWithExportOnly<T>(
  column: ColumnWithExportRender<T>
): column is ColumnWithExportRenderOnly<T> {
  return (column as ColumnWithExportRenderOnly<T>).exportConfig?.render !== undefined;
}

export type ColumnWithExportRender<T> = ColumnType<T> | ColumnWithExportRenderOnly<T>;

export type ColumnsWithExportRender<T> = ColumnWithExportRender<T>[];

export function removeExportConfig<T>(columns: ColumnsWithExportRender<T>): ColumnType<T>[] {
  return columns.map((column) => {
    if (isColumnWithExportOnly(column)) {
      const { exportConfig, ...columnWithoutCsv } = column;
      return columnWithoutCsv;
    } else {
      return column;
    }
  });
}

export function prepareSingleRecordForExport<T>(
  record: T | undefined,
  columns: ColumnsWithExportRender<T>,
  exportMode?: string
) {
  const safeCsvRender = (record: T | undefined, csvRender: ExportRender<T>) =>
    record ? csvRender(record) : undefined;

  const mapped = columns
    .filter(isColumnWithExportOnly)
    .filter((x) => !exportMode || x.exportConfig?.modes?.includes(exportMode))
    .map(
      ({ title, exportConfig: { render, title: exportTitle } }) =>
        [exportTitle || title?.toString() || '', safeCsvRender(record, render)] as const
    );
  return Object.fromEntries(mapped) as Record<string, string | number | undefined>;
}

export function prepareRecordsForExport<T>(
  records: T[],
  columns: ColumnsWithExportRender<T>,
  exportMode?: string
) {
  return records.map((record) => prepareSingleRecordForExport(record, columns, exportMode));
}

export function prepareSingleRecordForXLSXExport<T>(
  record: T | undefined,
  columns: ColumnsWithExportRender<T>,
  exportMode?: string
) {
  const safeCsvRender = (record: T | undefined, csvRender: ExportRender<T>) =>
    record ? csvRender(record) : undefined;

  const mapped = columns
    .filter(isColumnWithExportOnly)
    .filter((x) => !exportMode || x.exportConfig?.modes?.includes(exportMode))
    .map(({ title, exportConfig: { render, title: exportTitle, xlsxConfig } }) => {
      const renderedTitle = exportTitle || title?.toString() || '';
      const renderedValue = safeCsvRender(record, render);

      if (xlsxConfig) {
        return [renderedTitle, { ...xlsxConfig, v: renderedValue }];
      } else {
        return [renderedTitle, renderedValue];
      }
    });
  return Object.fromEntries(mapped) as Record<string, string | number | undefined | CellObject>;
}

export function prepareRecordsXLSXForExport<T>(
  records: T[],
  columns: ColumnsWithExportRender<T>,
  exportMode?: string
) {
  return records.map((record) => prepareSingleRecordForXLSXExport(record, columns, exportMode));
}
