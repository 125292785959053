import React, { useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import s from './AddShipTosStep.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-final-form';
import {
  TradingPartnersForAddShipToModalDocument,
  TradingPartnersForAddShipToModalQuery
} from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnersForAddShipToModal.query';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  TradingPartnerWithShipTosDocument,
  TradingPartnerWithShipTosQuery
} from 'pages/TradingPartnersPage/gql/__generated__/shipTosByTradingPartner.query';
import { VendorMarket } from '../AddTradingPartnerStepper/AddTradingPartnerStepper';
import { groupBy } from 'lodash-es';
import { isGroupedSelectOption } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';

interface AddShipTosStepProps {
  vendorMarkets: VendorMarket[];
  initLoading: boolean;
}

interface ShipToSelection {
  shipTo: ShipTo;
  selected: boolean;
}

export const AddShipTosStep = ({ vendorMarkets, initLoading }: AddShipTosStepProps) => {
  const [showAddShopTosFromTpModal, setShowAddShopTosFromTpModal] = useState<boolean>(false);
  const form = useForm();

  return (
    <FieldArray name="rdds" key="rdds">
      {({ fields }) => (
        <div className={s.add_ship_to_form}>
          <span className={s.copy_description}>
            <span>Optional</span>: Select a Trading Partner's Ship-To you'd like to copy.
          </span>
          <div className={s.buttons}>
            <AlloyButton
              onClick={() => {
                form.mutators.setRdds([]);
              }}
              disabled={!form.getState().values.rdds || form.getState().values.rdds.length === 0}
            >
              Clear selection
            </AlloyButton>
            <AlloyButton
              onClick={() => setShowAddShopTosFromTpModal(true)}
              type="primary"
              loading={initLoading}
            >
              Add Ship-Tos
            </AlloyButton>
          </div>
          <div style={{ marginBottom: '12px' }}>
            {
              (fields.value || []).filter((shipToObj: ShipToSelection) => !!shipToObj.selected)
                .length
            }{' '}
            Ship-Tos selected
          </div>
          <AddShipTosTable
            loading={false}
            shipTos={form
              .getState()
              .values.rdds?.map((shipToObj: ShipToSelection) => shipToObj.shipTo)}
            selected={form
              .getState()
              .values.rdds?.filter((shipToObj: ShipToSelection) => shipToObj.selected)
              .map((shipToObj: ShipToSelection) => shipToObj.shipTo.id)}
            setSelected={(selected) => {
              form.mutators.setRdds(
                form.getState().values.rdds?.map((shipToObj: ShipToSelection) => ({
                  shipTo: shipToObj.shipTo,
                  selected: selected.includes(shipToObj.shipTo.id)
                }))
              );
            }}
          />
          <AddShipToFromTradingPartnerModal
            visible={showAddShopTosFromTpModal}
            vendorMarkets={vendorMarkets}
            onAdd={(shipTos: ShipTo[]) => {
              const existingRddIds: string[] = (form.getState().values.rdds || []).map(
                (shipToObj: ShipToSelection) => shipToObj.shipTo.id
              );
              form.mutators.setRdds([
                ...(form.getState().values.rdds || []),
                ...shipTos
                  .filter((shipTo) => !existingRddIds.includes(shipTo.id))
                  .map((shipTo) => ({ shipTo, selected: true }))
              ]);
            }}
            onClose={() => setShowAddShopTosFromTpModal(false)}
          />
        </div>
      )}
    </FieldArray>
  );
};

interface AddShipToFromTradingPartnerModalProps {
  visible: boolean;
  onAdd: (shipTos: ShipTo[]) => void;
  onClose: () => void;
  vendorMarkets: VendorMarket[];
}

type TradingPartnersForAddShipToModal = InferNodeType<
  TradingPartnersForAddShipToModalQuery,
  'tradingPartners'
>;
export type ShipTo = NonNullable<
  TradingPartnerWithShipTosQuery['tradingPartner']
>['retailerDeliveryDestinations'][number];

export const getTradingPartnerOptions = (tradingPartners: TradingPartnersForAddShipToModal[]) => {
  const options = [];
  const rcDict = groupBy(tradingPartners, 'vendorMarket.id');
  const vendorMarkets = tradingPartners.map((tp) => tp.vendorMarket);
  for (const retailerChannelId in rcDict) {
    const name = vendorMarkets.find((vm) => vm.id === retailerChannelId)?.name || '';
    options.push({
      label: name,
      options: rcDict[retailerChannelId]
        .sort((tp1, tp2) => (tp1.name || '').localeCompare(tp2.name || ''))
        .map((tp) => ({
          label: tp.name || '',
          value: tp.id
        }))
    });
  }
  return options;
};

const AddShipToFromTradingPartnerModal = ({
  visible,
  onAdd,
  onClose,
  vendorMarkets
}: AddShipToFromTradingPartnerModalProps) => {
  const [selectedTradingPartner, setSelectedTradingPartner] =
    useState<TradingPartnersForAddShipToModal>();
  const [selected, setSelected] = useState<string[]>([]);
  const [shipTos, setShipTos] = useState<ShipTo[]>([]);

  const form = useForm();

  const { data: tpsData, loading: tpsDataLoading } = useQuery(
    TradingPartnersForAddShipToModalDocument,
    {}
  );

  const { data: shipTosData, loading: shipTosDataLoading } = useQuery(
    TradingPartnerWithShipTosDocument,
    {
      variables: {
        id: selectedTradingPartner?.id || ''
      },

      skip: !selectedTradingPartner
    }
  );

  const tradingPartners = useMemo(() => {
    const vendorMarketId = form.getState().values.vendorMarketId;
    return getNodesFromEdges(tpsData?.tradingPartners).filter(
      (tp) =>
        tp.vendorMarket.vendor?.id ===
        vendorMarkets.find((vendorMarket) => vendorMarket.id === vendorMarketId)?.vendor?.id
    );
  }, [form, tpsData?.tradingPartners, vendorMarkets]);

  useEffect(() => {
    setShipTos(shipTosData?.tradingPartner?.retailerDeliveryDestinations || []);
    setSelected(
      (shipTosData?.tradingPartner?.retailerDeliveryDestinations || []).map((shipTo) => shipTo.id)
    );
  }, [shipTosData]);

  useEffect(() => {
    if (!visible) {
      setSelectedTradingPartner(undefined);
      setSelected([]);
    }
  }, [visible]);

  return (
    <AlloyModal
      open={visible}
      title="Add Ship-Tos"
      closable
      destroyOnClose
      onCancel={onClose}
      onOk={() => {
        onAdd(shipTos.filter((shipTo) => selected.includes(shipTo.id)));
        onClose();
      }}
    >
      <AlloyDivider />
      <AlloySelect
        data-testid="tp-add-ship-to-trading-partner-select"
        placeholder="Select Trading Partner"
        onChange={(value) => {
          setSelectedTradingPartner(tradingPartners.find((tp) => tp.id === value));
        }}
        loading={tpsDataLoading}
        style={{ width: '360px', marginBottom: '16px' }}
        value={selectedTradingPartner?.id}
        options={getTradingPartnerOptions(tradingPartners)}
        filterOption={(input: any, option: any) => {
          const isGrouped = isGroupedSelectOption(option);
          return !isGrouped && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        showSearch
      />
      <AddShipTosTable
        loading={shipTosDataLoading}
        shipTos={shipTos || []}
        selected={selected}
        setSelected={setSelected}
      />
      <AlloyDivider />
    </AlloyModal>
  );
};

const AddShipTosTable = ({
  shipTos,
  loading,
  selected,
  setSelected
}: {
  shipTos: ShipTo[];
  loading: boolean;
  selected: string[];
  setSelected: (selected: string[]) => void;
}) => {
  const columns: ColumnsType<ShipTo> = [
    {
      title: 'External ID',
      render: (_, item) => item.externalId
    },
    {
      title: 'Name',
      render: (_, item) => item.name
    }
  ];

  return (
    <AlloyTable
      size="small"
      loading={loading}
      rowKey={(item) => item.id}
      columns={columns}
      dataSource={shipTos}
      rowSelection={{
        onChange: (selectedRowKeys) => {
          if (selectedRowKeys.length - selected.length > 1) {
            // selected all
            setSelected(shipTos.map((shipTo) => shipTo.id));
          } else if (selectedRowKeys.length - selected.length < -1) {
            // deselect all
            setSelected([]);
          } else {
            setSelected(selectedRowKeys.map((key) => key as string));
          }
        },
        selectedRowKeys: selected,
        type: 'checkbox'
      }}
    />
  );
};
