import { RoundedRadioButton } from 'components/RoundedRadioButton';
import React, { useMemo } from 'react';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import s from './TopOpportunities.module.scss';
import starIcon from 'assets/icons/star_icon.svg';
import {
  RateCellWithTargetCompact,
  onCellRateWithTargetCompact
} from 'components/RateCellWithTargetCompact/RateCellWithTarget';
import clsx from 'clsx';
import { sumBy, upperFirst } from 'lodash-es';
import { GapToTarget, GapToTargetLegend } from './GapToTarget/GapToTarget';
import { NOT_AVAILABLE } from 'common/constants';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { ReportType } from 'graphql/__generated__/types';
import {
  ExecutiveSummaryOpportunitiesReportDocument,
  ExecutiveSummaryOpportunitiesReportQuery
} from './gql/__generated__/executiveSummaryOpportunitiesReport.query';
import { useQuery } from '@apollo/client';
import { transformCalendarValueToFiscalCalendarWeekInput } from 'components/PeriodCalendar/helpers';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { notEmpty } from 'common/helpers/notEmpty';
import {
  ExecutiveSummaryOpportunitiesDetailsDocument,
  ExecutiveSummaryOpportunitiesDetailsQuery
} from './gql/__generated__/executiveSummaryOpportunitiesDetails.query';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyTable, ColumnType } from 'components/ui/AlloyTable/AlloyTable';

const { Option } = AlloySelect;

const availableModes: { key: ReportType; title: string }[] = [
  { key: 'CUSTOMER', title: 'Customer' },
  { key: 'CUSTOMER_LOCATION', title: 'Customer Location' },
  { key: 'SOURCE_LOCATION', title: 'Source Location' }
];

const getName = (mode: ReportType) => {
  if (mode === 'CUSTOMER') return 'Customer';
  if (mode === 'CUSTOMER_LOCATION') return 'Customer Location';
  if (mode === 'SOURCE_LOCATION') return 'Source Location';
};

type ReportMetric = NonNullable<
  NonNullable<
    ExecutiveSummaryOpportunitiesReportQuery['executiveSummaryOpportunitiesReport']
  >['metrics'][number]
>;

const commonColumns: ColumnType<ReportMetric>[] = [
  {
    title: 'Total Orders',
    width: 100,
    align: 'right',
    key: 'total',
    render: (_, { totalOrders }) => (totalOrders || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.totalOrders, b.totalOrders)
  },
  {
    title: 'On Time',
    width: 100,
    align: 'right',
    key: 'onTime',
    render: (_, { onTimeShipment }) => (onTimeShipment || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.onTimeShipment, b.onTimeShipment)
  },
  {
    title: 'Shipped Late',
    width: 100,
    align: 'right',
    key: 'late',
    render: (_, { lateShipment }) => (lateShipment || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.lateShipment, b.lateShipment)
  },
  {
    title: 'Pending to ship',
    width: 100,
    align: 'right',
    key: 'pending',
    render: (_, { pendingToShip }) => (pendingToShip || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.pendingToShip, b.pendingToShip)
  },
  {
    title: 'Cancelled',
    width: 100,
    align: 'right',
    key: 'cancelled',
    render: (_, { cancelledShipment }) => (cancelledShipment || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.cancelledShipment, b.cancelledShipment)
  },
  {
    title: 'OTS',
    width: 60,
    key: 'ots',
    // We manually calculate target now, but it should come from BE.
    render: (_, { onTimeShipmentRate, gapToTarget }) => (
      <RateCellWithTargetCompact
        value={onTimeShipmentRate}
        target={Math.min(onTimeShipmentRate + gapToTarget, 100)}
      />
    ),
    onCell: ({ onTimeShipmentRate, gapToTarget }) =>
      onCellRateWithTargetCompact(
        onTimeShipmentRate,
        Math.min(onTimeShipmentRate + gapToTarget, 100)
      ),
    sorter: (a, b) => safeNumberComparator(a.onTimeShipmentRate, b.onTimeShipmentRate)
  },
  {
    title: 'Gap To Target',
    width: 120,
    key: 'gap',
    // We manually calculate target now, but it should come from BE.
    render: (_, { onTimeShipmentRate, gapToTarget }) => (
      <GapToTarget
        value={onTimeShipmentRate}
        target={Math.min(onTimeShipmentRate + gapToTarget, 100)}
      />
    ),
    sorter: (a, b) => safeNumberComparator(a.gapToTarget, b.gapToTarget)
  }
];

export const TopOpportunities = ({
  period,
  businessUnitIds,
  vendorMarketIds
}: {
  period: CalendarValue;
  businessUnitIds: string[];
  vendorMarketIds: string[];
}) => {
  const [mode, setMode] = useQueryParam('report-type', withDefault(StringParam, 'CUSTOMER'));
  const [top, setTop] = useQueryParam('top', withDefault(NumberParam, 5));

  const { data, loading } = useQuery(ExecutiveSummaryOpportunitiesReportDocument, {
    variables: {
      filters: {
        businessUnitIds,
        vendorMarketIds,
        fiscalCalendarWeeks: transformCalendarValueToFiscalCalendarWeekInput(period)
      },
      limit: top,
      reportType: mode as ReportType
    }
    // It's better to refetch data each time, so it's fresh.
  });

  const columnsWithName: ColumnType<ReportMetric>[] = [
    {
      title: getName(mode as ReportType),
      width: 150,
      key: 'name',
      render: (_, { name }) => upperFirst(name || '') || NOT_AVAILABLE,
      sorter: (a, b) => safeLocaleCompare(a.name, b?.name)
    },
    ...commonColumns
  ];

  const metrics = useMemo(
    () => (data?.executiveSummaryOpportunitiesReport?.metrics || []).filter(notEmpty),
    [data]
  );

  return (
    <section>
      <div className={s.header}>
        <div className={s.opportunities}>
          <div className={s.text}>
            <img alt="" width="24" src={starIcon} className={s.star} />
            Top
          </div>
          <AlloySelect
            value={top}
            onChange={(value) => {
              setTop(value as number);
            }}
            bordered={false}
            dropdownStyle={{ minWidth: '120px', fontWeight: 'bold' }}
          >
            {[5, 10, 15, 20].map((opportunities) => (
              <Option key={opportunities} value={opportunities}>
                {opportunities} Opportunities
              </Option>
            ))}
          </AlloySelect>
        </div>
        <div className={s.mode}>
          <RoundedRadioButton
            buttons={availableModes}
            value={mode}
            onChange={(key) => {
              setMode(key);
            }}
          />
        </div>
      </div>
      <div className={s.table}>
        <div className={s.legend}>
          <GapToTargetLegend />
        </div>
        <AlloyTable
          data-testid="at_risk_orders_table"
          rowKey={(row) => `${mode}-${row.id}`}
          className={clsx(`styled_report_table`)}
          size="small"
          dataSource={metrics}
          columns={columnsWithName}
          bordered
          pagination={false}
          loading={loading}
          summary={(data) => {
            if (!data || data?.length === 0) return <></>;
            // We can do it in one pass, but it's more cumbersome
            const total = sumBy(data, 'totalOrders');
            const onTime = sumBy(data, 'onTimeShipment');
            const late = sumBy(data, 'lateShipment');
            const pending = sumBy(data, 'pendingToShip');
            const cancelled = sumBy(data, 'cancelledShipment');

            return (
              <AlloyTable.Summary>
                <AlloyTable.Summary.Row>
                  <AlloyTable.Summary.Cell index={1} className={s.total} colSpan={2}>
                    Total:
                  </AlloyTable.Summary.Cell>
                  <AlloyTable.Summary.Cell index={2} className={s.total}>
                    {total.toLocaleString()}
                  </AlloyTable.Summary.Cell>
                  <AlloyTable.Summary.Cell index={3} className={s.total}>
                    {onTime.toLocaleString()}
                  </AlloyTable.Summary.Cell>
                  <AlloyTable.Summary.Cell index={4} className={s.total}>
                    {late.toLocaleString()}
                  </AlloyTable.Summary.Cell>
                  <AlloyTable.Summary.Cell index={5} className={s.total}>
                    {pending.toLocaleString()}
                  </AlloyTable.Summary.Cell>
                  <AlloyTable.Summary.Cell index={6} className={s.total}>
                    {cancelled.toLocaleString()}
                  </AlloyTable.Summary.Cell>
                </AlloyTable.Summary.Row>
              </AlloyTable.Summary>
            );
          }}
          expandable={{
            // Make sure that there is an id
            rowExpandable: (row) => row.id !== null && typeof row.id !== 'undefined',
            expandedRowRender: (record) => (
              <Details
                businessUnitIds={businessUnitIds}
                vendorMarketIds={vendorMarketIds}
                reportType={mode as ReportType}
                period={period}
                id={record.id!}
              />
            )
          }}
        />
      </div>
    </section>
  );
};

// We manually set target now, but it should come from BE.
const CURRENT_HARDCODED_TARGET = 100;

type ReportDetailsMetric = NonNullable<
  NonNullable<
    ExecutiveSummaryOpportunitiesDetailsQuery['executiveSummaryOpportunitiesDetails']
  >['metrics'][number]
>;

const detailsColumns: ColumnType<ReportDetailsMetric>[] = [
  {
    title: 'Customer',
    width: 150,
    key: 'customer',
    render: (_, { customerName }) => upperFirst(customerName || '') || NOT_AVAILABLE,
    sorter: (a, b) => safeLocaleCompare(a.customerName, b.customerName)
  },
  {
    title: 'Customer Location',
    width: 150,
    key: 'customerLocation',
    render: (_, { customerLocationName }) => customerLocationName || NOT_AVAILABLE,
    sorter: (a, b) => safeLocaleCompare(a.customerLocationName, b.customerLocationName)
  },
  {
    title: 'Customer Location Number',
    width: 150,
    align: 'right',
    key: 'customerLocationNumber',
    render: (_, { customerLocationNumber }) => customerLocationNumber || NOT_AVAILABLE,
    sorter: (a, b) => safeLocaleCompare(a.customerLocationNumber, b.customerLocationNumber)
  },
  {
    title: 'Source Location',
    width: 150,
    key: 'sourceLocation',
    render: (_, { sourceLocationName }) => sourceLocationName || NOT_AVAILABLE,
    sorter: (a, b) => safeLocaleCompare(a.sourceLocationName, b.sourceLocationName)
  },
  {
    title: 'Total Orders',
    width: 100,
    align: 'right',
    key: 'total',
    render: (_, { totalOrders }) => (totalOrders || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.totalOrders, b.totalOrders)
  },
  {
    title: 'Total Late',
    width: 100,
    align: 'right',
    key: 'late',
    render: (_, { lateShipment }) => (lateShipment || 0).toLocaleString(),
    sorter: (a, b) => safeNumberComparator(a.lateShipment, b.lateShipment)
  },
  {
    title: 'OTS',
    width: 60,
    key: 'ots',
    render: (_, { onTimeShipmentRate }) => (
      <RateCellWithTargetCompact value={onTimeShipmentRate} target={CURRENT_HARDCODED_TARGET} />
    ),
    onCell: ({ onTimeShipmentRate }) =>
      onCellRateWithTargetCompact(onTimeShipmentRate, CURRENT_HARDCODED_TARGET),
    sorter: (a, b) => safeNumberComparator(a.onTimeShipmentRate, b.onTimeShipmentRate)
  }
];

const Details = ({
  period,
  businessUnitIds,
  vendorMarketIds,
  reportType,
  id
}: {
  period: CalendarValue;
  businessUnitIds: string[];
  vendorMarketIds: string[];
  reportType: ReportType;
  id: number;
}) => {
  const { data, loading } = useQuery(ExecutiveSummaryOpportunitiesDetailsDocument, {
    variables: {
      filters: {
        businessUnitIds,
        vendorMarketIds,
        fiscalCalendarWeeks: transformCalendarValueToFiscalCalendarWeekInput(period)
      },
      id,
      reportType
    }
  });

  const metrics = useMemo(
    () => (data?.executiveSummaryOpportunitiesDetails?.metrics || []).filter(notEmpty),
    [data]
  );

  return (
    <div className={s.details_table_wrapper}>
      <AlloyTable
        key="id"
        className={clsx(`styled_report_table`)}
        columns={detailsColumns}
        dataSource={metrics}
        pagination={false}
        loading={loading}
        summary={(data) => {
          if (!data || data?.length === 0) return <></>;
          // We can do it in one pass, but it's more cumbersome
          const total = sumBy(data, 'totalOrders');
          const late = sumBy(data, 'lateShipment');

          return (
            <AlloyTable.Summary>
              <AlloyTable.Summary.Row>
                <AlloyTable.Summary.Cell index={1} className={s.total} colSpan={4}>
                  Total:
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={2} className={s.total}>
                  {total.toLocaleString()}
                </AlloyTable.Summary.Cell>
                <AlloyTable.Summary.Cell index={3} className={s.total}>
                  {late.toLocaleString()}
                </AlloyTable.Summary.Cell>
              </AlloyTable.Summary.Row>
            </AlloyTable.Summary>
          );
        }}
      />
    </div>
  );
};
