import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import s from './WarehouseOrderProcessingPage.module.scss';
import clsx from 'clsx';
import { App, Empty } from 'ant5';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloyAlert } from 'components/ui/AlloyAlert/AlloyAlert';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput'; //TODO: use AlloyFormField
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect'; //TODO: use AlloyFormField
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyUpload, UploadChangeParam } from 'components/ui/AlloyUpload/AlloyUpload';
import { LastUpdated } from './components/LastUpdated/LastUpdated';
import CancelIcon from 'assets/icons/common/cross.svg';
import UploadIcon from 'assets/icons/inventoryOptimization/upload_icon.svg';
import { UploadFile } from 'ant5/es/upload/interface';
import { ValidateOrdersSpreadsheetDocument } from './gql/__generated__/validateOrdersSpreadsheet.mutation';
import { ValidateInventorySpreadsheetDocument } from './gql/__generated__/validateInventorySpreadsheet.mutation';
import {
  WhseOpenOrdersProcessedDocument,
  WhseOpenOrdersProcessedQuery
} from './gql/__generated__/whseOpenOrdersProcessed.query';
import {
  OrderSortColumn,
  SortOrderDirection,
  ValidateOrdersSpreadsheetPayload
} from 'graphql/__generated__/types';
import { SaveOrdersSpreadsheetDocument } from './gql/__generated__/saveOrdersSpreadsheet.mutation';
import { SaveInventorySpreadsheetDocument } from './gql/__generated__/saveInventorySpreadsheet.mutation';
import { ProcessOrdersAndInventoryDocument } from './gql/__generated__/processOrdersAndInventory.mutation';
import { notEmpty } from 'common/helpers/notEmpty';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { dateFormat } from 'common/helpers/date';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { Paginator } from 'components/Paginator/Paginator';
import { DEFAULT_PAGE_SIZE_OPTIONS, EMPTY } from 'common/constants';
import { getColumnSortOrder } from 'common/helpers/sorting';
import ErrorDisplay from 'components/Common/ErrorDisplay';
// import { ExpandChevronButton } from 'components/ui/ExpandChevronButton/ExpandChevronButton'; //TODO: use this in the future globally, perhaps
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { MaterialsView } from './components/MaterialsView/MaterialsView';
import { formatStatus } from '../WarehouseOrderProcessingPage/helpers';

export type DisplayMode = 'orders' | 'materials' | 'cuts';
const DEFAULT_TAB: DisplayMode = 'orders';

type WarehouseOrderProcessingTab = {
  value: DisplayMode;
  label: React.ReactNode;
  disabled?: boolean;
};
const ORDERS_TAB: WarehouseOrderProcessingTab = {
  value: 'orders',
  label: 'Orders',
  disabled: false
};
const MATERIALS_TAB: WarehouseOrderProcessingTab = {
  value: 'materials',
  label: 'Materials',
  disabled: false
};
const CUTS_TAB: WarehouseOrderProcessingTab = {
  value: 'cuts',
  label: 'Cuts',
  disabled: true //Disabled for now, until this page is built
};
const tabs = [ORDERS_TAB, MATERIALS_TAB, CUTS_TAB];

type WhseOpenOrdersProcessed = InferNodeType<
  WhseOpenOrdersProcessedQuery,
  'whseOpenOrdersProcessed'
>;

type Materials = NonNullable<NonNullable<WhseOpenOrdersProcessed['materials']>[number]>;

const ORDERS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3];

const getRowKey = (record: WhseOpenOrdersProcessed) => record.orderNumber;
const getExpandedRowKey = (material: Materials) => material.materialId;

const TABLE_WIDTH = 1590;

export const WarehouseOrderProcessingPage = () => {
  const { message } = App.useApp();

  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  const modeWithType = mode as DisplayMode;

  const [selectedRows, setSelectedRows] = useState<WhseOpenOrdersProcessed[]>([]);

  // const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  // const [limit] = useQueryParam('limit', withDefault(NumberParam, ORDERS_DEFAULT_PAGE_SIZE));
  const [pageSize, setPageSize] = useQueryParam(
    'limit',
    withDefault(NumberParam, ORDERS_DEFAULT_PAGE_SIZE)
  );
  const [sortColumn, setSortColumn] = useQueryParam('order_column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order_order', StringParam);
  const [search, setSearch] = useQueryParam('order_search', StringParam);
  const [after, setAfter] = useQueryParam('order_after', StringParam);
  const [before, setBefore] = useQueryParam('order_before', StringParam);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
  const [ordersFile, setOrdersFile] = useState<UploadFile>();
  const [ordersFileUploadStatus, setOrdersFileUploadStatus] = useState<string | undefined>();
  const [inventoryFile, setInventoryFile] = useState<UploadFile>();
  const [inventoryFileUploadStatus, setInventoryFileUploadStatus] = useState<string | undefined>(
    ''
  );
  const [ordersForOverrides, setOrdersForOverrides] = useState<
    ValidateOrdersSpreadsheetPayload['duplicates']
  >([]);

  const [selectedOrdersForOverride, setSelectedOrdersForOverride] = useState<string[]>([]);

  // STEP 2: After user uploads the SAP orders excel file, FE calls the validateOrdersSpreadsheet query
  const [validateOrdersFileData] = useMutation(ValidateOrdersSpreadsheetDocument, {});

  // Step 1: After user uploads the inventory excel file, FE calls the validateInventorySpreadsheet query
  const [validateInventoryFileData] = useMutation(ValidateInventorySpreadsheetDocument, {});

  // STEP 4: Save orders w/without overrides
  const [saveValidatedOrdersFile] = useMutation(SaveOrdersSpreadsheetDocument, {});

  // STEP 3: Save inventory and then execute sendValidatedOrdersFile function
  const [saveValidatedInventoryFile] = useMutation(SaveInventorySpreadsheetDocument, {});

  // STEP 5: process the uploaded orders and inventory before displaying them in the table
  const [processOrderAndInventoryFiles, { loading: processOrdersAndInventoryLoading }] =
    useMutation(ProcessOrdersAndInventoryDocument, {});

  // On page load/render, call the whseOpenOrdersProcessed query
  const { data, loading, error, refetch } = useQuery(WhseOpenOrdersProcessedDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as OrderSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before
    }
  });

  const openOrdersList = useMemo(() => getNodesFromEdges(data?.whseOpenOrdersProcessed), [data]);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(ORDERS_DEFAULT_PAGE_SIZE);
      setSortColumn('SHIP_TO');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  // STEP 2: User uploads the SAP orders excel file, FE calls the validateOrdersSpreadsheet query
  const ordersFileValidation = async (file: UploadFile) => {
    await validateOrdersFileData({
      // Call the query and input the file the user uploaded
      variables: {
        input: {
          data: file.originFileObj as File
        }
      },
      onCompleted: ({ validateOrdersSpreadsheet }) => {
        if (validateOrdersSpreadsheet && validateOrdersSpreadsheet.duplicates.length > 0) {
          /* If the query has been called and the file has duplicates: 
            filter out empty rows, 
            return only the duplicates, 
            set the orders file state to the file that contains duplicates, 
            set the file upload status to 'override
          */
          setOrdersForOverrides(validateOrdersSpreadsheet.duplicates.filter(notEmpty));
          setOrdersFile(file);
          setOrdersFileUploadStatus('override');
        } else if (validateOrdersSpreadsheet && validateOrdersSpreadsheet?.validOrders > 0) {
          /* If the file has no duplicates: 
            set the upload status to 'done,
            and set the ordersFile state to the file that was uploaded
          */
          setOrdersFileUploadStatus('done');
          setOrdersFile(file);
          message.success(validateOrdersSpreadsheet.message, 5);
        } else {
          setOrdersFile(file);
          message.error(validateOrdersSpreadsheet?.message, 5);
        }
      }
    });
  };

  // Step 1: User uploads the inventory excel file, FE calls the validateInventorySpreadsheet query
  const inventoryFileValidation = async (file: UploadFile<any>) => {
    await validateInventoryFileData({
      variables: {
        input: {
          data: file.originFileObj as File
        }
      },
      onCompleted: ({ validateInventorySpreadsheet }) => {
        if (validateInventorySpreadsheet && validateInventorySpreadsheet?.inventoryValidated > 0) {
          setInventoryFileUploadStatus('done');
          setInventoryFile(file);
          message.success(validateInventorySpreadsheet.message, 5);
        } else {
          setInventoryFile(file);
          message.error(validateInventorySpreadsheet?.message, 5);
        }
      }
    });
  };

  // STEP 4: Save orders w/without overrides
  const sendValidatedOrdersFile = async () => {
    await saveValidatedOrdersFile({
      variables: {
        input: {
          accepted: selectedOrdersForOverride,
          rejected: ordersForOverrides
            .filter(notEmpty)
            .filter((order) => !selectedOrdersForOverride.includes(order)),
          data: ordersFile?.originFileObj as File
        }
      },
      onCompleted: ({ saveOrdersSpreadsheet }) => {
        if (saveOrdersSpreadsheet?.message.includes('error')) {
          message.error(saveOrdersSpreadsheet?.message, 5);
        } else {
          message.success(saveOrdersSpreadsheet?.message, 5);
        }

        setUploadFileModalVisible(false);
        processedOrdersAndInventoryAfterFilesUploads();
      }
    });
  };

  // STEP 3: Save inventory and then execute sendValidatedOrdersFile function
  const sendValidatedInventoryFile = async () => {
    await saveValidatedInventoryFile({
      variables: {
        input: {
          data: inventoryFile?.originFileObj as File
        }
      },
      onCompleted: ({ saveInventorySpreadsheet }) => {
        if (saveInventorySpreadsheet?.message.includes('error')) {
          message.error(saveInventorySpreadsheet?.message, 5);
        } else {
          message.success(saveInventorySpreadsheet?.message, 5);
        }

        sendValidatedOrdersFile();
      }
    });
  };

  //Step 5: process the uploaded orders and inventory before displaying them in the table
  const processedOrdersAndInventoryAfterFilesUploads = async () => {
    await processOrderAndInventoryFiles({
      onCompleted: ({ processOrdersAndInventory }) => {
        if (processOrdersAndInventory?.message.includes('error')) {
          message.error(processOrdersAndInventory?.message, 5);
        } else {
          message.success(processOrdersAndInventory?.message, 5);
        }

        refetch();
      }
    });
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const uploadOrdersProps = {
    accept: '.csv, .xls, .xlsx, .XLSX, .CSV, .XLS',
    name: 'file',
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        setOrdersFileUploadStatus('loading');
        ordersFileValidation(file);
      }
    }
  };

  const uploadInventoryProps = {
    accept: '.csv, .xls, .xlsx, .XLSX, .CSV, .XLS',
    name: 'file',
    customRequest: dummyRequest,
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        setInventoryFileUploadStatus('loading');
        inventoryFileValidation(file);
      }
    }
  };

  const resetOrdersFileUpload = () => {
    setOrdersFile(undefined);
    setOrdersFileUploadStatus(undefined);
  };

  const resetInventoryFileUpload = () => {
    setInventoryFile(undefined);
    setInventoryFileUploadStatus(undefined);
  };

  const clearModalWindowState = () => {
    resetInventoryFileUpload();
    resetOrdersFileUpload();
    setOrdersForOverrides([]);
    setSelectedOrdersForOverride([]);
  };

  const sendUploadedFiles = () => {
    sendValidatedInventoryFile();
  };

  const EmptyTableText = (
    <>
      <div className={s.table_empty_state_title}>No items found</div>
      <div>Upload orders and inventory files</div>
    </>
  );

  const UploadButton = (
    <AlloyButton onClick={() => setUploadFileModalVisible(true)}>Upload</AlloyButton>
  );

  const UploadFilesFooter = (
    <div className={s.modal_footer}>
      <div>
        <AlloyButton onClick={() => setUploadFileModalVisible(false)}>Cancel</AlloyButton>
      </div>
      {ordersFileUploadStatus === 'override' ? (
        <div>
          <AlloyButton type={'primary'} onClick={() => sendUploadedFiles()}>
            Overwrite {selectedOrdersForOverride ? selectedOrdersForOverride.length : null} orders
          </AlloyButton>
        </div>
      ) : (
        <div>
          <AlloyButton
            type={'primary'}
            disabled={inventoryFileUploadStatus !== 'done' || ordersFileUploadStatus !== 'done'}
            onClick={() => sendUploadedFiles()}
          >
            Upload
          </AlloyButton>
        </div>
      )}
    </div>
  );

  const nextPage = async () => {
    setAfter(data?.whseOpenOrdersProcessed?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.whseOpenOrdersProcessed?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const ordersTableColumns: ColumnsType<WhseOpenOrdersProcessed> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: 'Order Number',
      key: 'ORDER_NUMBER',
      width: '175px',
      render: (_, item) => item.orderNumber,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'ORDER_NUMBER'),
      sorter: true
    },
    {
      title: 'Line Items',
      key: 'LINE_ITEMS',
      width: '118px',
      render: (_, item) => item.lineItems,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'LINE_ITEMS'),
      sorter: true
    },
    {
      title: 'Status',
      key: 'STATUS',
      width: '148px',
      render: (_, item) => formatStatus(item.status),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'STATUS'),
      sorter: true
    },
    {
      title: 'Order Date',
      key: 'ORDER_DATE',
      width: '148px',
      render: (_, item) => dateFormat(item.orderDate),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'ORDER_DATE'),
      sorter: true
    },
    {
      title: 'Suggested RDD',
      key: 'RDD',
      width: '148px',
      render: (_, item) => dateFormat(item.rdd),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'RDD'),
      sorter: true
    },
    {
      title: 'Ship To',
      key: 'SHIP_TO',
      render: (_, item) => item.shipTo,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SHIP_TO'),
      sorter: true
    },
    {
      title: 'Total Units',
      key: 'TOTAL_UNITS',
      width: '148px',
      align: 'right',
      render: (_, item) => <span className={s.number_value}>{item.totalUnits ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_UNITS'),
      sorter: true
    },
    {
      title: 'Total Available Qty',
      key: 'TOTAL_AVAILABLE_QTY',
      width: '147px',
      align: 'right',
      render: (_, item) => (
        <span className={s.number_value}>{item.totalAvailableQty ?? EMPTY}</span>
      ),
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_AVAILABLE_QTY'),
      sorter: true
    },
    {
      title: 'Total Discrepancy',
      key: 'TOTAL_DISCREPANCY',
      width: '148px',
      align: 'right',
      render: (_, item) => <span className={s.number_value}>{item.totalDiscrepancy ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'TOTAL_DISCREPANCY'),
      sorter: true
    }
  ];

  const overrideColumns: ColumnsType<{ orderNumber: string }> = [
    {
      title: 'ORDER NUMBER',
      key: 'orderNumber',
      dataIndex: 'orderNumber',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber)
    }
  ];

  const overrideTableData = ordersForOverrides.filter(notEmpty).map((duplicate) => {
    return { orderNumber: duplicate };
  });

  const openOrderMaterialColumns: ColumnsType<Materials> = [
    {
      width: '32px'
    },
    {
      title: 'Material ID',
      key: 'materialId',
      width: '175px',
      render: (_, item) => item.materialId
    },
    {
      width: '118px'
    },
    {
      title: 'Status',
      key: 'status',
      width: '148px',
      render: (_, item) => formatStatus(item.status)
    },
    {
      title: 'Description',
      key: 'description',
      render: (_, item) => item.description
    },
    {
      title: 'Units',
      key: 'units',
      align: 'right',
      width: '148px',
      render: (_, item) => <span className={s.number_value}>{item.units ?? EMPTY}</span>
    },
    {
      title: 'Available Qty',
      key: 'availableQty',
      align: 'right',
      width: '147px',
      render: (_, item) => <span className={s.number_value}>{item.availableQty ?? EMPTY}</span>
    },
    {
      title: 'Discrepancy',
      key: 'discrepancy',
      align: 'right',
      width: '148px',
      render: (_, item) => <span className={s.number_value}>{item.discrepancy ?? EMPTY}</span>
    }
  ];

  const currentPageOrdersExpandableKeys = useMemo(() => {
    return openOrdersList.map(getRowKey);
  }, [openOrdersList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageOrdersExpandableKeys.every((order) => expandedRowKeys.includes(order)),
    [expandedRowKeys, currentPageOrdersExpandableKeys]
  );

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <AlloySpin spinning={processOrdersAndInventoryLoading}>
        <div className={s.header}>
          <div data-testid="page-title" className={s.page_title}>
            <PageHeader className={s.page_title}>Warehouse Order Processing</PageHeader>
            <LastUpdated />
          </div>
        </div>

        <div className={s.page_toggle}>
          <AlloySegmented
            options={tabs}
            value={mode as DisplayMode}
            onChange={(value: DisplayMode) => setMode(value)}
          />
        </div>

        <div data-testid="table-tools-container" className={s.table_tools_container}>
          <div data-testid="search-and-filters-container" className={s.left}>
            <div className={s.search_bar}>
              <AlloyInput
                placeholder="Search"
                allowClear={true}
                prefix={<SearchOutlined width="14px" height="14px" />}
                disabled={true} //TODO: enable in a separate ticket when we build this functionality - do we want to hide it altogether?
              />
            </div>
            <AlloySelect
              className={clsx(s.dc_select, s.select)}
              placeholder="Filter"
              allowClear
              options={[
                {
                  key: 'all',
                  value: 'all',
                  label: 'All'
                }
              ]}
              disabled={true} //TODO: filter to be implemented in a future ticket
            />
          </div>

          {modeWithType === 'orders' && (
            <div data-testid="table-actions-container" className={s.table_actions_container}>
              <AlloyButton type={'primary'} onClick={() => setUploadFileModalVisible(true)}>
                Upload
              </AlloyButton>
            </div>
          )}
        </div>

        {modeWithType === 'orders' && (
          <>
            <div className={s.quantity_display_and_paginator_container}>
              <div>
                <QuantityDisplay
                  count={
                    selectedRows?.length > 0
                      ? selectedRows.length
                      : data?.whseOpenOrdersProcessed?.totalCount
                  }
                  titleSingular={`${selectedRows?.length > 0 ? 'Selected ' : ''} Order`}
                  titleMultiple={`${selectedRows?.length > 0 ? 'Selected ' : ''} Orders`}
                />
              </div>
              <div>
                <Paginator
                  // pageSize={limit}
                  pageSize={pageSize || ORDERS_DEFAULT_PAGE_SIZE}
                  hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
                  hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
                  handlePageSizeChange={handlePageSizeChange}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  onlyButtons={false}
                />
              </div>
            </div>

            <AlloyTable
              data-testid="open-orders-table"
              className={s.open_orders_table}
              loading={loading}
              scroll={{ x: TABLE_WIDTH }}
              sticky
              pagination={false}
              columns={ordersTableColumns.filter(notEmpty)}
              dataSource={openOrdersList}
              expandable={{
                //for future use of the chevron expand button component
                // columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
                //   <ExpandChevronButton
                //     expanded={areAllKeysExpandedOnThePage}
                //     onClick={(expanded) => {
                //       expanded
                //         ? setExpandedRowKeys(
                //             expandedRowKeys.filter(
                //               (x) => !currentPageOrdersExpandableKeys.includes(x)
                //             )
                //           )
                //         : setExpandedRowKeys([
                //             ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                //           ]);
                //     }}
                //     collapseMessage="Collapse all"
                //     expandMessage="Expand all"
                //   />
                // ),
                // expandIcon: ({ expanded, onExpand, record }) => (
                //   <RightOutlined
                //     data-testid="expand-row-icon"
                //     className={clsx(s.expand_row_icon, { [s.expanded_row_icon]: expanded })}
                //     onClick={(e) => onExpand(record, e)}
                //   />
                // ),
                columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
                  <ExpandButton
                    expanded={areAllKeysExpandedOnThePage}
                    onClick={(expanded) => {
                      return expanded
                        ? setExpandedRowKeys(
                            expandedRowKeys.filter(
                              (x) => !currentPageOrdersExpandableKeys.includes(x)
                            )
                          )
                        : setExpandedRowKeys([
                            ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                          ]);
                    }}
                    collapseMessage="Collapse all"
                    expandMessage="Expand all"
                  />
                ),
                //for future use of the chevron expand buttons
                // expandIcon: ({ expanded, onExpand, record }) => (
                //   <RightOutlined
                //     data-testid="expand-row-icon"
                //     className={clsx(s.expand_row_icon, { [s.expanded_row_icon]: expanded })}
                //     onClick={(e) => onExpand(record, e)}
                //   />
                // ),
                expandedRowKeys: expandedRowKeys,
                expandedRowRender: ({ materials }) => (
                  <AlloyTable //TODO: table header color change if possible
                    loading={loading}
                    data-testid="open-order-materials-table"
                    className={s.open_order_materials_table}
                    //sticky //TODO: understand if this is needed -- should probably be over a certain number of rows only
                    columns={openOrderMaterialColumns.filter(notEmpty)}
                    dataSource={materials?.map((material) => material).filter(notEmpty)}
                    pagination={false}
                    rowKey={getExpandedRowKey}
                  />
                ),
                onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
                rowExpandable: ({ materials }) => !!materials
              }}
              onChange={(_pagination, _filters, sorter) => {
                const column =
                  String((sorter as SorterResult<WhseOpenOrdersProcessed>).columnKey) || 'NAME'; //TODO: do I need to change this to SCHIP_TO?
                const order =
                  (sorter as SorterResult<WhseOpenOrdersProcessed>).order === 'ascend' ||
                  !(sorter as SorterResult<WhseOpenOrdersProcessed>).order
                    ? 'ASC'
                    : 'DESC';
                handleTableSorting(column, order);
              }}
              rowKey={getRowKey}
              rowSelection={{
                preserveSelectedRowKeys: true,
                onChange: (_keys, rows) => {
                  setSelectedRows(rows);
                },
                type: 'checkbox',
                selectedRowKeys: selectedRows?.map((row) => row.orderNumber),
                // @ts-ignore
                getCheckboxProps(record) {
                  return { 'data-testid': record.orderNumber };
                }
              }}
              locale={{
                emptyText: (
                  <Empty className={s.table_empty_state} description={EmptyTableText}>
                    {UploadButton}
                  </Empty>
                )
              }}
            />

            <div className={s.bottom_paginator}>
              <Paginator
                // pageSize={limit}
                pageSize={pageSize || ORDERS_DEFAULT_PAGE_SIZE}
                hasNextPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasNextPage}
                hasPreviousPage={!!data?.whseOpenOrdersProcessed?.pageInfo.hasPreviousPage}
                handlePageSizeChange={handlePageSizeChange}
                prevPage={prevPage}
                nextPage={nextPage}
                onlyButtons={true}
              />
            </div>
          </>
        )}

        {modeWithType === 'materials' && <MaterialsView />}

        <AlloyModal
          title="Upload Orders & Inventory"
          className={s.upload_file_modal}
          open={uploadFileModalVisible}
          afterClose={() => clearModalWindowState()}
          onCancel={() => setUploadFileModalVisible(false)}
          destroyOnClose
          classNames={{
            header: s.modal_header,
            body: s.modal_body,
            content: s.modal_content
          }}
          footer={UploadFilesFooter}
        >
          {ordersFileUploadStatus === 'override' ? (
            <div>
              <div className={s.duplicates_alert}>
                <AlloyAlert
                  type={'warning'}
                  message={`${ordersForOverrides.length} duplicates detected`}
                />
              </div>
              <div className={s.duplicates_description}>
                Select the orders numbers you would like to overwrite with the new data uploaded.
              </div>
              <div>
                <div>
                  <AlloyTable
                    columns={overrideColumns}
                    dataSource={overrideTableData}
                    rowKey={(record) => record.orderNumber}
                    rowSelection={{
                      type: 'checkbox',
                      onSelectAll: (selected, selectedRows) => {
                        setSelectedOrdersForOverride(selectedRows.map((row) => row.orderNumber));
                      },
                      selectedRowKeys: selectedOrdersForOverride,
                      onChange: (_keys, rows) => {
                        setSelectedOrdersForOverride(rows.map((row) => row.orderNumber));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={s.upload_file_modal_description}>
                Upload SAP orders and/or Inventory exports
              </div>
              <section className={s.upload_section}>
                <div className={s.upload_section_title}>Files to upload</div>
                <div className={s.upload_section_element}>
                  <img
                    className={s.upload_section_element_icon}
                    alt="upload icon"
                    src={UploadIcon}
                  />
                  {inventoryFileUploadStatus === 'done' ||
                  inventoryFileUploadStatus === 'loading' ? (
                    <>
                      <div className={s.upload_section_element_description}>
                        {inventoryFile?.name}
                      </div>
                      {inventoryFileUploadStatus === 'loading' ? (
                        <div>
                          <AlloySpin /> Uploading
                        </div>
                      ) : null}
                      <div>
                        <img
                          alt="cancel icon"
                          className={s.cancel_icon}
                          onClick={() => resetInventoryFileUpload()}
                          src={CancelIcon}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={s.upload_section_element_description}>
                        Upload inventory
                        <div>Support .csv, .xls, .xlsx only</div>
                      </div>
                      <AlloyUpload {...uploadInventoryProps}>
                        <AlloyButton type={'link'}>Inventory export</AlloyButton>
                      </AlloyUpload>
                    </>
                  )}
                </div>
                <div className={s.upload_section_element}>
                  <img
                    className={s.upload_section_element_icon}
                    alt="upload icon"
                    src={UploadIcon}
                  />
                  {ordersFileUploadStatus === 'done' || ordersFileUploadStatus === 'loading' ? (
                    <>
                      <div className={s.upload_section_element_description}>{ordersFile?.name}</div>
                      {ordersFileUploadStatus === 'loading' ? (
                        <div>
                          Uploading <LoadingOutlined />
                        </div>
                      ) : null}
                      <div>
                        <img
                          className={s.cancel_icon}
                          alt="cancel icon"
                          onClick={() => resetOrdersFileUpload()}
                          src={CancelIcon}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={s.upload_section_element_description}>
                        Upload SAP orders
                        <div>Support .csv, .xls, .xlsx only</div>
                      </div>
                      <AlloyUpload {...uploadOrdersProps}>
                        <AlloyButton disabled={inventoryFileUploadStatus !== 'done'} type={'link'}>
                          SAP orders
                        </AlloyButton>
                      </AlloyUpload>
                    </>
                  )}
                </div>
              </section>
            </>
          )}
        </AlloyModal>
      </AlloySpin>
    </>
  );
};
