import React from 'react';
import { Table as AntTable } from 'ant5';
import type { TableProps, ColumnType, ColumnsType, TableRef, ColumnGroupType } from 'ant5/es/table';
import type {
  SorterResult,
  FilterValue,
  TablePaginationConfig,
  SortOrder
} from 'ant5/es/table/interface';
import clsx from 'clsx';
import s from './AlloyTable.module.scss';

// Taken from ant design implements
type AnyObject = Record<PropertyKey, any>;
type AlloyTableProps<T extends AnyObject> = Omit<TableProps<T>, 'bordered'> & {
  bordered?: boolean | 'outer-only';
  //Ref should be defined here, since we will be ignoring forwarRef types
  ref?: React.ForwardedRef<TableRef>;
};

const AlloyTableBase = <T extends AnyObject>(
  {
    onHeaderRow: userOnHeaderRow,
    rowClassName: userRowClassName,
    className: userClassName,
    bordered: userBordered = 'outer-only',
    ...otherProps
    //There is no ref here, since we get it from forwardRef
  }: AlloyTableProps<T>,
  ref?: React.ForwardedRef<TableRef>
) => {
  const onHeaderRow: NonNullable<TableProps<T>['onHeaderRow']> = React.useCallback(
    (columns, index) => {
      const userHeaderProps = userOnHeaderRow ? userOnHeaderRow(columns, index) : {};
      return {
        ...userHeaderProps,
        className: clsx(s.header, userHeaderProps.className)
      };
    },
    [userOnHeaderRow]
  );

  const rowClassName: NonNullable<TableProps<T>['rowClassName']> = React.useCallback(
    (columns, index, indent) => {
      const userRowClassNameString =
        typeof userRowClassName === 'function'
          ? userRowClassName(columns, index, indent)
          : userRowClassName;
      return clsx(s.row, userRowClassNameString);
    },
    [userRowClassName]
  );

  const bordered = typeof userBordered === 'boolean' ? userBordered : false;
  return (
    <AntTable<T>
      {...otherProps}
      ref={ref}
      onHeaderRow={onHeaderRow}
      bordered={bordered}
      className={clsx({ [s.outerBorder]: userBordered === 'outer-only' }, userClassName)}
      rowClassName={rowClassName}
    />
  );
};

export const AlloyTable = React.forwardRef(AlloyTableBase) as unknown as typeof AlloyTableBase & {
  SELECTION_COLUMN: typeof AntTable.SELECTION_COLUMN;
  EXPAND_COLUMN: typeof AntTable.EXPAND_COLUMN;
  Summary: typeof AntTable.Summary;
};

AlloyTable.SELECTION_COLUMN = AntTable.SELECTION_COLUMN;
AlloyTable.Summary = AntTable.Summary;
AlloyTable.EXPAND_COLUMN = AntTable.EXPAND_COLUMN;

// Re-exporting types
export type {
  ColumnType,
  ColumnsType,
  ColumnGroupType,
  SorterResult,
  SortOrder,
  FilterValue,
  TablePaginationConfig,
  TableRef,
  AnyObject,
  AlloyTableProps
};
