import React, { useMemo } from 'react';
import { range } from 'lodash-es';
import clsx from 'clsx';
import { EMPTY } from 'common/constants';
import { Metrics } from '../types';
import { groupBy } from 'lodash-es';
import {
  isCorrectPeriod,
  transformCalendarValueToWholeYear
} from 'components/PeriodCalendar/helpers';
import { ExecutiveSummaryShipmentOnTimeRateTrendDocument } from '../gql/__generated__/executiveSummaryShipmentOnTimeRateTrend.query';
import { useQuery } from '@apollo/client';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyTable, ColumnType } from 'components/ui/AlloyTable/AlloyTable';

type PeriodKey = `period${number}`;

type TableRecord = {
  year: number;
} & Partial<Record<PeriodKey, number>>;

function transformMetricsToTableRecords(metrics: Metrics): TableRecord[] {
  const grouped = groupBy(metrics, 'year');
  const result: TableRecord[] = [];

  for (const year in grouped) {
    const currentYear = grouped[year];
    const current = {} as TableRecord;
    current.year = parseInt(year);
    for (const record of currentYear) {
      if (record.period && isCorrectPeriod(record.period)) {
        current[`period${record.period}`] = parseFloat(record.onTimeShipmentRate);
      }
    }
    result.push(current);
  }
  return result;
}

const periods: ColumnType<TableRecord>[] = range(1, 14).map((x) => ({
  title: `P${x}`,
  width: 70,
  render: (_, record) =>
    record[`period${x}`] !== null && typeof record[`period${x}`] !== 'undefined'
      ? record[`period${x}`] + '%'
      : EMPTY
}));

const year: ColumnType<TableRecord> = {
  title: 'YEAR',
  width: 50,
  render: (_, record) => record.year
};

const columns = [year, ...periods];

export const OTSTable = ({
  period,
  businessUnitIds,
  vendorMarketIds
}: {
  period: CalendarValue;
  businessUnitIds: string[];
  vendorMarketIds: string[];
}) => {
  const { data, loading } = useQuery(ExecutiveSummaryShipmentOnTimeRateTrendDocument, {
    variables: {
      filters: {
        businessUnitIds,
        vendorMarketIds,
        fiscalCalendarWeeks: transformCalendarValueToWholeYear(period)
      },
      aggregateBy: 'PERIOD'
    }
  });

  const metrics = useMemo(
    () =>
      transformMetricsToTableRecords(
        (data?.executiveSummaryShipmentOnTimeRateTrend?.metrics || []).filter(notEmpty)
      ),
    [data]
  );

  return (
    <AlloyTable
      data-testid="at_risk_orders_table"
      rowKey={(x) => x.year}
      className={clsx(`styled_report_table`)}
      style={{
        marginTop: '30px',
        marginBottom: '30px'
      }}
      size="small"
      dataSource={metrics}
      columns={columns}
      pagination={false}
      bordered
      loading={loading}
    />
  );
};
