import { useMutation } from '@apollo/client';
import React from 'react';
import { useState } from 'react';
import { TradingPartnerAssortment } from '../../AssortmentPage';
import { BulkUpdateVendorProductDtcInventoryReserveDocument } from './gql/__generated__/bulkUpdateVendorProductDtcInventoryReserve.mutation';
import { TradingPartnerAssortmentsDocument } from 'pages/AssortmentPage/gql/__generated__/tradingPartnerAssortments.query';
import { App } from 'ant5';
import { AlloyInputNumber } from 'components/ui/AlloyInputNumber/AlloyInputNumber';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import s from './ReserveBulkUpdateModal.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

interface ReserveBulkUpdateModalProps {
  selectedRows: TradingPartnerAssortment[];
  visible: boolean;
  close: (clearSelection: boolean) => void;
  tradingPartnerId: string;
  vendorMarketId: string;
}

export const ReserveBulkUpdateModal = ({
  selectedRows,
  visible,
  close,
  vendorMarketId,
  tradingPartnerId
}: ReserveBulkUpdateModalProps) => {
  const [reserve, setReserve] = useState(0);

  const { message } = App.useApp();

  const [bulkUpdateInventoryReserve, { loading }] = useMutation(
    BulkUpdateVendorProductDtcInventoryReserveDocument,
    {
      refetchQueries: [TradingPartnerAssortmentsDocument]
    }
  );

  return (
    <AlloyModal
      open={visible}
      title="Reserve changing"
      classNames={{
        content: s.reserve_bulk_update_modal,
        body: s.reserve_bulk_update_modal_body,
        footer: s.reserve_bulk_update_modal_footer,
        header: s.reserve_bulk_update_modal_header
      }}
      onCancel={() => close(false)}
      footer={
        <>
          <AlloyButton size="large" onClick={() => close(false)}>
            Cancel
          </AlloyButton>
          <AlloyButton
            size="large"
            type="primary"
            onClick={async () => {
              const result = await bulkUpdateInventoryReserve({
                variables: {
                  input: {
                    tradingPartnerId: tradingPartnerId,
                    vendorMarketId: vendorMarketId,
                    vendorProductDtcInventoryReserve: reserve,
                    vendorProductIds: selectedRows.map((row) => row.retailerProductId)
                  }
                }
              });
              if (result.data?.bulkUpdateVendorProductDtcInventoryReserve?.ok) {
                message.success('Reserve was successfully changed');
              } else {
                // console.error(message.error); // go to parent to console log error
                message.error("Reserve wasn't changed");
              }
              close(true);
              setReserve(0);
            }}
          >
            Ok
          </AlloyButton>
        </>
      }
    >
      <AlloySpin spinning={loading}>
        <AlloyRow style={{ alignItems: 'baseline' }}>
          Change To:
          <AlloyInputNumber
            style={{ marginLeft: '16px' }}
            min={0}
            value={reserve}
            onChange={(value) => setReserve(Math.round(value ?? 0))}
          />
        </AlloyRow>
      </AlloySpin>
    </AlloyModal>
  );
};
