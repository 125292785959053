import React from 'react';
import { useMutation } from '@apollo/client';
import { App } from 'ant5';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import moment, { Moment } from 'moment';
import { UpdateSalesOrderShipmentMabdDocument } from './gql/__generated__/updateSalesOrderShipmentMABD.mutation';
import { AlloyDatePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';

export const SalesOrderShipmentDateField = ({
  salesOrderId,
  deliveryWindowEnd
}: {
  salesOrderId: string;
  deliveryWindowEnd: Moment | undefined;
}) => {
  const { message } = App.useApp();

  const [updateSalesOrderDate, { loading }] = useMutation(UpdateSalesOrderShipmentMabdDocument, {
    onCompleted: (data) => {
      message.success('Sales Order Date was successfully changed');
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`Error happened during Sales Order Date changing: ${error.message}`);
    }
  });

  return (
    <AlloySpin spinning={loading}>
      <AlloyDatePicker
        size={'small'}
        placeholder=""
        allowClear={false}
        value={!loading ? deliveryWindowEnd : undefined}
        format="M/D/YY"
        onClick={(e) => {
          e.preventDefault();
        }}
        onChange={(date) => {
          updateSalesOrderDate({
            variables: {
              input: {
                id: salesOrderId,
                mustArriveByDate: date ? moment(date as Moment).format('YYYY-MM-DD') : ''
              }
            }
          });
        }}
      />
    </AlloySpin>
  );
};
