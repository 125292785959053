import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, FormInstance } from 'ant5';
import s from '../ActiveAssortmentTab.module.scss';
import { FormField } from 'pages/TradingPartnersPage/components/EditTradingPartnerModal/EditTradingPartnerModal';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof (FormField & { arrayName: string });
  record: FormField & { arrayName: string };
  handleSave: (arrayName: string, newValue: string) => void;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = (props) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave(record.arrayName, values.name);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <AlloyInput
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          data-testid={`edit-trading-partner-${record.slug?.toLowerCase()}-name-edit`}
        />
      </Form.Item>
    ) : (
      <div className={s.editable_cell_value_wrap} onClick={toggleEdit}>
        {children}
        <div
          className={s.edit_text}
          data-testid={`edit-trading-partner-${record.slug?.toLowerCase()}-click-to-edit-button`}
        >
          Click to edit
        </div>
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
