import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SaveInventorySpreadsheetMutationVariables = Types.Exact<{
  input: Types.SaveInventorySpreadsheetInput;
}>;


export type SaveInventorySpreadsheetMutation = { __typename?: 'RootMutationType', saveInventorySpreadsheet?: { __typename?: 'SaveInventorySpreadsheetPayload', message: string, uploaded: number } | null };


export const SaveInventorySpreadsheetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"saveInventorySpreadsheet"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SaveInventorySpreadsheetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"saveInventorySpreadsheet"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"uploaded"}}]}}]}}]} as unknown as DocumentNode<SaveInventorySpreadsheetMutation, SaveInventorySpreadsheetMutationVariables>;