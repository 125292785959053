import React, { useState, useMemo } from 'react';
import s from './SKUSScenarioPlanning.module.scss';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Select, Tooltip, Switch, Button, Spin } from 'antd';
import {
  GetTradingPartnerDocument,
  GetGtinDataDocument,
  GetDCsDocument
} from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioCreate.query';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ScenarioConstraintsData } from './SKUScenariosConstraints';
import {
  invConstDcCodes,
  invConstExternalId,
  dcSourceName
} from 'pages/InventoryOptimizationPage/inventoryOptimizationConstants';
import { notEmpty } from 'common/helpers/notEmpty';
interface skusContainsType {
  key: React.Key;
  skuNumber: string | null | undefined;
  gtin14ProductId: number | null | undefined;
  tpExternalId: string;
  productInfo: string | null | undefined;
  selected: boolean;
  scenarioConstraintDcDetails?: (number | null)[] | null | undefined;
  scenarioConstraintExistingDcDetails: (number | null)[] | null | undefined;
  currentDC?: (string | null | undefined)[] | null | undefined;
  scenarioDC?: (string | null | undefined)[] | undefined;
  defaultDC?: (string | null | undefined)[] | null | undefined;
}
type tradingPartnerOptiontype = {
  id: string | null | undefined;
  dcId?: number | null | undefined;
  value: string | null | undefined;
  label: string | null | undefined;
  tpExternalId?: string | undefined;
};

interface ScenarioConstraintsProps {
  handleSelectedSkusList: (updatedSelectedSkusState: ScenarioConstraintsData[]) => void;
  sharedGroupSkusList: ScenarioConstraintsData[];
}

const tradingPartnersArray = invConstExternalId.TP_EXTERNAL_ID;
const dcCodeNames = invConstDcCodes.DC_CODES.map((dcCode) => {
  return {
    code: dcCode,
    source: dcSourceName
  };
});

const SKUSScenarioPlanning: React.FC<ScenarioConstraintsProps> = ({
  handleSelectedSkusList,
  sharedGroupSkusList
}) => {
  const [allSkusList, setAllSkusList] = useState<skusContainsType[] | undefined>();
  const [allGtinsDcs, setAllGtinsDcs] = useState<skusContainsType[] | undefined>();
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(true);
  const [searchSKU, setSearchSKU] = useState<string>('');
  const [selectedWebsiteName, setSelectedWebsiteName] = useState<string | null>(null);
  const [selectedSkusList, setSelectedSkusList] = useState<string[] | undefined>(
    allSkusList?.map((allSkus) => (allSkus.key ? allSkus.key.toString() : ''))
  );
  const [showGroupConstraints, setShowGroupConstraints] = useState(false);
  const [selectedGroupConstraintDc, setSelectedGroupConstraintDc] = useState<string[]>([]);
  const [isSelectedRemove, setIsSelectedRemove] = useState(false);
  const [dcOption, setDcOption] = useState<tradingPartnerOptiontype[]>();

  const { data: marketPlaceData, loading: marketPlaceLoading } = useQuery(
    GetTradingPartnerDocument,
    {
      variables: { tpExternalIds: tradingPartnersArray }
    }
  );
  const AllWebsitesOptions = useMemo(
    () =>
      marketPlaceData?.getTradingPartners?.map((data) => {
        return {
          id: data?.id,
          value: data?.id,
          label: data?.name,
          tpExternalId: data?.externalId
        };
      }),
    [marketPlaceData]
  );

  const [fetchDCs, { loading: dcLoading }] = useLazyQuery(GetDCsDocument);

  const [fetchGTINDCs, { loading: gtinDcloading }] = useLazyQuery(GetGtinDataDocument);

  const getFilteredScenarioDC = (
    selectedDC: string[],
    scenarioDC?: (string | null | undefined)[] | null | undefined
  ) => {
    return scenarioDC?.filter((item) => item && !selectedDC.includes(item)) || [];
  };

  const selectedGroupSkusList = (isGroup = true) => {
    const _sharedGroupSkusList = [...sharedGroupSkusList];
    allSkusList
      ?.filter((sku) => sku.key && selectedSkusList?.includes(sku.key.toString()))
      .forEach((sku) => {
        const existingEntryIndex = _sharedGroupSkusList.findIndex(
          (entry) => entry.key === sku.key && entry.websiteName === selectedWebsiteName
        );

        const scenarioDC = isGroup
          ? isSelectedRemove
            ? existingEntryIndex !== -1
              ? getFilteredScenarioDC(
                  selectedGroupConstraintDc,
                  sharedGroupSkusList[existingEntryIndex]?.scenarioDC
                )
              : getFilteredScenarioDC(selectedGroupConstraintDc, sku.defaultDC)
            : existingEntryIndex !== -1
              ? [
                  ...new Set([
                    ...selectedGroupConstraintDc,
                    ...(sharedGroupSkusList[existingEntryIndex]?.scenarioDC ?? [])
                  ])
                ]
              : [...new Set([...selectedGroupConstraintDc, ...(sku.defaultDC ?? [])])]
          : sku.defaultDC || [];
        const updatedSku = {
          ...sku,
          websiteName: selectedWebsiteName,
          scenarioDC,
          scenarioConstraintDcDetails: getDcIds(scenarioDC, dcOption),
          currentDC: sku.currentDC
        };

        if (existingEntryIndex !== -1) {
          _sharedGroupSkusList[existingEntryIndex] = { ...updatedSku };
        } else {
          _sharedGroupSkusList.push({ ...updatedSku });
        }
      });

    handleSelectedSkusList([..._sharedGroupSkusList]);
    setSelectedWebsiteName(null);
    setShowGroupConstraints(false);
  };

  const groupSelectedBtnHandle = () => {
    setShowGroupConstraints(false);
    setSelectedWebsiteName(null);
  };

  const handleSearchSku = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchSKU(query);

    const filterSKUs = allGtinsDcs?.filter(
      (item) =>
        item?.productInfo?.toLowerCase().includes(query.toLowerCase()) ||
        item?.skuNumber?.toString().includes(query)
    );
    setAllSkusList(filterSKUs);
  };

  const handleCheckBox = (key: React.Key) => {
    const updatedCheckboxes = allSkusList?.map((checkbox) =>
      checkbox.key === key ? { ...checkbox, selected: !checkbox.selected } : checkbox
    );

    setAllSkusList(updatedCheckboxes);

    setSelectAllCheckbox(
      updatedCheckboxes ? updatedCheckboxes.every((checkbox) => checkbox.selected) : true
    );

    setSelectedSkusList(
      updatedCheckboxes
        ?.filter((filtercheckbox) => filtercheckbox?.selected)
        .map((selectValue) => (selectValue.key ? selectValue.key.toString() : ''))
    );
  };

  const handleSelectAllCheckbox = () => {
    const updatedCheckboxes = allSkusList?.map((allCheckbox) => ({
      ...allCheckbox,
      selected: !selectAllCheckbox
    }));

    setAllSkusList(updatedCheckboxes);
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  const getDcNames = (
    keys: (number | null)[] | null | undefined,
    data: tradingPartnerOptiontype[] | undefined
  ): (string | null | undefined)[] | undefined => {
    return keys?.filter(notEmpty).map((key) => {
      const matchingObject = data?.find((obj) => obj.dcId === key);
      return matchingObject && matchingObject.label;
    });
  };
  const getDcIds = (
    keys: (string | null | undefined)[] | undefined,
    data: tradingPartnerOptiontype[] | undefined
  ): (number | null)[] | undefined => {
    return keys?.filter(notEmpty).map((key) => {
      const matchingObject = data?.find((obj) => obj.value === key);
      return matchingObject ? Number(matchingObject.dcId) : null;
    });
  };
  function getMatchingDcIds(
    dcids: (number | null)[] | null | undefined,
    dcList: tradingPartnerOptiontype[] | undefined
  ): (number | null)[] | undefined {
    const dcListDcids = dcList?.map((dc) => dc.dcId).filter(notEmpty);
    const matchingDcids = dcids?.filter((dcid) => dcid && dcListDcids?.includes(dcid));
    return matchingDcids;
  }

  const handleWebsiteChange = async (value: string) => {
    const valueArray = value.split('/');
    setSelectedWebsiteName(valueArray[1]);

    const { data: gtinDCs } = await fetchGTINDCs({ variables: { tpIds: [valueArray[0]] } });
    const { data: dcData } = await fetchDCs({
      variables: { dcCodes: dcCodeNames }
    });

    const dcList = dcData?.getDcs?.map((dc) => {
      return {
        id: dc?.distributionCenterCode,
        dcId: dc?.distributionCenterId,
        value: dc?.distributionCenterName,
        label: dc?.distributionCenterName
      };
    });
    const allSKUs =
      gtinDCs &&
      gtinDCs?.getGtin14Dcs?.map((gtinDC, index) => {
        return {
          key: `${index}+${gtinDC?.gtin14ProductId}+${gtinDC?.gtin14}`,
          gtin14ProductId: gtinDC?.gtin14ProductId,
          skuNumber: gtinDC?.gtin14,
          productInfo: gtinDC?.gtin14ProductDescription,
          selected: true,
          scenarioConstraintExistingDcDetails: getMatchingDcIds(
            gtinDC?.distributionCenterIds,
            dcList
          ),
          currentDC: getDcNames(gtinDC?.distributionCenterIds, dcList)?.filter(notEmpty),
          defaultDC: getDcNames(gtinDC?.distributionCenterIds, dcList)?.filter(notEmpty),
          tpExternalId: valueArray[2]
        };
      });
    setAllGtinsDcs(allSKUs);
    setAllSkusList(allSKUs);
    setDcOption(dcList);
    setSelectedSkusList(allSKUs?.map((allSkus) => (allSkus.key ? allSkus?.key?.toString() : '')));
    setSelectedGroupConstraintDc([]);
    setSelectAllCheckbox(true);
  };

  const dcHandleChange = (selectedDcValues: (string | null | undefined)[], key: React.Key) => {
    const skus = allSkusList ? [...allSkusList] : [];
    const skuIndex = skus.findIndex((sku) => sku.key === key);
    skus[skuIndex].defaultDC = selectedDcValues;
    setAllSkusList(skus);
  };

  const gruopDCHandleChange = (selectedDC: string[]) => {
    setSelectedGroupConstraintDc(selectedDC);
  };

  const groupSKUSHandleChange = (selectedDcValues: string[]) => {
    setSelectedSkusList(selectedDcValues);
  };

  const groupConstraintApplyBtnHandle = () => {
    setShowGroupConstraints(true);
  };

  const cancelBtnHandle = () => {
    setShowGroupConstraints(false);
  };

  const resetHandleChange = () => {
    setSelectedWebsiteName(null);
    setSelectedGroupConstraintDc([]);
    setShowGroupConstraints(false);
    setSearchSKU('');
  };

  const handleSwitch = () => {
    setIsSelectedRemove(!isSelectedRemove);
  };

  const handleApplyGroupConstraints = () => {
    selectedGroupSkusList(true);
    if (isSelectedRemove) {
      setIsSelectedRemove(!isSelectedRemove);
    } else {
      return;
    }
  };

  return (
    <div className={s.allSKUsInfoContainer} data-testid="inv-createScenario-applyConstraints-table">
      <div className={s.fixedElements}>
        <div className={s.applyConstraintDiv}>
          <span data-testid="inv-constraints-table-label">Apply Constraints</span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={resetHandleChange}
            data-testid="inv-constraints-filter-reset"
          >
            <UndoOutlined style={{ marginRight: '3px' }} rotate={90} />
            Reset
          </span>
        </div>

        <div className={s.selectorStyle} data-testid="inv-scenarioPlanning-selectTPField">
          <Select
            placeholder="Select Trading Partner"
            loading={marketPlaceLoading}
            onChange={handleWebsiteChange}
            value={selectedWebsiteName}
            style={{ width: '100%' }}
          >
            {AllWebsitesOptions &&
              AllWebsitesOptions.map((listItems) => {
                return (
                  <Select.Option
                    key={`${listItems.value}/${listItems.label}/${listItems.tpExternalId}`}
                  >
                    {listItems.label}
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        {!showGroupConstraints && (
          <div className={s.filterContainer}>
            <div className={s.skusSearchContainer}>
              <SearchOutlined />
              <input
                data-testid="inv-scenarioPlanning-searchInputFiled"
                placeholder="Search with GTIN & description"
                className={s.skusInputValue}
                value={searchSKU}
                onChange={handleSearchSku}
              />
            </div>
          </div>
        )}

        {selectedWebsiteName && !showGroupConstraints && (
          <div className={s.allSKUsSelectedCheckbox}>
            <div className={s.allSKUsSelect}>
              <input
                data-testid="inv-scenarioPlanning-skusParentCheckbox"
                type="checkbox"
                checked={selectAllCheckbox}
                onChange={handleSelectAllCheckbox}
              />
              <span data-testid="inv-scenarioPlanning-parentCheckboxSkus">
                {allSkusList?.length} GTINs
              </span>
            </div>
            {(gtinDcloading || dcLoading) && <Spin />}
            <div className={s.applyFilterBtn} onClick={groupConstraintApplyBtnHandle}>
              Apply Group Constraints
            </div>
          </div>
        )}
        {showGroupConstraints && <div className={s.groupTitle}>Apply Group Constraints</div>}
      </div>

      {showGroupConstraints && (
        <div>
          <Select
            className={s.selectorStyle}
            value={selectedSkusList}
            placeholder="Select"
            onChange={groupSKUSHandleChange}
            mode="multiple"
            showArrow
            maxTagCount={1}
            style={{ width: '100%' }}
          >
            {allSkusList?.map((skusOptions) => {
              return (
                <Select.Option value={skusOptions?.key?.toString()} key={skusOptions.key}>
                  {skusOptions.skuNumber}
                </Select.Option>
              );
            })}
          </Select>

          <Select
            className={s.selectorStyle}
            placeholder="Select"
            onChange={gruopDCHandleChange}
            mode="multiple"
            value={selectedGroupConstraintDc}
            showArrow
            maxTagCount={1}
            style={{ width: '100%' }}
          >
            {dcOption?.map((DCoption) => {
              return (
                <Select.Option value={DCoption.value} key={DCoption.id}>
                  {DCoption.label}
                </Select.Option>
              );
            })}
          </Select>

          <div className={s.groupConstraints}>
            <div className={s.toggleButton}>
              <p className={!isSelectedRemove ? s.addRemove : s.untoggled}>Add</p>
              <Switch style={{ marginTop: '8px', textAlign: 'right' }} onClick={handleSwitch} />
              <p className={isSelectedRemove ? s.addRemove : s.untoggled}>Remove</p>
            </div>
            <div className={s.cancelandapplybtn}>
              <button onClick={cancelBtnHandle}> Cancel</button>
              <Button type="primary" onClick={handleApplyGroupConstraints}>
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}

      {selectedWebsiteName && !showGroupConstraints && (
        <>
          <div className={s.scrollableElements}>
            {allSkusList?.map((skuDetails) => (
              <div
                data-testid="inv-scenarioPlanning-skusContentsDetails"
                key={skuDetails.key}
                className={s.skusContainer}
              >
                <div className={s.skusNumbersdetail}>
                  <div className={s.numberDetails}>
                    <input
                      data-testid="inv-scenarioPlanning-skusChildCheckbox"
                      type="checkbox"
                      checked={skuDetails.selected || selectAllCheckbox}
                      onChange={() => skuDetails.key && handleCheckBox(skuDetails.key)}
                    />
                    <span data-testid="inv-scenarioPlanning-skusContentCheckboxId">
                      {skuDetails.skuNumber}
                    </span>
                  </div>

                  <div className={s.dcMultiSelectStyle}>
                    <Select
                      placeholder="Select"
                      onChange={(selectedValue) =>
                        skuDetails.key && dcHandleChange(selectedValue, skuDetails.key)
                      }
                      value={skuDetails.defaultDC || []}
                      bordered={false}
                      mode="multiple"
                      showArrow
                      maxTagCount={1}
                      style={{ width: '165px' }}
                    >
                      {dcOption?.map((DCoption) => {
                        return <Select.Option key={DCoption.value}>{DCoption.label}</Select.Option>;
                      })}
                    </Select>
                  </div>
                </div>

                <div style={{ paddingLeft: '12px' }}>
                  <div>
                    <Tooltip placement="right" title="Gatorade G2 Thirst Quencher, Lower Sugar…">
                      <span
                        data-testid="inv-scenarioPlanning-skusContentCheckboxProductInfo"
                        className={s.skusProductInfoName}
                      >
                        {skuDetails.productInfo}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={s.applyBtn}>
            <Button
              onClick={() => {
                selectedGroupSkusList(false);
                groupSelectedBtnHandle();
              }}
              type="primary"
            >
              Apply
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SKUSScenarioPlanning;
