import React, { useMemo, useState } from 'react';
import s from './LastUpdated.module.scss';
import checkmarkIcon from 'assets/icons/inventory/checkmark.svg';
import warningIcon from 'assets/icons/inventory/warning.svg';
import { useQuery } from '@apollo/client';
import {
  InventoryReconciliationDataFreshnessDocument,
  InventoryReconciliationDataFreshnessQuery
} from './gql/__generated__/inventoryReconciliationDataFreshness.query';
import { throttle } from 'lodash-es';
import moment from 'moment';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

type FreshnessStatus = NonNullable<
  NonNullable<
    NonNullable<
      InventoryReconciliationDataFreshnessQuery['inventoryReconciliationDataFreshness']
    >['warehouses']
  >[number]
>;
const FreshStatusIcon = ({ isFresh }: { isFresh: boolean }) => {
  return isFresh ? (
    <img src={checkmarkIcon} alt="Up to date" />
  ) : (
    <img src={warningIcon} alt="Has delay" />
  );
};

const FreshStatus = ({ freshnessData }: { freshnessData: FreshnessStatus }) => {
  return (
    <div>
      <span>{freshnessData.type}: </span>
      {freshnessData?.sapInfo?.lastDate && (
        <div className={s.updated_string}>
          <span>SAP last updated at:</span>{' '}
          <span className={s.time_status}>
            {moment(freshnessData?.sapInfo?.lastDate).format(formatString)}
            <FreshStatusIcon isFresh={freshnessData?.sapInfo?.isFresh} />
          </span>
        </div>
      )}
      {freshnessData?.wmsInfo?.lastDate && (
        <div className={s.updated_string}>
          <span>WMS last updated at:</span>{' '}
          <span className={s.time_status}>
            {moment(freshnessData?.wmsInfo?.lastDate).format(formatString)}
            <FreshStatusIcon isFresh={freshnessData?.wmsInfo?.isFresh} />
          </span>
        </div>
      )}
    </div>
  );
};

const formatString = 'M/D/YY h:mm A';
const MINIMUM_REFETCH_TIME = 30_000;
// Refetch last updated info each 5 minutes
const AUTO_REFETCH_TIME = 5 * 60_000;

export const LastUpdated = () => {
  const [isOpen, setOpen] = useState(false);
  const { data, loading, error, refetch } = useQuery(InventoryReconciliationDataFreshnessDocument, {
    pollInterval: AUTO_REFETCH_TIME
  });
  // Refetch no more often than 1 time in MINIMUM_REFETCH_TIME.
  const throttledRefetch = useMemo(
    () => throttle(refetch, MINIMUM_REFETCH_TIME, { leading: true, trailing: false }),
    [refetch]
  );

  if (error) return <></>;

  const isFresh = !data?.inventoryReconciliationDataFreshness?.warehouses?.some(
    (x) => !x?.sapInfo?.isFresh || !x.wmsInfo?.isFresh
  );

  const onOpenChange = (open: boolean) => {
    if (open) {
      throttledRefetch();
    }
    setOpen(open);
  };

  const notEmptyWarehouses = (data?.inventoryReconciliationDataFreshness?.warehouses || []).filter(
    notEmpty
  );

  return (
    <AlloyTooltip
      placement="bottomLeft"
      overlayClassName={s.overlay}
      open={isOpen}
      style={{ width: 300 }}
      onOpenChange={(open) => onOpenChange(open)}
      overlay={
        loading ? (
          'Loading...'
        ) : (
          <div className={s.sources}>
            {notEmptyWarehouses.length > 0
              ? notEmptyWarehouses.map((x) => <FreshStatus freshnessData={x} key={x.type} />)
              : 'No freshness data available.'}
          </div>
        )
      }
    >
      <span className={s.last_update_date} data-testid="inventory-visibility-last-updated">
        {loading ? (
          'Loading...'
        ) : (
          <>
            <span>{isFresh ? 'All sources up to date' : 'Delay in some sources'}</span>
            <FreshStatusIcon isFresh={isFresh} />
          </>
        )}
      </span>
    </AlloyTooltip>
  );
};
