import React, { useState } from 'react';
import s from './ValidationRulesModal.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { DeleteOutlined } from '@ant-design/icons';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyRadio } from 'components/ui/AlloyRadio/AlloyRadio';
import { AlloySpace } from 'components/ui/AlloySpace/AlloySpace';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';
import { useEnumValue } from 'common/useEnumValue';
import { cloneDeep } from 'lodash-es';
import { AssortmentFieldValidation } from 'graphql/__generated__/types';

interface ValidationRulesModalProps {
  fieldName: string;
  onSaveValidationRules: (validation: AssortmentFieldValidation) => void;
  onClose: () => void;
  initValidation: AssortmentFieldValidation;
  viewOnly?: boolean;
}

const LimitVariations = ['LIMIT', 'RANGE', 'NO_LIMIT'];

export const getValidationTypeTitle = (value: string) => {
  switch (value) {
    case 'TEXT':
      return 'Alphanumeric with special characters';
    case 'NUMERIC':
      return 'Numeric';
    default:
      return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  }
};

const getLimitTitle = (value: string) => {
  switch (value) {
    case 'LIMIT':
      return 'Set exact length';
    case 'RANGE':
      return 'Range';
    case 'NO_LIMIT':
      return 'No limit';
    default:
      return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
  }
};

export const ValidationRulesModal = ({
  fieldName,
  onSaveValidationRules,
  onClose,
  initValidation,
  viewOnly
}: ValidationRulesModalProps) => {
  const [validation, setValidation] = useState(cloneDeep(initValidation));

  const { enumValues: validationTypes } = useEnumValue('AssortmentConfigValidationType');

  const validateLimit = (minLimit?: number, maxLimit?: number) => {
    if (minLimit === undefined) {
      return 'Min limit should be provided';
    } else if (minLimit !== undefined && minLimit <= 0) {
      return 'Min limit should be more than 0';
    } else if (maxLimit !== undefined && maxLimit <= 0) {
      return 'Max limit should be more than 0';
    } else if (minLimit !== undefined && maxLimit !== undefined && minLimit > maxLimit) {
      return 'Min limit should be less than max limit';
    }
    return null;
  };

  const validateExactlength = (length?: number) =>
    length === undefined
      ? 'Exact length value should be provided'
      : length <= 0
        ? 'Exact length value should be more than 0'
        : null;

  const getErrorsNotification = () => {
    const errors = validateRules();
    return errors.length > 0 ? <div className={s.validation_message}>{errors[0]}</div> : null;
  };

  const validateRules = () => {
    const errors = [] as string[];
    validation.rules?.forEach((rule) => {
      if (rule.value) {
        if (rule.value.includes(',')) {
          const min = rule.value.split(',')[0] ? Number(rule.value.split(',')[0]) : undefined;
          const max = rule.value.split(',')[1] ? Number(rule.value.split(',')[1]) : undefined;
          const error = validateLimit(min, max);
          if (error) errors.push(error);
        } else {
          const exactLength = rule.value ? Number(rule.value) : undefined;
          const error = validateExactlength(exactLength);
          if (error) errors.push(error);
        }
      }
    });
    return errors;
  };

  return (
    <AlloyModal
      title={
        <>
          <span data-testid="validation-rules-header">{fieldName} validation rules</span>
          <AlloyDivider />
        </>
      }
      closable
      open
      destroyOnClose
      onCancel={onClose}
      onOk={() => {
        const errors = validateRules();
        if (errors.length === 0) {
          onSaveValidationRules(validation);
        }
      }}
      okButtonProps={
        {
          'data-testid': 'validation-rules-ok-button'
        } as any
      }
      cancelButtonProps={
        {
          'data-testid': 'validation-rules-cancel-button',
          style: viewOnly ? { visibility: 'hidden' } : undefined
        } as any
      }
      closeIcon={<CloseOutlined data-testid="validation-rules-close-button" />}
    >
      <div className={s.rules_container}>
        {(validation.rules || []).map((rule, index) => (
          <div className={s.rule} key={index}>
            <div className={s.rule_title}>
              <span data-testid={`validation-rules-${index + 1}-title`}>Rule {index + 1}</span>
              {validation.rules && validation.rules.length > 1 && (
                <DeleteOutlined
                  onClick={() => {
                    validation.rules?.splice(index, 1);
                    setValidation({
                      ...validation
                    });
                  }}
                />
              )}
            </div>
            <div
              className={s.rule_subtitle}
              data-testid={`validation-rules-${index + 1}-character-type-title`}
            >
              Character type
            </div>
            <AlloyRadio.Group
              key="types"
              disabled={viewOnly}
              onChange={(e) => {
                if (validation.rules) {
                  validation.rules[index].type = e.target.value;
                  setValidation({
                    ...validation
                  });
                }
              }}
              value={rule.type}
            >
              <AlloySpace direction="vertical">
                {validationTypes?.map((validationType) => (
                  <AlloyRadio
                    key={validationType}
                    value={validationType}
                    data-testid={`validation-rules-${
                      index + 1
                    }-character-type-${validationType.toLowerCase()}-radio-button`}
                  >
                    <span
                      data-testid={`validation-rules-${
                        index + 1
                      }-character-type-${validationType.toLowerCase()}-title`}
                    >
                      {getValidationTypeTitle(validationType)}
                    </span>
                  </AlloyRadio>
                ))}
              </AlloySpace>
            </AlloyRadio.Group>
            <div
              className={s.rule_subtitle}
              data-testid={`validation-rules-${index + 1}-character-limit-title`}
            >
              Character limit
            </div>
            <AlloyRadio.Group
              key="LimitVariations"
              disabled={viewOnly}
              onChange={(e) => {
                if (validation.rules) {
                  if (e.target.value === 'NO_LIMIT') {
                    validation.rules[index].value = undefined;
                  } else if (e.target.value === 'RANGE') {
                    validation.rules[index].value = '1,';
                  } else {
                    validation.rules[index].value = '0';
                  }
                  setValidation({
                    ...validation
                  });
                }
              }}
              value={
                rule.value !== undefined && rule.value !== null
                  ? rule.value.includes(',')
                    ? 'RANGE'
                    : 'LIMIT'
                  : 'NO_LIMIT'
              }
            >
              <AlloySpace direction="vertical">
                {LimitVariations.map((limitVariation) => (
                  <AlloyRadio
                    key={limitVariation}
                    value={limitVariation}
                    data-testid={`validation-rules-${
                      index + 1
                    }-character-limit-${limitVariation.toLowerCase()}-radio-button`}
                  >
                    <span
                      data-testid={`validation-rules-${
                        index + 1
                      }-character-limit-${limitVariation.toLowerCase()}-title`}
                    >
                      {getLimitTitle(limitVariation)}
                    </span>
                  </AlloyRadio>
                ))}
              </AlloySpace>
            </AlloyRadio.Group>
            {rule.value !== undefined && rule.value !== null && rule.value.includes(',') && (
              <div className={s.range_value_container}>
                <div>
                  <label
                    htmlFor={`min_range_${index}`}
                    data-testid={`validation-rules-${index + 1}-min-limit-title`}
                  >
                    Enter min limit
                  </label>
                  <AlloyInput
                    disabled={viewOnly}
                    type="number"
                    id={`min_range_${index}`}
                    data-testid={`validation-rules-${index + 1}-min-limit-input`}
                    value={
                      validation.rules && validation.rules[index].value?.split(',')[0]
                        ? Number(validation.rules[index].value?.split(',')[0])
                        : ''
                    }
                    onChange={(e) => {
                      if (validation.rules) {
                        validation.rules[index].value = `${
                          e.currentTarget.value ? Number(e.currentTarget.value) : ''
                        },${validation.rules[index].value?.split(',')[1]}`;
                        setValidation({
                          ...validation
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`max_range_${index}`}
                    data-testid={`validation-rules-${index + 1}-max-limit-title`}
                  >
                    Enter max limit
                  </label>
                  <AlloyInput
                    disabled={viewOnly}
                    type="number"
                    id={`max_range_${index}`}
                    data-testid={`validation-rules-${index + 1}-max-limit-input`}
                    value={
                      validation.rules && validation.rules[index].value?.split(',')[1]
                        ? Number(validation.rules[index].value?.split(',')[1])
                        : ''
                    }
                    onChange={(e) => {
                      if (validation.rules) {
                        validation.rules[index].value = `${
                          validation.rules[index].value?.split(',')[0]
                        },${e.currentTarget.value ? Number(e.currentTarget.value) : ''}`;
                        setValidation({
                          ...validation
                        });
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {rule.value !== undefined && rule.value !== null && !rule.value.includes(',') && (
              <div className={s.limit_value_container}>
                <label
                  htmlFor={`limit${index}`}
                  data-testid={`validation-rules-${index + 1}-limit-title`}
                >
                  Enter exact length
                </label>
                <AlloyInput
                  disabled={viewOnly}
                  type="number"
                  id={`limit${index}`}
                  data-testid={`validation-rules-${index + 1}-limit-input`}
                  value={
                    validation.rules && validation.rules[index].value
                      ? Number(validation.rules[index].value)
                      : ''
                  }
                  onChange={(e) => {
                    if (validation.rules) {
                      validation.rules[index].value = `${e.currentTarget.value}`;
                      setValidation({
                        ...validation
                      });
                    }
                  }}
                />
              </div>
            )}
            {getErrorsNotification()}
          </div>
        ))}
        {!viewOnly && (
          <AlloyButton
            data-testid="validation-rules-add-rule-button"
            className={s.add_rule_button}
            type="link"
            onClick={() => {
              setValidation({
                ...validation,
                rules: [
                  ...(validation.rules || []),
                  {
                    type: 'TEXT',
                    value: undefined
                  }
                ]
              });
            }}
          >
            + Add another rule
          </AlloyButton>
        )}
        <AlloyDivider />
      </div>
    </AlloyModal>
  );
};
