import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodQuery = { __typename?: 'RootQueryType', periodReport: Array<{ __typename?: 'OtifReportByPeriod', acceptedFillRate?: any | null, approximateLostOpportunity?: number | null, inFull?: any | null, onTime?: any | null, orderCount?: number | null, otif?: any | null, submittedFillRate?: any | null, rsvValue?: any | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', period: number, week: number, year: number } }> };


export const ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"executiveIntelligenceServiceOtifSalesOrdersReportsByPeriod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"periodReport"},"name":{"kind":"Name","value":"otifSalesOrdersReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"approximateLostOpportunity"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inFull"}},{"kind":"Field","name":{"kind":"Name","value":"onTime"}},{"kind":"Field","name":{"kind":"Name","value":"orderCount"}},{"kind":"Field","name":{"kind":"Name","value":"otif"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"rsvValue"}}]}}]}}]} as unknown as DocumentNode<ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodQuery, ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodQueryVariables>;