import React, { useMemo, useState } from 'react';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  stringifyCalendarValue
} from 'components/PeriodCalendar/helpers';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ChargebacksReportDetailsByPeriodForTableDocument,
  ChargebacksReportDetailsByPeriodForTableQuery
} from './gql/__generated__/chargebacksReportDetailsByPeriodForTable.query';
import { AlloyTable, ColumnsType, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { groupBy, sumBy } from 'lodash-es';
import s from './Table.module.scss';
import currency from 'currency.js';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import clsx from 'clsx';
import { EMPTY } from 'common/constants';
import { __EMPTY__DIMENION__, stringifyBackendValue } from 'pages/Chargebacks/helpers';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import Icon from '@ant-design/icons';
import FilterIcon from 'assets/icons/filter.svg?react';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { ChargebacksReportDetailsByPeriodForFiltersDocument } from './gql/__generated__/chargebacksReportDetailsByPeriodForFilters.query';
import { AlloyPopover } from 'components/ui/AlloyPopover/AlloyPopover';
import { MultipleSelectWithSearch } from 'components/TableFilters/MultipleSelectWithSearch/MultipleSelectWithSearch';
import { getValueWithAll, setArrayWithAllAndAllowEmptyState } from 'common/helpers/selectAll';
import { RightOutlined } from '@ant-design/icons';
import { JsonParam, useQueryParam, withDefault } from 'use-query-params';
import { AlloyBadge } from 'components/ui/AlloyBadge/AlloyBadge';
import { useDeepCompareEffect } from 'use-deep-compare';

const ALL = '__ALL__';
type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForTableQuery['chargebackDetailsReportsByPeriod'][number];

type ChargebackReportByPeriodForFilters = Omit<
  ChargebackReportByPeriod,
  '__typename' | 'financialCharge' | 'fiscalCalendarWeek'
>;

type Dimension = keyof Omit<ChargebackReportByPeriodForFilters, 'totalValue'>;

type AllowedChargebackDimensions =
  | 'BUSINESS_UNIT'
  | 'ISSUE_TYPE'
  | 'STATUS'
  | 'SUB_TYPE_OF_THE_NON_COMPLIANCE'
  | 'TRADING_PARTNER';

const _dimensionToField: { [K in AllowedChargebackDimensions]?: Dimension } = {
  BUSINESS_UNIT: 'businessUnit',
  TRADING_PARTNER: 'tradingPartner',
  ISSUE_TYPE: 'issueType',
  SUB_TYPE_OF_THE_NON_COMPLIANCE: 'subTypeOfTheNonCompliance',
  STATUS: 'status'
} as const;

const fieldToDimension: { [K in Dimension]?: AllowedChargebackDimensions } = {
  businessUnit: 'BUSINESS_UNIT',
  tradingPartner: 'TRADING_PARTNER',
  issueType: 'ISSUE_TYPE',
  subTypeOfTheNonCompliance: 'SUB_TYPE_OF_THE_NON_COMPLIANCE',
  status: 'STATUS'
} as const;

const ALL_AVAILABLE_DIMENSIONS = Object.keys(fieldToDimension) as Dimension[];

type TableEntry = {
  name: string;
  key: string;
  dimension: Dimension;
  total: number;
  children?: TableEntry[];
} & {
  [K in `year_${number}_week_${number}`]: number;
};

const filters: { label: string; value: Dimension }[] = [
  { label: 'BU', value: 'businessUnit' },
  { label: 'VC', value: 'tradingPartner' },
  { label: 'Issue', value: 'issueType' },
  { label: 'Sub-Issue', value: 'subTypeOfTheNonCompliance' },
  { label: 'Status', value: 'status' }
];

const initialfilterObject = {} as Record<Dimension, string[]>;
filters.forEach((filter) => {
  initialfilterObject[filter.value] = [ALL];
});

const chargebackReportsToTableEntry = (
  items: ChargebackReportByPeriod[],
  name: string,
  dimension: Dimension,
  key: string
): TableEntry => {
  const result: TableEntry = {
    name,
    key,
    dimension,
    total: 0
  };
  items.forEach((item) => {
    const field: `year_${number}_week_${number}` = `year_${item.fiscalCalendarWeek.year}_week_${item.fiscalCalendarWeek.week}`;
    if (field) {
      result[field] = currency(result[field] || 0).add(item.financialCharge).value;
      result.total = currency(result.total || 0).add(item.financialCharge).value;
    }
  });

  return result;
};

const getTableDataByDimensions = (
  data: ChargebackReportByPeriod[],
  dimensions: Dimension[],
  parentKey = ''
): TableEntry[] => {
  if (dimensions.length === 0) {
    return [];
  }

  const dimension = dimensions[0];
  const dataWithoutTotal = data
    .filter((x) => !x.totalValue)
    .map((x) => ({ ...x, [dimension]: x[dimension] || __EMPTY__DIMENION__ }));
  const groupedData = groupBy(dataWithoutTotal, dimension);

  const resultArray: TableEntry[] = [];

  for (const key in groupedData) {
    const currentGroup = groupedData[key];

    const combinedKey = parentKey ? `${parentKey}_${key}` : key;

    const entry = chargebackReportsToTableEntry(currentGroup, key, dimension, combinedKey);

    // Recursively group the rest of the dimensions
    if (dimensions.length > 1) {
      entry.children = getTableDataByDimensions(currentGroup, dimensions.slice(1), combinedKey);
    }

    resultArray.push(entry);
  }

  return [...resultArray].sort((a, b) => safeLocaleCompare(a.name, b.name));
};

const getTableFilterValues = (
  data: ChargebackReportByPeriodForFilters[]
): Record<Dimension, string[]> => {
  const result: Record<Dimension, string[]> = {} as Record<Dimension, string[]>;

  ALL_AVAILABLE_DIMENSIONS.forEach((dimension) => {
    const allValues = data.filter((x) => !x.totalValue).map((x) => x[dimension] || '');
    const distinctValues = new Set<string>(allValues);
    result[dimension] = [...distinctValues];
  });

  return result;
};

// TODO: add multiple years?
const getColumns = (fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[]) => {
  const columns: ColumnsType<TableEntry> = [
    {
      title: '',
      render: (_, { name, dimension }) => (
        <div>
          {dimension === 'issueType' ||
          dimension === 'status' ||
          dimension === 'subTypeOfTheNonCompliance'
            ? stringifyBackendValue(name)
            : name}
        </div>
      ),
      width: 160,
      fixed: 'left',
      key: 'name'
    },
    ...fiscalCalendarWeeks.map(({ week, year }): ColumnType<TableEntry> => {
      return {
        title: <div className={s.column_title}>{convertWeekToPeriodWeekString(week)}</div>,
        render: (_, record) => (
          <div className={clsx(s.value)}>
            {isNaN(record[`year_${year}_week_${week}`])
              ? EMPTY
              : currency(record[`year_${year}_week_${week}`]).format()}
          </div>
        ),
        width: 120,
        align: 'left' as const,
        key: `year_${year}_week_${week}`
      };
    }),
    {
      title: 'Total',
      render: (_, record) => <div className={clsx(s.value)}>{currency(record.total).format()}</div>,
      width: 160,
      align: 'center' as const,
      fixed: 'right',
      key: 'total'
    }
  ];

  return {
    columns,
    width: sumBy(columns, 'width')
  };
};

export const Table = ({
  fiscalCalendarWeeks,
  period
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  period: CalendarValue;
}) => {
  const [selectedFieldDimensions, setSelectedFieldDimensions] = useQueryParam<{
    enabled: Dimension[];
    filters: { [K in Dimension]?: string[] };
  }>(
    'table_filter',
    withDefault(JsonParam, {
      enabled: ['businessUnit', 'issueType'],
      filters: initialfilterObject
    })
  );

  const selectedDimensions = useMemo(
    () => selectedFieldDimensions.enabled.map((x) => fieldToDimension[x]).filter(notEmpty),
    [selectedFieldDimensions]
  );

  const chargebackData = useQuery(ChargebacksReportDetailsByPeriodForTableDocument, {
    variables: {
      dimensions: selectedDimensions,
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      }
    }
  });

  const [getFiltersData, filtersData] = useLazyQuery(
    ChargebacksReportDetailsByPeriodForFiltersDocument,
    {
      variables: {
        filters: {
          fiscalCalendarWeeks,
          countryCode: 'US'
        }
      }
    }
  );

  const tableFilters = useMemo(
    () => getTableFilterValues(filtersData.data?.chargebackDetailsReportsByPeriod || []),
    [filtersData.data?.chargebackDetailsReportsByPeriod]
  );

  const filteredData = useMemo(() => {
    let filtered = chargebackData.data?.chargebackDetailsReportsByPeriod || [];
    for (const filter in selectedFieldDimensions.filters) {
      if (selectedFieldDimensions.filters[filter as Dimension]?.includes(ALL)) continue;
      filtered = filtered.filter((x) =>
        selectedFieldDimensions.filters[filter as Dimension]?.includes(x[filter as Dimension] || '')
      );
    }
    return filtered;
  }, [chargebackData.data?.chargebackDetailsReportsByPeriod, selectedFieldDimensions.filters]);

  const tableData = useMemo(
    () => getTableDataByDimensions(filteredData, selectedFieldDimensions.enabled),
    [filteredData, selectedFieldDimensions.enabled]
  );

  const { columns, width } = useMemo(() => getColumns(fiscalCalendarWeeks), [fiscalCalendarWeeks]);
  const areMultipleYearsSelected = period.years.length > 1;

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  // Used to reset values if filter changes
  useDeepCompareEffect(() => {
    setExpandedRowKeys([]);
  }, [period, selectedFieldDimensions]);

  if (chargebackData.error) return <div>Could not load data due to an error</div>;

  return (
    <div>
      <h2 className={s.title}>
        <span>
          Report period breakdown{' '}
          {`(${areMultipleYearsSelected ? period.years : ''}${stringifyCalendarValue(period)})`}
        </span>
        <Filter
          filters={filters}
          initialSelectedOptions={selectedFieldDimensions}
          handleApply={(dimensions) => setSelectedFieldDimensions(dimensions)}
          loading={filtersData.loading}
          filtersFromTable={tableFilters}
          loadFilters={() => {
            // This allow us to call it only when variables change
            if (!filtersData.called) getFiltersData();
          }}
        />
      </h2>
      <AlloyTable
        loading={chargebackData.loading}
        columns={columns}
        dataSource={tableData}
        rowKey="key"
        pagination={false}
        size="small"
        scroll={{ x: width }}
        indentSize={5}
        className={s.table}
        sticky={{
          offsetScroll: 6
        }}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[])
        }}
      />
    </div>
  );
};

interface FilterItem<T> {
  value: T;
  label: string;
}

interface FilterProps<T> {
  filters: FilterItem<T>[];
  initialSelectedOptions: {
    enabled: T[];
    filters: Record<string, string[]>;
  };
  handleApply: (selectedOptions: { enabled: T[]; filters: Record<string, string[]> }) => void;
  allowEmpty?: boolean;
  loading?: boolean;
  filtersFromTable: Record<Dimension, string[]>;
  loadFilters?: () => void;
}

const Filter = <T extends string>({
  filters,
  initialSelectedOptions,
  handleApply,
  allowEmpty = false,
  loading,
  filtersFromTable,
  loadFilters
}: FilterProps<T>) => {
  const [open, setOpen] = useState(false);
  // Otherwise it doesn't close automatically.
  // If we use "destroyTooltipOnHide" prop, it kinda jumps
  const [openPopup, setOpenPopup] = useState('');

  const [tempSelectedOptions, setTempSelectedOptions] = useState<T[]>(
    initialSelectedOptions.enabled
  );

  const [tempSelectedFilters, setTempSelectedFilters] = useState<Record<T, string[]>>(() => {
    const filterObject = {} as Record<T, string[]>;
    filters.forEach((filter) => {
      filterObject[filter.value] = [ALL];
    });
    return filterObject;
  });

  const setBranchValue = (branch: string) => (value: string[]) =>
    setTempSelectedFilters({
      ...tempSelectedFilters,
      [branch]: value
    });

  const handleOptionsChange = (value: T, isChecked: boolean) => {
    setTempSelectedOptions((prevState) => {
      const newSelection = isChecked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value);
      return filters.filter((item) => newSelection.includes(item.value)).map((item) => item.value);
    });
    setBranchValue(value)([ALL]);
  };

  const applyChanges = () => {
    if (!allowEmpty && tempSelectedOptions.length === 0) {
      setTempSelectedOptions(initialSelectedOptions.enabled);
      setTempSelectedFilters(initialSelectedOptions.filters);
    } else {
      handleApply({ enabled: tempSelectedOptions, filters: tempSelectedFilters });
    }
    setOpen(false);
  };

  const cancelChanges = () => {
    onOpenChange(false);
  };

  // Reset values if "apply" was not pressed
  const onOpenChange = (open: boolean) => {
    if (open) {
      if (loadFilters) {
        loadFilters();
      }
    }
    setTempSelectedOptions(initialSelectedOptions.enabled);
    setTempSelectedFilters(initialSelectedOptions.filters);
    setOpenPopup('');
    setOpen(open);
  };

  const onFilterChange = (branch: T, filters: string[], allValues: string[]) => {
    setArrayWithAllAndAllowEmptyState(
      filters || [],
      tempSelectedFilters[branch] || [],
      setBranchValue(branch),
      allValues,
      ALL
    );
  };

  return (
    <AlloyPopover
      open={open}
      onOpenChange={onOpenChange}
      trigger={['click']}
      zIndex={3}
      overlayClassName={s.filters_wrapper}
      overlayInnerStyle={{ borderRadius: '8px', padding: '8px 16px' }}
      arrow={false}
      placement="bottom"
      content={() => (
        <>
          <div className={s.filters}>
            {filters.map((item) => {
              const onOpenChange = (isOpen: boolean) => {
                if (tempSelectedFilters[item.value]?.length === 0) {
                  setBranchValue(item.value as Dimension)([ALL]);
                }
                if (isOpen) {
                  setOpenPopup(item.value);
                } else {
                  setOpenPopup('');
                }
              };

              const allName = `All ${item.label}${item.label === 'Status' ? 'es' : 's'}`;
              const someName = `Some ${item.label}${item.label === 'Status' ? 'es' : 's'}`;
              const noneName = `None ${item.label}${item.label === 'Status' ? 'es' : 's'}`;

              const areAllSelected = (tempSelectedFilters[item.value] || [])
                .map((x) => x.toLowerCase())
                .includes(ALL.toLowerCase());

              const text = loading
                ? '...'
                : `${areAllSelected ? allName : (tempSelectedFilters[item.value] || []).length === 0 ? noneName : someName} (${areAllSelected ? filtersFromTable[item.value as Dimension]?.length : tempSelectedFilters[item.value]?.length})`;

              return (
                <div key={JSON.stringify(item.value)} className={s.filter_line_item}>
                  <AlloyCheckbox
                    type="checkbox"
                    value={item.value}
                    checked={tempSelectedOptions.includes(item.value)}
                    onChange={(e) => handleOptionsChange(item.value, e.target.checked)}
                  >
                    {item.label}
                  </AlloyCheckbox>
                  <AlloyPopover
                    open={openPopup === item.value}
                    onOpenChange={onOpenChange}
                    trigger={['click']}
                    destroyTooltipOnHide
                    content={() => (
                      <MultipleSelectWithSearch
                        displaySelected={false}
                        values={
                          getValueWithAll(
                            tempSelectedFilters[item.value] || [],
                            filtersFromTable[item.value as Dimension] || [],
                            true,
                            ALL
                          ) || []
                        }
                        setValues={(values) =>
                          onFilterChange(
                            item.value,
                            values,
                            filtersFromTable[item.value as Dimension] || []
                          )
                        }
                        options={filtersFromTable[item.value as Dimension].map((x) => ({
                          name: stringifyBackendValue(x),
                          value: x
                        }))}
                        allValuesOption={{ value: ALL, name: allName }}
                      />
                    )}
                    placement="rightTop"
                    arrow={false}
                    zIndex={4}
                    overlayClassName={clsx(s.filters_wrapper, s.filters_subsection_wrapper)}
                    overlayInnerStyle={{
                      borderRadius: '4px',
                      padding: '4px 4px 0',
                      minWidth: '100px',
                      maxWidth: '320px'
                    }}
                  >
                    <AlloyButton
                      loading={loading}
                      disabled={!tempSelectedOptions.includes(item.value)}
                      style={{
                        width: '175px',
                        justifyContent: 'space-between',
                        paddingLeft: '7px',
                        paddingRight: '7px'
                      }}
                    >
                      {text}
                      <RightOutlined style={{ fontSize: '10px' }} />
                    </AlloyButton>
                  </AlloyPopover>
                </div>
              );
            })}
          </div>
          <div className={s.confirm}>
            <AlloyButton onClick={cancelChanges}>Cancel</AlloyButton>
            <AlloyButton onClick={applyChanges} type={'primary'}>
              Apply
            </AlloyButton>
          </div>
        </>
      )}
    >
      <AlloyButton className={s.filters_button} icon={<Icon component={() => <FilterIcon />} />}>
        <span>Add Filter</span>
        <AlloyBadge
          count={initialSelectedOptions.enabled.length}
          size="small"
          style={{ backgroundColor: '#023e73' }}
        />
      </AlloyButton>
    </AlloyPopover>
  );
};
