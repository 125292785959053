import React, { useState, useEffect } from 'react';
import './styles.scss';

interface NotificationProps {
  message: string;
  onComplete: () => void;
}

const Notification = ({ message, onComplete }: NotificationProps) => {
  const [msg, setMsg] = useState('');
  const [show, setShow] = useState(false);
  const [timeout, setNewTimeout] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (msg !== message) {
      if (timeout) clearTimeout(timeout);

      setMsg(message);
      setShow(true);
      setNewTimeout(
        setTimeout(() => {
          setShow(false);
        }, 3000)
      );
    }
  }, [message, msg, timeout]);

  const resetMsg = () => {
    if (!show) {
      setMsg('');
      onComplete();
    }
  };

  return (
    <>
      <div
        className={`notification ${show ? 'show' : ''} ${!show && msg ? 'drop' : ''}`}
        onTransitionEnd={resetMsg}
      >
        <div className="message">{msg}</div>
      </div>
    </>
  );
};

export default Notification;
