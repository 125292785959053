import React, { useMemo, useState } from 'react';
import s from './RepackPlanning.module.scss';
import { ArrayParam, JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import clsx from 'clsx';
import { setArrayWithAll } from 'common/helpers/selectAll';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import {
  StatChangeIndicator,
  StatChangeIndicatorProps
} from 'components/ui/StatChangeIndicator/StatChangeIndicator';
import { CalendarOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { TotalsGraphs } from './components/TotalsGraphs/TotalsGraphs';
import { useQuery } from '@apollo/client';
import { InventoryRepackWeeklyTotalsReportDocument } from './gql/__generated__/inventoryRepackWeeklyTotalsReport.query';
import { EMPTY } from 'common/constants';
import { useDeepCompareMemo } from 'use-deep-compare';
import { getFinancialInfo, convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { range } from 'lodash-es';
import {
  getCurrentReferenceDate,
  transformCalendarValueToFiscalCalendarWeekInput
} from 'components/PeriodCalendar/helpers';
import { isCalendarValueContinuous } from 'common/helpers/graph';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { FulfillTable } from './components/FulfillTable/FulfillTable';
import { CutsTable } from './components/CutsTable/CutsTable';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { notEmpty } from 'common/helpers/notEmpty';
import {
  CUTS_FILTERS_QUERY_PARAM,
  CUTS_SEARCH_QUERY_PARAM,
  FULFIL_FILTERS_QUERY_PARAM,
  FULFILL_SEARCH_QUERY_PARAM
} from './constants';
import { RepackItemType, RepackOptimizerStage } from 'graphql/__generated__/types';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Export } from './components/Export/Export';
import { StageSelector } from './components/StageSelector/StageSelector';

const CASES = ' CS';
const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2024;

const getIsMoved = (isMoved: string | undefined) => {
  if (isMoved === 'moved') return true;
  if (isMoved === 'non_moved') return false;
  return undefined;
};

const referenceDate = getCurrentReferenceDate(true);
const currentPeriod = getFinancialInfo(referenceDate);

const getDefaultPeriod = () => {
  const { week } = currentPeriod;
  const end = week;
  const start = Math.max(1, week - 3);
  return { start, end };
};

type DisplayMode = 'summary' | 'fulfill' | 'cuts';
const DEFAULT_TAB: DisplayMode = 'summary';

type RepackTab = { value: DisplayMode; label: React.ReactNode };

const getTabs = (stage: RepackOptimizerStage): RepackTab[] => {
  return [
    {
      value: 'summary',
      label: 'Executive Summary'
    },
    {
      value: 'fulfill',
      label: (
        <div className={s.tabTitle}>
          <div>{stage === 'MAIN' ? 'Fulfill' : 'Production'}</div>
          <div className={s.subtitle}>{stage === 'MAIN' ? 'This Week' : 'Next Week'}</div>
        </div>
      )
    },
    {
      value: 'cuts',
      label: (
        <div className={s.tabTitle}>
          <div>Cuts</div>
          <div className={s.subtitle}>{stage === 'MAIN' ? 'This Week' : 'Next Week'}</div>
        </div>
      )
    }
  ];
};

const currentPeriodString = `P${currentPeriod.period}W${currentPeriod.periodWeek} ${currentPeriod.year}`;

const getCityNameAccordingToStage = (name: string, stage: RepackOptimizerStage) => {
  return `${name} ${stage === 'MAIN' ? 'Fulfill Qty' : 'Prod. Qty'}`;
};

export const RepackPlanningPage = () => {
  const [isSecondStageStarted, setIsSecondStageReady] = useState(false);

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  const [stage] = useQueryParam('stage', withDefault(StringParam, 'MAIN'));

  // Well, it's not an actual type transformation, it's for convinience only.
  const modeWithType = mode as DisplayMode;

  // Well, it's not an actual type transformation, it's for convinience only.
  // Also, we default to main if FF is disabled, so we don't break stuff
  const stageWithType = stage as RepackOptimizerStage;

  const [cutsSearchTerm] = useQueryParam(CUTS_SEARCH_QUERY_PARAM, withDefault(ArrayParam, []));
  const [cutsFilters] = useQueryParam(CUTS_FILTERS_QUERY_PARAM, withDefault(JsonParam, {}));
  const [fulfillFilters] = useQueryParam(FULFIL_FILTERS_QUERY_PARAM, withDefault(JsonParam, {}));
  const [fulfillSearchTerm] = useQueryParam(
    FULFILL_SEARCH_QUERY_PARAM,
    withDefault(ArrayParam, [])
  );

  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [CURRENT_YEAR],
      ...getDefaultPeriod()
    })
  );
  const isContinious = useMemo(() => isCalendarValueContinuous(period), [period]);

  // TODO: Backend should fix it and accept week/periodWeek properly!!!!
  const filters = useDeepCompareMemo(() => {
    // Backend accepts it in uppercase only
    const type = (
      (modeWithType === 'fulfill' ? fulfillFilters : cutsFilters).type || ''
    ).toUpperCase() as RepackItemType;

    const isMoved = getIsMoved(
      (modeWithType === 'fulfill' ? fulfillFilters : cutsFilters)?.isMoved
    );

    const nonEmptySearch = (modeWithType === 'fulfill' ? fulfillSearchTerm : cutsSearchTerm).filter(
      notEmpty
    ) as string[];

    return {
      fiscalCalendarWeeks:
        modeWithType === 'summary'
          ? transformCalendarValueToFiscalCalendarWeekInput(period, true)
          : [
              {
                year: currentPeriod.year,
                period: currentPeriod.period,
                week: currentPeriod.periodWeek
              }
            ],
      // Need to pass undefined, but if value is undef it breaks
      // Also, for summary we disable these filters
      type: modeWithType === 'summary' ? undefined : type || undefined,
      // Currently smart-search returns nothing if we pass empty array
      smartSearchTerms:
        modeWithType === 'summary' || !nonEmptySearch.length ? undefined : nonEmptySearch,
      isMoved,
      stage: stageWithType
    };
  }, [
    cutsFilters,
    cutsSearchTerm,
    fulfillFilters,
    fulfillSearchTerm,
    modeWithType,
    period,
    stageWithType
  ]);

  // Hard-coded to be "current week". Searching/filtering is disabled. Stage is included.
  const exportFiltetrs = useMemo(
    () => ({
      fiscalCalendarWeeks: [
        {
          year: currentPeriod.year,
          period: currentPeriod.period,
          week: currentPeriod.periodWeek
        }
      ],
      stage: stageWithType
    }),
    [stageWithType]
  );

  const totalsData = useQuery(InventoryRepackWeeklyTotalsReportDocument, {
    variables: {
      filters
    }
  });

  const availableFiltersData = {
    loading: false
  };

  const buOptions = ['Frito Lay NA'];

  const isExportDisabled = stageWithType === 'SECOND' && !isSecondStageStarted;

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>Repack planning dashboard</PageHeader>
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            loading={availableFiltersData.loading}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={bus}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], bus as string[], setBus);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL BUs', value: 'all' },
              ...buOptions.map((bu) => ({
                label: bu,
                value: bu
              }))
            ]}
          />
          {modeWithType === 'summary' ? (
            <PeriodCalendar
              value={period}
              onChange={setPeriod}
              years={
                STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
              }
              // If we have more years, we want to allow to select prev periods, else nope
              pastOnly={STARTING_YEAR === CURRENT_YEAR}
            />
          ) : (
            <AlloyInput
              style={{ width: '200px' }}
              size="large"
              disabled
              prefix={
                <div style={{ paddingRight: '4px' }}>
                  <CalendarOutlined />
                </div>
              }
              suffix={<DownOutlined />}
              value={convertWeekToPeriodWeekString(currentPeriod.week)}
              contentEditable={false}
            />
          )}
          <AlloyTooltip
            title={isExportDisabled ? 'Forecasted demand step is not completed' : undefined}
            placement="bottomLeft"
          >
            <AlloyButton
              type="default"
              size="large"
              onClick={() => setIsExportModalVisible(true)}
              disabled={isExportDisabled}
            >
              Export
            </AlloyButton>
          </AlloyTooltip>
        </div>
      </div>
      <div className={s.stats}>
        {stage === 'MAIN' ? (
          <StatsRow
            stats={[
              {
                title: 'Total Po Qty',
                'data-testid': 'total-po-qty',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.poQuantity
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'In Inventory',
                'data-testid': 'in-inventory',
                value: (
                  <NumberAndText
                    number={formatPercentage(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        .inInventoryFillRate
                    )}
                    text="%"
                  />
                ),
                loading: totalsData.loading,
                tooltip: '(Inventory + Stale) / Total'
              },
              {
                title: 'Required Production',
                'data-testid': 'required-production',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.requiredProduction
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Cuts',
                'data-testid': 'cuts',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.cuts
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Total Projected Prod.',
                'data-testid': 'total-projected-production',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        .projectedProduction
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Projected Fill Rate',
                'data-testid': 'projected-fill-rate',
                value: (
                  <NumberAndText
                    number={formatPercentage(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.projectedFillRate
                    )}
                    text="%"
                  />
                ),
                loading: totalsData.loading
              }
            ]}
          />
        ) : (
          <StatsRow
            stats={[
              {
                title: 'Total Forecasted Demand',
                'data-testid': 'total-forecasted-demand',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.forecastedDemand
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Required Production',
                'data-testid': 'required-production',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.requiredProduction
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Cuts',
                'data-testid': 'cuts',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.cuts
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Total Projected Prod.',
                'data-testid': 'total-projected-production',
                value: (
                  <NumberAndText
                    number={formatNumber(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        .projectedProduction
                    )}
                    text={CASES}
                  />
                ),
                loading: totalsData.loading
              },
              {
                title: 'Produced/Forecast',
                'data-testid': 'produced-forecast',
                value: (
                  <NumberAndText
                    number={formatPercentage(
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics.forecastFillRate
                    )}
                    text="%"
                  />
                ),
                loading: totalsData.loading
              }
            ]}
          />
        )}
        <StatsRow
          stats={[
            {
              title: getCityNameAccordingToStage('Memphis', stageWithType),
              'data-testid': 'memphis',
              value: (
                <NumberAndText
                  number={formatNumber(
                    (
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        ?.citiesFulfillQuantity || []
                    ).find((x) => x?.name.toLowerCase() === 'memphis')?.quantity
                  )}
                  text={CASES}
                />
              ),
              loading: totalsData.loading
            },
            {
              title: getCityNameAccordingToStage('Grand Prairie', stageWithType),
              'data-testid': 'grand-prairie',
              value: (
                <NumberAndText
                  number={formatNumber(
                    (
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        ?.citiesFulfillQuantity || []
                    ).find((x) => x?.name.toLowerCase() === 'grand_praire')?.quantity
                  )}
                  text={CASES}
                />
              ),
              loading: totalsData.loading
            },
            {
              title: getCityNameAccordingToStage('Hamburg', stageWithType),
              'data-testid': 'hamburg',
              value: (
                <NumberAndText
                  number={formatNumber(
                    (
                      totalsData.data?.inventoryRepackWeeklyTotalsReport?.metrics
                        ?.citiesFulfillQuantity || []
                    ).find((x) => x?.name.toLowerCase() === 'hamburg')?.quantity
                  )}
                  text={CASES}
                />
              ),
              loading: totalsData.loading
            }
          ]}
        />
      </div>
      <div className={s.stage_selector}>
        <StageSelector setIsSecondStageReady={setIsSecondStageReady} />
      </div>
      <div className={s.mode_selector}>
        <AlloySegmented
          options={getTabs(stageWithType)}
          value={mode}
          onChange={(value) => setMode(value)}
        />
      </div>
      {modeWithType === 'summary' && (
        <div>
          <TotalsGraphs filters={filters} isContinious={isContinious} />
        </div>
      )}
      {modeWithType === 'fulfill' && (
        <div>
          <FulfillTable filters={filters} stage={stageWithType} />
        </div>
      )}
      {modeWithType === 'cuts' && (
        <div>
          <CutsTable filters={filters} referenceDate={referenceDate} stage={stageWithType} />
        </div>
      )}
      <Export
        filters={exportFiltetrs}
        periodString={currentPeriodString}
        isExportModalVisible={isExportModalVisible}
        setIsExportModalVisible={setIsExportModalVisible}
      />
    </>
  );
};

interface StatsTile {
  title: string;
  value: number | string | React.ReactChild;
  loading?: boolean;
  tooltip?: string;
  change?: StatChangeIndicatorProps;
  'data-testid'?: string;
}

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${parsedValue.toFixed(2)}`;
};

const formatNumber = (value: any, suffix = '') => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${parsedValue.toLocaleString('en-US')} ${suffix}`.trim();
};

const StatsRow = ({ stats }: { stats: StatsTile[] }) => {
  return (
    <div className={s.row_wrapper}>
      {stats.map((x) => (
        <div
          className={s.tile}
          key={x.title + x.value}
          data-testid={x['data-testid'] ? `${x['data-testid']}_tile` : undefined}
        >
          <div
            className={s.title}
            data-testid={x['data-testid'] ? `${x['data-testid']}_title` : undefined}
          >
            {x.title}
            {x.tooltip && (
              <AlloyTooltip title={x.tooltip} placement="topRight">
                <InfoCircleOutlined className={s.tooltip_icon} />
              </AlloyTooltip>
            )}
          </div>
          <div
            className={s.bottom}
            data-testid={x['data-testid'] ? `${x['data-testid']}_value` : undefined}
          >
            {x.loading ? (
              <div className={s.loading} />
            ) : (
              <>
                <div className={s.value}>{x.value}</div>
                {x.change && <StatChangeIndicator {...x.change} />}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const NumberAndText = ({ text, number = '' }: { text?: string; number: string | number }) => {
  return (
    <div className={s.number_and_text}>
      <span className={s.number}>{number}</span>
      {text && <span className={s.text}>{text}</span>}
    </div>
  );
};
