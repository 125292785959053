import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import s from './MaterialsView.module.scss';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { App } from 'ant5';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import {
  WhseMaterialsProcessedDocument,
  WhseMaterialsProcessedQuery
} from './gql/__generated__/whseMaterialsProcessed.query';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { StringParam, useQueryParam } from 'use-query-params';
import { MaterialSortColumn, SortMaterialDirection } from 'graphql/__generated__/types';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { notEmpty } from 'common/helpers/notEmpty';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { formatStatus } from '../../helpers';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import scissorsIcon from 'assets/icons/warehouseOrderProcessing/scissors_icon.svg';
import checkmarkIcon from 'assets/icons/warehouseOrderProcessing/checkmark_icon.svg';
import { EMPTY } from 'common/constants';

export const getMaterialsPageColumns = () => {};

type WhseMaterialsProcessed = InferNodeType<WhseMaterialsProcessedQuery, 'whseMaterialsProcessed'>;

type Orders = NonNullable<NonNullable<WhseMaterialsProcessed['orders']>[number]>;

// const MATERIALS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3]; //TODO: are we interested in pagination? it's not in the design

const getRowKey = (record: WhseMaterialsProcessed) => record.materialId;
const getExpandedRowKey = (order: Orders) => order.orderNumber;

const TABLE_WIDTH = 1590;

export const MaterialsView = () => {
  const { message } = App.useApp();

  const [materialsSortColumn, setMaterialsSortColumn] = useQueryParam(
    'material_column',
    StringParam
  );
  const [materialsSortOrder, setMaterialsSortOrder] = useQueryParam('material_order', StringParam);
  const [search, setSearch] = useQueryParam('material_search', StringParam);
  const [after, setAfter] = useQueryParam('material_after', StringParam);
  const [before, setBefore] = useQueryParam('material_before', StringParam);

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  // On page load/render, call the whseMaterialsProcessed query
  const { data, loading, error, refetch } = useQuery(WhseMaterialsProcessedDocument, {
    variables: {
      first: 100000,
      sort: {
        column: materialsSortColumn as MaterialSortColumn,
        direction: materialsSortOrder as SortMaterialDirection
      }
    }
  });

  const materialsProcessedList = useMemo(
    () => getNodesFromEdges(data?.whseMaterialsProcessed),
    [data]
  );

  useEffect(() => {
    if (!materialsSortColumn || !materialsSortOrder) {
      setMaterialsSortColumn('MATERIAL_ID');
      setMaterialsSortOrder('DESC');
    }
  }, [setMaterialsSortColumn, setMaterialsSortOrder, materialsSortColumn, materialsSortOrder]);

  const handleTableSorting = (column: string, order: string) => {
    setMaterialsSortColumn(column);
    setMaterialsSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const EmptyCell = () => {};

  const materialsTableColumns: ColumnsType<WhseMaterialsProcessed> = [
    {
      title: 'Material Id',
      key: 'MATERIAL_ID',
      width: '175px',
      render: (_, record) => record.materialId,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'MATERIAL_ID'),
      sorter: true
    },
    {
      title: 'Description',
      key: 'DESCRIPTION',
      render: (_, record) => record.description,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'DESCRIPTION'),
      sorter: true
    },
    {
      title: 'Status',
      key: 'STATUS',
      width: '148px',
      render: (_, record) => formatStatus(record.status),
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'STATUS'),
      sorter: true
    },
    {
      title: 'Units Ordered',
      key: 'UNITS_ORDERED',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.unitsOrdered ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'UNITS_ORDERED'),
      sorter: true
    },
    {
      title: 'Inventory',
      key: 'INVENTORY',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.inventory ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'INVENTORY'),
      sorter: true
    },
    {
      title: 'Discrepancy',
      key: 'DISCREPANCY',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.discrepancy ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'DISCREPANCY'),
      sorter: true
    },
    {
      title: 'Order Number',
      key: 'ORDER_NUMBER',
      width: '175px',
      align: 'left',
      render: (_, record) => {
        if (record.orders && record.orders.length) {
          return (
            <ListOfItemsColumn
              items={record.orders.map((order) => order?.orderNumber || '') || []}
              splitToLines
            />
          );
        } else {
          return null;
        }
      }
    },
    { title: '', key: 'cutAndApproveFakeColumn', width: 97, render: EmptyCell }
  ];

  const FAKE_COLUMN_VARIABLE_WIDTH = {
    title: '',
    key: 'expanderVariable',
    render: EmptyCell
  };

  const FAKE_COLUMN_MED_WIDTH = {
    title: '',
    key: 'expanderMed',
    width: 175,
    render: EmptyCell
  };

  const FAKE_COLUMN_SML_WIDTH = {
    title: '',
    key: 'expanderSml',
    width: 148,
    render: EmptyCell
  };

  const materialsExpandedRowColumns: ColumnsType<Orders> = [
    FAKE_COLUMN_MED_WIDTH,
    FAKE_COLUMN_VARIABLE_WIDTH,
    {
      key: 'STATUS',
      width: '148px',
      render: (_, record) => formatStatus(record.status)
    },
    {
      key: 'UNITS_ORDERED',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.unitsOrdered ?? EMPTY}</span>
    },
    FAKE_COLUMN_SML_WIDTH,
    FAKE_COLUMN_SML_WIDTH,
    {
      key: 'ORDER_NUMBER',
      width: '175px',
      align: 'left',
      render: (_, record) => record.orderNumber
    },
    {
      //placeholder for scissors svg
      width: '40px',
      render: (_, record) => {
        return (
          <div>
            <img src={scissorsIcon} alt="scissors icon" className={s.scissors_icon} />
          </div>
        );
      }
    },
    {
      //placeholder for checkmark svg
      width: '40px',
      render: (_, record) => {
        return (
          <div>
            <img src={checkmarkIcon} alt="checkmark icon" className={s.checkmark_icon} />
          </div>
        );
      }
    }
  ];

  const currentPageOrdersExpandableKeys = useMemo(() => {
    return materialsProcessedList.map(getRowKey);
  }, [materialsProcessedList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageOrdersExpandableKeys.every((material) => expandedRowKeys.includes(material)),
    [expandedRowKeys, currentPageOrdersExpandableKeys]
  );

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <div className={s.quantity_display_container}>
        <QuantityDisplay
          count={data?.whseMaterialsProcessed?.totalCount || 0}
          titleSingular={'Material'}
          titleMultiple={'Materials'}
        />
      </div>
      <AlloyTable
        data-testid="materials-processed-table"
        className={s.materials_processed_table}
        loading={loading}
        scroll={{ x: TABLE_WIDTH }}
        sticky
        pagination={false}
        columns={materialsTableColumns.filter(notEmpty)}
        dataSource={materialsProcessedList}
        expandable={{
          columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
            <ExpandButton
              expanded={areAllKeysExpandedOnThePage}
              onClick={(expanded) => {
                return expanded
                  ? setExpandedRowKeys(
                      expandedRowKeys.filter((x) => !currentPageOrdersExpandableKeys.includes(x))
                    )
                  : setExpandedRowKeys([
                      ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                    ]);
              }}
              collapseMessage="Collapse all"
              expandMessage="Expand all"
            />
          ),
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: ({ orders }) => (
            <AlloyTable //TODO: table header color change if possible
              loading={loading}
              data-testid="expanded-row-materials-table"
              className={s.expanded_row_materials_table}
              columns={materialsExpandedRowColumns.filter(notEmpty)}
              dataSource={orders?.map((order) => order).filter(notEmpty)}
              pagination={false}
              rowKey={getExpandedRowKey}
              showHeader={false}
            />
          ),
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
          rowExpandable: ({ orders }) => !!orders
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column =
            String((sorter as SorterResult<WhseMaterialsProcessed>).columnKey) || 'MATERIAL_ID';
          const order =
            (sorter as SorterResult<WhseMaterialsProcessed>).order === 'ascend' ||
            !(sorter as SorterResult<WhseMaterialsProcessed>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
        rowKey={getRowKey}
      />
    </>
  );
};
