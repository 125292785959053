import React from 'react';
import { Layout as AntLayout, LayoutProps } from 'ant5';

export function AlloyLayout({ ...otherProps }: LayoutProps) {
  return <AntLayout {...otherProps} />;
}

AlloyLayout.Header = AntLayout.Header;
AlloyLayout.Footer = AntLayout.Footer;
AlloyLayout.Sider = AntLayout.Sider;
AlloyLayout.Content = AntLayout.Content;

// Re-exporting types
export type { LayoutProps };
