import React, { useState, useMemo, useEffect } from 'react';
import { notEmpty } from 'common/helpers/notEmpty';
import { SearchOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { TableFilters } from 'components/TableFilters';
import moment from 'moment';
import {
  ColumnsWithExportRender,
  prepareSingleRecordForXLSXExport,
  removeExportConfig
} from 'common/helpers/tableExportAlloy';
import { useQueryParam, withDefault, ArrayParam } from 'use-query-params';
import s from './InventoryTable.module.scss';
import { Filter } from 'components/TableFilters/TableFilters';
import { safeNumberComparator } from 'common/helpers/comparators';
import { dateFormat, setDateToNullIf1900 } from 'common/helpers/date';
import {
  FILTERS_QUERY_PARAM_STRING,
  FilterKeys,
  InventoryMetric,
  MappedInventoryMetricDetails,
  MappedInventoryMetricBatchDetails,
  mapInventoryMetricDetailsToIncludeUOM
} from '../../types';
import { EMPTY } from 'common/constants';

import {
  MATERIAL,
  MATERIAL_DESCRIPTION,
  BUN,
  BATCH,
  CLEAR_NOT_CLEAR,
  MB5B_QTY,
  WMS_QTY,
  DIFF,
  DAYS,
  PLANT,
  INVENTORY_HEADERS,
  GTIN,
  SAP_SL,
  BASE_UOM,
  TRANSACTION_NUMBER,
  ITEM,
  FROM_SL,
  FROM_BATCH,
  TO_SL,
  TO_BATCH,
  QTY,
  PERFECT_SWAP_HEADERS
} from './export';
import { ApolloClient, useQuery } from '@apollo/client';
import { uniq } from 'lodash-es';
import { InventoryReconciliationFilters } from 'graphql/__generated__/types';
import { InventoryReconciliationMainTableDocument } from './gql/__generated__/inventoryReconciliationMainTable.query';
import { InventoryReconciliationBatchDetailsDocument } from './gql/__generated__/inventoryReconciliationBatchDetails.query';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import { CellObject, utils as XLSXutils, writeFileXLSX } from 'non_npm_dependencies/xlsx';
import { Warning } from '../Warning/Warning';
import { Chip } from 'components/alloy/Chip/Chip';
import { InventoryReconciliationPerfectBatchSwapsExportDocument } from './gql/__generated__/inventoryReconciliationPerfectBatchSwapsExport.query';
import { DisplayMode } from 'pages/Inventory/Inventory';
import { useFeatureFlag, useFeatureFlags } from 'common/useFeatureFlags/useFeatureFlags';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { ReconciliationModal } from '../ReconciliationModal/ReconciliationModal';
import { RECONCILIATION_FF, RECONCILIATION_FF_BUTTON } from 'common/useFeatureFlags/featureFlags';
import { paginate, sortTableDataWithTableSorter } from 'common/helpers/table';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';

function showLengthOrValue(array: string[], word: string) {
  if (array.length > 1) {
    return `${array.length} ${word}`;
  } else {
    return array[0];
  }
}

const TABLE_WIDTH = 1960;

function parseNumberAndFormat(
  number: string,
  useGrouping: boolean | undefined = undefined
): string {
  return number === null
    ? EMPTY
    : (parseFloat(number) || 0).toLocaleString(undefined, {
        minimumFractionDigits: 3,
        useGrouping
      });
}

function parseNumberForExport(number: string): number {
  return parseFloat(number) || 0;
}

const tableFilters: Filter<FilterKeys>[] = [
  {
    name: 'Status',
    type: 'single',
    field: 'status',
    options: [
      {
        name: 'Variance',
        value: 'true'
      },
      {
        name: 'No Variance',
        value: 'false'
      }
    ]
  },
  {
    name: 'GTIN Units Of Measurement',
    short: 'GTIN UOM',
    type: 'multiple',
    field: 'uom',
    options: [
      {
        name: 'CS',
        value: 'CS'
      },
      {
        name: 'EA',
        value: 'EA'
      },
      {
        name: 'PAK',
        value: 'PAK'
      }
    ]
  }
];

const getKey = (record: InventoryMetric): string => `${record?.sapMaterialId}-${record?.plant}`;
const EmptyCell = () => {};

const FAKE_COLUMN_OF_EXPANDER_WIDTH = {
  title: '',
  key: 'expander',
  width: 48,
  render: EmptyCell
};

const columns: ColumnsType<InventoryMetric> = [
  AlloyTable.SELECTION_COLUMN,
  AlloyTable.EXPAND_COLUMN,
  {
    title: 'Sap Material ID',
    key: 'sapMaterialId',
    width: 160,
    render: (_, { sapMaterialId }) => sapMaterialId || EMPTY,
    sorter: (a, b) => (a?.sapMaterialId || '').localeCompare(b?.sapMaterialId || '')
  },
  {
    title: 'Description',
    key: 'description',
    render: (_, { name }) => <span className={s.description}>{name || EMPTY}</span>,
    sorter: (a, b) => (a?.name || '').localeCompare(b?.name || '')
  },
  {
    title: 'Plant',
    key: 'plant',
    width: 65,
    render: (_, { plant }) => plant || EMPTY,
    sorter: (a, b) => (a?.plant || '').localeCompare(b?.plant || '')
  },
  FAKE_COLUMN_OF_EXPANDER_WIDTH,
  {
    title: 'Batch',
    key: 'batch',
    width: 135,
    render: (_, { batchNumbers }) =>
      batchNumbers.length > 0 ? showLengthOrValue(uniq(batchNumbers), 'Batches') : EMPTY
  },
  {
    title: 'GTIN',
    key: 'gtin',
    width: 140,
    render: (_, { gtins }) => (gtins.length > 0 ? showLengthOrValue(uniq(gtins), 'GTINs') : EMPTY)
  },
  {
    title: 'GTIN UOM',
    key: 'uom',
    width: 70,
    render: (_, { unitsOfMeasure }) =>
      unitsOfMeasure.length > 0 ? showLengthOrValue(uniq(unitsOfMeasure), 'UOMs') : EMPTY
  },
  {
    title: 'Status',
    key: 'variance',
    width: 95,
    render: (_, { variance }) => (variance ? 'Variance' : 'No variance'),
    sorter: (a, b) => (a.variance ? 1 : 0) - (b.variance ? 1 : 0)
  },
  {
    title: 'Sap On Hand',
    width: 100,
    align: 'right',
    key: 'sapOnHand',
    render: (_, { sapQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(sapQuantity)}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.sapQuantity, b.sapQuantity)
  },
  {
    title: 'WMS On Hand',
    width: 100,
    align: 'right',
    key: 'thirdPartyOnHand',
    render: (_, { wmsQuantity }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(wmsQuantity)}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.wmsQuantity, b.wmsQuantity)
  },
  {
    title: 'Discrepancy Count',
    width: 100,
    align: 'right',
    key: 'discrepancy',
    render: (_, { discrepancyCount }) => (
      <span style={{ fontWeight: 'bold' }}>{parseNumberAndFormat(discrepancyCount)}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.discrepancyCount, b.discrepancyCount)
  },
  {
    title: 'Base UOM',
    width: 50,
    key: 'baseUnitOfMeasure',
    render: (_, { baseUnitOfMeasure }) => <span>{baseUnitOfMeasure}</span>
  },
  {
    title: 'Discrepancy Reasons',
    width: 300,
    key: 'discrepancyReasons',
    render: EmptyCell
  },
  {
    title: 'Days with Variance',
    width: 85,
    align: 'right',
    key: 'daysOutOfSync',
    render: (_, { daysOutOfSync }) => (
      <span style={{ fontWeight: 'bold' }}>{daysOutOfSync ?? EMPTY}</span>
    ),
    sorter: (a, b) => safeNumberComparator(a.daysOutOfSync, b.daysOutOfSync)
  },
  {
    title: 'Stale Date',
    width: 100,
    key: 'staleDate',
    align: 'right',
    render: (_, { expirationDate, isGrouped }) =>
      isGrouped ? '' : dateFormat(setDateToNullIf1900(expirationDate)) || EMPTY
  }
];

const detailsColumnsWithCsv: ColumnsWithExportRender<MappedInventoryMetricDetails> = [
  {
    title: 'Sap Material ID',
    key: 'sapMaterialId',
    width: 160,
    render: EmptyCell,
    exportConfig: {
      title: MATERIAL,
      render: ({ sapMaterialId }) => sapMaterialId || EMPTY
    }
  },
  {
    title: 'Description',
    key: 'description',
    render: EmptyCell,
    exportConfig: {
      render: ({ name }) => name || EMPTY,
      title: MATERIAL_DESCRIPTION
    }
  },
  {
    title: 'Plant',
    key: 'plant',
    width: 65,
    render: EmptyCell,
    exportConfig: {
      title: PLANT,
      render: ({ plant }) => plant || EMPTY
    }
  },
  {
    title: 'Batch',
    key: 'batch',
    width: 135,
    render: (_, { batchNumber }) => batchNumber || EMPTY,
    exportConfig: {
      title: BATCH,
      render: ({ batchNumber }) => batchNumber || EMPTY
    }
  },
  {
    title: 'GTIN',
    key: 'gtin',
    width: 140,
    render: (_, { gtins }) => (gtins.length > 0 ? showLengthOrValue(uniq(gtins), 'GTINs') : EMPTY),
    exportConfig: {
      title: GTIN,
      render: ({ gtins }) => (gtins.length > 0 ? showLengthOrValue(uniq(gtins), 'GTINs') : EMPTY)
    }
  },
  {
    title: 'GTIN UOM',
    key: 'uom',
    width: 70,
    render: (_, { unitsOfMeasure }) =>
      unitsOfMeasure.length > 0 ? showLengthOrValue(uniq(unitsOfMeasure), 'UOMs') : EMPTY,
    exportConfig: {
      title: BUN,
      render: ({ unitsOfMeasure }) =>
        unitsOfMeasure.length > 0 ? showLengthOrValue(uniq(unitsOfMeasure), 'UOMs') : EMPTY
    }
  },
  {
    title: 'Status',
    key: 'variance',
    width: 95,
    render: (_, { variance }) => (variance ? 'Variance' : 'No variance'),
    exportConfig: {
      title: CLEAR_NOT_CLEAR,
      render: ({ variance }) => (variance ? 'Not Clear' : 'Clear')
    }
  },
  {
    title: 'Sap On Hand',
    width: 100,
    align: 'right',
    key: 'sapOnHand',
    render: (_, { sapQuantity, sapDaysSinceUpdate, sapOutdated }) => (
      <span className={s.divided}>
        {sapOutdated ? <Warning text={`Not changed in ${sapDaysSinceUpdate} days`} /> : <div />}
        {parseNumberAndFormat(sapQuantity)}
      </span>
    ),
    exportConfig: {
      title: MB5B_QTY,
      render: ({ sapQuantity }) => parseNumberForExport(sapQuantity),
      xlsxConfig: {
        t: 'n',
        z: '#,##0.000'
      }
    }
  },
  {
    title: 'WMS On Hand',
    width: 100,
    align: 'right',
    key: 'thirdPartyOnHand',
    render: (_, { wmsQuantity, wmsDaysSinceUpdate, wmsOutdated }) => (
      <span className={s.divided}>
        {wmsOutdated ? <Warning text={`Not changed in ${wmsDaysSinceUpdate} days`} /> : <div />}
        {parseNumberAndFormat(wmsQuantity)}
      </span>
    ),
    exportConfig: {
      title: WMS_QTY,
      render: ({ wmsQuantity }) => parseNumberForExport(wmsQuantity),
      xlsxConfig: {
        t: 'n',
        z: '#,##0.000'
      }
    }
  },
  {
    title: 'Discrepancy Count',
    width: 100,
    align: 'right',
    key: 'discrepancy',
    render: (_, { discrepancyCount }) => <span>{parseNumberAndFormat(discrepancyCount)}</span>,
    exportConfig: {
      title: DIFF,
      render: ({ discrepancyCount }) => parseNumberForExport(discrepancyCount),
      xlsxConfig: {
        t: 'n',
        z: '#,##0.000'
      }
    }
  },
  {
    title: 'Base UOM',
    width: 50,
    key: 'baseUnitOfMeasure',
    render: (_, { baseUnitOfMeasure }) => <span>{baseUnitOfMeasure}</span>,
    exportConfig: {
      title: BASE_UOM,
      render: ({ baseUnitOfMeasure }) => baseUnitOfMeasure
    }
  },
  {
    title: 'Discrepancy Reasons',
    width: 300,
    key: 'discrepancyReasons',
    render: (_, { discrepancyReason }) =>
      discrepancyReason ? <Chip label={discrepancyReason} /> : <></>
  },
  {
    title: 'Days with Variance',
    width: 85,
    align: 'right',
    key: 'daysOutOfSync',
    render: (_, { daysOutOfSync }) => <span>{daysOutOfSync ?? EMPTY}</span>,
    exportConfig: {
      render: ({ daysOutOfSync }) => daysOutOfSync ?? EMPTY,
      title: DAYS
    }
  },
  {
    title: 'Stale Date',
    width: 100,
    key: 'staleDate',
    align: 'right',
    render: (_, { expirationDate }) => dateFormat(setDateToNullIf1900(expirationDate)) || EMPTY
  }
];

// If we pass Table.SELECTION_COLUMN/EXPAND_COLUMN to initial columns array, removeExportConfig "breaks" them.
const detailsColumns: ColumnsType<MappedInventoryMetricDetails> = [
  AlloyTable.SELECTION_COLUMN,
  FAKE_COLUMN_OF_EXPANDER_WIDTH,
  ...removeExportConfig(detailsColumnsWithCsv).slice(0, 3),
  AlloyTable.EXPAND_COLUMN,
  ...removeExportConfig(detailsColumnsWithCsv).slice(3)
];

const batchDetailsColumns: ColumnsType<MappedInventoryMetricBatchDetails> = [
  AlloyTable.SELECTION_COLUMN,
  AlloyTable.EXPAND_COLUMN,
  {
    title: 'Sap Material ID',
    key: 'sapMaterialId',
    width: 160,
    render: EmptyCell
  },
  {
    title: 'Description',
    key: 'description',
    render: EmptyCell
  },
  {
    title: 'Plant',
    key: 'plant',
    width: 65,
    render: EmptyCell
  },
  {
    title: 'Batch',
    key: 'batch',
    width: 135,
    render: EmptyCell
  },
  {
    title: 'GTIN',
    key: 'gtin',
    width: 140,
    render: (_, { gtin }) => gtin || EMPTY
  },
  {
    title: 'GTIN UOM',
    key: 'uom',
    width: 70,
    render: (_, { unitOfMeasure }) => unitOfMeasure || EMPTY
  },
  {
    title: 'Status',
    key: 'variance',
    width: 95,
    render: (_, { variance }) => (variance ? 'Variance' : 'No variance')
  },
  {
    title: 'Sap On Hand (CS)',
    width: 100,
    align: 'right',
    key: 'sapOnHand',
    render: (_, { sapQuantity }) => <span>{parseNumberAndFormat(sapQuantity)}</span>
  },
  {
    title: 'WMS On Hand (CS)',
    width: 100,
    align: 'right',
    key: 'thirdPartyOnHand',
    render: (_, { wmsQuantity }) => <span>{parseNumberAndFormat(wmsQuantity)}</span>
  },
  {
    title: 'Discrepancy Count (CS)',
    width: 100,
    align: 'right',
    key: 'discrepancy',
    render: (_, { discrepancyCount }) => <span>{parseNumberAndFormat(discrepancyCount)}</span>
  },
  {
    title: 'Base UOM',
    width: 50,
    key: 'baseUnitOfMeasure',
    render: (_, { baseUnitOfMeasure }) => <span>{baseUnitOfMeasure}</span>
  },
  {
    title: 'Discrepancy Reasons',
    width: 300,
    key: 'discrepancyReasons',
    render: EmptyCell
  },
  {
    title: 'Days with Variance',
    width: 85,
    align: 'right',
    key: 'daysOutOfSync',
    render: (_, { daysOutOfSync }) => <span>{daysOutOfSync ?? EMPTY}</span>
  },
  {
    title: 'Stale Date',
    width: 100,
    key: 'staleDate',
    align: 'right',
    render: (_, { expirationDate }) => dateFormat(setDateToNullIf1900(expirationDate)) || EMPTY
  }
];

const prepareItemsForExport = async (
  items: InventoryMetric[],
  client: ApolloClient<object>,
  filters: InventoryReconciliationFilters
): Promise<{ [x: string]: string | number | undefined | CellObject }[]> => {
  const sapMaterialIds = items.map((x) => x.sapMaterialId);
  const plants = Array.from(new Set(items.map((x) => x.plant)));
  const batchNumbers = Array.from(new Set(items.map((x) => x.batchNumbers).flat()));

  const metricsWithDetails = await client.query({
    query: InventoryReconciliationBatchDetailsDocument,
    variables: {
      filters,
      sapMaterialIds,
      plants,
      batchNumbers
    }
  });

  const metrics =
    metricsWithDetails.data.inventoryReconciliationBatchDetails?.metrics.map(
      mapInventoryMetricDetailsToIncludeUOM
    ) || [];

  const result: Record<string, string | number | undefined | CellObject>[] = metrics
    .filter(notEmpty)
    .map((x) => ({
      ...prepareSingleRecordForXLSXExport(x, detailsColumnsWithCsv),
      // https://pepsico-ecomm.atlassian.net/browse/DESC-8714
      [SAP_SL]: '0004'
    }));

  return result;
};

const preparePerfectBatchSwapExport = async (
  items: InventoryMetric[],
  client: ApolloClient<object>,
  filters: InventoryReconciliationFilters
): Promise<{ [x: string]: string | number | undefined | CellObject }[]> => {
  const sapMaterialIds = items.map((x) => x.sapMaterialId);
  const plants = Array.from(new Set(items.map((x) => x.plant)));

  const metricsWithDetails = await client.query({
    query: InventoryReconciliationPerfectBatchSwapsExportDocument,
    variables: {
      filters,
      sapMaterialIds,
      plants
    }
  });

  const swaps = metricsWithDetails.data.inventoryReconciliationPerfectBatchSwapsExport || [];

  const result: Record<string, string | number | undefined | CellObject>[] = swaps.map((x) => ({
    [TRANSACTION_NUMBER]: x.transaction,
    [PLANT]: x.plant,
    [ITEM]: x.sapMaterialId,
    [FROM_SL]: x.fromSl,
    [FROM_BATCH]: x.fromBatch,
    [TO_SL]: x.toSl,
    [TO_BATCH]: x.toBatch,
    [QTY]: {
      t: 'n',
      z: '#,##0.000',
      v: parseNumberForExport(x.quantity)
    }
  }));

  return result;
};

export const InventoryTable = ({
  selectedRows,
  setSelectedRows,
  resetSelectedRows,
  exportModalVisible,
  setExportModalVisible,
  dcsFilename,
  filters,
  mode
}: {
  selectedRows: InventoryMetric[];
  setSelectedRows: React.Dispatch<React.SetStateAction<InventoryMetric[]>>;
  exportModalVisible: boolean;
  setExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  resetSelectedRows: () => void;
  dcsFilename: string;
  filters: InventoryReconciliationFilters;
  mode: DisplayMode;
}) => {
  const [reconcileVisible, setReconcileVisible] = useState(false);
  const {
    featureFlags: [
      { enabled: isReconciliationPhaseTwoEnabled },
      { enabled: isReconcileButtonEnabled }
    ]
  } = useFeatureFlags([{ name: RECONCILIATION_FF }, { name: RECONCILIATION_FF_BUTTON }]);
  const { data, loading, error, client } = useQuery(InventoryReconciliationMainTableDocument, {
    variables: {
      filters: filters
    }
  });

  const inventory = useMemo(
    () => data?.inventoryReconciliationMainTable?.metrics?.filter(notEmpty) || [],
    [data]
  );

  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const cellWithColSpan = (record: InventoryMetric) => {
    if (expandedRowKeys.includes(getKey(record)) && record.isGrouped) {
      return {
        rowSpan: 2,
        className: clsx(s.expanded, s.combined_row)
      };
    }
    return {};
  };

  const columnsWithSpan: ColumnsType<InventoryMetric> = columns
    .map((x, index) => ([2, 3, 4].includes(index) ? { ...x, onCell: cellWithColSpan } : x))
    .filter((x) => !isReconciliationPhaseTwoEnabled || x.key !== 'discrepancyReasons');

  const INITIAL_PAGINATION = 50;
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION);
  const [page, setPage] = useState(1);
  const [sorter, setSorter] = useState<SorterResult<InventoryMetric>>();
  const _isSinglePage = inventory.length <= pageSize;
  const isPaginationHidden = inventory.length <= INITIAL_PAGINATION;

  // Reset page after filters/mode change
  useEffect(() => {
    setPage(1);
  }, [mode, filters]);

  const [exportAsync, setExportAsync] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });
  const onDownloadWithDetailsClick = async (orders: InventoryMetric[]) => {
    try {
      setExportAsync({ loading: true, error: false });
      const itemsWithDetails = await prepareItemsForExport(orders, client, filters);

      const filename = `inventory_recon_${dcsFilename}${moment().format(
        'MM-DD-YYYY_hh-mm-ss_A'
      )}.xlsx`;

      const worksheet = XLSXutils.json_to_sheet(itemsWithDetails, { header: INVENTORY_HEADERS });
      // TODO: automate or move it to columns defenition?
      worksheet['!cols'] = [
        { wpx: 86 },
        { wpx: 35 },
        { wpx: 120 },
        { wpx: 200 },
        { wpx: 100 },
        { wpx: 42 },
        { wpx: 75 },
        { wpx: 50 },
        { wpx: 35 },
        { wpx: 65 },
        { wpx: 65 },
        { wpx: 65 },
        { wpx: 90 },
        { wpx: 85 },
        { wpx: 75 },
        { wpx: 65 },
        { wpx: 85 },
        { wpx: 95 },
        { wpx: 80 }
      ];
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, 'Inventory Reconciliation');
      writeFileXLSX(workbook, filename, { compression: true });

      resetSelectedRows();
      setExportModalVisible(false);
      setExportAsync({
        loading: false,
        error: false
      });
    } catch {
      setExportAsync({ loading: false, error: true });
    }
  };

  const onDownloadPerfectBatchClick = async (orders: InventoryMetric[]) => {
    try {
      setExportAsync({ loading: true, error: false });
      const swaps = await preparePerfectBatchSwapExport(orders, client, filters);

      const filename = `perfect_batch_swaps_${dcsFilename}${moment().format(
        'MM-DD-YYYY_hh-mm-ss_A'
      )}.xlsx`;

      const worksheet = XLSXutils.json_to_sheet(swaps, { header: PERFECT_SWAP_HEADERS });
      worksheet['!cols'] = [
        { wpx: 75 },
        { wpx: 50 },
        { wpx: 120 },
        { wpx: 50 },
        { wpx: 60 },
        { wpx: 50 },
        { wpx: 60 }
      ];
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, 'Perfect Batch Swaps');
      writeFileXLSX(workbook, filename, { compression: true });

      resetSelectedRows();
      setExportModalVisible(false);
      setExportAsync({
        loading: false,
        error: false
      });
    } catch {
      setExportAsync({ loading: false, error: true });
    }
  };

  const currentPageItemsExpandableKeys = useMemo(() => {
    return paginate(sortTableDataWithTableSorter(inventory, sorter), page, pageSize)
      .filter((x) => x.isGrouped)
      .map(getKey);
  }, [inventory, page, pageSize, sorter]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageItemsExpandableKeys.every((item) => expandedRowKeys.includes(item)),
    [expandedRowKeys, currentPageItemsExpandableKeys]
  );

  return (
    <div style={{ marginBottom: '20px' }}>
      {isReconcileButtonEnabled && (
        <FullScreenEditingModal
          title={'Reconciliation'}
          open={reconcileVisible}
          onClose={() => setReconcileVisible(false)}
          buttons={null}
          containerClassName={s.fullscreen_modal_container}
        >
          <ReconciliationModal
            rowsToReconcile={selectedRows.length === 0 ? undefined : selectedRows}
            parentFilters={filters}
          />
        </FullScreenEditingModal>
      )}
      <AlloyModal
        title="Export view"
        open={exportModalVisible}
        onCancel={() => setExportModalVisible(false)}
        width={400}
        destroyOnClose
        footer={
          <>
            <div className={s.modal_buttons}>
              <AlloyButton
                type="secondary"
                className={s.bold}
                size="large"
                onClick={() => setExportModalVisible(false)}
                data-testid="inventory-visibility-export-modal-close-button"
              >
                Cancel
              </AlloyButton>

              {isReconciliationPhaseTwoEnabled ? (
                <AlloyButton
                  size="large"
                  style={{ width: 'auto' }}
                  type="primary"
                  onClick={() =>
                    onDownloadWithDetailsClick(selectedRows?.length ? selectedRows : inventory)
                  }
                  loading={exportAsync.loading}
                  data-testid="inventory-visibility-export-modal-download-button"
                  disabled={(!selectedRows?.length && !inventory?.length) || exportAsync.loading}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`}
                </AlloyButton>
              ) : (
                <AlloyDropdown.Button
                  size="large"
                  style={{ width: 'auto' }}
                  type="primary"
                  menu={{
                    items: [
                      {
                        key: 1,
                        label: `Download ${
                          selectedRows.length > 0 ? `selected` : `all`
                        } perfect batch swaps`,
                        onClick: () =>
                          onDownloadPerfectBatchClick(
                            selectedRows?.length ? selectedRows : inventory
                          )
                      }
                    ]
                  }}
                  onClick={() =>
                    onDownloadWithDetailsClick(selectedRows?.length ? selectedRows : inventory)
                  }
                  loading={exportAsync.loading}
                  data-testid="inventory-visibility-export-modal-download-button"
                  disabled={(!selectedRows?.length && !inventory?.length) || exportAsync.loading}
                >
                  Download {selectedRows.length > 0 ? `selected` : `all`}
                </AlloyDropdown.Button>
              )}
            </div>
            {exportAsync.error && (
              <p className={s.error}>Error occured while loading xlsx, try again later.</p>
            )}
          </>
        }
      >
        <AlloySpin spinning={exportAsync.loading}>
          {!selectedRows?.length && !inventory?.length ? (
            'Nothing to export. Please change filters.'
          ) : (
            <>
              <p>
                We will export{' '}
                {selectedRows.length > 0
                  ? `${selectedRows.length} selected`
                  : `all ${inventory.length}`}{' '}
                products into a <code>.xlsx</code> file.
              </p>
            </>
          )}
        </AlloySpin>
      </AlloyModal>
      <div className={s.topBar}>
        <div className={s.left}>
          <div className={s.search}>
            <MultipleValuesInput
              placeholder="Search by GTIN, SAP Material ID or description"
              value={
                nonEmptySearch
                  ? typeof nonEmptySearch === 'string'
                    ? [nonEmptySearch]
                    : nonEmptySearch
                  : []
              }
              onChange={setSearchTerm}
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
              splitInputValue={/[^0-9a-zA-Z-]+/g}
            />
          </div>
          <TableFilters
            filters={tableFilters}
            queryParamName={FILTERS_QUERY_PARAM_STRING}
            loading={false}
          />
        </div>
        {isReconcileButtonEnabled && (
          <AlloyButton
            onClick={() => setReconcileVisible(true)}
            size="large"
            data-testid="export-inventory-reconcile-button"
          >
            Reconcile {selectedRows.length || 'all'}
          </AlloyButton>
        )}
      </div>
      {error ? (
        <div style={{ marginTop: '40px' }}>Could not load data due to an error. Try again.</div>
      ) : (
        <>
          <div className={s.totalAndSelectedWrapper}>
            <div className={s.totalAndSelected}>
              <div data-testid="inventory-total-products">
                {loading ? '...' : inventory.length} product{inventory.length === 1 ? '' : 's'}
                {selectedRows.length > 0 && ', '}
              </div>
              {selectedRows.length > 0 && (
                <>
                  <div>{selectedRows.length} selected</div>
                  <AlloyButton
                    type="secondary"
                    onClick={resetSelectedRows}
                    data-testid="visibility-clear-selection"
                  >
                    Clear selection
                  </AlloyButton>
                </>
              )}
            </div>
          </div>
          <AlloyTable
            rowKey={getKey}
            scroll={{ x: isReconciliationPhaseTwoEnabled ? TABLE_WIDTH - 300 : TABLE_WIDTH }}
            sticky={{
              offsetScroll: 6
            }}
            rowSelection={{
              preserveSelectedRowKeys: true,
              onChange: (_keys, rows) => {
                setSelectedRows(rows);
              },
              type: 'checkbox',
              selectedRowKeys: selectedRows.map(getKey),
              //@ts-ignore
              getCheckboxProps(record) {
                return { 'data-testid': getKey(record) };
              }
            }}
            columns={columnsWithSpan}
            loading={loading}
            dataSource={inventory}
            className={clsx(
              `styled_report_table`,
              s.table,
              isPaginationHidden && s.tableNoPagination
            )}
            rowClassName={(record) => {
              if (expandedRowKeys.includes(getKey(record)) && record.isGrouped) return s.expanded;
              return s.collapsed;
            }}
            size="small"
            pagination={
              isPaginationHidden
                ? false
                : {
                    position: ['topRight'],
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200'],
                    pageSize: pageSize,
                    size: 'small',
                    current: page
                  }
            }
            onChange={(pagination, _filter, sorting) => {
              if (pagination.current) {
                setPage(pagination.current);
              }
              if (pagination.pageSize) {
                setPageSize(pagination.pageSize);
              }
              // For now handling only 1-way-sorting
              if (sorting) {
                if (Array.isArray(sorting)) {
                  setSorter(sorting[0]);
                } else {
                  setSorter(sorting);
                }
              }
            }}
            expandable={{
              // It's not the best idea to use internal antd classes, but it's unlikely they change them
              columnTitle: currentPageItemsExpandableKeys.length > 0 && (
                <ExpandButton
                  expanded={areAllKeysExpandedOnThePage}
                  onClick={(expanded) => {
                    if (expanded) {
                      setExpandedRowKeys(
                        expandedRowKeys.filter((x) => !currentPageItemsExpandableKeys.includes(x))
                      );
                    } else {
                      setExpandedRowKeys([
                        ...new Set([...currentPageItemsExpandableKeys, ...expandedRowKeys])
                      ]);
                    }
                  }}
                  collapseMessage="Collapse all"
                  expandMessage="Expand all"
                />
              ),
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
              rowExpandable: (record) => !!record.isGrouped,
              expandedRowRender: (record, _index, _indent, expanded) =>
                expanded && <MetricDetails record={record} filters={filters} />,
              indentSize: 0
            }}
          />
        </>
      )}
    </div>
  );
};

export const MetricDetails = ({
  record,
  filters
}: {
  record: InventoryMetric;
  filters: InventoryReconciliationFilters;
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { enabled: isReconciliationPhaseTwoEnabled } = useFeatureFlag({ name: RECONCILIATION_FF });
  const detailsColumnsWithFeatureFlag = detailsColumns.filter(
    (x) => !isReconciliationPhaseTwoEnabled || x.key !== 'discrepancyReasons'
  );

  const { data, loading, error } = useQuery(InventoryReconciliationBatchDetailsDocument, {
    variables: {
      filters: filters,
      sapMaterialIds: record.sapMaterialId,
      plants: [record.plant],
      batchNumbers: record.batchNumbers
    }
  });

  const details = useMemo(
    () =>
      (
        data?.inventoryReconciliationBatchDetails?.metrics.map(
          mapInventoryMetricDetailsToIncludeUOM
        ) || []
      ).filter(notEmpty),
    [data]
  );

  return error ? (
    <div>Could not load data due to an error. Try again.</div>
  ) : (
    <AlloyTable
      scroll={{ x: isReconciliationPhaseTwoEnabled ? TABLE_WIDTH - 300 : TABLE_WIDTH }}
      rowKey="batchNumber"
      columns={detailsColumnsWithFeatureFlag}
      dataSource={details}
      loading={loading}
      rowClassName={(record, index) => {
        return clsx({
          [s.last_row]:
            index === details.length - 1 && !expandedRowKeys.includes(record.batchNumber),
          [s.expanded_batch]: expandedRowKeys.includes(record.batchNumber)
        });
      }}
      pagination={false}
      className={clsx(s.details_table_wrapper, s.table_no_header, s.masked_select_column)}
      expandable={{
        rowExpandable: (record) => record.isGrouped,
        expandedRowKeys: expandedRowKeys,
        onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
        expandedRowRender: (record, index, _indent, expanded) =>
          expanded && (
            <BatchDetails batchDetails={record.gtinDetails} isLast={index === details.length - 1} />
          )
      }}
      rowSelection={{
        renderCell: EmptyCell
      }}
    />
  );
};

export const BatchDetails = ({
  batchDetails,
  isLast
}: {
  batchDetails: MappedInventoryMetricBatchDetails[];
  isLast: boolean;
}) => {
  const { enabled: isReconciliationPhaseTwoEnabled } = useFeatureFlag({ name: RECONCILIATION_FF });
  const batchDetailsColumnsWithFeatureFlag = batchDetailsColumns.filter(
    (x) => !isReconciliationPhaseTwoEnabled || x.key !== 'discrepancyReasons'
  );

  return (
    <AlloyTable
      scroll={{ x: isReconciliationPhaseTwoEnabled ? TABLE_WIDTH - 300 : TABLE_WIDTH }}
      rowKey="gtin"
      columns={batchDetailsColumnsWithFeatureFlag}
      dataSource={batchDetails}
      rowClassName={(_record, index) => {
        return clsx({
          [s.last_row_batch]: index === batchDetails.length - 1,
          [s.last_row]: index === batchDetails.length - 1 && isLast
        });
      }}
      pagination={false}
      className={clsx(s.table_no_header, s.masked_select_column)}
      expandable={{
        rowExpandable: () => false,
        expandedRowRender: EmptyCell
      }}
      rowSelection={{
        renderCell: EmptyCell
      }}
    />
  );
};
