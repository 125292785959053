import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RepackChangeLogsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.InventoryRepackFilters>;
}>;


export type RepackChangeLogsQuery = { __typename?: 'RootQueryType', repackChangeLogs?: { __typename?: 'RepackChangeLogs', logs?: Array<{ __typename?: 'RepackChangeLog', action: Types.RepackUserAction, comment?: string | null, insertedAt: any, itemId: string, newQuantity: any, oldQuantity: any, period: number, plant: string, reason?: string | null, stage: Types.RepackOptimizerStage, userName: string, week: number, year: number }> | null } | null };


export const RepackChangeLogsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"repackChangeLogs"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"InventoryRepackFilters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"repackChangeLogs"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"comment"}},{"kind":"Field","name":{"kind":"Name","value":"insertedAt"}},{"kind":"Field","name":{"kind":"Name","value":"itemId"}},{"kind":"Field","name":{"kind":"Name","value":"newQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"oldQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"plant"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"stage"}},{"kind":"Field","name":{"kind":"Name","value":"userName"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}}]}}]}}]} as unknown as DocumentNode<RepackChangeLogsQuery, RepackChangeLogsQueryVariables>;