import React, { useMemo } from 'react';
import s from './CutSummariesToolbar.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import {
  PoDetailsCutSummaryOrderDetailsPage,
  PurchaseOrderWithOverdue
} from 'pages/OrderDetailsPage/types';
import LoaderSpinner from 'components/LoaderSpinner';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { ExportCuts } from 'pages/OrderDetailsPage/components/CutsExport';
import { Filter, TableFilters } from 'components/TableFilters/TableFilters';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';

export type CutsFilterKeys = 'cutReason';
export const CUTS_FILTERS_QUERY_PARAM_STRING = 'cutsFilters';

interface CutSummariesToolbarProps {
  cutSummariesList: PoDetailsCutSummaryOrderDetailsPage[] | null | undefined;
  loading: boolean;
  search: string[];
  setSearch: React.Dispatch<React.SetStateAction<string[]>>;
  purchaseOrder: PurchaseOrderWithOverdue;
  existingCutReasons: { name: string; value: string }[];
}

const CutSummariesToolbar = ({
  cutSummariesList,
  loading,
  search,
  setSearch,
  purchaseOrder,
  existingCutReasons
}: CutSummariesToolbarProps) => {
  const tableFilters = useMemo(() => {
    const tableFiltersRaw: Filter<CutsFilterKeys>[] = [
      {
        name: 'CUT REASON',
        type: 'multiple',
        field: 'cutReason',
        options: existingCutReasons
      }
    ];
    return tableFiltersRaw.sort((a, b) => a.name.localeCompare(b.name));
  }, [existingCutReasons]);

  if (loading) return <LoaderSpinner />;

  return (
    <AlloyRow className={s.toolbar_container}>
      <div className={s.count_and_search_container}>
        <div className={s.product_count}>
          {cutSummariesList?.length} Product{`${cutSummariesList?.length === 1 ? '' : 's'}`}
        </div>
        <div className={s.search}>
          <MultipleValuesInput
            data-testid={'cut_summaries_search'}
            placeholder={'Search by UPC, ASIN or Description'}
            value={search}
            onChange={setSearch}
            allowClear={true}
            prefix={<SearchOutlined width="14px" height="14px" />}
            splitInputValue={/[^0-9a-zA-Z-,.()&]+/g}
          />
          <div className={s.filters}>
            <TableFilters
              filters={tableFilters}
              queryParamName={CUTS_FILTERS_QUERY_PARAM_STRING}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className={s.action_buttons_container}>
        <ExportCuts cuts={cutSummariesList} purchaseOrder={purchaseOrder} />
      </div>
    </AlloyRow>
  );
};

export default CutSummariesToolbar;
