import { EMPTY } from 'common/constants';
import React, { ReactElement } from 'react';
import s from './StatsTile.module.scss';
import clsx from 'clsx';
import filterIcon from 'assets/icons/filter_icon.svg';
import filterIconFilled from 'assets/icons/filter_icon_filled.svg';
import { Tooltip } from 'ant5';
import { InfoCircleOutlined } from '@ant-design/icons';

// I am not sure if it's the best api. Maybe simpler to invert control, pass value as string always and format it outside?
export const StatsTile = ({
  title,
  count,
  loading = true,
  error = false,
  percentage = false,
  maximumFractionDigits = 2,
  tooltip
}: {
  title: string;
  count: number | string | React.ReactChild;
  loading: boolean;
  error?: boolean;
  percentage?: boolean;
  maximumFractionDigits?: number;
  tooltip?: string;
}) => {
  const isString = typeof count === 'string';
  const isNaN = Number.isNaN(count);

  return (
    <div className={s.tile}>
      <div className={s.title}>
        {title || ''}
        {!!tooltip ? (
          <span className={s.icon}>
            <Tooltip title={tooltip} placement="top">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        ) : null}
      </div>
      {loading ? (
        <div className={s.loading} />
      ) : (
        <div className={s.count}>
          {error || count === null || isNaN
            ? EMPTY
            : React.isValidElement(count)
              ? count
              : isString
                ? count
                : `${count.toLocaleString('en-US', { maximumFractionDigits })}${percentage ? '%' : ''}`}
        </div>
      )}
    </div>
  );
};

export const StatsButton = ({
  title,
  count,
  loading = true,
  error = false,
  percentage = false,
  maximumFractionDigits = 2,
  isActive = false,
  onClick
}: {
  title: string;
  count: number | string;
  loading: boolean;
  error?: boolean;
  percentage?: boolean;
  maximumFractionDigits?: number;
  isActive: boolean;
  onClick: () => void;
}) => {
  const isString = typeof count === 'string';
  const isNaN = Number.isNaN(count);

  return (
    <button
      className={clsx(s.tile, s.tile_button, { [s.tile_button_active]: isActive })}
      onClick={onClick}
    >
      <div className={s.title_wrapper}>
        <div className={s.title}>{title || ''}</div>
        <img className={s.tile_icon} src={isActive ? filterIconFilled : filterIcon} alt="" />
      </div>
      {loading ? (
        <div className={s.loading} />
      ) : (
        <div className={s.count}>
          {error || count === null || isNaN
            ? EMPTY
            : isString
              ? count
              : `${count.toLocaleString('en-US', { maximumFractionDigits })}${percentage ? '%' : ''}`}
        </div>
      )}
    </button>
  );
};

export const CustomStatsTile = ({
  title,
  content,
  loading = true,
  error = false,
  isActive = false,
  className
}: {
  title: string;
  content: ReactElement;
  loading: boolean;
  error?: boolean;
  isActive?: boolean;
  className?: string;
}) => {
  return (
    <div className={clsx(s.tile, className, { [s.tile_button_active]: isActive })}>
      <div className={s.title_wrapper}>
        <div className={s.title}>{title || ''}</div>
        <img className={s.tile_icon} src={isActive ? filterIconFilled : filterIcon} alt="" />
      </div>
      {loading ? <div className={s.loading} /> : <div className={s.content}>{content}</div>}
    </div>
  );
};
