import React from 'react';
import s from './Chargebacks.module.scss';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import {
  getCurrentPeriod,
  transformCalendarValueToPeriodWeekYear
} from 'components/PeriodCalendar/helpers';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { range } from 'lodash-es';
import { useQueryParam, withDefault, JsonParam } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { Graph } from './components/Graph/Graph';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Table } from './components/Table/Table';

const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2024;

const getDefaultPeriod = () => {
  const { week } = getCurrentPeriod(true);
  const end = week;
  return { start: 1, end };
};
const defaultPeriod = getDefaultPeriod();

const DEFAULT_PERIOD_STATE: CalendarValue = {
  mode: Mode.PERIOD_WEEK,
  isRange: true,
  years: [CURRENT_YEAR],
  ...defaultPeriod
};

export const Chargebacks = () => {
  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, DEFAULT_PERIOD_STATE)
  );

  const fiscalCalendarWeeks = useDeepCompareMemo(() => {
    return transformCalendarValueToPeriodWeekYear(period, true);
  }, [period]);

  return (
    <div>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>Chargebacks</PageHeader>
        </div>
        <div className={s.page_filters}>
          <PeriodCalendar
            value={period}
            onChange={setPeriod}
            years={
              STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
            }
            // If we have more years, we want to allow to select prev periods, else nope
            pastOnly={STARTING_YEAR === CURRENT_YEAR}
          />
          <AlloyButton size="large" type="secondary">
            Export
          </AlloyButton>
        </div>
      </div>
      <Graph fiscalCalendarWeeks={fiscalCalendarWeeks} />
      <Table fiscalCalendarWeeks={fiscalCalendarWeeks} period={period} />
    </div>
  );
};
