import React, { useMemo } from 'react';
import { TradingPartner } from '../../../../TradingPartnerViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import s from './ShipToViewTab.module.scss';
import { ViewTable } from 'components/Common/ViewMode/ViewTable/ViewTable';
import { DeliveryDestination } from 'pages/ShipToPage/components/BulkAddStoreModal/components/DownloadCurrentShipTos';
import clsx from 'clsx';

export const ShipToViewTab = ({ tradingPartner }: { tradingPartner: TradingPartner }) => {
  const activeCount = useMemo(
    () => tradingPartner?.retailerDeliveryDestinations?.filter((rdd) => rdd?.active).length ?? 0,
    [tradingPartner]
  );
  const totalCount = useMemo(
    () => tradingPartner?.retailerDeliveryDestinations?.length ?? 0,
    [tradingPartner]
  );

  const shipToContent = (
    <ViewTable<DeliveryDestination>
      data={
        tradingPartner.retailerDeliveryDestinations
          ?.filter((rdd): rdd is DeliveryDestination => !!rdd)
          .sort((rdd1, rdd2) =>
            rdd1.active === rdd2.active
              ? (rdd1.externalId ?? '').localeCompare(rdd2.externalId ?? '')
              : rdd1.active
                ? -1
                : 1
          ) || []
      }
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          classname: s.name_column,
          withTooltip: true
        },
        {
          title: 'External ID',
          dataIndex: 'externalId',
          classname: s.external_id_column,
          withTooltip: true
        }
      ]}
      rowClassName={(record) => clsx({ [s.inactive]: !record?.active })}
    />
  );

  return (
    <ViewRow
      title={
        <div>
          <div>Ship-To</div>
          <div>
            {totalCount}
            {totalCount > 0 ? ` (${activeCount} Active, ${totalCount - activeCount} Inactive)` : ''}
          </div>
        </div>
      }
      content={shipToContent}
      bordered={false}
    />
  );
};
